import { Route } from '@angular/router';

import { CheckInCheckOutComponent } from 'app/layout/common/check-in-check-out/check-in-check-out.component';

export const CheckInCheckOutRoutes: Route[] = [
    {
        path: 'check-out',
        component: CheckInCheckOutComponent,
    },
];
