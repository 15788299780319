import { Injectable } from '@angular/core';

import { AutenticacaoUsuarioObservable } from 'app/shared/observables';
import { CookieService, IdiomaService } from 'app/core/services';
import { Router } from '@angular/router';
import { IdiomaSiglaEnum, RotaRedirecionamentoEnum } from 'app/shared/enums';
import { UsuarioLogadoInterface, InfoInterface } from 'app/shared/interfaces';



@Injectable({
    providedIn: 'root',
})
export class LoginAutomaticoService {
    rotaRedirecionamentoEnum = RotaRedirecionamentoEnum;

    constructor(
        private router: Router,    
        private cookieService: CookieService,
        private idiomaService: IdiomaService,
        private autenticacaoUsuarioObservable: AutenticacaoUsuarioObservable,
    ) {}

    iniciarConfiguracoes(info: InfoInterface): void {
        const usuarioLogado: UsuarioLogadoInterface = {
            id: info.usuario.id,
            nome: info.usuario.nome,
            sobrenome: info.usuario.sobrenome,
            email: info.usuario.email,
            categoria: info.usuario.categoria,
            arquivado: false,
            idioma: info.usuario.idioma,
            obrigar_resetar_senha: false,
            usuario_unidade: {
                id: info.usuario.usuario_unidade.id,
                permite_cadastro_usuario: info.usuario.usuario_unidade.permite_cadastro_usuario,
            },
        };

        this.alterarIdioma(info.usuario.idioma);
        localStorage.setItem('hash_token', info.token.refresh_token);
        this.autenticacaoUsuarioObservable.setInformacoesAutenticacaoUsuario = usuarioLogado;

        localStorage.setItem('accessToken', info.token.access_token);
        localStorage.setItem('usuarioLogado', JSON.stringify(usuarioLogado));
        
        this.cookieService.definirCookie('unidadeId', info.unidade.id);
        this.cookieService.definirCookie('clienteId', info?.cliente?.id);

    }

    redirecionarPagina(info: InfoInterface): void {
        if (info.estrutura.tipo === this.rotaRedirecionamentoEnum.SILO) {
            this.router.navigateByUrl(
                `/armazenagem/visualizacao/visao-detalhada-silo/principal/${info.estrutura.estrutura_armazenagem_divisao_id}`
            );
        }

        if (info.estrutura.tipo === this.rotaRedirecionamentoEnum.ARMAZEM) {
            if (info.estrutura.estrutura_armazenagem_divisao_id) {
                this.router.navigateByUrl(
                    `/armazenagem/visualizacao/visao-detalhada-armazem/principal/${info.estrutura.id}/${info.estrutura.estrutura_armazenagem_divisao_id}`
                );
            } else {
                this.router.navigateByUrl(
                    `/armazenagem/visualizacao/visao-detalhada-armazem/principal/${info.estrutura.id}`
                );
            }
        }
    }

    private alterarIdioma(sigla: IdiomaSiglaEnum): void {
        this.idiomaService.alterarIdioma(sigla || IdiomaSiglaEnum.PORTUGUES);
    }
}
