import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { SiloAneisInterface, SiloPropriedadeInterface } from 'app/shared/interfaces';
import { IDesenhaAneisVisaoSuperiorService, IDesenhaPendulosVisaoSuperiorService } from 'app/shared/services';

@Component({
    selector: 'app-visao-superior-silo',
    templateUrl: './visao-superior-silo.component.html',
    styleUrls: ['./visao-superior-silo.component.scss'],
})
export class VisaoSuperiorSiloComponent implements OnChanges {
    @Output() enviaStatusConfiguracaoPendulos = new EventEmitter<any>();
    @Input() cabos: any[];
    @Input() aneis: SiloAneisInterface[];
    @Input() propriedadesSilo: SiloPropriedadeInterface;
    @Input() comunicacaoPendulos: string;
    @Input() caboCentral;
    constructor(
        private desenhaPendulosVisaoSuperiorService: IDesenhaPendulosVisaoSuperiorService,
        private desenhaAneisVisaoSuperiorService: IDesenhaAneisVisaoSuperiorService
    ) {}

    ngOnChanges(): void {
        this.desenhaPendulos(this.cabos);
        this.desenhaAneis();
    }

    desenhaPendulos(pendulos): void {
        const desenharPendulos = this.desenhaPendulosVisaoSuperiorService.execute(pendulos, this.comunicacaoPendulos);
        this.enviaStatusConfiguracaoPendulos.emit(desenharPendulos);
    }

    desenhaAneis(): void {
        this.desenhaAneisVisaoSuperiorService.execute(this.aneis, this.propriedadesSilo?.diametro ?? 0);
    }
}
