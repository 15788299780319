<mat-form-field [floatLabel]="'always'" class="w-full text-[#535767] mt-5" (click)="abrirCalendario()">
    <mat-label>{{ "DATA_HORA" | transloco }}</mat-label>
    <input
        [formControl]="controleDataHora"
        matInput
        type="text"
        ngxDaterangepickerMd
        [locale]="locale"
        (click)="abrirCalendario($event)"
        [singleDatePicker]="true"
        [linkedCalendars]="true"
        [timePicker]="true"
        [timePickerSeconds]="exibeSegundos"
        [timePickerIncrement]="1"
        [showCancel]="true"
        [timePicker24Hour]="true"
        [placeholder]="placeholder"
        required
    />

    <button
        type="button"
        class="ngx-daterangepicker-action waves-effect waves-light btn w-7 h-7"
        (click)="abrirCalendario($event)"
    ></button>
    <mat-error *ngIf="controleDataHora.hasError('required')" class="flex mt-3">
        <mat-icon class="icon-size-3 mr-1 text-[#A44040]" svgIcon="heroicons_solid:information-circle"> </mat-icon>
        {{ "PREENCHE_CAMPO" | transloco }}
    </mat-error>
</mat-form-field>
