export const MENUS_GESTAO_UNIDADE_ES = [
    {
        menu: 'GESTIÓN DE LAS UNIDADES',
        description: 'GESTIÓN DE LAS UNIDADES',
        link: 'gestao-unidades/visualizacao/painel-geral',
        icon: 'icon-module-gestao-unidade',
        separator: false,
        permission: true,
        groups: [
            {
                title: 'Visualización',
                icon: 'grid_view',
                separator: true,
                items: [
                    {
                        menu: 'Panel General',
                        icon: 'dashboard',
                        link: 'gestao-unidades/visualizacao/painel-geral',
                    },
                ],
            },
            {
                title: 'Almacenamiento y Secadora',
                icon: 'icon-operacional-armazenagem-secador',
                separator: true,
                items: [
                    {
                        menu: 'Rentabilidad',
                        icon: 'icon-gestao-unidades-rentabilidade',
                        link: 'gestao-unidades/armazenagem-secador/rentabilidade',
                    },
                    {
                        menu: 'Calidad',
                        icon: 'icon-gestao-unidades-qualidade',
                        link: 'gestao-unidades/armazenagem-secador/qualidade',
                    },
                    {
                        menu: 'Operacional',
                        icon: 'icon-gestao-unidades-operacional',
                        link: 'gestao-unidades/armazenagem-secador/operacional',
                    },
                ],
            },
            {
                title: 'Recepción y Expedición',
                icon: 'icon-menu-expedicao-recebimento-truck-azul',
                separator: true,
                items: [
                    {
                        menu: 'Panel y listado',
                        icon: 'icon-gestao-unidades-amostragem-classificacao-painel-listagem',
                        link: 'gestao-unidades/recebimento-expedicao/painel-listagem',
                    },
                ],
            },
            {
                title: 'Muestra y clasificación',
                icon: 'icon-gestao-unidades-amostragem-classificacao',
                separator: true,
                items: [
                    {
                        menu: 'Panel y listado',
                        icon: 'icon-gestao-unidades-amostragem-classificacao-painel-listagem',
                        link: 'gestao-unidades/amostragem-classificacao/painel-listagem',
                    },
                    {
                        menu: 'Categorías de muestra',
                        icon: 'icon-gestao-unidades-amostragem-classificacao-categorias-amostras',
                        link: 'gestao-unidades/amostragem-classificacao/categorias-amostras',
                    },
                ],
            },
            {
                title: 'Parametrizaciones',
                icon: 'icon-gestao-unidades-parametrizacoes',
                separator: true,
                items: [
                    {
                        menu: 'Parametrizaciones',
                        icon: 'icon-gestao-unidades-parametrizacoes-ordem-de-servico',
                        link: 'gestao-unidades/parametrizacoes/lista-parametrizacoes',
                    },
                ],
            },
            {
                title: 'Eficiencia',
                icon: 'icon-gestao-unidades-eficiencia',
                separator: true,
                items: [
                    {
                        menu: 'Medidores de flujo',
                        icon: 'icon-gestao-unidades-eficiencia-medidores-de-fluxo',
                        link: 'gestao-unidades/eficiencia/medidores-fluxo',
                    },
                    {
                        menu: 'Procesos',
                        icon: 'icon-gestao-unidades-eficiencia-processos',
                        link: 'gestao-unidades/eficiencia/processos',
                    },
                    {
                        menu: 'Registro de procesos',
                        icon: 'icon-gestao-unidades-eficiencia-processos',
                        link: 'gestao-unidades/eficiencia/cadastro-processos',
                    },
                ],
            },
        ],
    },
];
