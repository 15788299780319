import { Injectable } from '@angular/core';
import { EquipamentoInformacoesSiloInterface, ModalComunicacaoSensoresface } from 'app/shared/interfaces';
export abstract class IMontaListaComunicacaoSensoresService {
    abstract execute(
        sensoresEquipamentos: any[],
        equipamentosEstrutura: EquipamentoInformacoesSiloInterface[]
    ): ModalComunicacaoSensoresface[];
}

@Injectable({ providedIn: 'root' })
export class MontaListaComunicacaoSensoresService implements IMontaListaComunicacaoSensoresService {
    execute(
        sensoresEquipamentos: any[],
        equipamentosEstrutura: EquipamentoInformacoesSiloInterface[]
    ): ModalComunicacaoSensoresface[] {
        let listaStatusComunicacao = [];
        sensoresEquipamentos.forEach((sensor) => {
            equipamentosEstrutura.forEach((equipamento) => {
                if (sensor.equipamento_id === equipamento.id) {
                    listaStatusComunicacao.push({
                        equipamento_id: equipamento.id,
                        sensor: sensor.nome,
                        instante: equipamento.equipamento_comunicacao.instante,
                        status: equipamento.equipamento_comunicacao.status,
                    });
                }
            });
        });
        return listaStatusComunicacao;
    }
}
