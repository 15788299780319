<div class="flex flex-col mt-4 mb-6 mx-1">
    <div class="text-[#0b0c0e] wl-cor-primaria flex mb-2">
        <mat-icon class="p-[2px] ml-[-3px]" [svgIcon]="'icon-grao'"></mat-icon>
        <span class="my-1 ml-2">{{ "ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.GRAO" | transloco }}</span>
    </div>
    <div class="p-2.5 flex justify-between text-xs sm:text-base items-center rounded bg-[#f8fafb]">
        <div class="text-xs sm:text-base">{{ "ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.GRAO" | transloco }}</div>
        <ng-container *ngIf="graoSelecionado?.nome">
            <span class="text-[#0b0c0e] text-xs sm:text-base">{{ graoSelecionado?.nome ?? "-" }}</span>
        </ng-container>
        <ng-container *ngIf="!graoSelecionado?.nome">
            <span class="opacity-50 text-xs sm:text-base">{{ "ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.NENHUM" | transloco }}</span>
        </ng-container>
    </div>

    <div style="min-height: 64px" class="px-2.5 flex justify-between items-center rounded text-xs sm:text-base">
        <div class="lg:w-[38%] flex">{{ "ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.AMOSTRAGENS" | transloco }}</div>
        <div class="flex flex-col max-h-72 overflow-y-auto">
            <ng-container *ngFor="let amostra of amostragem">
                <div class="flex flex-row min-w-36 justify-center lg:mb-1">
                    <span class="flex flex-row justify-center">
                        <p class="w-auto flex items-center">
                            {{ amostra?.data_amostra | date : "dd/MM/yyyy" }}
                        </p>
                        <ng-container *ngIf="amostra?.data_amostra">
                            <ng-container *ngIf="verificaValidadeDeAmostragemService.execute(amostra?.data_amostra)">
                                <p
                                    class="flex items-center"
                                    matTooltipPosition="above"
                                    matTooltip="{{ 'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.TOOLTIP_AMOSTRAGEM_RECENTE' | transloco }}"
                                    matTooltipClass="tooltip-padrao tooltip-amostragem"
                                >
                                    <mat-icon class="text-[15px] text-[#34901c]"> check_circle </mat-icon>
                                </p>
                            </ng-container>

                            <ng-container *ngIf="!verificaValidadeDeAmostragemService.execute(amostra?.data_amostra)">
                                <p
                                    class="flex items-center"
                                    matTooltipPosition="above"
                                    matTooltip="{{ 'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.TOOLTIP_FAVOR_REALIZAR_AMOSTRAGEM' | transloco }}"
                                    matTooltipClass="tooltip-padrao tooltip-amostragem"
                                >
                                    <mat-icon class="text-[15px] text-[#a44040] blink"> warning </mat-icon>
                                </p>
                            </ng-container>
                        </ng-container>
                    </span>
                </div>
                <div class="flex flex-row text-sm justify-between mb-4">
                    <div class="flex flex-col text-center mx-1 my-auto">
                        <span class="text-[#0b0c0e]" *ngIf="!graoSelecionado?.nome">
                            {{ retornaNomeGrao(amostra?.produto_id) ?? amostra?.produto?.nome }}
                        </span>
                    </div>
                    <div class="flex flex-col wl-cor-primaria text-center mx-1">
                        <p
                            matTooltipPosition="above"
                            matTooltip="{{ 'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.TOOLTIP_UMIDADE_GRAO' | transloco }}"
                            matTooltipClass="tooltip-padrao tooltip-amostragem"
                        >
                            {{ "ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.UG" | transloco }} (%)
                        </p>
                        <span
                            class="text-[#0b0c0e]"
                            [ngClass]="amostra?.umidade_controle ? '' : 'opacity-50 text-center rounded-md'"
                        >
                            {{ amostra?.umidade_controle ? (amostra?.umidade_controle | number : "1.1-1") : "-" }}
                        </span>
                    </div>
                    <div class="flex flex-col wl-cor-primaria text-center mx-1">
                        <p
                            matTooltipPosition="above"
                            matTooltip="{{ 'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.TOOLTIP_AVARIADOS' | transloco }}"
                            matTooltipClass="tooltip-padrao tooltip-amostragem"
                        >
                            {{ "ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.AVR" | transloco }} (%)
                        </p>
                        <span
                            class="text-[#0b0c0e]"
                            [ngClass]="amostra?.avariados_controle ? '' : 'opacity-50 text-center rounded-md'"
                        >
                            {{ amostra?.avariados_controle ? (amostra?.avariados_controle | number : "1.1-1") : "-" }}
                        </span>
                    </div>
                    <div class="flex flex-col wl-cor-primaria text-center mx-1">
                        <p
                            matTooltipPosition="above"
                            matTooltip="{{ 'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.TOOLTIP_IMPUREZAS' | transloco }}"
                            matTooltipClass="tooltip-padrao tooltip-amostragem"
                        >
                            {{ "ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.IMP" | transloco }} (%)
                        </p>
                        <span
                            class="text-[#0b0c0e]"
                            [ngClass]="amostra?.impureza_controle ? '' : 'opacity-50 text-center rounded-md'"
                        >
                            {{ amostra?.impureza_controle ? (amostra.impureza_controle | number : "1.1-1") : "-" }}
                        </span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
