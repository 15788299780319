<div class="flex flex-col">
    <div class="flex flex-col justify-center content-center gap-5 p-5 mt-7">
        <span class="text-center wl-cor-primaria text-xl">Funcionalidade não disponível na versão mobile.</span>
        <div class="text-center">
            <span class="text-lg text-[#2d303c]">Para acessar a página </span>
            <span class="text-lg wl-cor-primaria" *ngIf="menuSelecionado">{{ menuSelecionado }}</span>
            <span class="text-lg wl-cor-primaria" *ngIf="menuSelecionado"> - </span>
            <span class="text-lg wl-cor-primaria" *ngIf="subMenuSelecionado">{{ subMenuSelecionado }}</span>
            <span class="text-lg text-[#2d303c]">, entre no sistema Ceres pelo seu computador.</span>
        </div>
    </div>
    <button
        class="px-6 py-4 bg-[#FCFCFC] border-x border-[#535767] rounded-md m-auto mb-4 mt-15"
        (click)="dialogRef.close()"
    >
        Fechar
    </button>
</div>
