import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OpcoesExportarRelatorioEnum } from 'app/shared/enums';

@Component({
    selector: 'app-botao-exportar',
    templateUrl: './botao-exportar.component.html',
    styleUrls: ['./botao-exportar.component.scss'],
})
export class BotaoExportarComponent {
    @Input() opcoesExportar: OpcoesExportarRelatorioEnum[];
    @Input() carregando: boolean = false;

    @Output() exportar = new EventEmitter<OpcoesExportarRelatorioEnum>();
}
