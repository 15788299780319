import { Injectable } from '@angular/core';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable } from 'rxjs';

import { IApiTransportInterface } from 'app/core/interfaces/';
import { HttpClient } from '@angular/common/http';
import { EstoqueTotalInterface } from 'app/modules/armazenagem/visualizacao/painel-geral/interfaces';
export abstract class IEstoqueTotalController {
    abstract buscarEstoqueTotal(): Observable<EstoqueTotalInterface>;
}

@Injectable({
    providedIn: 'root',
})
export class EstoqueTotalController implements IEstoqueTotalController {
    private transport: IApiTransportInterface;

    constructor(private http: HttpClient, private apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/armazenagem');
    }

    buscarEstoqueTotal(): Observable<any> {
        return this.http.get(`${this.transport.path}/painel-estoque-total`);
    }
}
