<div class="text-[#0b0c0e] wl-cor-primaria flex mb-2">
    <mat-icon class="p-[2px] ml-[-3px]" [svgIcon]="'icon-sensores'"></mat-icon>
    <span class="my-1 ml-2">{{ "SENSORES_INFORMACOES_GERAIS_COMPONENT.SENSORES" | transloco }}</span>
</div>

<!-- TEMPERATURA GERAL -->
<ng-container
    *ngIf="verificaTipoSensorExistente(tipoSensorEnum.DIGITAL) && verificaTipoSensorExistente(tipoSensorEnum.TERMOPAR)"
>
    <div class="pl-2 flex justify-between items-center text-xs sm:text-base rounded h-[54px] bg-[#f8fafb]">
        <div class="lg:w-[75%] w-[135px] sm:w-full">{{ "SENSORES_INFORMACOES_GERAIS_COMPONENT.TEMP_GERAL" | transloco }}</div>
        <span class="flex flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
            {{ "SENSORES_INFORMACOES_GERAIS_COMPONENT.MIN" | transloco }}
            <span
                class="rounded-md sm:min-w-[56.5px] flex justify-center pt-1 pb-1"
                [ngClass]="
                    sensores?.temperatura?.geral?.min
                        ? 'pl-3 pr-3'
                        : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
                "
                [ngStyle]="{
                    background: defineCoresTemperatura(sensores?.temperatura?.geral?.min)?.fundo,
                    color: defineCoresTemperatura(sensores?.temperatura?.geral?.min)?.cor
                }"
            >
                {{ sensores?.temperatura?.geral?.min ? (sensores?.temperatura?.geral?.min | number : "1.1-1") : "-" }}
            </span>
        </span>

        <span class="flex flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
            {{ "SENSORES_INFORMACOES_GERAIS_COMPONENT.MED" | transloco }}
            <span
                class="rounded-md sm:min-w-[56.5px] flex justify-center text-center pt-1 pb-1"
                [ngClass]="
                    sensores?.temperatura?.geral?.med
                        ? 'pl-3 pr-3'
                        : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
                "
                [ngStyle]="{
                    background: defineCoresTemperatura(sensores?.temperatura?.geral?.med)?.fundo,
                    color: defineCoresTemperatura(sensores?.temperatura?.geral?.med)?.cor
                }"
            >
                {{ sensores?.temperatura?.geral?.med ? (sensores?.temperatura?.geral?.med | number : "1.1-1") : "-" }}
            </span>
        </span>

        <span class="flex flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
            {{ "SENSORES_INFORMACOES_GERAIS_COMPONENT.MAX" | transloco }}
            <span
                class="rounded-md sm:min-w-[56.5px] flex justify-center text-center pt-1 pb-1"
                [ngClass]="
                    sensores?.temperatura?.geral?.max
                        ? 'pl-3 pr-3'
                        : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
                "
                [ngStyle]="{
                    background: defineCoresTemperatura(sensores?.temperatura?.geral?.max)?.fundo,
                    color: defineCoresTemperatura(sensores?.temperatura?.geral?.max)?.cor
                }"
            >
                {{ sensores?.temperatura?.geral?.max ? (sensores?.temperatura?.geral?.max | number : "1.1-1") : "-" }}
            </span>
        </span>
    </div>
</ng-container>
<!-- TEMPERATURA DIGITAL -->
<ng-container *ngIf="verificaTipoSensorExistente(tipoSensorEnum.DIGITAL)">
    <div class="pl-2 flex justify-between items-center text-xs sm:text-base rounded h-[54px]">
        <div class="lg:w-[75%] w-[135px] sm:w-full">{{ "SENSORES_INFORMACOES_GERAIS_COMPONENT.TEMP_DIGITAL" | transloco }}</div>
        <span class="flex flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
            <span class="hidden sm:flex">
                {{ "SENSORES_INFORMACOES_GERAIS_COMPONENT.MIN" | transloco }}
            </span>
            <span
                class="rounded-md sm:min-w-[56.5px] flex justify-center text-center pt-1 pb-1"
                [ngClass]="
                    sensores?.temperatura?.digital?.min !== null
                        ? 'pl-3 pr-3'
                        : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
                "
                [ngStyle]="{
                    background: defineCoresTemperatura(sensores?.temperatura?.digital?.min)?.fundo,
                    color: defineCoresTemperatura(sensores?.temperatura?.digital?.min)?.cor
                }"
            >
                {{
                    sensores?.temperatura?.digital?.min !== null
                        ? (sensores?.temperatura?.digital?.min | number : "1.1-1")
                        : "-"
                }}
            </span>
        </span>

        <span class="flex flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
            <span class="hidden sm:flex">
                {{ "SENSORES_INFORMACOES_GERAIS_COMPONENT.MED" | transloco }}
            </span>
            <span
                class="rounded-md sm:min-w-[56.5px] flex justify-center text-center pt-1 pb-1"
                [ngClass]="
                    sensores?.temperatura?.digital?.med !== null
                        ? 'pl-3 pr-3'
                        : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
                "
                [ngStyle]="{
                    background: defineCoresTemperatura(sensores?.temperatura?.digital?.med)?.fundo,
                    color: defineCoresTemperatura(sensores?.temperatura?.digital?.med)?.cor
                }"
            >
                {{
                    sensores?.temperatura?.digital?.med !== null
                        ? (sensores?.temperatura?.digital?.med | number : "1.1-1")
                        : "-"
                }}
            </span>
        </span>

        <span class="flex flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
            <span class="hidden sm:flex">
                {{ "SENSORES_INFORMACOES_GERAIS_COMPONENT.MAX" | transloco }}
            </span>
            <span
                class="rounded-md sm:min-w-[56.5px] flex justify-center text-center pt-1 pb-1"
                [ngClass]="
                    sensores?.temperatura?.digital?.max !== null
                        ? 'pl-3 pr-3'
                        : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
                "
                [ngStyle]="{
                    background: defineCoresTemperatura(sensores?.temperatura?.digital?.max)?.fundo,
                    color: defineCoresTemperatura(sensores?.temperatura?.digital?.max)?.cor
                }"
            >
                {{
                    sensores?.temperatura?.digital?.max !== null
                        ? (sensores?.temperatura?.digital?.max | number : "1.1-1")
                        : "-"
                }}
            </span>
        </span>
    </div>
</ng-container>

<!-- TEMPERATURA TERMOPAR -->
<ng-container *ngIf="verificaTipoSensorExistente(tipoSensorEnum.TERMOPAR)">
    <div class="pl-2 flex justify-between items-center text-xs sm:text-base rounded h-[54px] bg-[#f8fafb]">
        <div class="lg:w-[75%] w-[135px] sm:w-full">
            {{ "SENSORES_INFORMACOES_GERAIS_COMPONENT.TEMP_TERMOPAR" | transloco }}
        </div>

        <span class="flex flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
            <span class="hidden sm:flex">
                {{ "SENSORES_INFORMACOES_GERAIS_COMPONENT.MIN" | transloco }}
            </span>
            <span
                class="rounded-md sm:min-w-[56.5px] flex justify-center text-center pt-1 pb-1"
                [ngClass]="
                    sensores?.temperatura?.termopar?.min !== null
                        ? 'pl-3 pr-3'
                        : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
                "
                [ngStyle]="{
                    background: defineCoresTemperatura(sensores?.temperatura?.termopar?.min)?.fundo,
                    color: defineCoresTemperatura(sensores?.temperatura?.termopar?.min)?.cor
                }"
            >
                {{
                    sensores?.temperatura?.termopar?.min !== null
                        ? (sensores?.temperatura?.termopar?.min | number : "1.1-1")
                        : "-"
                }}
            </span>
        </span>

        <span class="flex flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
            <span class="hidden sm:flex">
                {{ "SENSORES_INFORMACOES_GERAIS_COMPONENT.MED" | transloco }}
            </span>
            <span
                class="rounded-md sm:min-w-[56.5px] flex justify-center text-center pt-1 pb-1"
                [ngClass]="
                    sensores?.temperatura?.termopar?.med !== null
                        ? 'pl-3 pr-3'
                        : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
                "
                [ngStyle]="{
                    background: defineCoresTemperatura(sensores?.temperatura?.termopar?.med)?.fundo,
                    color: defineCoresTemperatura(sensores?.temperatura?.termopar?.med)?.cor
                }"
            >
                {{
                    sensores?.temperatura?.termopar?.med !== null
                        ? (sensores?.temperatura?.termopar?.med | number : "1.1-1")
                        : "-"
                }}
            </span>
        </span>

        <span class="flex flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
            <span class="hidden sm:flex">
                {{ "SENSORES_INFORMACOES_GERAIS_COMPONENT.MAX" | transloco }}
            </span>
            <span
                class="rounded-md sm:min-w-[56.5px] flex justify-center text-center pt-1 pb-1"
                [ngClass]="
                    sensores?.temperatura?.termopar?.max !== null
                        ? 'pl-3 pr-3'
                        : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
                "
                [ngStyle]="{
                    background: defineCoresTemperatura(sensores?.temperatura?.termopar?.max)?.fundo,
                    color: defineCoresTemperatura(sensores?.temperatura?.termopar?.max)?.cor
                }"
            >
                {{
                    sensores?.temperatura?.termopar?.max !== null
                        ? (sensores?.temperatura?.termopar?.max | number : "1.1-1")
                        : "-"
                }}
            </span>
        </span>
    </div>
</ng-container>

<!-- UMIDADE RELATIVA -->
<ng-container *ngIf="temSensorUmidade && sensores?.umidade?.min">
    <div class="pl-2 flex justify-between items-center text-xs sm:text-base rounded h-[54px]">
        <div class="lg:w-[75%] w-[135px] sm:w-full">
            {{ "SENSORES_INFORMACOES_GERAIS_COMPONENT.UMIDADE_RELATIVA" | transloco }}
        </div>

        <span class="flex flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
            <span class="hidden sm:flex">
                {{ "SENSORES_INFORMACOES_GERAIS_COMPONENT.MIN" | transloco }}
            </span>
            <span
                class="rounded-md sm:min-w-[56.5px] flex justify-center text-center pt-1 pb-1"
                [ngClass]="
                    sensores?.umidade?.min !== null ? 'pl-3 pr-3' : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
                "
                [ngStyle]="{
                    background: defineCoresUmidade(sensores?.temperatura?.termopar?.min)?.fundo,
                    color: defineCoresUmidade(ssensores?.umidade?.min)?.cor
                }"
            >
                {{ sensores?.umidade?.min !== null ? (sensores?.umidade?.min | number : "1.1-1") : "-" }}
            </span>
        </span>

        <span class="flex flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
            <span class="hidden sm:flex">
                {{ "SENSORES_INFORMACOES_GERAIS_COMPONENT.MED" | transloco }}
            </span>
            <span
                class="rounded-md sm:min-w-[56.5px] flex justify-center text-center pt-1 pb-1"
                [ngClass]="
                    sensores?.umidade?.med !== null ? 'pl-3 pr-3' : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
                "
                [ngStyle]="{
                    background: defineCoresUmidade(sensores?.temperatura?.termopar?.med)?.fundo,
                    color: defineCoresUmidade(ssensores?.umidade?.med)?.cor
                }"
            >
                {{ sensores?.umidade?.med !== null ? (sensores?.umidade?.med | number : "1.1-1") : "-" }}
            </span>
        </span>

        <span class="flex flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
            <span class="hidden sm:flex">
                {{ "SENSORES_INFORMACOES_GERAIS_COMPONENT.MAX" | transloco }}
            </span>
            <span
                class="rounded-md sm:min-w-[56.5px] flex justify-center text-center pt-1 pb-1"
                [ngClass]="
                    sensores?.umidade?.max !== null ? 'pl-3 pr-3' : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
                "
                [ngStyle]="{
                    background: defineCoresUmidade(sensores?.temperatura?.termopar?.max)?.fundo,
                    color: defineCoresUmidade(ssensores?.umidade?.max)?.cor
                }"
            >
                {{ sensores?.umidade?.max !== null ? (sensores?.umidade?.max | number : "1.1-1") : "-" }}
            </span>
        </span>
    </div>
</ng-container>
<ng-container>
    <div class="w-full py-3 hidden sm:block" *ngIf="!verificaObjetoVazio(sensorCO2?.co2)">
        <app-sensor-co2-informacoes-gerais
            [sensorCO2]="sensorCO2"
            [equipamentos]="equipamentos"
            [sensoreCO2Equipamentos]="sensoreCO2Equipamentos"
        ></app-sensor-co2-informacoes-gerais>
    </div>
</ng-container>
