import { BehaviorSubject, Observable } from 'rxjs';

export class PossuiDadosSilo3DObservable {
    possuiDadosSilo3D: BehaviorSubject<boolean | null> = new BehaviorSubject(null);

    get possuiDados$(): Observable<boolean> {
        return this.possuiDadosSilo3D.asObservable();
    }

    set possuiDados(value: boolean) {
        this.possuiDadosSilo3D.next(value);
    }
}
