import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
    selector: 'app-barra-progresso',
    templateUrl: './barra-progresso.component.html',
    styleUrls: ['./barra-progresso.component.scss'],
})
export class BarraProgressoComponent implements OnInit, OnChanges {
    @Input() progresso: number;
    @Input() tamanho: number;
    valoresProgresso: number;

    constructor() {}

    ngOnInit() {}

    ngOnChanges(): void {
        const valorProgresso = document.getElementById('valor-progresso');
        if (valorProgresso !== null) {
            this.valoresProgresso = this.normalizarValoresProgresso(this.tamanho, this.progresso);

            if (
                this.valoresProgresso < 0 ||
                this.valoresProgresso === null ||
                this.valoresProgresso === undefined ||
                isNaN(this.valoresProgresso)
            ) {
                this.valoresProgresso = 0;
            }

            if (this.valoresProgresso > 100) {
                this.valoresProgresso = 100;
            }

            if (this.valoresProgresso == 0) {
                valorProgresso.style.marginLeft = '24px';
            }

            if (this.valoresProgresso == 100) {
                valorProgresso.style.marginLeft = '0px';
            }

            valorProgresso.style.width = `${this.valoresProgresso}%`;
        }
    }

    redirecionarPainelGeral(): void {
        localStorage.removeItem('urlAtual');
        const url = location.origin + '/armazenagem/visualizacao/painel-geral';
        window.open(url);
    }

    normalizarValoresProgresso(tamanho: number, progresso: number): number {
        if (tamanho < 0) {
            tamanho = 0;
        }
        return Math.round((progresso * 100) / tamanho);
    }
}
