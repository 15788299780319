import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DataUltimaComunicacaoModule, EscalaCoresTemperaturaModule } from 'app/shared/components/';
import { VisaoSuperiorArmazemComponent } from './visao-superior-armazem.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    imports: [
        CommonModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatSlideToggleModule,
        DataUltimaComunicacaoModule,
        EscalaCoresTemperaturaModule,
        MatIconModule,
        TranslocoModule
    ],
    declarations: [VisaoSuperiorArmazemComponent],
    exports: [VisaoSuperiorArmazemComponent],
})
export class VisaoSuperiorArmazemModule {}
