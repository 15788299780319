import { CategoriaEstruturaArmazenagemEnum } from 'app/shared/enums';

export interface IEstruturaArmazenagemSelectInterface {
    id: string;
    nome: string;
    estrutura_armazenagem: {
        id: string;
        nome: string
        categoria: CategoriaEstruturaArmazenagemEnum;
    };    
}
