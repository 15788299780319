export const MENUS_ARMAZENAGEM_EN = [
    {
        menu: 'STORAGE',
        description: 'STORAGE',
        link: 'armazenagem/visualizacao/painel-geral',
        icon: 'icon-module-armazenagem',
        separator: false,
        permission: true,
        groups: [
            {
                title: 'Visualization',
                icon: 'grid_view',
                separator: true,
                items: [
                    {
                        menu: 'Dashboard',
                        icon: 'dashboard',
                        link: 'armazenagem/visualizacao/painel-geral',
                    },
                    {
                        menu: 'Silos',
                        icon: 'icon-menu-silos',
                        link: 'armazenagem/visualizacao/visao-geral-silos',
                    },
                    {
                        menu: 'Warehouses',
                        icon: 'icon-menu-armazens',
                        link: 'armazenagem/visualizacao/visao-geral-armazens',
                    },
                ],
            },
            {
                title: 'Analysis',
                icon: 'query_stats',
                separator: true,
                items: [
                    {
                        menu: 'Reports',
                        icon: 'insert_chart',
                        childrensItems: [
                            {
                                menu: 'Thermometry',
                                icon: 'device_thermostat',
                                link: 'armazenagem/analise/relatorios/termometria',
                            },
                            {
                                menu: 'Aeration - Operation',
                                icon: 'icon-menu-aeracao-tempo',
                                link: 'armazenagem/analise/relatorios/aeracao-funcionamento',
                            },
                            {
                                menu: 'Aeration - Hygroscopic Eq.',
                                icon: 'waterfall_chart',
                                link: 'armazenagem/analise/relatorios/aeracao-equilibrio-higroscopico',
                            },
                            {
                                menu: 'Plenum sensor',
                                icon: 'icon-menu-sensor-plenum',
                                link: 'armazenagem/analise/relatorios/sensor-plenum',
                            },                           
                            // {
                            //     menu: 'Volumetry',
                            //     icon: 'icone-volumetria-white',
                            //     link: 'armazenagem/analise/relatorios/volumetria',
                            // },
                            {
                                menu: 'CO<sub>2</sub> sensor',
                                icon: 'icon-menu-leitura-co2',
                                link: 'armazenagem/analise/relatorios/sensor-co2',
                            },
                            {
                                menu: 'Volume meter 3D',
                                icon: 'icon-menu-volumetria-3d',
                                link: 'armazenagem/analise/relatorios/medidor-volume-3d',
                            },
                        ],
                    },
                    {
                        menu: 'Graphs',
                        icon: 'area_chart',
                        childrensItems: [
                            {
                                menu: 'Storage unit',
                                icon: 'icon-menu-unidade-armazenadora',
                                link: 'armazenagem/analise/graficos/unidade-armazenadora',
                            },
                            {
                                menu: 'Rainfall measurement',
                                icon: 'icon-menu-pluviometria',
                                link: 'armazenagem/analise/graficos/pluviometria',
                            },
                        ],
                    },
                    {
                        menu: 'Events',
                        icon: 'article',
                        link: 'armazenagem/analise/eventos',
                    },
                ],
            },
            {
                title: 'Registration',
                icon: 'article',
                separator: true,
                items: [
                    {
                        menu: 'Sampling',
                        icon: 'icon-menu-amostragens',
                        link: 'armazenagem/cadastro/amostragens/principal',
                    },
                    {
                        menu: 'Grains',
                        icon: 'grain',
                        link: 'armazenagem/cadastro/graos',
                    },
                    {
                        menu: 'Programs',
                        icon: 'border_outer',
                        link: 'armazenagem/cadastro/programas',
                    },
                    {
                        menu: 'Rules',
                        icon: 'margin',
                        link: 'armazenagem/cadastro/regras',
                    },
                ],
            },
            {
                title: 'Configuration',
                icon: 'settings',
                separator: true,
                items: [
                    {
                        menu: 'Inventory',
                        icon: 'icone-menu-estoque',
                        link: 'armazenagem/configuracao/estoque',
                    },
                ],
            },
        ],
    },
];
