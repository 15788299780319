import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BotaoExportarComponent } from './botao-exportar.component';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    imports: [CommonModule, MatIconModule, TranslocoModule, MatButtonModule, MatMenuModule, MatProgressSpinnerModule],
    declarations: [BotaoExportarComponent],
    exports: [BotaoExportarComponent],
})
export class BotaoExportarModule {}
