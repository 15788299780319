import { Injectable } from '@angular/core';
import { GraficoOpcoes } from 'app/shared/interfaces';
import { horariosGraficoExpansivoProcessos } from 'app/shared/constants';
export abstract class IDefineGraficoHorarioProcessosService {
    abstract execute(series: number[], porcentagem: number[], anotacao?: boolean): Partial<GraficoOpcoes>;
}

@Injectable({ providedIn: 'root' })
export class DefineGraficoHorarioProcessosService implements IDefineGraficoHorarioProcessosService {
    execute(series: number[], porcentagem: number[], anotacao?: boolean): Partial<GraficoOpcoes> {
        const paletaCores = porcentagem.map((valor) => {
            switch (true) {
                case valor === null || valor === undefined:
                    return '#EAEAEA';
                case valor <= 25:
                    return '#F47560';
                case valor <= 50:
                    return '#F8DB42';
                case valor <= 75:
                    return '#E8A838';
                case valor <= 100:
                    return '#32BC7A';
                default:
                    return '#EAEAEA';
            }
        });

        const labels = horariosGraficoExpansivoProcessos;

        return {
            series: series,
            chart: {
                width: '480px',
                type: 'pie',
            },
            labels: labels,
            theme: {
                monochrome: {
                    enabled: false,
                },
            },
            dataLabels: {
                formatter: function (val, opts) {
                    const name = opts.w.globals.labels[opts.seriesIndex];
                    let porcentagemValor = porcentagem[opts.seriesIndex];
                    if (porcentagemValor === null || porcentagemValor === undefined) {
                        porcentagemValor = 0;
                    }
                    if (anotacao) {
                        return `${porcentagemValor}%`;
                    } else {
                        return `${name}`;
                    }
                },
                dropShadow: {
                    enabled: false,
                },
                style: {
                    colors: ['#535767'],
                },
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        offset: anotacao ? 55 : 0,
                    },
                    customScale: 0.90,
                },
            },
            grid: {
                padding: {
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                },
            },
            legend: {
                show: false,
            },
            tooltip: {
                enabled: false,
            },
            colors: paletaCores,
        } as Partial<GraficoOpcoes>;
    }
}
