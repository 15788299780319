import { FormBuilder, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import { MatDialog } from '@angular/material/dialog';

import { forkJoin, Subject, takeUntil } from 'rxjs';

import { editorTextoConfig } from 'app/layout/common/check-in-check-out/data/editor-texto-config';
import { ICheckInCheckOutController } from 'app/layout/common/check-in-check-out/controllers';
import { tituloPagina } from 'app/layout/common/check-in-check-out/data/titulo-pagina';
import { ModalConfirmacaoComponent } from 'app/shared/components';
import { configuracaoTamanhoModal } from 'app/shared/constants';
import { SetaTituloPaginaService } from 'app/shared/services';

import {
    AtividadesCheckInCheckOutInterface,
    ContatosCheckInCheckOutInterface,
} from 'app/layout/common/check-in-check-out/interfaces';
import { Router } from '@angular/router';
import { CheckInCheckOutObservable } from 'app/shared/observables';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
    selector: 'app-check-in-check-out',
    templateUrl: './check-in-check-out.component.html',
    styleUrls: ['./check-in-check-out.component.scss'],
})
export class CheckInCheckOutComponent implements OnInit, OnDestroy {
    @ViewChild('atividadeSelect') atividadeSelect: ElementRef<HTMLInputElement>;

    permitirClienteVisualizarControl: UntypedFormControl = new UntypedFormControl();
    separatorKeysCodes: number[] = [ENTER, COMMA];
    atividades: AtividadesCheckInCheckOutInterface[];
    contatos: ContatosCheckInCheckOutInterface[];
    editorTextoConfig = editorTextoConfig;
    atividadesSelecionadas: string[] = [];
    formGroup: FormGroup;

    private unsubscribeAll: Subject<void> = new Subject<void>();

    constructor(
        private checkInCheckOutController: ICheckInCheckOutController,
        private checkInCheckOutObservable: CheckInCheckOutObservable,
        private breakpointObserver: BreakpointObserver,
        private setaTitulo: SetaTituloPaginaService,
        private formBuilder: FormBuilder,
        private router: Router,

        readonly dialog: MatDialog
    ) {
        this.setaTitulo.setaTituloPaginaService(tituloPagina);
    }

    ngOnInit(): void {
        forkJoin({
            atividades: this.checkInCheckOutController.buscaAtividade(),
            contatos: this.checkInCheckOutController.buscaContato(),
        }).subscribe({
            next: (result) => {
                this.atividades = result.atividades;
                this.contatos = result.contatos;

                this.inicializarFormulario();
            },
            error: (error) => {
                console.error(error);
            },
        });
    }

    adicionarAtividade(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();

        if (value) {
            this.atividadesSelecionadas.push(value);
        }
    }

    removerAtividade(atividade: string): void {
        const index = this.atividadesSelecionadas.indexOf(atividade);

        if (this.atividadesSelecionadas.indexOf(atividade) >= 0) {
            this.atividadesSelecionadas.splice(index, 1);
        }
    }

    selecionarAtividades(event: MatAutocompleteSelectedEvent): void {
        this.atividadesSelecionadas.push(event.option.viewValue);
        this.atividadeSelect.nativeElement.value = '';

        this.formGroup.get('atividade').setValue(this.atividadesSelecionadas);
    }

    exibirAtividadesDisponiveis(): AtividadesCheckInCheckOutInterface[] {
        return this.atividades?.filter((atividade) => !this.atividadesSelecionadas.includes(atividade.nome));
    }

    abrirModalConfirmacao(): void {
        const mobile = this.breakpointObserver.isMatched('(max-width: 600px)');

        const dialogRef = this.dialog.open(ModalConfirmacaoComponent, {
            width: mobile
                ? configuracaoTamanhoModal.confirmacao.mobile.width
                : configuracaoTamanhoModal.confirmacao.desktop.width,
            minHeight: mobile
                ? configuracaoTamanhoModal.confirmacao.mobile.height
                : configuracaoTamanhoModal.confirmacao.desktop.height,
            data: {
                mensagem: 'Deseja realmente continuar?',
                icone: 'warning_amber',
                cor_size: 'wl-cor-primaria',
            },
        });

        dialogRef.afterClosed().subscribe((resultado) => {
            if (resultado) {
                this.checkInCheckOutController.salvar(this.formGroup.getRawValue()).subscribe({
                    next: (response) => {
                        console.log(response);
                    },
                    error: (error) => {
                        console.log(error);
                    },
                });
            }
        });
    }

    finalizarCheckOut(): void {
        this.router.navigateByUrl('armazenagem/visualizacao/painel-geral');
    }

    contatoTipoChamadoAtivo(): boolean {
        const contatoTipoChamado = this.contatos.find((contato) => contato.nome.includes('Chamado'));

        return contatoTipoChamado ? this.formGroup.get(`${contatoTipoChamado.id}`)?.value : false;
    }

    private buscarAtividades(): void {
        this.checkInCheckOutController
            .buscaAtividade()
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe({
                next: (atividades) => {
                    this.atividades = atividades;
                },
                error: (error) => {
                    console.log(error);
                },
            });
    }

    private buscarContatos(): void {
        this.checkInCheckOutController
            .buscaContato()
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe({
                next: (contatos) => {
                    this.contatos = contatos;
                },
                error: (error) => {
                    console.log(error);
                },
            });
    }

    private desabilitarCampos(): void {
        this.formGroup.get('dataCheckIn').disable();
        this.formGroup.get('tempoAnaliseAteCheckout').disable();
        this.formGroup.get('tempoDesdeUltimoCheckIn').disable();
        this.formGroup.get('tempoDesdeUltimoContatoEnviado').disable();
    }

    private inicializarFormulario(): void {
        const contatosControls = this.contatos?.reduce((acc, contato) => {
            acc[contato.id] = [false];
            return acc;
        }, {});

        this.formGroup = this.formBuilder.group({
            dataCheckIn: ['12/11/2024 16:45:46', [Validators.required]],
            tempoAnaliseAteCheckout: ['01:40:00', [Validators.required]],
            tempoDesdeUltimoCheckIn: ['12 dias', [Validators.required]],
            tempoDesdeUltimoContatoEnviado: ['12 dias', [Validators.required]],
            atividade: ['', [Validators.required]],
            notasChamado: '',
            anotacao: '',
            ...contatosControls,
        });

        this.desabilitarCampos();
        this.inicializarContadorCheckOut();
    }

    inicializarContadorCheckOut(): void {
        this.checkInCheckOutObservable.contadorCheckOut$.pipe(takeUntil(this.unsubscribeAll)).subscribe((valor) => {
            this.formGroup.get('tempoAnaliseAteCheckout')?.setValue(valor);
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
