import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraoInformacoesGeraisComponent } from './grao-informacoes-gerais.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconsModule } from 'app/shared/icons/icons.module';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [CommonModule, MatTooltipModule, MatIconModule, TranslocoModule],
    declarations: [GraoInformacoesGeraisComponent],
    exports: [GraoInformacoesGeraisComponent],
})
export class GraoInformacoesGeraisModule {}
