<div
    *ngIf="!playStartado"
    [ngClass]="{ container: !visao3D, 'container-3d': visao3D }"
    class="w-9 h-[33px] flex justify-center items-center"
    (click)="abrirModalTemperatura()"
    matTooltip="{{'VER_ESCALAS_DE_COR_DE_TEMPERATURA' | transloco}}"
    matTooltipClass="tooltip-escala-cores"
    [matTooltipPosition]="'below'"
>
    <div *ngIf="!visao3D">
        <mat-icon class="icone-gota mt-[5px]" [svgIcon]="'icon-gota'"></mat-icon>
        <mat-icon class="icone-gota-hover hidden" [svgIcon]="'icon-gota-hover'"></mat-icon>
    </div>
    <div *ngIf="visao3D">
        <mat-icon class="icone-gota-branca mt-[5px]" [svgIcon]="'icon-gota-branca'"></mat-icon>
        <mat-icon class="icone-gota-hover hidden" [svgIcon]="'icon-gota-hover'"></mat-icon>
    </div>
</div>
<div
    *ngIf="playStartado"
    class="mb-[-15px] w-9 h-[33px] bg-[#E5E5E5] flex justify-center items-center ml-6 mt-[-12px] opacity-75"
>
    <mat-icon class="icone-gota" [svgIcon]="'icon-gota'"></mat-icon>
</div>
