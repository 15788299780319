import { Injectable } from '@angular/core';
import { TipoVisualizacaoSiloEnum } from 'app/shared/enums';
export abstract class ICarregarTipoVisualizacaoService {
    abstract carregarTipo(): any[];
}

@Injectable({ providedIn: 'root' })
export class CarregarTipoVisualizacaoService implements ICarregarTipoVisualizacaoService {    
    public carregarTipo(): any[] {
        const obj = Object.keys(TipoVisualizacaoSiloEnum);
        let tipoVisualisacao = [];
        for (const x of obj) {
            let tipo = null;
            let nome = null;
            switch (x) {
                case TipoVisualizacaoSiloEnum.VISAO_3D: {
                    tipo = x;
                    nome = 'VISAO_3D';
                    break;
                }
                case TipoVisualizacaoSiloEnum.VISAO_PLANA: {
                    tipo = x;
                    nome = 'VISAO_PLANA';
                    break;
                }
                case TipoVisualizacaoSiloEnum.SUPERIOR: {
                    tipo = x;
                    nome = 'VISAO_SUPERIOR';
                    break;
                }
            }
            tipoVisualisacao.push({ tipo: tipo, nome: nome });
        }
        return tipoVisualisacao;
    }
}
