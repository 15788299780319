import { Injectable } from '@angular/core';
import { GraficoOpcoes } from 'app/shared/interfaces';
import { mth, shortD, shortM, day } from 'app/shared/enums';
import moment from 'moment';
import { serieAnotacaoQualidade } from 'app/modules/gestao-unidades/operacional/shared/interfaces';
import { duploYaxisGrafico, unicoYaxisGrafico } from 'app/shared/constants';
import { ApexYAxis } from 'ng-apexcharts';
export abstract class IDefineGraficoLinesService {
    abstract execute(
        series: any[],
        seriesAnotacao: serieAnotacaoQualidade[] | null,
        modal?: boolean,
        tickAmount?: number,
        labelDia?: string[] | null,
        labelSemana?: string[] | null,
        labelMes?: string[] | null,
        labelAno?: string[] | null,
        date_inicio?: string,
        date_fim?: string
    ): Partial<GraficoOpcoes>;
}

@Injectable({ providedIn: 'root' })
export class DefineGraficoLinesService implements IDefineGraficoLinesService {
    constructor() {}
    execute(
        series: any[],
        seriesAnotacao: serieAnotacaoQualidade[] | ApexYAxis | null,
        modal?: boolean,
        tickAmount?: number,
        labelDia?: string[] | null,
        labelSemana?: string[] | null,
        labelMes?: string[] | null,
        labelAno?: string[] | null
    ): Partial<GraficoOpcoes> {
        return {
            annotations: {
                points: seriesAnotacao,
            },
            series: series,
            chart: {
                height: modal ? 650 : 380,
                width: modal ? '100%' : 800,
                type: 'line',
                zoom: {
                    enabled: false,
                },
                defaultLocale: 'pt-br',
                locales: [
                    {
                        name: 'pt-br',
                        options: {
                            months: [
                                mth.JANEIRO,
                                mth.FEVEREIRO,
                                mth.MARCO,
                                mth.ABRIL,
                                mth.MAIO,
                                mth.JUNHO,
                                mth.JULHO,
                                mth.AGOSTO,
                                mth.SETEMBRO,
                                mth.OUTUBRO,
                                mth.NOVEMBRO,
                                mth.DEZEMBRO,
                            ],
                            shortMonths: [
                                shortM.JAN,
                                shortM.FEV,
                                shortM.MAR,
                                shortM.ABR,
                                shortM.MAI,
                                shortM.JUN,
                                shortM.JUL,
                                shortM.AGO,
                                shortM.SET,
                                shortM.OUT,
                                shortM.NOV,
                                shortM.DEZ,
                            ],
                            days: [day.DOMINGO, day.SEGUNDA, day.TERCA, day.QUARTA, day.QUINTA, day.SEXTA, day.SABADO],
                            shortDays: [
                                shortD.DOM,
                                shortD.SEG,
                                shortD.TER,
                                shortD.QUA,
                                shortD.QUI,
                                shortD.SEX,
                                shortD.SAB,
                            ],
                        },
                    },
                ],
            },
            stroke: {
                width: 2,
                curve: 'straight',
                lineCap: 'butt',
                colors: undefined,
                dashArray: seriesAnotacao ? [0, 0, 0, 0, 3, 3, 3] : 0,
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                markers: {
                    width: 14,
                    height: 14,
                    radius: 2,
                    strokeWidth: 0,
                    strokeColor: '#fff',
                    fillColors: undefined,
                    customHTML: undefined,
                    onClick: undefined,
                    offsetX: 0,
                    offsetY: 0,
                },
                fontFamily: 'obvia-wide-regular',
                color: '#333',
                fontSize: '9',
                fontWeight: 400,
            },
            yaxis:
                typeof seriesAnotacao === 'object'
                    ? seriesAnotacao
                    : seriesAnotacao
                    ? duploYaxisGrafico
                    : unicoYaxisGrafico,

            xaxis: {
                labels: {
                    trim: false,
                    datetimeFormatter: {
                        year: 'yyyy',
                        month: 'MMM / yy',
                        day: 'dd MMM',
                        hour: 'HH:mm',
                    },
                    datetimeUTC: false,
                },
                categories: labelDia ? labelDia : labelSemana ? labelSemana : labelMes ? labelMes : labelAno,
                type: 'datetime',
                axisBorder: {
                    show: true,
                    color: '#444',
                    strokeWidth: 2,
                },
                axisTicks: {
                    show: true,
                    color: '#444',
                    height: 5,
                },
            },
            tooltip: {
                x: {
                    show: true,
                    formatter(val, opts) {
                        if (labelDia) {
                            return moment(val).locale('pt-br').format('DD[/]MMM [-] HH:mm');
                        }
                        if (labelSemana) {
                            return `${moment(val).locale('pt-br').format('ddd')} - ${moment(val)
                                .locale('pt-br')
                                .format('DD [/] MMM [-] HH:mm')} `;
                        }
                        if (labelMes) {
                            return `${moment(val).locale('pt-br').format('ddd')} - ${moment(val)
                                .locale('pt-br')
                                .format('DD [/] MM [/] YY [-] HH:mm')} `;
                        }
                        if (labelAno) {
                            return `${moment(val).locale('pt-br').format('ddd')} - ${moment(val)
                                .locale('pt-br')
                                .format('DD [/] MMM [/] YY [-] HH:mm')} `;
                        }
                    },
                },
            },
            grid: {
                borderColor: '#f1f1f1',
            },
        } as Partial<GraficoOpcoes>;
    }
}
