<div class="max-w-[547px] componente-modal text-center flex flex-col sm:p-6">
    <span class="wl-cor-primaria text-2xl mt-8">
        {{ data.configuracaoStatus.acionamento_manual.modal.titulo }}
    </span>

    <p
        class="text-[#535767] text-lg mt-6"
        [innerHTML]="
            substituiVariavel(data.configuracaoStatus.acionamento_manual.modal.mensagem, data.unidadeArmazenagem.nome)
        "
    ></p>

    <div class="w-64 mx-auto text-left flex flex-col mt-5">
        <span class="text-[#535767] text-sm font-semibold mt-6"
            >{{ "MODAL_ACIONAMENTO_MANUAL_AERACAO_COMPONENT.PONTOS_DE_ATENCAO" | transloco }}:</span
        >
        <span class="text-[#535767] text-sm mt-3 flex items-center">
            <mat-icon class="mr-4" [svgIcon]="'icone-horario-pico-energia'"></mat-icon>
            {{ "MODAL_ACIONAMENTO_MANUAL_AERACAO_COMPONENT.HORARIOS_DE_PICO_DE_ENERGIA" | transloco }}
        </span>
        <span class="text-[#535767] text-sm mt-3 flex items-center">
            <mat-icon class="mr-4" [svgIcon]="'icone-sobrecarga-eletrica'"></mat-icon>
            {{ "MODAL_ACIONAMENTO_MANUAL_AERACAO_COMPONENT.SOBRECARGAS_ELETRICAS" | transloco }}
        </span>
        <span class="text-[#535767] text-sm mt-3 flex items-center">
            <mat-icon class="mr-4" [svgIcon]="'icone-qualidade-grao'"></mat-icon>
            {{ "MODAL_ACIONAMENTO_MANUAL_AERACAO_COMPONENT.QUALIDADE_GRAO_ARMAZENADO" | transloco }}
        </span>
    </div>
    <mat-dialog-actions class="flex justify-between p-10 mt-6">
        <button (click)="dialogRef.close()" type="button" class="w-[128px] h-[49px] leading-5 text-[16px]">
            {{ "CANCELAR" | transloco }}
        </button>
        <button
            (click)="acionamentoManual()"
            type="button"
            class="wl-bg-cor-secundaria border-none w-[128px] h-[49px] leading-5 text-[16px] text-white capitalize"
        >
            {{ data.configuracaoStatus.acionamento_manual.modal.botaoAcao }}
        </button>
    </mat-dialog-actions>
</div>
