import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { delay, take } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IControleAeracaoAutomaticaController } from 'app/shared/controllers';
import { HistoricoControleAeracaoColunasEnum } from 'app/shared/enums';
import { HistoricoControleAeracaoInterface } from 'app/shared/interfaces';
import { ApiPaginacao } from 'app/shared/models';

@Component({
    selector: 'app-modal-historico-controle-aeracao',
    templateUrl: './modal-historico-controle-aeracao.component.html',
    styleUrls: ['./modal-historico-controle-aeracao.component.scss'],
})
export class ModalHistoricoControleAeracaoComponent implements OnInit {
    tabelaColunas: string[] = [
        HistoricoControleAeracaoColunasEnum.DATA,
        HistoricoControleAeracaoColunasEnum.CONTROLE,
        HistoricoControleAeracaoColunasEnum.USUARIO,
    ];
    paginacao = new ApiPaginacao();
    carregando: boolean = true;
    carregandoDadosAdicionais: boolean = false;    
    historico: HistoricoControleAeracaoInterface[] = [];

    constructor(
        public readonly dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: any,
        private controleAeracaoAutomaticaController: IControleAeracaoAutomaticaController,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.paginacao.limit = 10;
        this.paginacao.page = 1;
        this.buscarHistorico();
    }

    ngAfterOnInit(): void {
        this.changeDetectorRef.detectChanges();
    }

    onScroll(): void {
        if (this.paginacao.page == this.paginacao.length) return;

        this.paginacao.page += 1;
        this.buscarHistorico(true);
    }

    buscarHistorico(dadosAdicionais?: boolean): void {
        if (dadosAdicionais) this.carregandoDadosAdicionais = true; else this.carregando = true;                 
        this.controleAeracaoAutomaticaController
            .buscarHistorico(this.data.armazenagemRef, this.paginacao)
            .pipe(
                take(1),                
                delay(1000)
            )
            .subscribe({
                next: (historico) => {
                    const data: HistoricoControleAeracaoInterface[] = [];
                    historico.data.forEach((elemento) => {
                        data.push(elemento);
                    });
                    this.historico = [...this.historico, ...data];
                    this.paginacao.length = historico.meta.totalPages;                    
                },
                error: (erro) => {
                    console.log(erro);                  
                },
                complete: () => {                    
                    if (dadosAdicionais) this.carregandoDadosAdicionais = false; else this.carregando = false;                                
                },
            });
    }
}
