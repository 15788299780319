import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagSensorCo2Component } from 'app/shared/components/tag-sensor-co2/tag-sensor-co2.component';
import { TranslocoRootModule } from 'app/transloco-root.module';
@NgModule({
    imports: [CommonModule, MatIconModule, TranslocoRootModule],
    declarations: [TagSensorCo2Component],
    exports: [TagSensorCo2Component],
})
export class TagSensorCo2Module {}
