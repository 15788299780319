import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaixaAlertaComponent } from './caixa-notificacao.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [CaixaAlertaComponent],
    imports: [CommonModule, MatIconModule],
    exports: [CaixaAlertaComponent],
})
export class CaixaAlertaModule {}
