import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { ModalEquilibrioHigroscopicoComponent } from 'app/shared/components/modal-equilibrio-higroscopico/modal-equilibrio-higroscopico.component';

@NgModule({
    imports: [CommonModule, MatIconModule, MatDialogModule, TranslocoRootModule],
    declarations: [ModalEquilibrioHigroscopicoComponent],
    exports: [ModalEquilibrioHigroscopicoComponent],
})
export class ModalEquilibrioHigroscopicoModule {}
