import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GraoInterface } from 'app/shared/components/modal-valores-plenum/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import { IApiTransportInterface } from 'app/core/interfaces';
import { ResponseInterface } from 'app/shared//interfaces';
import { IObjectToAnyService } from 'app/shared/services';

export abstract class IGraosUnidadeController {
    abstract buscaGraos(): Observable<ResponseInterface<GraoInterface>>;
}

@Injectable({
    providedIn: 'root',
})
export class GraosUnidadeController implements IGraosUnidadeController {
    private transport: IApiTransportInterface;

    constructor(
        public readonly apiTransportService: ApiTransportService,
        private readonly objectToAnyService: IObjectToAnyService
    ) {
        this.transport = this.apiTransportService.execute('/geral/produto');
    }

    buscaGraos(): Observable<any> {
        this.transport.options.params = this.objectToAnyService.toQueryString({
            page: 1,
            limit: 50,
            'filter.arquivado': 'false',
        });

        return this.transport.http.get<any>(`${this.transport.path}`, this.transport.options);
    }
}
