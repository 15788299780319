import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ModalEscalaCoresCO2Component } from 'app/shared/components';
import { TranslocoRootModule } from 'app/transloco-root.module';

@NgModule({
    imports: [CommonModule, MatIconModule, MatDialogModule, TranslocoRootModule],
    declarations: [ModalEscalaCoresCO2Component],
})
export class ModalEscalaCoresCO2Module {}
