import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagSensorPlenumComponent } from 'app/shared/components/tag-sensor-plenum/tag-sensor-plenum.component';
import { TranslocoModule } from '@ngneat/transloco';
@NgModule({
    imports: [CommonModule, MatIconModule, TranslocoModule],
    declarations: [TagSensorPlenumComponent],
    exports: [TagSensorPlenumComponent],
})
export class TagSensorPlenumModule {}
