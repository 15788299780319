<div class="d-flex componente-modal w-full">
    <div class="d-flex mb-5 modal-botao-fechar">
        <mat-icon id="icon-close" (click)="dialogRef.close()">close</mat-icon>
    </div>

    <div class="d-flex text-center pb-lg-0 mt-2 modal-titulo">
        <p class="text-sm sm:text-2xl">
            {{
                "MODAL_HISTORICO_VARIAVEL_AQUECIMENTO_COMPONENT.HISTORICO_ALTERACOES_VARIAVEL_AQUECIMENTO"
                    | transloco
            }}
        </p>
    </div>

    <div
        class="componente-tabela-modal my-5 sm:mt-8 overflow-hidden overflow-y-auto max-h-60"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()"
        [scrollWindow]="false"
    >
        <ng-container *ngIf="!carregando; else carregandoTabela">
            <table mat-table [dataSource]="historico" class="table-fixed sm:table-auto">
                <ng-container matColumnDef="data" class="w-[100px] sm:w-full">
                    <th mat-header-cell *matHeaderCellDef class="text-xs sm:text-base">
                        <span class="m-auto">{{ "DATA_HORA" | transloco }}</span>
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center text-xs sm:text-base">
                        {{
                            element.instante
                                ? (element.instante | date : "dd/MM/yyyy HH:mm:ss")
                                : ("AGUARDANDO_LEITURA" | transloco)
                        }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="variavel">
                    <th mat-header-cell *matHeaderCellDef class="text-xs sm:text-base">
                        <span class="m-auto">{{ "MODAL_HISTORICO_VARIAVEL_AQUECIMENTO_COMPONENT.VARIAVEL_DE_AQUECIMENTO" | transloco }}</span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="w-full flex justify-center text-xs sm:text-base">
                            {{
                                element.aquecimento_ar_valor
                                    ? (element.aquecimento_ar_valor | number : "1.1" : "pt")
                                    : "-"
                            }}
                            {{ element.aquecimento_ar_valor ? "°C" : "" }}
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-xs sm:text-base">
                        <span class="m-auto">{{ "USUARIO" | transloco }}</span>
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center text-xs sm:text-base">
                        {{ element.usuario?.nome ?? "-" }} {{ element.usuario?.sobrenome ?? "" }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="tabelaColunas"></tr>
                <tr mat-row *matRowDef="let row; let i = index; columns: tabelaColunas"></tr>
            </table>
        </ng-container>
        <ng-container *ngIf="carregandoDadosAdicionais">
            <div class="h-[100px] flex">
                <span class="m-auto flex">
                    <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
                </span>
            </div>
        </ng-container>
    </div>
</div>
<ng-template #carregandoTabela>
    <div class="h-[200px] flex">
        <span class="m-auto flex">
            <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
        </span>
    </div>
</ng-template>
<ng-template [ngIf]="!carregando && historico.length == 0">
    <div class="flex">
        <span class="m-auto flex"> {{ "NENHUM_REGISTRO_ENCONTRADO" | transloco }} </span>
    </div>
</ng-template>
