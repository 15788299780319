<div class="bg-[#F5F6F7] w-full h-full">
    <div class="flex flex-row h-full">
        <p id="textoSensores" class="w-13">
            {{ "ARMAZENAGEM_LISTA_ARMAZENS_SCREEN.SENSORES" | transloco }}
        </p>
        <div class="overflow-x-scroll h-full w-full containerCanvaArmazem">
            <canvas
                #selector
                class="plain-warehouse-view"
                style="z-index: 11; position: relative"
                [attr.data-config]="config"
                [attr.data-thermal]="thermal"
                [attr.data-humidity]="humidity"
                [attr.data-thermocouples]="thermocouples"
                [attr.data-sequence]="sequence"
                [attr.data-emc]="emc"
                [attr.data-level]="level"
                [attr.data-communicatingCables]="communicatingCables"
                [attr.data-alias]="alias"
                [attr.data-cables]="cables"
                [attr.data-selected-map]="selected"
                width="100%"
            >
            </canvas>
            <span class="bg-[#F5F6F7] w-[calc(100%-40px)] absolute left-[20px] text-center">{{ "ARMAZENAGEM_LISTA_ARMAZENS_SCREEN.PENDULOS" | transloco }}</span>
        </div>
    </div>
</div>
