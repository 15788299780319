<div class="d-flex componente-modal w-full">
    <div class="d-flex mb-5 modal-botao-fechar">
        <mat-icon id="icon-close" (click)="dialogRef.close()">close</mat-icon>
    </div>

    <div class="d-flex text-center pb-lg-0 mt-2 modal-titulo">
        <p class="text-sm sm:text-2xl">
            {{ "HISTORICO_COMANDO_AERACAO" | transloco }}
        </p>
    </div>

    <div
        class="componente-tabela-modal comando-aeracao mb-5 mt-5 sm:mt-8 overflow-y-auto md:overflow-x-hidden max-h-60"            
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()"
        [scrollWindow]="false"
    >
        <ng-container *ngIf="!carregando; else carregandoTabela">
            <table mat-table [dataSource]="historico">
                <ng-container matColumnDef="inclusao">
                    <th mat-header-cell *matHeaderCellDef class="text-xs sm:text-base">
                        <span class="m-auto w-[100px]">{{ "INCLUSAO" | transloco }}</span>
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center text-xs sm:text-base">
                        <span class="m-auto w-[100px]">
                            {{
                                element.instante_criacao
                                    ? (element.instante_criacao | date : "dd/MM/yyyy HH:mm:ss")
                                    : ("AGUARDANDO_LEITURA" | transloco)
                            }}
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="atualizacao">
                    <th mat-header-cell *matHeaderCellDef class="text-xs sm:text-base">
                        <span class="m-auto w-[100px]">{{ "ATUALIZACAO" | transloco }}</span>
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center text-xs sm:text-base">
                        <span class="m-auto w-[100px]">
                            {{
                                element.instante_validacao
                                    ? (element.instante_validacao | date : "dd/MM/yyyy HH:mm:ss")
                                    : ("AGUARDANDO_LEITURA" | transloco)
                            }}
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="tipo">
                    <th mat-header-cell *matHeaderCellDef class="text-xs sm:text-base">
                        <span class="m-auto w-[50px]">{{ "TIPO" | transloco }}</span>
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-xs sm:text-base">
                        <div class="w-full flex justify-center">
                            <app-status-on-off
                                [statusOn]="element.comando == 'ligar'"
                                [possuiStatusComunicacao]="false"
                            ></app-status-on-off>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="estagio">
                    <th mat-header-cell *matHeaderCellDef class="text-xs sm:text-base">
                        <span class="m-auto w-[140px]">{{ "ESTAGIO" | transloco }}</span>
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center text-xs sm:text-base">
                        {{
                            element.status_comando
                                ? (Object.values(statusPilhaComandoAeracaoEnum)[
                                      (Object.keys(statusPilhaComandoAeracaoEnum).indexOf(element.status_comando))
                                  ] | transloco)
                                : "-"
                        }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="operacao">
                    <th mat-header-cell *matHeaderCellDef class="text-xs sm:text-base">
                        <span class="m-auto w-[140px]">{{ "OPERACAO" | transloco }}</span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="w-full flex justify-center">
                            <app-status-automatico-manual-com-icone-descricao
                                [statusAutomatico]="element.automatico"
                                class="max-w-[140px]"
                            >
                            </app-status-automatico-manual-com-icone-descricao>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-xs sm:text-base">
                        <span class="m-auto w-[200px]">{{ "USUARIO" | transloco }}</span>
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center text-xs sm:text-base max-w-[200px]">
                        {{ element.usuario?.nome ?? "-" }} {{ element.usuario?.sobrenome ?? "" }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="resultado">
                    <th mat-header-cell *matHeaderCellDef class="text-xs sm:text-base w-15">
                        <span class="m-auto w-[80px]">{{ "RESULTADO" | transloco }}</span>
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center">
                        <div class="w-full flex justify-center">
                            <span class="w-[80px]">
                                <mat-icon
                                    *ngIf="element?.status_aeracao?.icone?.tipo"
                                    class="text-[{{ element?.status_aeracao?.alerta?.cor }}] icon-size-5 {{
                                        element?.status_aeracao?.icone?.classIcone
                                    }}"
                                    [svgIcon]="element?.status_aeracao?.icone?.tipo"
                                ></mat-icon>
                                {{ !element?.status_aeracao?.icone?.tipo ? "-" : "" }}
                            </span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="detalhes">
                    <th mat-header-cell *matHeaderCellDef class="text-xs sm:text-base text-center">
                        <span class="m-auto w-auto">{{ "DETALHES" | transloco }}</span>
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center">
                        {{ element.status_aeracao?.icone?.texto ?? "-" }}
                    </td>
                </ng-container>
                
                <tr mat-header-row *matHeaderRowDef="tabelaColunas"></tr>
                <tr mat-row *matRowDef="let row; let i = index; columns: tabelaColunas"></tr>
            </table>
        </ng-container>
        <ng-container *ngIf="carregandoDadosAdicionais">            
            <div class="h-[100px] flex">
                <span class="m-auto flex">
                    <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
                </span>
            </div>                    
        </ng-container>
    </div>
</div>

<ng-template #carregandoTabela>
    <div class="h-[200px] flex">
        <span class="m-auto flex">
            <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
        </span>
    </div>
</ng-template>
<ng-template [ngIf]="!carregando && historico.length == 0">
    <div class="flex">
        <span class="m-auto flex"> {{ "NENHUM_REGISTRO_ENCONTRADO" | transloco }} </span>
    </div>
</ng-template>
