<div
    [ngClass]="checkOutAberto ? 'opacity-87' : 'opacity-100'"
    class="w-[200px] flex justify-between items-center mr-5 text-[#64748B] text-base"
>
    <mat-icon
        [ngClass]="checkOutAberto ? 'cursor-default' : 'cursor-pointer'"
        class="p-0.5"
        (click)="reiniciarContagemCheckOut()"
        [svgIcon]="'refresh'"
    ></mat-icon>
    <mat-icon
        [ngClass]="checkOutAberto ? 'cursor-default' : 'cursor-pointer'"
        class="p-0.5"
        (click)="alternarContagem()"
        [svgIcon]="!contadorPausado ? 'pause' : 'play_arrow'"
    ></mat-icon>
    <span class="text-center w-[40px]">{{ tempoDecorrido$ | async }}</span>
    <span class="cursor-pointer" [matMenuTriggerFor]="menu" #menuTrigger>Check-out</span>
    <mat-menu #menu="matMenu" class="px-3 py-2">
        <p class="text-center wl-cor-primaria mb-3">Deseja fazer Check-out?</p>
        <div class="flex justify-between">
            <button
                type="button"
                class="action w-[105px] h-[43px] mr-5 leading-4 text-[14px] text-white wl-bg-cor-secundaria border-btn-salvar"
                (click)="abrirCheckOut()"
            >
                Sim
            </button>
            <button type="button" class="w-[105px] h-[43px] leading-4 text-[14px]">
                {{ "CANCELAR" | transloco }}
            </button>
        </div>
    </mat-menu>
</div>
