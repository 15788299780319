import { LocaleConfig } from 'ngx-daterangepicker-material';
import { CookieHelper } from 'app/shared/helpers';
import { IdiomaSiglaEnum } from 'app/shared/enums';

export class configuracaoCalendario {
    public carregaCalendario(): LocaleConfig {
        let idioma = CookieHelper.carregarCookie('idioma');
        switch (idioma) {
            case IdiomaSiglaEnum.INGLES:
                return {
                    separator: ' - ',
                    applyLabel: 'Apply',
                    cancelLabel: 'Cancel',
                    customRangeLabel: 'Custom',
                    daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                    monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    displayFormat: 'DD/MM/YYYY HH:mm',
                };
            case IdiomaSiglaEnum.ESPANHOL:
                return {
                    separator: ' - ',
                    applyLabel: 'Aplicar',
                    cancelLabel: 'Cancelar',
                    customRangeLabel: 'Personalizado',
                    daysOfWeek: ['Dom', 'Lun ', 'Mar', 'Mié ', 'Jue ', 'Vie', 'Sáb'],
                    monthNames: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
                    displayFormat: 'DD/MM/YYYY HH:mm',
                };

            default:
                return {
                    separator: ' - ',
                    applyLabel: 'Aplicar',
                    cancelLabel: 'Cancelar',
                    customRangeLabel: 'Personalizado',
                    daysOfWeek: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
                    monthNames: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                    displayFormat: 'DD/MM/YYYY HH:mm',
                };
        }
    }
}
