export const MENUS_GESTAO_UNIDADE_EN = [
    {
        menu: 'STORAGE UNIT MANAGEMENT',
        description: 'STORAGE UNIT MANAGEMENT',
        link: 'gestao-unidades/visualizacao/painel-geral',
        icon: 'icon-module-gestao-unidade',
        separator: false,
        permission: true,
        groups: [
            {
                title: 'Visualization',
                icon: 'grid_view',
                separator: true,
                items: [
                    {
                        menu: 'General Panel',
                        icon: 'dashboard',
                        link: 'gestao-unidades/visualizacao/painel-geral',
                    },
                ],
            },
            {
                title: 'Storage and Dryer',
                icon: 'icon-operacional-armazenagem-secador',
                separator: true,
                items: [
                    {
                        menu: 'Profitability',
                        icon: 'icon-gestao-unidades-rentabilidade',
                        link: 'gestao-unidades/armazenagem-secador/rentabilidade',
                    },
                    {
                        menu: 'Quality',
                        icon: 'icon-gestao-unidades-qualidade',
                        link: 'gestao-unidades/armazenagem-secador/qualidade',
                    },
                    {
                        menu: 'Operational',
                        icon: 'icon-gestao-unidades-operacional',
                        link: 'gestao-unidades/armazenagem-secador/operacional',
                    },
                ],
            },
            {
                title: 'Receiving and Shipping',
                icon: 'icon-menu-expedicao-recebimento-truck-azul',
                separator: true,
                items: [
                    {
                        menu: 'Panel and Listing',
                        icon: 'icon-gestao-unidades-amostragem-classificacao-painel-listagem',
                        link: 'gestao-unidades/recebimento-expedicao/painel-listagem',
                    },
                ],
            },
            {
                title: 'Sampling and Classification',
                icon: 'icon-gestao-unidades-amostragem-classificacao',
                separator: true,
                items: [
                    {
                        menu: 'Panel and Listing',
                        icon: 'icon-gestao-unidades-amostragem-classificacao-painel-listagem',
                        link: 'gestao-unidades/amostragem-classificacao/painel-listagem',
                    },
                    {
                        menu: 'Sample Categories',
                        icon: 'icon-gestao-unidades-amostragem-classificacao-categorias-amostras',
                        link: 'gestao-unidades/amostragem-classificacao/categorias-amostras',
                    },
                ],
            },
            {
                title: 'Parameter Settings',
                icon: 'icon-gestao-unidades-parametrizacoes',
                separator: true,
                items: [
                    {
                        menu: 'Parameter Settings',
                        icon: 'icon-gestao-unidades-parametrizacoes-ordem-de-servico',
                        link: 'gestao-unidades/parametrizacoes/lista-parametrizacoes',
                    },
                ],
            },
            {
                title: 'Efficiency',
                icon: 'icon-gestao-unidades-eficiencia',
                separator: true,
                items: [
                    {
                        menu: 'Flow meters',
                        icon: 'icon-gestao-unidades-eficiencia-medidores-de-fluxo',
                        link: 'gestao-unidades/eficiencia/medidores-fluxo',
                    },
                    {
                        menu: 'Processes',
                        icon: 'icon-gestao-unidades-eficiencia-processos',
                        link: 'gestao-unidades/eficiencia/processos',
                    },
                    {
                        menu: 'Process log',
                        icon: 'icon-gestao-unidades-eficiencia-processos',
                        link: 'gestao-unidades/eficiencia/cadastro-processos',
                    },
                ],
            },
        ],
    },
];
