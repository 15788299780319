import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalVisaoSuperiorSiloModel } from 'app/shared/components/modal-visao-superior-silo/models';

@Component({
    selector: 'app-modal-visao-superior-silo',
    templateUrl: './modal-visao-superior-silo.component.html',
    styleUrls: ['./modal-visao-superior-silo.component.scss'],
})
export class ModalVisaoSuperiorSiloComponent implements OnInit {
    pendulos: Number;

    constructor(
        public dialogRef: MatDialogRef<ModalVisaoSuperiorSiloModel>,
        @Inject(MAT_DIALOG_DATA) public data: ModalVisaoSuperiorSiloModel
    ) {
        this.pendulos = data.pendulos;
    }

    ngOnInit() {}
}
