import { Component, Input } from '@angular/core';
import { EstacaoMeteorologicaHelper, VerificaObjetoVazio } from 'app/shared/helpers';
import { EstacaoMeteorologicaInterface } from 'app/shared/interfaces';

@Component({
    selector: 'app-template-informacoes-estacao-metereologica',
    templateUrl: './template-informacoes-estacao-metereologica.component.html',
})
export class TemplateInformacoesEstacaoMetereologicaComponent {
    @Input() estacao: EstacaoMeteorologicaInterface;
    
    verificaObjetoVazio = VerificaObjetoVazio.execute;
    carregaEstiloEstacaoMeteorologica = EstacaoMeteorologicaHelper.carregaEstiloEstacaoMeteorologica;
    carregaFonteEstacaoMeteorologica = EstacaoMeteorologicaHelper.carregaEstiloFonteEstacaoMeteorologica;
}
