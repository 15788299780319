import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ModalConfirmacaoComponent } from './modal-confirmacao.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    declarations: [ModalConfirmacaoComponent],
    imports: [MatIconModule, MatDialogModule, CommonModule, TranslocoModule],
    exports: [ModalConfirmacaoComponent],
})
export class ModalConfirmacaoModule {}
