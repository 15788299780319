export enum OpcoesPersonalizadasEnum {
    HOJE = 'HOJE',
    ONTEM = 'ONTEM',
    ULTIMAS_24_HORAS = 'ULTIMAS_24_HORAS',
    ESTA_SEMANA = 'ESTA_SEMANA',
    ULTIMOS_7_DIAS = 'ULTIMOS_7_DIAS',
    ULTIMOS_10_DIAS = 'ULTIMOS_10_DIAS',
    ESTE_MES = 'ESTE_MES',
    ULTIMOS_30_DIAS = 'ULTIMOS_30_DIAS',
    ULTIMOS_3_MESES = 'ULTIMOS_3_MESES',
    ESTE_ANO = 'ESTE_ANO',
    ANO_PASSADO = 'ANO_PASSADO',
}
