import { TranslocoModule } from '@ngneat/transloco';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertasAeracaoComponent } from './alertas-aeracao.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [CommonModule, MatIconModule, TranslocoModule],
    declarations: [AlertasAeracaoComponent],
    exports: [AlertasAeracaoComponent],
})
export class AlertasAeracaoModule {}
