import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalStatusAeracaoComponent } from './modal-status-aeracao.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslocoRootModule } from 'app/transloco-root.module';

@NgModule({
    imports: [CommonModule, MatIconModule, MatDialogModule, TranslocoRootModule],
    declarations: [ModalStatusAeracaoComponent],
})
export class ModalStatusAeracaoModule {}
