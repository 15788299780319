import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { StatusAutomaticoManualComIconeDescricaoModule, StatusOnOffModule } from 'app/shared/components';
import { ModalHistoricoComandoAeracaoComponent } from 'app/shared/components/modal-historico-comando-aeracao/modal-historico-comando-aeracao.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatTableModule,
        MatProgressSpinnerModule,
        StatusAutomaticoManualComIconeDescricaoModule,
        StatusOnOffModule,
        TranslocoRootModule,
        InfiniteScrollModule
    ],
    declarations: [ModalHistoricoComandoAeracaoComponent],
    exports: [ModalHistoricoComandoAeracaoComponent],
})
export class ModalHistoricoComandoAeracaoModule {}
