<ng-container>
    <div class="flex items-center justify-end py-2" id="valor-progresso">
        <span class="font-normal text-base text-[#15161A]">{{ valoresProgresso }}%</span>
    </div>

    <mat-progress-bar mode="determinate" value="{{ valoresProgresso }}"></mat-progress-bar>

    <div class="flex items-center justify-start">
        <p class="font-normal text-base text-[#535767] py-4">
            {{
                "BARRA_PROGRESSO_COMPONENT.OS_DADOS_ESTAO_SENDO_CARREGADOS_ISSO_PODE_LEVAR_ALGUM_TEMPO_PARA_CONTINUAR_ACESSANDO_O_SISTEMA"
                | transloco
            }}
            <a
                class="font-bold text-base wl-cor-primaria underline cursor-pointer"
                (click)="redirecionarPainelGeral()"
                target="_blank"
                >{{"BARRA_PROGRESSO_COMPONENT.CLIQUE_AQUI" | transloco}}</a
            >
            {{
                "BARRA_PROGRESSO_COMPONENT.E_VA_PARA_O_PAINEL_GERAL"
                | transloco
            }}.
        </p>
    </div>
</ng-container>
