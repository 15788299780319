<div class="componente-modal">
    <div *ngIf="data.icone" class="text-center modal-titulo justify-center flex flex-col">
        <div class="text-center justify-center items-center flex flex-col mb-5">
            <mat-icon
                class="mx-auto cor-icone fill-[var(--cor-primaria)] mb-5"
                [svgIcon]="data?.icone"
                [ngClass]="data.cor_size ?? 'icon-size-6'"
            ></mat-icon>
            <p class="text-base">
                {{ data.mensagem | transloco}}
            </p>
        </div>

        <mat-dialog-actions class="flex justify-between">
            <button
                (click)="dialogRef.close()"
                type="button"
                class="px-5 h-[40px] leading-5 text-md text-[#535767] mx-3 border-[#535767]"
            >
                {{ "CANCELAR" | transloco }}
            </button>
            <button
                (click)="dialogRef.close(true)"
                type="button"
                class="wl-bg-cor-secundaria border-none px-5 h-[40px] leading-5 text-md text-white mx3"
            >
                {{ "CONFIRMAR" | transloco }}
            </button>
        </mat-dialog-actions>
    </div>
</div>
