import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalEscalaTemperaturaModal } from './modal/modal-escala-temperatura.modal';

@Component({
    selector: 'app-escala-cores-temperatura',
    templateUrl: './escala-cores-temperatura.component.html',
    styleUrls: ['./escala-cores-temperatura.component.scss'],
})
export class EscalaCoresTemperaturaComponent {
    @Input() playStartado?: boolean;
    @Input() visao3D?: boolean = false;

    constructor(private _matDialog: MatDialog) {}

    abrirModalTemperatura(): void {
        this._matDialog.open(ModalEscalaTemperaturaModal, {
            minHeight: '524px',
        });
    }
}
