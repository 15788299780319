import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagSensorVolumetriaComponent } from 'app/shared/components/tag-sensor-volumetria/tag-sensor-volumetria.component';
import { TranslocoModule } from '@ngneat/transloco';
@NgModule({
    imports: [CommonModule, MatIconModule, TranslocoModule],
    declarations: [TagSensorVolumetriaComponent],
    exports: [TagSensorVolumetriaComponent],
})
export class TagSensorVolumetriaModule {}
