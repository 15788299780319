<div class="componente-modal">
    <div class="modal-botao-fechar">
        <mat-icon id="icon-close" (click)="dialogRef.close()">close</mat-icon>
    </div>

    <div class="text-center mt-4 modal-titulo justify-center flex">
        <p class="text-sm sm:text-2xl">{{ "MODAL_EQUILIBRIO_HIGROSCOPICO_COMPONENT.ENTENDA_VALORES_DO_EQUILIBRIO_HIGROSCOPICO" | transloco }}</p>
    </div>
    <div class="mt-10 text-xs sm:text-base">
        <p>
            {{ "MODAL_EQUILIBRIO_HIGROSCOPICO_COMPONENT.ATRAVES_DA_LEITURA_DOS_SENSORES_TEMPERATURA_E_UMIDADE_RELATIVA_E_POSSIVEL_REALIZAR_CALCULO_FAZENDO_USO_DE_MODELOS_MATEMATICOS_PARA_ENCONTRAR_EQUILIBRIO_HIGROSCOPICO_CADA_TIPO_DE_GRAO" | transloco }}
        </p>

        <p class="mt-5">
            {{ "MODAL_EQUILIBRIO_HIGROSCOPICO_COMPONENT.VALOR_CALCULADO_EQUILIBRIO_HIGROSCOPICO_INFERENCIA_DE_ACORDO_COM_CONDICOES_DO_AR_INTERGRANULAR" | transloco }}
        </p>

        <p class="mt-5">
            {{ "MODAL_EQUILIBRIO_HIGROSCOPICO_COMPONENT.SENDO_ASSIM_NAO_REPRESENTA_MESMO_VALOR_UMIDADE_GRAO_QUE_E_UMA_LEITURA_REALIZADA_NA_AMOSTRAGEM_POR_APARELHOS_ESPECIFICOS_MEDICAO_UMIDADE" | transloco }}
        </p>
    </div>
</div>
