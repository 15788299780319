<ng-container *ngIf="!possuiStatusComunicacao && statusOn != 'indefinido'">
    <span
        *ngIf="statusOn"
        [ngClass]="classWidth"
        class="flex rounded-md h-7 sm:h-8 justify-center bg-[#C7EDFF] text-[#185BC3] text-xs sm:text-base items-center cursor-default"
        #tooltip="matTooltip"
        [matTooltip]="'STATUS_ON_OFF_COMPONENT.LIGADO' | transloco"
        matTooltipClass="tooltip-tabela-aeracao"
        matTooltipPosition="above"
    >
        {{ "STATUS_ON_OFF_COMPONENT.ON" | transloco }}
    </span>
    <span
        *ngIf="!statusOn"
        [ngClass]="classWidth"
        class="flex rounded-md h-7 sm:h-8 justify-center bg-[#BCC0C5] text-[#131727] text-xs sm:text-base items-center cursor-default"
        #tooltip="matTooltip"
        [matTooltip]="'STATUS_ON_OFF_COMPONENT.DESLIGADO' | transloco"
        matTooltipClass="tooltip-tabela-aeracao"
        matTooltipPosition="above"
    >
        {{ "STATUS_ON_OFF_COMPONENT.OFF" | transloco }}
    </span>
</ng-container>
<ng-container *ngIf="possuiStatusComunicacao && statusOn != 'indefinido'">
    <ng-container *ngIf="equipamentoComunicando">
        <span
            *ngIf="statusOn"
            [ngClass]="classWidth"
            class="flex rounded-md h-7 sm:h-8 justify-center bg-[#C7EDFF] text-[#185BC3] text-xs sm:text-base items-center cursor-default"
            #tooltip="matTooltip"
            [matTooltip]="'STATUS_ON_OFF_COMPONENT.LIGADO' | transloco"
            matTooltipClass="tooltip-tabela-aeracao"
            matTooltipPosition="above"
        >
            {{ "STATUS_ON_OFF_COMPONENT.ON" | transloco }}
        </span>
        <span
            *ngIf="!statusOn"
            [ngClass]="classWidth"
            class="flex rounded-md h-7 sm:h-8 justify-center bg-[#BCC0C5] text-[#131727] text-xs sm:text-base items-center cursor-default"
            #tooltip="matTooltip"
            [matTooltip]="'STATUS_ON_OFF_COMPONENT.DESLIGADO' | transloco"
            matTooltipClass="tooltip-tabela-aeracao"
            matTooltipPosition="above"
        >
            {{ "STATUS_ON_OFF_COMPONENT.OFF" | transloco }}
        </span>
    </ng-container>

    <ng-container
        *ngIf="!equipamentoComunicando && naoNuloOuIndefinido(equipamentoComunicando)"
    >
        <span
            [ngClass]="classWidth"
            class="flex rounded-md h-7 sm:h-8 justify-center bg-[#F9E3E3] text-[#A44040] text-xs sm:text-base items-center cursor-default"
            #tooltip="matTooltip"
            [matTooltip]="'STATUS_ON_OFF_COMPONENT.SEM_COMUNICACAO' | transloco"
            matTooltipClass="tooltip-tabela-aeracao"
            matTooltipPosition="above"
        >
            {{ "STATUS_ON_OFF_COMPONENT.S_C" | transloco }}
        </span>
    </ng-container>
    <ng-container *ngIf="equipamentoComunicando === undefined || equipamentoComunicando === null">
        <span
            [ngClass]="classWidth"
            class="flex rounded-md h-7 sm:h-8 justify-center bg-[#E8E9EA] text-[#030229] text-xs sm:text-base items-center cursor-default"
        >
            -
        </span>
    </ng-container>
</ng-container>

<ng-container *ngIf="statusOn === 'indefinido'">
    <span
        #tooltip="matTooltip"
        [matTooltip]="'Indefinido'"
        matTooltipClass="tooltip-tabela-aeracao"
        matTooltipPosition="above"
        [ngClass]="classWidth"
        class="flex rounded-md h-7 sm:h-8 justify-center bg-[#E8E9EA] text-[#030229] text-xs sm:text-base items-center cursor-default"
    >
        -
    </span>
</ng-container>
