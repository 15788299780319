<div class="containerFiltro formulario flex flex-row justify-between items-center">
    <mat-form-field [floatLabel]="'always'" class="w-96" [hideRequiredMarker]="false">
        <mat-label class="text-[#444444] text-base">
            {{
                "ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.DEFINIR_TEMPERATURA_REPRESENTACAO_PENDULO"
                    | transloco
            }}
        </mat-label>
        <mat-select [formControl]="temperaturaControle">
            <mat-option *ngFor="let item of opcoesTemperatura" [value]="item.valor">
                {{ item.nome }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-slide-toggle [color]="'primary'" [formControl]="linhasArcosControle">
        <span class="text-base">
            {{ "ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.EXIBIR_LINHAS_ARCOS" | transloco }}
        </span>
    </mat-slide-toggle>
</div>

<div class="mb-6 flex flex-row items-center justify-between h-10">
    <div class="flex flex-row visa-superior">
        <app-escala-cores-temperatura class="block"> </app-escala-cores-temperatura>
        <button
            (click)="aumentarZoom()"
            class="flex items-center justify-center bg-[#E5E5E5] rounded-none w-9 h-[33px] border-solid border-[#d4d3d3]"
        >
            <mat-icon svgIcon="heroicons_outline:plus" class="text-[#444]"></mat-icon>
        </button>
        <button
            (click)="diminuirZoom()"
            class="flex items-center justify-center bg-[#E5E5E5] rounded-none w-9 h-[33px] border-solid border-[#d4d3d3]"
        >
            <mat-icon svgIcon="heroicons_outline:minus" class="text-[#444]"></mat-icon>
        </button>
        <button
            (click)="resetarZoom()"
            class="flex items-center justify-center bg-[#E5E5E5] rounded-none w-9 h-[33px] border-solid border-[#d4d3d3]"
        >
            <mat-icon svgIcon="icon-arrow-circle" class="p-1"></mat-icon>
        </button>
    </div>
    <div class="relative">
        <app-data-ultima-comunicacao
            *ngIf="listaEquipamentos"
            [equipamentos]="listaEquipamentos"
            [visualizarTempo]="true"
            [tamanhoIconeStatus]="'icon-size-09'"
        >
        </app-data-ultima-comunicacao>
    </div>
    <div class="flex flex-wrap justify-between w-[60%] text-xs">
        <span>A1: {{ "ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.ARCO" | transloco }} 1</span>
        <span>L1: {{ "ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.LINHA" | transloco }} 1</span>
        <span>
            {{ "ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.SENSORES_DE_ACORDO_COM_BORDA" | transloco }}
        </span>
        <span class="flex flex-row items-center">
            <svg width="18" height="3" viewBox="0 0 18 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.0297852" y="0.388672" width="17.0388" height="1.72266" fill="#535767" />
            </svg>
            <span class="ml-3">
                {{ "ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.DIGITAL" | transloco }}
            </span>
        </span>
        <span class="flex flex-row items-center">
            <svg width="18" height="5" viewBox="0 0 18 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.0686035" y="0.0273438" width="17.0388" height="1.72266" fill="#A44040" />
                <rect x="0.0686035" y="2.75" width="17.0388" height="1.72266" fill="#A44040" />
            </svg>

            <span class="ml-3">
                {{ "ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.TERMOPAR" | transloco }}
            </span>
        </span>
        <span class="flex flex-row items-center">
            <svg width="14" height="3" viewBox="0 0 14 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.107422 1.25H13.3184" stroke="#535767" stroke-width="2" stroke-dasharray="3 3" />
            </svg>

            <span class="ml-3">
                {{ "ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.UMIDADE_RELATIVA_TEMPERATURA" | transloco }}
            </span>
        </span>
        <span class="flex flex-row items-center">
            <span class="ml-3">
                {{ "ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.ENTENDA_VISAO_SUPERIOR" | transloco }}
            </span>
            <mat-icon
                class="wl-cor-primaria icon-size-3 ml-3 cursor-pointer"
                (click)="abrirModalEntendaVisaoSuberior()"
                [svgIcon]="'icon-info'"
            ></mat-icon>
        </span>
    </div>
</div>

<div class="overflow-x-auto">
    <div class="w-full" id="containerVisaoSuperiorArmazem">
        <div class="w-full relative mt-6" id="containerArmamzem"></div>
    </div>
</div>
