const caminho = '../../../../../../../assets/img/Modal_EHE';

export const opcoesSelectGraos = [
    { id: 1, valor: 'Arroz', imagem: `${caminho}/01_arroz_sem_casca.gif` },
    { id: 2, valor: 'Soja', imagem: `${caminho}/19_soja.gif` },
    { id: 3, valor: 'Milho', imagem: `${caminho}/15_milho.gif` },
    { id: 4, valor: 'Trigo', imagem: `${caminho}/22_trigo.gif` },
    { id: 5, valor: 'Aveia', imagem: `${caminho}/02_aveia.gif` },
    { id: 6, valor: 'Canola', imagem: `${caminho}/07_canola.gif` },
    { id: 7, valor: 'Feijão', imagem: `${caminho}/11_feijao.gif` },
    { id: 8, valor: 'Cevada', imagem: `${caminho}/09_cevada.gif` },
    { id: 9, valor: 'Sorgo', imagem: `${caminho}/20_sorgo.gif` },
    { id: 10, valor: 'Polpa Cítrica', imagem: `${caminho}/20_sorgo.gif` },
    { id: 11, valor: 'Milho-Pipoca', imagem: `${caminho}/16_milho_pipoca.gif` },
    { id: 12, valor: 'Milheto', imagem: `${caminho}/14_milheto.gif` },
    { id: 13, valor: 'Girassol', imagem: `${caminho}/13_girassol.gif` },
    { id: 14, valor: 'Triticale', imagem: `${caminho}/21_titricale.gif` },
    { id: 15, valor: 'Caroço de Algodão', imagem: `${caminho}/08_caroco_algodao.gif` },
    { id: 16, valor: 'Nabo Forrageiro', imagem: `${caminho}/17_nabo_forrag.gif` },
    { id: 17, valor: 'Painço', imagem: `${caminho}/18_painco.gif` },
    { id: 18, valor: 'Café Descascado', imagem: `${caminho}/05_cafe_descascado.gif` },
    { id: 19, valor: 'Brachiaria', imagem: `${caminho}/04_brachiaria.gif` },
    { id: 20, valor: 'Gergelim', imagem: `${caminho}/12_gergelim.gif` },
    { id: 21, valor: 'Ervilha', imagem: `${caminho}/10_ervilha.gif` },
    { id: 22, valor: 'Café Pergaminho', imagem: `${caminho}/06_cafe_pergaminho.gif` },
    { id: 23, valor: 'Azevém', imagem: `${caminho}/03_azevem.gif` },
    { id: 24, valor: 'Cártamo', imagem: `${caminho}/03_azevem.gif` },
];
