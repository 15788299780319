export const MENUS_GESTAO_UNIDADE = [
    {
        menu: 'Gestão de Unidades',
        description: 'Gestão de Unidades',
        link: 'gestao-unidades/visualizacao/painel-geral',
        icon: 'icon-module-gestao-unidade',
        separator: false,
        permission: true,
        groups: [
            {
                title: 'Visualização',
                icon: 'grid_view',
                separator: true,
                items: [
                    {
                        menu: 'Painel Geral',
                        icon: 'dashboard',
                        link: 'gestao-unidades/visualizacao/painel-geral',
                    },
                ],
            },
            {
                title: 'Armazenagem e secador',
                icon: 'icon-operacional-armazenagem-secador',
                separator: true,
                items: [
                    {
                        menu: 'Rentabilidade',
                        icon: 'icon-gestao-unidades-rentabilidade',
                        link: 'gestao-unidades/armazenagem-secador/rentabilidade',
                    },
                    {
                        menu: 'Qualidade',
                        icon: 'icon-gestao-unidades-qualidade',
                        link: 'gestao-unidades/armazenagem-secador/qualidade',
                    },
                    {
                        menu: 'Operacional',
                        icon: 'icon-gestao-unidades-operacional',
                        link: 'gestao-unidades/armazenagem-secador/operacional',
                    },
                ],
            },
            {
                title: 'Recebimento e expedição',
                icon: 'icon-menu-expedicao-recebimento-truck-azul',
                separator: true,
                items: [
                    {
                        menu: 'Painel Listagem',
                        icon: 'icon-gestao-unidades-amostragem-classificacao-painel-listagem',
                        link: 'gestao-unidades/recebimento-expedicao/painel-listagem',
                    },
                ],
            },
            {
                title: 'Amostragem e Classificação',
                icon: 'icon-gestao-unidades-amostragem-classificacao',
                separator: true,
                items: [
                    {
                        menu: 'Painel de Listagem',
                        icon: 'icon-gestao-unidades-amostragem-classificacao-painel-listagem',
                        link: 'gestao-unidades/amostragem-classificacao/painel-listagem',
                    },
                    {
                        menu: 'Categorias de Amostras',
                        icon: 'icon-gestao-unidades-amostragem-classificacao-categorias-amostras',
                        link: 'gestao-unidades/amostragem-classificacao/categorias-amostras',
                    },
                ],
            },
            {
                title: 'Parametrizações',
                icon: 'icon-gestao-unidades-parametrizacoes',
                separator: true,
                items: [
                    {
                        menu: 'Parametrizações',
                        icon: 'icon-gestao-unidades-parametrizacoes-ordem-de-servico',
                        link: 'gestao-unidades/parametrizacoes/lista-parametrizacoes',
                    },
                ],
            },
            {
                title: 'Eficiência',
                icon: 'icon-gestao-unidades-eficiencia',
                separator: true,
                items: [
                    {
                        menu: 'Medidores de fluxo',
                        icon: 'icon-gestao-unidades-eficiencia-medidores-de-fluxo',
                        link: 'gestao-unidades/eficiencia/medidores-fluxo',
                    },
                    {
                        menu: 'Processos',
                        icon: 'icon-gestao-unidades-eficiencia-processos',
                        link: 'gestao-unidades/eficiencia/processos',
                    },
                    {
                        menu: 'Cadastro dos Processos',
                        icon: 'icon-gestao-unidades-eficiencia-processos',
                        link: 'gestao-unidades/eficiencia/cadastro-processos',
                    },
                ],
            },
        ],
    },
];
