import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiTransportService } from 'app/core/services/transport';
import { IApiTransportInterface } from 'app/core/interfaces';
import { IObjectToAnyService } from 'app/shared/services';
import { ResponseInterface } from 'app/shared/interfaces';
import { ApiPaginacao } from 'app/shared/models';
import { PainelInformacoesAeracaoInterface } from '../interfaces';

export abstract class IInformacoesAeracaoController {
    abstract buscaInformacoesAeracaoSilo(
        paginacao: ApiPaginacao
    ): Observable<ResponseInterface<PainelInformacoesAeracaoInterface>>;
    abstract buscaInformacoesAeracaoArmazem(
        paginacao: ApiPaginacao
    ): Observable<ResponseInterface<PainelInformacoesAeracaoInterface>>;
}

@Injectable({
    providedIn: 'root',
})
export class InformacoesAeracaoController implements IInformacoesAeracaoController {
    private transport: IApiTransportInterface;

    constructor(
        public readonly apiTransportService: ApiTransportService,
        private readonly objectToAnyService: IObjectToAnyService
    ) {
        this.transport = this.apiTransportService.execute('/armazenagem');
    }

    buscaInformacoesAeracaoSilo(paginacao: ApiPaginacao): Observable<any> {
        this.transport.options.params = this.objectToAnyService.toQueryString({
            page: paginacao.page,
            limit: paginacao.limit,
        });
        return this.transport.http.get(
            `${this.transport.path}/silo/painel-informacoes-aeracao`,
            this.transport.options
        );
    }

    buscaInformacoesAeracaoArmazem(paginacao: ApiPaginacao): Observable<any> {
        this.transport.options.params = this.objectToAnyService.toQueryString({
            page: paginacao.page,
            limit: paginacao.limit,
        });
        return this.transport.http.get(
            `${this.transport.path}/armazem/painel-informacoes-aeracao`,
            this.transport.options
        );
    }
}
