import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { ApiTransportService } from 'app/core/services/transport';
import { IApiTransportInterface } from 'app/core/interfaces';

import {
    AtividadesCheckInCheckOutInterface,
    ContatosCheckInCheckOutInterface,
} from 'app/layout/common/check-in-check-out/interfaces';

export abstract class ICheckInCheckOutController {
    abstract buscaAtividade(): Observable<AtividadesCheckInCheckOutInterface[]>;
    abstract buscaContato(): Observable<ContatosCheckInCheckOutInterface[]>;
    abstract salvar(params: any): Observable<any>;
}
@Injectable({
    providedIn: 'root',
})
export class CheckInCheckOutController implements ICheckInCheckOutController {
    private transport: IApiTransportInterface;

    constructor(public readonly apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/base/categoria');
    }

    buscaAtividade(): Observable<AtividadesCheckInCheckOutInterface[]> {
        return this.transport.http.get<AtividadesCheckInCheckOutInterface[]>(`${this.transport.path}/atividade`);
    }
    buscaContato(): Observable<ContatosCheckInCheckOutInterface[]> {
        return this.transport.http.get<ContatosCheckInCheckOutInterface[]>(`${this.transport.path}/contato`);
    }

    salvar(params: any): Observable<any> {
        return of(null);
    }
}
