<ng-container *ngIf="configuracaoStatusAerador">
    <mat-icon
        (click)="abrirStatusAeracao()"
        [svgIcon]="configuracaoStatusAerador.icone.tipo"
        [class]="configuracaoStatusAerador.icone.classIcone"
        class="cursor-pointer ml-[15%]"
        #tooltip="matTooltip"
        [matTooltip]="configuracaoStatusAerador.icone.texto"
        matTooltipClass="tooltip-tabela-aeracao"
        matTooltipPosition="above"
    ></mat-icon>
</ng-container>
<ng-container *ngIf="configuracaoStatusAerador === undefined || configuracaoStatusAerador === null">
    <span
        class="flex rounded-md max-w-13 h-8 justify-center bg-[#E8E9EA] text-[#030229] text-base items-center cursor-default"
    >
        -
    </span>
</ng-container>
