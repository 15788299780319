<div class="componente-modal">
    <div class="d-flex mb-5 modal-botao-fechar">
        <mat-icon id="icon-close" (click)="fecharModal()">close</mat-icon>
    </div>
    <div class="modal-titulo flex justify-center items-center">
        <mat-icon [svgIcon]="'icon-temperatura'"></mat-icon>
        <span class="text-sm sm:text-2xl">{{ "ESCALA_CORES_TEMPERATURA" | transloco }} (°C)</span>
    </div>
    <div class="mt-10 text-[#00091F] font-medium flex flex-col items-center">
        <div class="flex items-center justify-start w-44 text-xs sm:text-base">
            <mat-icon
                class="mr-4 icon-size-6"
                svgIcon="mat_solid:circle"
                [ngStyle]="{
                    color: defineCoresTemperatura(0)?.fundo
                }"
            ></mat-icon
            >{{ "MENOR_QUE" | transloco }} 6
        </div>
        <div class="flex mt-3 items-center justify-start w-44 text-xs sm:text-base">
            <mat-icon
                class="mr-4 icon-size-6"
                svgIcon="mat_solid:circle"
                [ngStyle]="{
                color: defineCoresTemperatura(6)?.fundo
            }"
            ></mat-icon
            >6-12
        </div>
        <div class="flex mt-3 items-center justify-start w-44 text-xs sm:text-base">
            <mat-icon
                class="mr-4 icon-size-6"
                svgIcon="mat_solid:circle"
                [ngStyle]="{
                color: defineCoresTemperatura(12.01)?.fundo
            }"
            ></mat-icon
            >12-18
        </div>
        <div class="flex mt-3 items-center justify-start w-44 text-xs sm:text-base">
            <mat-icon
                class="mr-4 icon-size-6"
                svgIcon="mat_solid:circle"
                [ngStyle]="{
                color: defineCoresTemperatura(18.01)?.fundo
            }"
            ></mat-icon
            >18-24
        </div>
        <div class="flex mt-3 items-center justify-start w-44 text-xs sm:text-base">
            <mat-icon
                class="mr-4 icon-size-6"
                svgIcon="mat_solid:circle"
                [ngStyle]="{
                color: defineCoresTemperatura(24.01)?.fundo
            }"
            ></mat-icon
            >24-30
        </div>
        <div class="flex mt-3 items-center justify-start w-44 text-xs sm:text-base">
            <mat-icon
                class="mr-4 icon-size-6"
                svgIcon="mat_solid:circle"
                [ngStyle]="{
                color: defineCoresTemperatura(30.01)?.fundo
            }"
            ></mat-icon
            >30-36
        </div>
        <div class="flex mt-3 items-center justify-start w-44 text-xs sm:text-base">
            <mat-icon
                class="mr-4 icon-size-6"
                svgIcon="mat_solid:circle"
                [ngStyle]="{
                color: defineCoresTemperatura(36.01)?.fundo
            }"
            ></mat-icon
            >{{ "MAIOR_QUE" | transloco }} 36
        </div>
        <div class="flex mt-3 items-center justify-start w-44 text-xs sm:text-base">
            <mat-icon class="text-[#CCCCCC] mr-4 icon-size-6" svgIcon="mat_solid:circle"></mat-icon>{{ "SEM_LEITURA_VALIDA" | transloco }}
        </div>
        <button class="botao-fechar-botton text-xs sm:text-base" (click)="fecharModal()">{{ "FECHAR" | transloco }}</button>
    </div>
</div>
