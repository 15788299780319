import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewChild,
} from '@angular/core';

declare var $: any;
declare var PlainWarehouseView: any;

@Component({
    selector: 'app-visao-plana-armazem',
    templateUrl: './visao-plana-armazem.component.html',
    styleUrls: ['./visao-plana-armazem.component.scss'],
})
export class VisaoPlanaArmazemComponent implements AfterViewInit {
    @ViewChild('selector')
    selector: ElementRef;

    @Input() config: string;

    @Input() level: string;

    @Input() thermal: string;

    @Input() humidity: string;

    @Input() emc: string;

    @Input() selected: number;

    @Input() thermocouples: string;

    @Input() sequence: string;

    @Input() communicatingCables: string;

    @Input() alias: string;

    @Input() cables?: [];

    @Input() siloViewOptions: any;

    element: any;

    instance: any;

    constructor(private zone: NgZone, private changeDetectorRef: ChangeDetectorRef) {}

    ngAfterViewInit() {
        this.zone.runOutsideAngular(() => this.initPlugin());
        this.changeDetectorRef.detectChanges();
    }

    private initPlugin() {
        this.element = $(this.selector.nativeElement);
        this.setViewOptions(this.element);
        this.instance = new PlainWarehouseView(this.element);
    }

    private setViewOptions(_element) {
        if (this.siloViewOptions) {
            if (this.siloViewOptions.redefineCablesOrder != null) {
                _element.attr('data-cable-order', this.siloViewOptions.redefineCablesOrder);
            }
        }
    }
}
