<button
    class="botao-exportar-relatorio"
    mat-button [matMenuTriggerFor]="menu"
    [disabled]="carregando"
>
    <ng-container *ngIf="!carregando; else carregandoDados">
        <mat-icon class="icon-size-4 mr-2" [svgIcon]="'icon-exportar-relatorio'"></mat-icon>
        <span>{{ "EXPORTAR" | transloco }}</span>
    </ng-container>
</button>
<mat-menu #menu="matMenu" class="menu-exportar-relatorio" xPosition="before">
    <ng-container *ngFor="let item of opcoesExportar">
        <button mat-menu-item (click)="exportar.emit(item)">{{ item }}</button>
    </ng-container>
</mat-menu>

<ng-template #carregandoDados>
    <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'" class="ml-2">  </mat-progress-spinner>
</ng-template>
