<div class="w-full flex sm:flex-row flex-col justify-between mt-5 sm:px-5 gap-2 sm:gap-5">
    <div class="flex flex-row justify-between items-center">
        <div class="text-[#535767] text-sm font-semibold pr-3">
            {{ "DADOS_EQUILIBRIO_HIGROSCOPICO_TEMPLATE.GRAO" | transloco }}
            <span class="text-lg inline-block max-w-[100px] mt-1 truncate capitalize">
                {{ nomeProduto ?? " - " }}
            </span>
        </div>
        <img class="w-8 mb-1" src="{{ iconeProduto }}" />
    </div>
    <div class="flex flex-row w-full justify-around sm:gap-3">
        <div>
            <p
                class="text-[#535767] text-xs sm:text-sm text-left font-medium"
                matTooltip="{{ 'DADOS_EQUILIBRIO_HIGROSCOPICO_TEMPLATE.TOOLTIP_EHAMB' | transloco }}"
                matTooltipClass="tooltip-padrao"
            >
                {{ "DADOS_EQUILIBRIO_HIGROSCOPICO_TEMPLATE.EHAMB" | transloco }}
            </p>
            <p
                class="text-xs sm:text-base font-semibold leading-4 mt-0.5"
                [ngClass]="
                    equilibrioHigroscopico?.equilibrio_higroscopico?.ehamb && temEstacaoAtiva
                        ? 'wl-cor-primaria'
                        : 'text-[#535767]'
                "
            >
                {{ formataCampo(equilibrioHigroscopico?.equilibrio_higroscopico?.ehamb) ?? "-" }}
            </p>
        </div>
        <div>
            <p
                class="text-[#535767] text-xs sm:text-sm text-left font-medium"
                matTooltip="{{ 'DADOS_EQUILIBRIO_HIGROSCOPICO_TEMPLATE.TOOLTIP_AQ' | transloco }}"
                matTooltipClass="tooltip-padrao"
            >
                {{ "DADOS_EQUILIBRIO_HIGROSCOPICO_TEMPLATE.AQ" | transloco }}
            </p>
            <p
                class="text-xs sm:text-base font-semibold leading-4 mt-0.5"
                [ngClass]="
                    equilibrioHigroscopico?.equilibrio_higroscopico?.aq && temEstacaoAtiva
                        ? 'wl-cor-primaria'
                        : 'text-[#535767]'
                "
            >
                {{ formataCampo(equilibrioHigroscopico?.equilibrio_higroscopico?.aq) ?? "-" }}
            </p>
        </div>
        <div>
            <p
                class="text-[#535767] text-xs sm:text-sm text-left font-medium"
                matTooltip="{{ 'DADOS_EQUILIBRIO_HIGROSCOPICO_TEMPLATE.TOOLTIP_TAQ' | transloco }}"
                matTooltipClass="tooltip-padrao"
            >
                {{ "DADOS_EQUILIBRIO_HIGROSCOPICO_TEMPLATE.TAQ" | transloco }}
            </p>
            <p
                class="text-xs sm:text-base font-semibold leading-4 mt-0.5"
                [ngClass]="
                    equilibrioHigroscopico?.equilibrio_higroscopico?.taq && temEstacaoAtiva
                        ? 'wl-cor-primaria'
                        : 'text-[#535767]'
                "
            >
                {{ formataCampo(equilibrioHigroscopico?.equilibrio_higroscopico?.taq) ?? "-" }}
            </p>
        </div>
        <div>
            <p
                class="text-[#535767] text-xs sm:text-sm text-left font-medium"
                matTooltip="{{ 'DADOS_EQUILIBRIO_HIGROSCOPICO_TEMPLATE.TOOLTIP_URAQ' | transloco }}"
                matTooltipClass="tooltip-padrao"
            >
                {{ "DADOS_EQUILIBRIO_HIGROSCOPICO_TEMPLATE.URAQ" | transloco }}
            </p>
            <p
                class="text-xs sm:text-base font-semibold leading-4 mt-0.5"
                [ngClass]="
                    equilibrioHigroscopico?.equilibrio_higroscopico?.uraq && temEstacaoAtiva
                        ? 'wl-cor-primaria'
                        : 'text-[#535767]'
                "
            >
                {{ formataCampo(equilibrioHigroscopico?.equilibrio_higroscopico?.uraq) ?? "-" }}
            </p>
        </div>
        <div>
            <p
                class="text-[#535767] text-xs sm:text-sm text-left font-medium"
                matTooltip="{{ 'DADOS_EQUILIBRIO_HIGROSCOPICO_TEMPLATE.TOOLTIP_EHAQ' | transloco }}"
                matTooltipClass="tooltip-padrao"
            >
                {{ "DADOS_EQUILIBRIO_HIGROSCOPICO_TEMPLATE.EHAQ" | transloco }}
            </p>
            <p
                class="text-xs sm:text-base font-semibold leading-4 mt-0.5"
                [ngClass]="
                    equilibrioHigroscopico?.equilibrio_higroscopico?.ehaq && temEstacaoAtiva
                        ? 'wl-cor-primaria'
                        : 'text-[#535767]'
                "
            >
                {{ formataCampo(equilibrioHigroscopico?.equilibrio_higroscopico?.ehaq) ?? "-" }}
            </p>
        </div>
    </div>
</div>
