<mat-form-field class="w-full text-[#535767]" (click)="abrirCalendario()">
    <mat-label class="text-sm">{{ "PERIODO" | transloco }} </mat-label>
    <input        
        matInput
        type="text"
        ngxDaterangepickerMd
        [formControl]="controlePeriodo"
        (keydown)="$event.preventDefault()"
        [locale]="traducaoCalendario"
        (click)="abrirCalendario()"
        [isInvalidDate]="dataInvalida"
        [showCustomRangeLabel]="habililarOpcoesPersonalizadas && !ocultarCalendario"
        [alwaysShowCalendars]="!ocultarCalendario"
        [ranges]="opcoesPersonalizadas"
        [linkedCalendars]="true"
        [timePicker]="true"
        [timePickerSeconds]="false"
        [showRangeLabelOnInput]="habililarOpcoesPersonalizadas"
        [timePickerIncrement]="1"
        [showCancel]="true"
        [timePicker24Hour]="true"
        [opens]="alinharLado ?? ''"
        placeholder="{{ 'SELECIONAR' | transloco }}"
        (rangeClicked)="opacaoPersonalizadaSelecionada($event)"
        (datesUpdated)="altaracaoDeRange()"
    />

    <button
        v-if="!ocultarCalendario"
        type="button"
        class="ngx-daterangepicker-action waves-effect waves-light btn w-5 h-5"
        (click)="abrirCalendario($event)"
    ></button>
</mat-form-field>
