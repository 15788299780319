import { Injectable } from '@angular/core';
import { SiloBaseEnum } from 'app/shared/enums';
import { InformacoesSiloInterface } from 'app/shared/interfaces';
import { IConfiguraEspacoSensoresMapaSiloService } from 'app/shared/services';

declare let $;
export abstract class IRecarregaVisaoSiloService {
    abstract execute(visao: any, dadosSilo: InformacoesSiloInterface, tipoSensor?: number, configuracao?: string): void;
}
@Injectable({ providedIn: 'root' })
export class RecarregaVisaoSiloService implements IRecarregaVisaoSiloService {
    constructor(private configuraEspacoSensoresMapaSiloService: IConfiguraEspacoSensoresMapaSiloService) {}
    public execute(visao: any, dadosSilo: any, tipoSensor?: number, configuracao?: string): void {
        const corMapa = {
            verde: 0,
            azul: 1,
            amerelo: 2,
        };

        let siloBaseV = false;
        let siloBaseSemiV = false;
        let e = $('.plain-silo-view').length > 0 ? $('.plain-silo-view') : $('.plain-warehouse-view');

        if (
            dadosSilo.estrutura_armazenagem_divisao?.silo?.base === SiloBaseEnum.EM_V ||
            dadosSilo.silo_propriedade?.silo_base === SiloBaseEnum.EM_V
        ) {
            siloBaseV = true;
            e.attr('data-cone-bottom', siloBaseV);
            e.attr(
                'data-y-offset',
                this.configuraEspacoSensoresMapaSiloService?.colocaSensoresMesmaLinha(configuracao)
            );
            e.attr(
                'data-roof-sensors',
                this.configuraEspacoSensoresMapaSiloService?.adequaEspacamentoSensores(configuracao)
            );
        }
        if (
            dadosSilo.estrutura_armazenagem_divisao?.silo?.base === SiloBaseEnum.SEMI_V ||
            dadosSilo.silo_propriedade?.silo_base === SiloBaseEnum.SEMI_V
        ) {
            siloBaseSemiV = true;
            e.attr('data-semicone-bottom', siloBaseSemiV);
            e.attr(
                'data-y-offset',
                this.configuraEspacoSensoresMapaSiloService?.colocaSensoresMesmaLinha(configuracao)
            );
            e.attr(
                'data-roof-sensors',
                this.configuraEspacoSensoresMapaSiloService?.adequaEspacamentoSensores(configuracao)
            );
        }

        if (
            dadosSilo.estrutura_armazenagem_divisao?.base === SiloBaseEnum.PLANA ||
            dadosSilo.silo_propriedade?.silo_base === SiloBaseEnum.PLANA
        ) {
            e.attr('data-large-roof-window', true);
        }

        if (tipoSensor === 0 || !tipoSensor) {
            e.attr(
                'data-config',
                dadosSilo?.mapa?.sensor_quantidade_temperatura ||
                    dadosSilo?.estrutura_armazenagem_divisao?.mapa?.sensor_quantidade_temperatura
            );
            e.attr(
                'data-level',
                dadosSilo?.mapa?.sensor_nivel_temperatura ||
                    dadosSilo?.estrutura_armazenagem_divisao?.mapa?.sensor_nivel_temperatura
            );
            e.attr('data-selected-map', tipoSensor ?? corMapa.verde);
        }
        if (tipoSensor === 1) {
            e.attr(
                'data-config',
                dadosSilo?.mapa?.sensor_quantidade_umidade ||
                    dadosSilo?.estrutura_armazenagem_divisao?.mapa?.sensor_quantidade_umidade
            );
            e.attr(
                'data-level',
                dadosSilo?.mapa?.sensor_nivel_umidade ||
                    dadosSilo?.estrutura_armazenagem_divisao?.mapa?.sensor_nivel_umidade
            );
            e.attr('data-selected-map', corMapa.azul);
        }
        if (tipoSensor === 3) {
            e.attr('data-config', dadosSilo?.mapa?.sensor_quantidade_temperatura);
            e.attr('data-level', dadosSilo?.mapa?.sensor_nivel_temperatura);
            e.attr('data-selected-map', corMapa.azul);
        }

        if (tipoSensor === 2) {
            e.attr(
                'data-config',
                dadosSilo?.mapa?.sensor_quantidade_umidade ||
                    dadosSilo?.estrutura_armazenagem_divisao?.mapa?.sensor_quantidade_umidade
            );
            e.attr(
                'data-level',
                dadosSilo?.mapa?.sensor_nivel_umidade ||
                    dadosSilo?.estrutura_armazenagem_divisao?.mapa?.sensor_nivel_umidade
            );
            e.attr('data-selected-map', corMapa.amerelo);
        }

        e.attr(
            'data-emc',
            dadosSilo?.mapa?.equilibrio_higroscopico ||
                dadosSilo?.estrutura_armazenagem_divisao?.mapa?.equilibrio_higroscopico
        );
        e.attr(
            'data-humidity',
            dadosSilo?.mapa?.umidade_relativa || dadosSilo?.estrutura_armazenagem_divisao?.mapa?.umidade_relativa
        );
        e.attr(
            'data-thermal',
            dadosSilo?.mapa?.temperatura || dadosSilo?.estrutura_armazenagem_divisao?.mapa?.temperatura
        );
        e.attr(
            'data-thermocouples',
            dadosSilo?.mapa?.digital_termopar || dadosSilo?.estrutura_armazenagem_divisao?.mapa?.digital_termopar
        );
        e.attr(
            'data-communicatingCables',
            dadosSilo?.mapa?.pendulo_comunicacao || dadosSilo?.estrutura_armazenagem_divisao?.mapa?.pendulo_comunicacao
        );

        if (visao) {
            visao.instance.reload();
        }
    }
}
