import { Component, Input } from '@angular/core';
import { textoResumo } from 'app/shared/components/resumo/constants';

@Component({
    selector: 'app-resumo',
    templateUrl: './resumo.component.html',
    styleUrls: ['./resumo.component.scss'],
})
export class ResumoComponent {
    @Input() siloNome: string;
    @Input() periodoDe: string;
    @Input() periodoAte: string;
    @Input() intervalo?: string;
    @Input() tipoRegistro?: string;
    @Input() labelRange?: string;
    @Input() acionamento?: string;

    siloNomeResumo(): string {
        return this.siloNome === textoResumo.todos ? textoResumo.todasEstruturas : this.siloNome;
    }
}
