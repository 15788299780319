<div class="w-full flex sm:flex-row flex-col justify-between mt-5 sm:px-5 gap-2 sm:gap-5">
    <div class="flex flex-row justify-between items-center">
        <div class="text-[#535767] text-sm font-semibold pr-3">
            {{ "DADOS_EQUILIBRIO_HIGROSCOPICO_SENSOR_PLENUM.GRAO" | transloco }}
            <span class="text-lg inline-block max-w-[100px] mt-1 truncate capitalize">
                {{ nomeProduto ?? " - " }}
            </span>
        </div>
        <img class="w-8 mb-1" src="{{ iconeProduto }}" />
    </div>
    <div class="flex flex-row w-full justify-around sm:gap-3">
        <div>
            <p
                class="text-[#535767] text-xs sm:text-sm text-left font-medium px-[2px]"
                matTooltip="{{ 'DADOS_EQUILIBRIO_HIGROSCOPICO_SENSOR_PLENUM.TOOLTIP_EHAMB' | transloco }}"
                matTooltipClass="tooltip-padrao"
            >
                {{ "DADOS_EQUILIBRIO_HIGROSCOPICO_SENSOR_PLENUM.EHAMB" | transloco }}
            </p>
            <p
                class="text-xs sm:text-base font-semibold leading-4 mt-0.5 px-[2px]"
                [ngClass]="
                    equilibrioHigroscopico?.equilibrio_higroscopico?.ehamb && temEstacaoAtiva
                        ? 'wl-cor-primaria'
                        : 'text-[#535767]'
                "
            >
                {{ formataCampo(equilibrioHigroscopico?.equilibrio_higroscopico?.ehamb) ?? "-" }}
            </p>
        </div>
        <div>
            <p
                class="text-[#535767] text-xs sm:text-sm text-left font-medium px-[2px]"
                matTooltip="{{ 'DADOS_EQUILIBRIO_HIGROSCOPICO_SENSOR_PLENUM.TOOLTIP_AQPLENUM' | transloco }}"
                matTooltipClass="tooltip-padrao"
            >
                {{ "DADOS_EQUILIBRIO_HIGROSCOPICO_SENSOR_PLENUM.AQPLENUM" | transloco }}
            </p>
            <p
                class="text-xs sm:text-base font-semibold leading-4 mt-0.5 px-[2px]"
                [ngClass]="
                    sensorPlenum?.temperatura && temEstacaoAtiva && !produtoInvalido()
                        ? 'wl-cor-primaria'
                        : 'text-[#535767]'
                "
            >
                <ng-container *ngIf="sensorPlenum?.temperatura && estacao?.ultima_leitura?.temperatura; else valorNull">
                    {{ formataCampo(sensorPlenum?.temperatura - estacao?.ultima_leitura?.temperatura) ?? "-" }}
                </ng-container>
                <ng-template #valorNull>-</ng-template>
            </p>
        </div>
        <div>
            <p
                class="text-[#535767] text-xs sm:text-sm text-left font-medium px-[2px]"
                matTooltip="{{ 'DADOS_EQUILIBRIO_HIGROSCOPICO_SENSOR_PLENUM.TOOLTIP_TPLENUM' | transloco }}"
                matTooltipClass="tooltip-padrao"
            >
                {{ "DADOS_EQUILIBRIO_HIGROSCOPICO_SENSOR_PLENUM.TPLENUM" | transloco }}
            </p>
            <p
                class="text-xs sm:text-base font-semibold leading-4 mt-0.5 px-[2px]"
                [ngClass]="
                    sensorPlenum?.temperatura && temEstacaoAtiva && !produtoInvalido()
                        ? 'wl-cor-primaria'
                        : 'text-[#535767]'
                "
            >
                {{ formataCampo(sensorPlenum?.temperatura) ?? "-" }}
            </p>
        </div>
        <div>
            <p
                class="text-[#535767] text-xs sm:text-sm text-left font-medium px-[2px]"
                matTooltip="{{ 'DADOS_EQUILIBRIO_HIGROSCOPICO_SENSOR_PLENUM.TOOLTIP_URPLENUM' | transloco }}"
                matTooltipClass="tooltip-padrao"
            >
                {{ "DADOS_EQUILIBRIO_HIGROSCOPICO_SENSOR_PLENUM.URPLENUM" | transloco }}
            </p>
            <p
                class="text-xs sm:text-base font-semibold leading-4 mt-0.5 px-[2px]"
                [ngClass]="
                    sensorPlenum?.umidade && temEstacaoAtiva && !produtoInvalido()
                        ? 'wl-cor-primaria'
                        : 'text-[#535767]'
                "
            >
                {{ formataCampo(sensorPlenum?.umidade) ?? "-" }}
            </p>
        </div>
        <div>
            <p
                class="text-[#535767] text-xs sm:text-sm text-left font-medium px-[2px]"
                matTooltip="{{ 'DADOS_EQUILIBRIO_HIGROSCOPICO_SENSOR_PLENUM.TOOLTIP_EHPLENUM' | transloco }}"
                matTooltipClass="tooltip-padrao"
            >
                {{ "DADOS_EQUILIBRIO_HIGROSCOPICO_SENSOR_PLENUM.EHPLENUM" | transloco }}
            </p>
            <p
                class="text-xs sm:text-base font-semibold leading-4 mt-0.5 px-[2px]"
                [ngClass]="
                    equilibrioHigroscopico?.equilibrio_higroscopico?.ehplenum && temEstacaoAtiva
                        ? 'wl-cor-primaria'
                        : 'text-[#535767]'
                "
            >
                {{ formataCampo(equilibrioHigroscopico?.equilibrio_higroscopico?.ehplenum) ?? "-" }}
            </p>
        </div>
    </div>
</div>
