<div class="h-full w-full flex items-center">
    <mat-icon class="p-[2px]" [svgIcon]="'icon-silo-historico'" *ngIf="siloNome"></mat-icon>

    <span class="mr-3 font-semibold text-lg wl-cor-primaria ml-2">{{ siloNomeResumo() }}</span>
    <div class="mr-3 text-[#535767]">
        <span class="font-semibold">{{"PERIODO" | transloco}}: </span>
        <ng-container *ngIf="labelRange">
            <span style="font-size: 12.5px"> {{ labelRange }}</span>
        </ng-container>
        <ng-container *ngIf="!labelRange">
            <span style="font-size: 12.5px">{{ periodoDe | date : "dd/MM/yyyy HH:mm" }}</span>
            <span> a </span>
            <span style="font-size: 12.5px">{{ periodoAte | date : "dd/MM/yyyy HH:mm" }}</span>
        </ng-container>
    </div>
    <ng-container *ngIf="tipoRegistro">
        <div class="text-[#535767]">
            <span class="font-semibold" [innerHtml]="tipoRegistro"></span>:
            <span style="font-size: 12.5px"> {{ intervalo }}</span>
        </div>
    </ng-container>
    <ng-container *ngIf="acionamento">
        <div class="text-[#535767]">
            <span class="font-semibold">Acionamento: </span>
            <span style="font-size: 12.5px"> {{ acionamento | titlecase }}</span>
        </div>
    </ng-container>
</div>
