import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';


import { Subject, takeUntil } from 'rxjs';

import { EquilibrioHigroscopicoModel } from 'app/shared/models';
import { IFormataCampoValorService } from 'app/shared/services';
import { produtoNaoInformado } from 'app/shared/constants';
import { IconeProdutoHelper } from 'app/shared/helpers';
import { MediaWatcherService } from 'app/core/media-watcher/media-watcher.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'app-tamplate-dados-equilibrio-higroscopico',
    templateUrl: './tamplate-dados-equilibrio-higroscopico.component.html',
    styleUrls: ['./tamplate-dados-equilibrio-higroscopico.component.scss'],
})
export class TamplateDadosEquilibrioHigroscopicoComponent implements OnInit, OnChanges {
    @Input() equilibrioHigroscopico: EquilibrioHigroscopicoModel;
    @Input() temEstacaoAtiva: boolean | undefined;
    @Input() nomeProduto: string;

    iconeProduto: string;
    modoMobile: boolean;

    private unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private formataCampoValorService: IFormataCampoValorService,
        private mediaWatcherService: MediaWatcherService,
        private transloco: TranslocoService
    ) {}

    ngOnInit() {
        this.mediaWatcherService.onMediaChange$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                this.modoMobile = !matchingAliases.includes('sm');
            });
    }

    ngOnChanges(): void {
        this.iconeProduto = this.selecionaIconeProduto(this.nomeProduto);
    }

    formataCampo(valor: number | string): number | string {
        if (!this.temEstacaoAtiva || this.produtoInvalido()) {
            return '-';
        }
        return this.formataCampoValorService.execute(valor);
    }

    private produtoInvalido(): boolean | false {
        return this.nomeProduto === produtoNaoInformado;
    }
    private selecionaIconeProduto(nomeProduto: string): string {
        return IconeProdutoHelper.selecionaIconeProduto(nomeProduto);
    }
}
