<div id="tooltipCustomPendulo" class="absolute z-99 rounded popoverCable" style="width: 200px; height: 80px">
    <div class="w-full bg-slate-200" *ngIf="penduloAtual">
        <span>{{ "P" + (penduloAtual?.pendulo?.codigo | number : "2.0") }}</span>
    </div>
    <div class="w-full bg-slate-100 text-sm p-1.5 text-left" *ngIf="penduloAtual">
        <p *ngIf="equipamentoAtual(penduloAtual)?.equipamento_comunicacao?.instante">
            <span>
                {{ "TIPO" | transloco }}:
                {{ penduloAtual?.pendulo?.pendulo_propriedade?.digital_termopar }}
            </span>
        </p>
        <p *ngIf="equipamentoAtual(penduloAtual)?.equipamento_comunicacao?.instante">
            <span> {{ "EQUIP" | transloco }}.: {{ equipamentoAtual(penduloAtual)?.nome }} </span>
        </p>
        <p *ngIf="equipamentoAtual(penduloAtual)?.equipamento_comunicacao?.instante">
            <span> {{ "STATUS" | transloco }}: </span>
            <span>
                {{
                    equipamentoAtual(penduloAtual)?.equipamento_comunicacao?.instante
                        ? (equipamentoAtual(penduloAtual)?.equipamento_comunicacao?.instante
                          | date : "dd/MM/yyyy HH:mm")
                        : ("AGUARDANDO_LEITURA" | transloco)
                }}
            </span>
            <mat-icon id="icontooltipCustomPendulo" svgIcon="mat_solid:circle"></mat-icon>
        </p>
    </div>
</div>
