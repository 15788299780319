import { Injectable } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';

import { EquipamentoCategoriaEnum, InformacoesEquipamentoCategoriaEnum } from 'app/shared/enums';
export abstract class IDefineCategoriaEquipamentoService {
    abstract execute(tipo: EquipamentoCategoriaEnum): string;
}

@Injectable({ providedIn: 'root' })
export class DefineCategoriaEquipamentoService implements IDefineCategoriaEquipamentoService {
    private categoriaMap = new Map<EquipamentoCategoriaEnum, InformacoesEquipamentoCategoriaEnum>([
        [EquipamentoCategoriaEnum.TERMOMETRIA, InformacoesEquipamentoCategoriaEnum.TERMOMETRIA],
        [EquipamentoCategoriaEnum.ESTACAO_METEOROLOGICA, InformacoesEquipamentoCategoriaEnum.ESTACAO_METEOROLOGICA],
        [EquipamentoCategoriaEnum.AUTOMACAO, InformacoesEquipamentoCategoriaEnum.AUTOMACAO],
        [EquipamentoCategoriaEnum.AERACAO, InformacoesEquipamentoCategoriaEnum.AERACAO],
        [EquipamentoCategoriaEnum.SECADOR, InformacoesEquipamentoCategoriaEnum.SECADOR],
    ]);

    constructor(private translocoService: TranslocoService) {}

    execute(tipo: EquipamentoCategoriaEnum): string {
        return this.translocoService.translate(
            this.categoriaMap.get(tipo) ?? InformacoesEquipamentoCategoriaEnum.LEITURA_AUXILIAR
        );
    }
}
