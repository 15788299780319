import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CheckInCheckOutObservable {
    private contadorCheckOut: BehaviorSubject<string | number | null> = new BehaviorSubject(null);

    set setValorContadorCheckOut(value: string | number | null) {
        this.contadorCheckOut.next(value);
    }

    get getValorContadorCheckOut(): string | number | null {
        return this.contadorCheckOut.getValue();
    }

    get contadorCheckOut$(): Observable<string | number | null> {
        return this.contadorCheckOut.asObservable();
    }
}
