import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { StatusAutomaticoManualComIconeDescricaoModule } from 'app/shared/components';
import { ModalHistoricoControleAeracaoComponent } from './modal-historico-controle-aeracao.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatTableModule,
        MatProgressSpinnerModule,
        StatusAutomaticoManualComIconeDescricaoModule,
        TranslocoRootModule,
        InfiniteScrollModule
    ],
    declarations: [ModalHistoricoControleAeracaoComponent],
    exports: [ModalHistoricoControleAeracaoComponent],
})
export class ModalHistoricoControleAeracaoModule {}
