import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TooltipPenduloHistoricoComponent } from './tooltip-pendulo-historico.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    declarations: [TooltipPenduloHistoricoComponent],
    imports: [CommonModule, MatIconModule, TranslocoModule],
    exports: [TooltipPenduloHistoricoComponent],
})
export class TooltipPenduloHistoricoModule {}
