<div class="componente-modal">
    <div class="d-flex mb-5 modal-botao-fechar">
        <mat-icon id="icon-close" (click)="matDialogRef.close()">close</mat-icon>
    </div>
    <div class="modal-titulo flex justify-center items-center">
        <span class="text-base sm:text-2xl"
            >{{ "ESCALA_CORES_CO" | transloco }}<sub>2</sub> ({{
                "PPM" | transloco
            }})</span
        >
    </div>
    <div class="mt-10 text-[#00091F] font-medium flex flex-col max-w-90 mx-auto text-sm sm:text-base">
        <span class="flex justify-start max-w-[340px]">
            <mat-icon
                class="mr-4 icon-size-6"
                svgIcon="mat_solid:circle"
                [ngStyle]="{
                    color: '#3D50F3'
                }"
            ></mat-icon
            >&lt; 400 -
            {{ "NIVEIS_DO_AR_AMBIENTE" | transloco }}
        </span>
        <span class="flex mt-3 justify-start max-w-[340px]">
            <mat-icon
                class="mr-4 icon-size-6"
                svgIcon="mat_solid:circle"
                [ngStyle]="{
                    color: '#3DE95A'
                }"
            ></mat-icon
            >400 {{ "A_MINUSCULO" | transloco }} 600 -
            {{ "NIVEIS_NORMAIS" | transloco }}
        </span>
        <span class="flex mt-3 justify-start max-w-[340px]">
            <mat-icon
                class="mr-4 icon-size-6"
                svgIcon="mat_solid:circle"
                [ngStyle]="{
                    color: '#FEE251'
                }"
            ></mat-icon
            >600 {{ "A_MINUSCULO" | transloco }} 1100 -
            {{ "DESTERIORACAO_INCIPIENTE" | transloco }}
        </span>      
       
        <span class="flex mt-3 justify-start max-w-[340px]">
            <mat-icon
                class="mr-4 icon-size-6"
                svgIcon="mat_solid:circle"
                [ngStyle]="{
                    color: '#EB7400'
                }"
            ></mat-icon
            >1100 {{ "A_MINUSCULO" | transloco }} 5000 -
            {{ "ELEVADA_DETERIORACAO" | transloco }}
        </span>
        <span class="flex mt-3 justify-start max-w-[340px]">
            <mat-icon
                class="mr-4 icon-size-6"
                svgIcon="mat_solid:circle"
                [ngStyle]="{
                    color: '#DE0606'
                }"
            ></mat-icon
            >> 5000 -
            {{ "NIVEIS_ALTAMENTE_INADEQUADOS" | transloco }}
        </span>
        <button class="botao-fechar-botton mx-auto" (click)="matDialogRef.close()">
            {{ "FECHAR" | transloco }}
        </button>
    </div>
</div>
