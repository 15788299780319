export enum NotificacaoAlterarEstoqueNivelEnum {
    SUCESSO = 'NOTIFICACAO_ALTERACAO_ESTOQUE_NIVEL_COMPONENT.SUCESSO',
    ERRO = 'NOTIFICACAO_ALTERACAO_ESTOQUE_NIVEL_COMPONENT.ERRO',
}

export enum NotificacaoRegistroAmostragemEnum {
    SUCESSO = 'NOTIFICACAO_REGISTRO_AMOSTRAGEM_COMPONENT.SUCESSO',
    ERRO = 'NOTIFICACAO_REGISTRO_AMOSTRAGEM_COMPONENT.ERRO',
}

export enum NotificacaoAtualizacaoAmostragemEnum {
    SUCESSO = 'NOTIFICACAO_ATUALIZACAO_AMOSTRAGEM_COMPONENT.SUCESSO',
    ERRO = 'NOTIFICACAO_ATUALIZACAO_AMOSTRAGEM_COMPONENT.ERRO',
    ALERTA = 'NOTIFICACAO_ATUALIZACAO_AMOSTRAGEM_COMPONENT.ALERTA',
    ARQUIVAR_SUCESSO = 'RECEBIMENTO_EXPEDICAO_PAINEL_LISTAGEM.RECEBIMENTO_DESARQUIVADO_COM_SUCESSO_EXCLAMACAO',
    ARQUIVAR_ERRO = 'NOTIFICACAO_ATUALIZACAO_AMOSTRAGEM_COMPONENT.ARQUIVAR_ERRO',
    DESARQUIVAR_SUCESSO = 'RECEBIMENTO_EXPEDICAO_PAINEL_LISTAGEM.RECEBIMENTO_ARQUIVADO_COM_SUCESSO_EXCLAMACAO',
    DESARQUIVAR_ERRO = 'NOTIFICACAO_ATUALIZACAO_AMOSTRAGEM_COMPONENT.DESARQUIVAR_ERRO',
    DESARQUIVAR_MENSAGEM = 'NOTIFICACAO_ATUALIZACAO_AMOSTRAGEM_COMPONENT.DESARQUIVAR_MENSAGEM',
    ARQUIVAR_MENSAGEM = 'NOTIFICACAO_ATUALIZACAO_AMOSTRAGEM_COMPONENT.ARQUIVAR_MENSAGEM',
}

export enum NotificacaoArquivarDesarquivarParametrizacaoEnum {
    ARQUIVAR_MENSAGEM = 'PAINEL_ORDEM_EXPEDICAO.DESEJA_REALMENTE_ARQUIVAR_A_PARAMETRIZACAO_INTERROGACAO_REGISTRO_FICARA_INATIVO',
    DESARQUIVAR_MENSAGEM = 'PAINEL_ORDEM_EXPEDICAO.DESEJA_REALMENTE_DESARQUIVAR_A_PARAMETRIZACAO_INTERROGACAO',
}

export enum NotificacaoAcessoUsuarioEnum {
    CADASTRO_SUCESSO = 'NOTIFICACAO_ACESSO_USUARIO_COMPONENT.CADASTRO_SUCESSO',
    CADASTRO_ERRO = 'NOTIFICACAO_ACESSO_USUARIO_COMPONENT.CADASTRO_ERRO',
    EDITAR_SUCESSO = 'NOTIFICACAO_ACESSO_USUARIO_COMPONENT.EDITAR_SUCESSO',
    EDITAR_ERRO = 'NOTIFICACAO_ACESSO_USUARIO_COMPONENT.EDITAR_ERRO',
}
