import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EstadoComunicacaoEnum, ListaEquipamentosEnum } from 'app/shared/enums';
import { EquipamentoInformacoesSiloInterface } from 'app/shared/interfaces';
import { IDefineCategoriaEquipamentoService, IRetornaStatusComunicacaoService } from 'app/shared/services';

@Component({
    selector: 'app-modal-equipamentos-fullscreen',
    templateUrl: './modal-equipamentos-fullscreen.component.html',
    styleUrls: ['./modal-equipamentos-fullscreen.component.scss'],
})
export class ModalEquipamentosFullscreenComponent implements OnInit {
    tabelaColunas: string[] = [
        ListaEquipamentosEnum.EQUIPAMENTO,
        ListaEquipamentosEnum.CATEGORIA,
        ListaEquipamentosEnum.STATUS,
        ListaEquipamentosEnum.DATA_HORA,
    ];

    listaStatusComunicacao: boolean[] = [];
    carregando: boolean = true;
    listaEquipamentos: EquipamentoInformacoesSiloInterface[] = [];
    estruturaArmazenagem: { nome: string };
    estadoComunicacaoEnum = EstadoComunicacaoEnum;

    constructor(
        readonly retornaStatusComunicacaoService: IRetornaStatusComunicacaoService,
        public readonly dialogRef: MatDialogRef<EquipamentoInformacoesSiloInterface[]>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            equipamentos: EquipamentoInformacoesSiloInterface[];
            estrutura: { nome: string };
        },
        public readonly defineCategoriaEquipamentoService: IDefineCategoriaEquipamentoService
    ) {}

    ngOnInit(): void {
        this.listaEquipamentos = this.data.equipamentos;
        this.estruturaArmazenagem = this.data.estrutura;
        this.listaEquipamentos?.forEach((equipamento) => {
            this.listaStatusComunicacao.push(equipamento.equipamento_comunicacao?.status);           
        });        
        this.carregando = false;
    }
}
