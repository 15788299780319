import { Component, Input, OnInit } from '@angular/core';
import { TipoSensoresTecnologiaEnum } from 'app/shared/enums';
import { VerificaObjetoVazio } from 'app/shared/helpers';
import { PenduloInformacoesSiloInterface } from 'app/shared/interfaces';
import { ICriaPaletaCoresTermometriaService, IVerificaValidadeDeAmostragemService } from 'app/shared/services';

@Component({
    selector: 'app-sensores-informacoes-gerais',
    templateUrl: './sensores-informacoes-gerais.component.html',
    styleUrls: ['./sensores-informacoes-gerais.component.scss'],
})
export class SensoresInformacoesGeraisComponent implements OnInit {
    @Input() pendulos: any[];
    @Input() sensores: any;
    @Input() temSensorUmidade: boolean;
    @Input() sensorCO2: any;
    @Input() sensoreCO2Equipamentos: any[];
    @Input() equipamentos: any[];

    tipoSensorEnum = TipoSensoresTecnologiaEnum;

    defineCoresTemperatura = this.criaPaletaCoresSensoresService.criarPaletaTemperatura;
    defineCoresUmidade = this.criaPaletaCoresSensoresService.criarPaletaUmidade;
    verificaObjetoVazio = VerificaObjetoVazio.execute;

    constructor(
        readonly criaPaletaCoresSensoresService: ICriaPaletaCoresTermometriaService,
        readonly verificaValidadeDeAmostragemService: IVerificaValidadeDeAmostragemService
    ) {}

    ngOnInit() {}

    verificaTipoSensorExistente(tipoSensor: string): boolean {
        return this.pendulos?.some((p) => p.pendulo.pendulo_propriedade?.digital_termopar === tipoSensor);
    }
}
