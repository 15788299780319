import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { TamplateDadosEquilibrioHSensorPlenumComponent } from 'app/shared/components/tamplate-dados-equilibrio-sensor-plenum/tamplate-dados-equilibrio-sensor-plenum.component';

@NgModule({
    imports: [CommonModule, TranslocoModule, MatTooltipModule],
    declarations: [TamplateDadosEquilibrioHSensorPlenumComponent],
    exports: [TamplateDadosEquilibrioHSensorPlenumComponent],
})
export class TamplateDadosEquilibrioSensorPlenumModule {}
