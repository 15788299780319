import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StatusAeracaoModel } from 'app/shared/models';
import { IConfigurarStatusAeracaoService } from 'app/shared/services';
import { ModalStatusAeracaoComponent } from 'app/shared/components';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AeradorStatuSodeEnum } from 'app/shared/enums';

const configuracoesTamanho = {
    widthDesktopPx: '650px',
    heightDesktopPx: '416px',
    breakpointMobile: '(max-width: 639px)',
    widthMobilePx: '100%',
    heightMobilePx: 'auto',
};
@Component({
    selector: 'app-status-aeracao',
    templateUrl: './status-aeracao.component.html',
    styleUrls: ['./status-aeracao.component.scss'],
})
export class StatusAeracaoComponent implements OnInit {
    @Output() statusAerador = new EventEmitter<StatusAeracaoModel>();

    @Input() statusCode?: AeradorStatuSodeEnum;
    @Input() equipamentoRele?: string;
    @Input() equipamentoStatus?: string;
    @Input() equipamentoChave?: string;
    @Input() habilitarModal?: boolean | false;
    configuracaoStatusAerador: StatusAeracaoModel;

    constructor(
        private configurarStatusAeracaoService: IConfigurarStatusAeracaoService,
        private dialog: MatDialog,
        private breakpointObserver: BreakpointObserver
    ) {}

    ngOnInit() {
        this.configuracaoStatusAerador = this.configurarStatusAeracaoService.execute(this.statusCode);
        this.statusAerador.emit(this.configuracaoStatusAerador);
    }

    abrirStatusAeracao(): void {
        if (!this.habilitarModal) return;

        const smallScreen = this.breakpointObserver.isMatched(configuracoesTamanho.breakpointMobile);
        const minWidth = smallScreen ? configuracoesTamanho.widthMobilePx : configuracoesTamanho.widthDesktopPx;
        const minHeight = smallScreen ? configuracoesTamanho.heightMobilePx : configuracoesTamanho.heightDesktopPx;
        this.dialog.open(ModalStatusAeracaoComponent, {
            minWidth,
            minHeight,
            data: {
                config: this.configuracaoStatusAerador,
                equipamentoRele: this.equipamentoRele,
                equipamentoStatus: this.equipamentoStatus,
                equipamentoChave: this.equipamentoChave,
            },
        });
    }
}
