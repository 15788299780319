import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { InformacoesEstacaoMeteorologicaComponent } from 'app/shared/components/informacoes-estacao-meteorologica/informacoes-estacao-meteorologica.component';
import { TemplateInformacoesEstacaoMetereologicaComponent } from 'app/shared/components/informacoes-estacao-meteorologica//template-informacoes-estacao-metereologica/template-informacoes-estacao-metereologica.component';
import { CarouselModule } from 'app/shared/components/carousel/carousel.module';

@NgModule({
    declarations: [InformacoesEstacaoMeteorologicaComponent, TemplateInformacoesEstacaoMetereologicaComponent],
    imports: [
        CarouselModule,
        CommonModule,
        MatIconModule,
        MatTooltipModule,
        TranslocoModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
    ],
    exports: [InformacoesEstacaoMeteorologicaComponent],
})
export class InformacoesEstacaoMeteorologicaModule {}
