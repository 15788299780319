import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { TranslocoRootModule } from "app/transloco-root.module";
import { InputTelefonePaisComponent } from "app/shared/components";
import { NgxMaskModule } from "ngx-mask";
@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    TranslocoRootModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    NgxMaskModule,
  ],
  exports: [InputTelefonePaisComponent],
  declarations: [InputTelefonePaisComponent],
})
export class InputTelefonePaisModule {}
