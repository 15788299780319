import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ICriaPaletaCoresTermometriaService } from 'app/shared/services';

@Component({
    selector: 'app-modal-escala-cores-co2',
    templateUrl: './modal-escala-cores-co2.component.html',
    styleUrls: ['./modal-escala-cores-co2.component.scss'],
})
export class ModalEscalaCoresCO2Component {
    defineCoresCO2 = this.criaPaletaCoresSensoresService.criarPaletaCO2;

    constructor(
        public matDialogRef: MatDialogRef<ModalEscalaCoresCO2Component>,
        readonly criaPaletaCoresSensoresService: ICriaPaletaCoresTermometriaService
    ) {}
}
