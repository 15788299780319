import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { TamplateDadosEquilibrioHigroscopicoComponent } from 'app/shared/components/tamplate-dados-equilibrio-higroscopico/tamplate-dados-equilibrio-higroscopico.component';

@NgModule({
    imports: [CommonModule, TranslocoModule, MatTooltipModule ],
    declarations: [TamplateDadosEquilibrioHigroscopicoComponent],
    exports: [TamplateDadosEquilibrioHigroscopicoComponent],
})
export class TamplateDadosEquilibrioHigroscopicoModule {}
