import { Injectable } from '@angular/core';

export abstract class IRetornaIndexHIstoricoService {
    abstract retornaIndex(id: number, array: any[]): any;
}
@Injectable({ providedIn: 'root' })
export class RetornaIndexHIstoricoService
    implements IRetornaIndexHIstoricoService
{
    public retornaIndex(instante: any, array: any[]): number {
        let index = null;
        array.forEach((item, position) => {
            if (instante === item.comunicacaoInfo.instante) {
                index = position;
            }
        });
        return index;
    }
}
