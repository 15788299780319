import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IControleAeracaoController } from 'app/modules/armazenagem/visualizacao/painel-geral/controllers';
import { ComandoEnum, SucessoApiEnum } from 'app/shared/enums';
import { IAlertaService } from 'app/shared/services';
import { take } from 'rxjs';
import { ComandoAeracaoInterface } from 'app/modules/armazenagem/visualizacao/painel-geral/interfaces';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'app-modal-acionamento-manul-aeracao',
    templateUrl: './modal-acionamento-manul-aeracao.component.html',
    styleUrls: ['./modal-acionamento-manul-aeracao.component.scss'],
})
export class ModalAcionamentoManulAeracaoComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) readonly data: any,
        readonly dialogRef: MatDialogRef<any>,
        readonly controleAeracaoController: IControleAeracaoController,
        private notificacaoService: IAlertaService,
        private transloco: TranslocoService
    ) {}

    substituiVariavel(valor: string, valorAtual: any): string {
        return valor.replace('{{valor}}', valorAtual);
    }

    acionamentoManual(): void {
        const payloadComando: ComandoAeracaoInterface = {
            estrutura_armazenagem_divisao_id: this.data.unidadeArmazenagem.id,
            comando:
                this.data.configuracaoStatus.acionamento_manual.modal.botaoAcao === ComandoEnum.DESLIGAR
                    ? ComandoEnum.DESLIGAR
                    : ComandoEnum.LIGAR,
        };

        this.controleAeracaoController
            .enviaComandoAeracao(payloadComando)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.dialogRef.close(true);
                    this.notificacaoService.mostrarSucesso(
                        this.transloco.translate(SucessoApiEnum.COMANDO_ENVIADO_SUCESSO)
                    );
                },
                error: (error) => {
                    this.dialogRef.close(false);
                    this.notificacaoService.mostrarErro(error.error.error.message, '');
                },
            });
    }
}
