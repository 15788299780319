import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-modal-entenda-visao-superior-armazem',
    templateUrl: './modal-entenda-visao-superior-armazem.component.html',
    styleUrls: ['./modal-entenda-visao-superior-armazem.component.scss'],
})
export class ModalEntendaVisaoSuperiorArmazemComponent implements OnInit {
    constructor(public matDialogRef: MatDialogRef<ModalEntendaVisaoSuperiorArmazemComponent>) {}

    ngOnInit() {}
}
