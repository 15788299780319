import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { ModalEquipamentosFullscreenComponent } from 'app/shared/components/modal-equipamentos-fullscreen/modal-equipamentos-fullscreen.component';


@NgModule({
    imports: [
        CommonModule, 
        MatIconModule, 
        MatDialogModule, 
        TranslocoRootModule,
        MatTableModule
    ],    
    declarations: [ModalEquipamentosFullscreenComponent],   
    exports: [ModalEquipamentosFullscreenComponent],
})
export class ModalEquipamentosFullscreenModule {}
