import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  ViewChild,
  OnDestroy,
  OnChanges,
} from "@angular/core";
import { AeracaoHistoricoInterface } from "app/modules/armazenagem/visualizacao/silos/interfaces";
import {
  OrigemDadosColetor,
  ProdutosProcerEnum,
  SiloBaseEnum,
} from "app/shared/enums";
import { AeradorInterface } from "app/shared/interfaces";
import { UnidadeParametrizacaoObservable } from "app/shared/observables";
import { IConfiguraEspacoSensoresMapaSiloService } from "app/shared/services";
import { take } from "rxjs";

declare let $: any;
declare let PlainSiloView: any;

@Component({
  selector: "app-visao-plana-historico-silo",
  templateUrl: "./visao-plana-historico-silo.component.html",
  styleUrls: ["./visao-plana-historico-silo.component.scss"],
})
export class VisaoPlanaHistoricoSiloComponent
  implements OnChanges, AfterViewInit, OnDestroy
{
  @ViewChild("selector") selector: ElementRef;

  @Input() historico: any;
  @Input() config: string;
  @Input() level: string;
  @Input() thermal: string;
  @Input() humidity: string;
  @Input() sequence: string;
  @Input() emc: string;
  @Input() thermocouples: string;
  @Input() selected: number;
  @Input() communicatingCables: string;
  @Input() communicatingInfo: any;
  @Input() siloViewOptions: any;
  @Input() alias: string;
  @Input() cables?: [];
  @Input() exibirAeradores?: boolean = false;
  @Input() exibirTooltip: boolean = true;

  possuiAeracaoAutomatica?: boolean = false;
  listaAeradores: AeradorInterface[] = [];
  aeracao: AeracaoHistoricoInterface;
  ordenacaoPendulos: string;

  element: any;
  instance: any;

  constructor(
    private zone: NgZone,
    private _changeDetectorRef: ChangeDetectorRef,
    private configuraEspacoSensoresMapaSiloService: IConfiguraEspacoSensoresMapaSiloService,
    private unidadeParametrizacaoObservable: UnidadeParametrizacaoObservable
  ) {}

  ngOnChanges(): void {
    this.listaAeradores = [];
    if (
      this.historico?.estrutura_armazenagem_divisao
        ?.estrutura_armazenagem_divisao_aerador?.length > 0
    ) {
      this.listaAeradores =
        this.historico?.estrutura_armazenagem_divisao?.estrutura_armazenagem_divisao_aerador;
      this.aeracao = this.historico?.estrutura_armazenagem_divisao.aeracao;
    }
  }

  ngAfterViewInit(): void {
    this.buscarUnidadesParametrizacao();
    this.zone.runOutsideAngular(() => this.initPlugin());
    this._changeDetectorRef.detectChanges();
  }

  private initPlugin(): void {
    this.element = $(this.selector.nativeElement);
    this.setViewOptions(this.element);
    this.instance = new PlainSiloView(this.element);
  }

  private setViewOptions(_element: any): void {
    this.podeDispararEventoCustomizadoAeradores(_element);
    if (this.siloViewOptions) {
      if (this.siloViewOptions === SiloBaseEnum.EM_V) {
        _element.attr("data-cone-bottom", true);
        const sensoresTelhado = this.configuraEspacoSensoresMapaSiloService
          ?.adequaEspacamentoSensores(this.config)
          .split(";");
        if (!sensoresTelhado.every((valor) => valor === "0")) {
          _element.attr(
            "data-y-offset",
            this.configuraEspacoSensoresMapaSiloService?.colocaSensoresMesmaLinha(
              this.config
            )
          );
          _element.attr(
            "data-roof-sensors",
            this.configuraEspacoSensoresMapaSiloService?.adequaEspacamentoSensores(
              this.config
            )
          );
        }
      }
      if (this.siloViewOptions === SiloBaseEnum.PLANA) {
        _element.attr("data-large-roof-window", true);
        const sensoresTelhado = this.configuraEspacoSensoresMapaSiloService
          ?.adequaEspacamentoSensores(this.config)
          .split(";");

        if (
          sensoresTelhado.length > 15 &&
          sensoresTelhado.some((item) => item !== "0")
        ) {
          _element.attr(
            "data-roof-sensors",
            this.configuraEspacoSensoresMapaSiloService?.adequaEspacamentoSensores(
              this.config
            )
          );
        }

        if (
          !sensoresTelhado.every((valor) => valor === "0") &&
          !this.ordenacaoPendulos
        ) {
          _element.attr(
            "data-roof-sensors",
            this.configuraEspacoSensoresMapaSiloService?.adequaEspacamentoSensores(
              this.config
            )
          );
        }
      }

      if (this.siloViewOptions === SiloBaseEnum.SEMI_V) {
        _element.attr("data-semicone-bottom", true);
        _element.attr(
          "data-y-offset",
          this.configuraEspacoSensoresMapaSiloService?.colocaSensoresMesmaLinha(
            this.config
          )
        );
        _element.attr(
          "data-roof-sensors",
          this.configuraEspacoSensoresMapaSiloService?.adequaEspacamentoSensores(
            this.config
          )
        );
      }
    }
  }
  private podeDispararEventoCustomizadoAeradores(_element): void {
    if (this.exibirAeradores) {
      _element.attr("data-exibir-aeradores", true);
    } else {
      _element.attr("data-exibir-aeradores", false);
    }
  }

  private buscarUnidadesParametrizacao(): void {
    this.unidadeParametrizacaoObservable.buscaParametrosUnidade$.subscribe({
      next: (response) => {
        if (response !== null) {
          this.possuiAeracaoAutomatica = response.unidade_produto_interno.some(
            (elemento) =>
              elemento.produto === ProdutosProcerEnum.AERACAO_AUTOMATICA
          );

          this.ordenacaoPendulos =
            response.unidade_parametrizacao?.origem_dados ===
            OrigemDadosColetor.COLETOR_CERES
              ? this.sequence
              : undefined;
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  ngOnDestroy(): void {
    this.element.attr("data-exibir-aeradores", false);
  }
}
