import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagSensorVolumetria3DComponent } from 'app/shared/components/tag-sensor-volumetria-3d/tag-sensor-volumetria-3d.component';
import { TranslocoModule } from '@ngneat/transloco';
@NgModule({
    imports: [CommonModule, MatIconModule, TranslocoModule],
    declarations: [TagSensorVolumetria3DComponent],
    exports: [TagSensorVolumetria3DComponent],
})
export class TagSensorVolumetria3DModule {}
