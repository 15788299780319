<div class="flex flex-row items-center justify-center">
    <div class="px-3">
        <button
            *ngIf="slidesDisponiveis.length > 1"
            class="w-7 h-7 rounded-full flex items-center justify-center bg-[#019DE80D] border-0"
            [disabled]="slideAtual === 0"
            (click)="voltarSlide()"
        >
            <mat-icon class="icon-size-5 wl-cor-primaria" [svgIcon]="'heroicons_solid:chevron-left'"></mat-icon>
        </button>
    </div>
    <div class="flex items-center w-full">
        <ng-content></ng-content>
    </div>
    <div class="px-3">
        <button
            *ngIf="slidesDisponiveis.length > 1"
            class="w-7 h-7 rounded-full flex items-center justify-center bg-[#019DE80D] border-0"
            [disabled]="slideAtual === slidesDisponiveis.length - 1"
            (click)="avancarSlide()"
        >
            <mat-icon class="icon-size-5 wl-cor-primaria" [svgIcon]="'heroicons_solid:chevron-right'"></mat-icon>
        </button>
    </div>
</div>
