import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import {
    TamplateDadosEquilibrioHigroscopicoModule,
    TamplateDadosEquilibrioSensorPlenumModule,
} from 'app/shared/components';
import { EquilibrioHigroscopicoListagemComponent } from 'app/shared/components/equilibrio-higroscopico-listagem/equilibrio-higroscopico-listagem.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        TranslocoCoreModule,
        TamplateDadosEquilibrioSensorPlenumModule,
        TamplateDadosEquilibrioHigroscopicoModule,
    ],
    declarations: [EquilibrioHigroscopicoListagemComponent],
    exports: [EquilibrioHigroscopicoListagemComponent],
})
export class EquilibrioHigroscopicoListagemModule {}
