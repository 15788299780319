<div class="relative">
    <canvas #canvasSilo3D class="w-full lg:w-[100%]"> </canvas>
    <div *ngIf="carregando" class="absolute inset-0 bg-white z-10">
        <div class="h-[200px] flex">
            <span class="m-auto flex">
                <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
            </span>
        </div>      
      </div>
</div>

<div class="justify-start z-99 absolute top-3 left-0 w-full" [ngClass]="{'esconderBotoes': carregando}">
    <div class="flex justify-between w-full">
        <div class="flex">
            <app-escala-cores-temperatura [visao3D]="true" class="pt-[12px]"></app-escala-cores-temperatura>
            <button
                mat-icon-button
                matTooltip="Aumentar o Zoom"
                matTooltipClass="tooltip-padrao"
                class="w-9 h-9 bg-transparent border-0"
                #botaoZoomIn
            >
                <mat-icon svgIcon="add_circle_outline" class="text-white icon-size-4"></mat-icon>
            </button>
            <button
                mat-icon-button
                matTooltip="Diminuir o Zoom"
                matTooltipClass="tooltip-padrao"
                class="w-9 bg-transparent border-0"
                #botaoZoomOut
            >
                <mat-icon svgIcon="remove_circle_outline" class="text-white icon-size-4"></mat-icon>
            </button>
            <button
                mat-icon-button
                matTooltip="Restaurar a Câmera"
                matTooltipClass="tooltip-padrao"
                class="w-9 bg-transparent border-0"
                #resetaCamera
            >
                <mat-icon svgIcon="home" class="text-white icon-size-4"></mat-icon>
            </button>
            <button
                mat-icon-button
                matTooltip="Rotacionar para a Direita"
                matTooltipClass="tooltip-padrao"
                class="w-9 bg-transparent border-0"
                #rotacionaDireita
            >
                <mat-icon svgIcon="rotate_right" class="text-white icon-size-4"></mat-icon>
            </button>
            <button
                mat-icon-button
                matTooltip="Rotacionar para a Esquerda"
                matTooltipClass="tooltip-padrao"
                class="w-9 bg-transparent border-0"
                #rotacionaEsquerda
            >
                <mat-icon svgIcon="rotate_left" class="text-white icon-size-4"></mat-icon>
            </button>
        </div>
        <div class="relative">
            <app-data-ultima-comunicacao
                *ngIf="silo"
                [totalEquipamento]="silo?.equipamentos?.length"
                [equipamentos]="silo?.equipamentos"
                [visualizarTempo]="true"
                [estruturaArmazenagem]="{ nome: silo?.nome }"
            >
            </app-data-ultima-comunicacao>
        </div>
    </div>
    <div class="flex-wrap pl-6" *ngIf="temPendulos">
        <mat-slide-toggle [color]="'primary'" [formControl]="pendulosControle">
            <span class="text-sm text-white">{{
                pendulosControle.value
                    ? ("VISAO_DETALHADA_SILOS_SCREEN.OCULTAR_TODAS_MEDICOES" | transloco)
                    : ("VISAO_DETALHADA_SILOS_SCREEN.EXIBIR_TODAS_MEDICOES" | transloco)
            }}</span>
        </mat-slide-toggle>
    </div>
</div>
