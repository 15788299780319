import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TooltipPenduloModule } from '../tooltip-pendulo/tooltip-pendulo.module';
import { VisaoPlanaSiloComponent } from './visao-plana-silo.component';
import { AeradoresVisaoSiloModule } from '../aeradores-visao-silo/aeradores-visao-silo.module';

@NgModule({
    declarations: [VisaoPlanaSiloComponent],
    imports: [CommonModule, TooltipPenduloModule, MatIconModule, MatTooltipModule, AeradoresVisaoSiloModule],
    exports: [VisaoPlanaSiloComponent],
})
export class VisaoPlanaSiloModule {}
