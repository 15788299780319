import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IVariavelAquecimentoController } from 'app/shared/controllers';
import { HistoricoVariavelAquecimentoColunasEnum } from 'app/shared/enums';
import { HistoricoVariavelAquecimentoInterface } from 'app/shared/interfaces';
import { ApiPaginacao } from 'app/shared/models';
import { delay, take } from 'rxjs';

@Component({
    selector: 'app-modal-historico-variavel-aquecimento',
    templateUrl: './modal-historico-variavel-aquecimento.component.html',
    styleUrls: ['./modal-historico-variavel-aquecimento.component.scss'],
})
export class ModalHistoricoVariavelAquecimentoComponent implements OnInit {
    tabelaColunas: string[] = [
        HistoricoVariavelAquecimentoColunasEnum.DATA,
        HistoricoVariavelAquecimentoColunasEnum.VARIAVEL,
        HistoricoVariavelAquecimentoColunasEnum.USUARIO,
    ];

    paginacao = new ApiPaginacao();
    carregando: boolean = true;
    carregandoDadosAdicionais: boolean = false;   
    historico: HistoricoVariavelAquecimentoInterface[] = [];

    constructor(
        public readonly dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: any,
        private variavelAquecimentoController: IVariavelAquecimentoController,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.paginacao.limit = 10;
        this.paginacao.page = 1;
        this.buscarHistorico();
    }

    ngAfterOnInit(): void {
        this.changeDetectorRef.detectChanges();
    }

    onScroll(): void {
        if (this.paginacao.page == this.paginacao.length) return;

        this.paginacao.page += 1;
        this.buscarHistorico(true);
    }

    buscarHistorico(dadosAdicionais?: boolean): void {  
        if (dadosAdicionais) this.carregandoDadosAdicionais = true; else this.carregando = true;
        this.variavelAquecimentoController
            .buscarHistorico(this.data.armazenagemRef, this.paginacao)
            .pipe(
                take(1),                
                delay(1000)
            )
            .subscribe({
                next: (historico) => {
                    const data: HistoricoVariavelAquecimentoInterface[] = [];
                    historico.data.forEach((elemento) => {
                        data.push(elemento);                        
                    });
                    this.historico = [...this.historico, ...data];                      
                    this.paginacao.length = historico.meta.totalPages;                    
                },
                error: (erro) => {
                    console.log(erro);                  
                },
                complete: () => {                    
                    if (dadosAdicionais) this.carregandoDadosAdicionais = false; else this.carregando = false;                                
                },
            });
    }
}
