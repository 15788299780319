import { Injectable } from '@angular/core';

import { AsideMenuObservable } from 'app/shared/observables';
import { Menu } from 'app/core/aside-menu/models/menu.model';
import { environment } from 'environments/environment';
import { IdiomaSiglaEnum, TipoAmbiente } from 'app/shared/enums';
import { CookieService } from 'app/core/services';

import { MENUS_ARMAZENAGEM } from 'app/core/aside-menu/constants/menus-armazenagem.constant';
import { MENUS_ARMAZENAGEM_EN } from 'app/core/aside-menu/constants/menus-armazenagem-en.constant';
import { MENUS_SECAGEM } from 'app/core/aside-menu/constants/menus-secagem.constant';
import { MENUS_GESTAO_UNIDADE } from 'app/core/aside-menu/constants/menus-gestao-unidade.constant';
import { MENUS_GESTAO_UNIDADE_EN } from 'app/core/aside-menu/constants/menus-gestao-unidade-en.constant';
import { MENUS_GESTAO_UNIDADE_ES } from 'app/core/aside-menu/constants/menus-gestao-unidade-es.constant';
import { MENUS_CONFIGURACOES } from 'app/core/aside-menu/constants/menus-configuracoes.constant';
import { MENUS_CONFIGURACOES_EN } from 'app/core/aside-menu/constants/menus-configuracoes-en.constant';
import { MENUS_SECAGEM_EN } from 'app/core/aside-menu/constants/menus-secagem-en.constant';
import { MENUS_ARMAZENAGEM_ES } from 'app/core/aside-menu/constants/menus-armazenagem-es.constant';
import { MENUS_CONFIGURACOES_ES } from 'app/core/aside-menu/constants/menus-configuracoes-es.constant';
import { MENUS_SECAGEM_ES } from 'app/core/aside-menu/constants/menus-secagem-es.constant';
import { MENUS_DEVELOPMENT } from 'app/core/aside-menu/constants/menus-development.constant';

@Injectable({
    providedIn: 'root',
})
export class AsideMenuService {
    private modulos: Menu[] = [];

    constructor(private asideMenuObservable: AsideMenuObservable, private cookieService: CookieService) {}

    carregarMenus(): void {
        const idioma = this.cookieService.obterCookie('idioma');
        this.modulos = [];

        switch (idioma) {
            case IdiomaSiglaEnum.INGLES:
                this.adicionarMenu(MENUS_ARMAZENAGEM_EN, MENUS_SECAGEM_EN, MENUS_GESTAO_UNIDADE_EN, MENUS_CONFIGURACOES_EN);
                break;
            case IdiomaSiglaEnum.ESPANHOL:
                this.adicionarMenu(MENUS_ARMAZENAGEM_ES, MENUS_SECAGEM_ES, MENUS_GESTAO_UNIDADE_ES, MENUS_CONFIGURACOES_ES);
                break;
            default:
                this.adicionarMenu(MENUS_ARMAZENAGEM, MENUS_SECAGEM, MENUS_GESTAO_UNIDADE, MENUS_CONFIGURACOES);
                break;
        }

        this.asideMenuObservable.modulosSubject.next(this.modulos);
    }

    proximoMenu(menu: any): void {
        this.asideMenuObservable.menusSubject.next(menu.groups);
    }

    selecionarModuloInicial(): Menu {
        return this.modulos[0];
    }

    selecionarMenuInicial(posicaoModulo?: any): Menu {
        return this.modulos[posicaoModulo ? posicaoModulo : 0].groups[0].items[0];
    }

    private adicionarMenu(menusArmazenagem: Menu[], menusSecagem: Menu[], menusGestaoUnidade: Menu[], menusConfiguracoes: Menu[]): void {
        menusArmazenagem.forEach((menu: Menu) => this.modulos.push(menu));
        menusSecagem.forEach((menu: Menu) => this.modulos.push(menu));
    
        if (environment.level.toUpperCase() === TipoAmbiente.NUVEM) {
            menusGestaoUnidade.forEach((menu: Menu) => this.modulos.push(menu));
        }
    
        menusConfiguracoes.forEach((menu: Menu) => this.modulos.push(menu));

        if (!environment.production && window.location.hostname.includes('localhost')) {
            MENUS_DEVELOPMENT.map((development: Menu) => this.modulos.push(development));
        }
    }

}
