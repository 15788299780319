import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusAutomaticoManualComponent } from './status-automatico-manual.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    imports: [CommonModule, MatTooltipModule, TranslocoModule],
    declarations: [StatusAutomaticoManualComponent],
    exports: [StatusAutomaticoManualComponent],
})
export class StatusAutomaticoManualModule {}
