import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ICriaPaletaCoresTermometriaService } from 'app/shared/services';

@Component({
    selector: 'app-modal-escala-temperatura',
    templateUrl: './modal-escala-temperatura.modal.html',
    styleUrls: ['./modal-escala-temperatura.modal.scss'],
})
export class ModalEscalaTemperaturaModal {
    defineCoresTemperatura = this.criaPaletaCoresSensoresService.criarPaletaTemperatura;
    constructor(
        public matDialogRef: MatDialogRef<ModalEscalaTemperaturaModal>,
        readonly criaPaletaCoresSensoresService: ICriaPaletaCoresTermometriaService
    ) {}

    fecharModal(): void {
        this.matDialogRef.close();
    }
}
