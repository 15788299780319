export enum EstoqueRentabilidadeEnum {
    ESTOQUE = 'Estoque',
    TONELADAS = 't',
    SACAS = 'sc',
    CAPACIDADE_TOTAL = 'Capacidade total',
    ESTOQUE_TOTAL_ATUAL = 'Estoque total atual',
    ESTOQUE_FIXADO_EM_CONTATO = 'ESTOQUE_FIXADO_EM_CONTRATO',
    ESTOQUE_TOTAL_ATUAL_GRAO = 'Estoque total atual por grão',
    PORCENTO = '%',
}

export enum UnidadeMedidaEnum {
    SACAS = 'sacas',
    TONELADAS = 'toneladas',
}
