import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AsideMenuObservable } from 'app/shared/observables';
import { Subject, takeUntil } from 'rxjs';
import { SiloHistoricoInterface } from 'app/modules/armazenagem/visualizacao/silos/interfaces';

@Component({
    selector: 'app-tooltip-pendulo-historico',
    templateUrl: './tooltip-pendulo-historico.component.html',
})
export class TooltipPenduloHistoricoComponent implements OnInit, AfterViewInit {
    @Input() historico: SiloHistoricoInterface;
    penduloAtual: any;
    menuFechado: boolean = true;
    tooltipAnterior: number = 0;
    tempoFecharTooltip: any;

    private unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private asideMenuObservable: AsideMenuObservable, private _changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.asideMenuObservable.menuMinimizadoSubject.pipe(takeUntil(this.unsubscribeAll)).subscribe((hide) => {
            this.menuFechado = hide;
            this._changeDetectorRef.detectChanges();
        });
    }

    ngAfterViewInit(): void {
        this.historico?.estrutura_armazenagem_divisao?.estrutura_armazenagem_divisao_pendulo?.sort((a, b) => a.pendulo.codigo - b.pendulo.codigo);
        document.getElementById('tooltipCustomPendulo').hidden = true;
        document.addEventListener('cableDetailsEvent', (event) => {
            clearTimeout(this.tempoFecharTooltip);
            this.tooltipCustomizado(event);
            this._changeDetectorRef.detectChanges();
        });
    }

    equipamentoAtual(estruturaArmazenagem: any): any {        
        return this.historico?.estrutura_armazenagem_divisao?.equipamentos.find(
            (equipamento) => equipamento?.id === estruturaArmazenagem?.pendulo?.pendulo_canal?.equipamento_id
        );
    }

    private tooltipCustomizado(event: any): void {
        const element = document.getElementById('tooltipCustomPendulo');

        if (element) {
            const iconeComunicacao = document.getElementById('icontooltipCustomPendulo');

            if (this.tooltipAnterior !== event.detail.id) {
                element.hidden = true;
                this.tooltipAnterior = event.detail.id;
            }

            this.penduloAtual = this.historico.estrutura_armazenagem_divisao.estrutura_armazenagem_divisao_pendulo[event.detail.id - 1];            
            this.configuraPosicaoTooltip(element, event);
            this.configuraIconeComunicacao(iconeComunicacao);

            element.hidden = false;
            this.tempoFecharTooltip = setTimeout(() => {
                element.hidden = true;
                clearTimeout(this.tempoFecharTooltip);
            }, 3000);

            this._changeDetectorRef.markForCheck();
        }
    }

    private configuraPosicaoTooltip(element: any, event: any): void {
        let menuWidth = 90;

        if (!this.menuFechado) {
            menuWidth = 280;
        }

        const widthTooltip = element?.style.width.replace(/[^0-9]/g, '');
        const heightTooltip = element?.style.height.replace(/[^0-9]/g, '');
        element.style.left = event?.detail.pageX - parseFloat(widthTooltip) / 2 - menuWidth + 'px';
        element.style.top = event?.detail.pageY - parseFloat(heightTooltip) * 4 + 'px';
    }

    private configuraIconeComunicacao(element: any): void {
        if (element && this.penduloAtual) {
            element.style.width = '12px';
            element.style.height = '12px';
            element.style.minHeight = '12px';
            element.style.minWidht = '12px';            
            if (this.equipamentoAtual(this.penduloAtual)?.equipamento_comunicacao?.status) {
                element.style.color = '#34901C';
            } else {
                element.style.color = '#D65757E5';
            }
        }
    }
}
