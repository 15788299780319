import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { EstacaoMeteorologicaHelper } from 'app/shared/helpers';
import { EstacaoMeteorologicaInterface, ResponseInterface } from 'app/shared/interfaces';
import { IOrdenarEstacoesService } from 'app/shared/services';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-informacoes-estacao-meteorologica',
    templateUrl: './informacoes-estacao-meteorologica.component.html',
    styleUrls: ['./informacoes-estacao-meteorologica.component.scss'],
})
export class InformacoesEstacaoMeteorologicaComponent implements OnDestroy, AfterViewInit, OnChanges {
    @ViewChild(MatPaginator) paginacao: MatPaginator;

    @Input() listagemEstacoesMeteorologicas: ResponseInterface<EstacaoMeteorologicaInterface>;
    @Input() buscandoEstacoes?: boolean;

    @Output() trocaEstacao = new EventEmitter();

    dataRecurso: ResponseInterface<EstacaoMeteorologicaInterface>;
    estacoes: EstacaoMeteorologicaInterface[];
    tamplateSlideSelecionado: string = '';
    carregamentoInicial: boolean = true;
    slidesDisponiveis: string[] = [];
    carregaEstiloEstacaoMeteorologica = EstacaoMeteorologicaHelper.carregaEstiloEstacaoMeteorologica;
    index: number = 0;

    private destroy$ = new Subject<boolean>();

    constructor(private ordenarEstacoes: IOrdenarEstacoesService, private changeDetectorRef: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        this.changeDetectorRef.detectChanges();
    }

    ngOnChanges(): void {
        if (this.listagemEstacoesMeteorologicas && this.carregamentoInicial) {
            this.dataRecurso = this.listagemEstacoesMeteorologicas;
            this.ordenarEstacoes.execute(this.listagemEstacoesMeteorologicas?.data);
            this.selecionarEstacao(this.listagemEstacoesMeteorologicas?.data[0]?.id);
            this.listagemEstacoesMeteorologicas?.data.filter((item) => this.slidesDisponiveis.push(item.id));
            this.carregamentoInicial = false;
        }
    }

    selecionarEstacao(idEstacao: string): void {
        this.tamplateSlideSelecionado = idEstacao;
        this.estacoes = this.listagemEstacoesMeteorologicas?.data?.filter((item) => item.id === idEstacao);
        this.trocaEstacao.emit(this.estacoes);
    }

    estacaoSemUltimaLeitura(): boolean {
        if (this.estacoes[0]?.ultima_leitura) {
            return true;
        } else {
            return false;
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(null);
        this.destroy$.complete();
    }
}
