import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ResumoComponent } from './resumo.component';
import { TranslocoRootModule } from 'app/transloco-root.module';

@NgModule({
    declarations: [ResumoComponent],
    imports: [CommonModule, MatIconModule, TranslocoRootModule],
    exports: [ResumoComponent],
})
export class ResumoModule {}
