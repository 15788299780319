import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PermissaoPerfilFuncionalidadeObservable } from 'app/shared/observables';

@Injectable({ providedIn: 'root' })
export class CarregarSiloPeloIdService {
    constructor(
        private _router: Router,
        private permissaoFuncionalidadeObservable: PermissaoPerfilFuncionalidadeObservable
    ) {}
    public buscarSiloId(id: any): void {
        this.permissaoFuncionalidadeObservable.setaPermissaoFuncionalidade = {
            parametrosRota: { id },
            carregando: true,
        };
        const aba = this._router.url.includes('historico') ? 'historico' : 'principal';
        this._router.navigate([], { skipLocationChange: true }).then(() => {
            this._router.navigateByUrl(`armazenagem/visualizacao/visao-detalhada-silo/${aba}/${id}`);
        });
    }
}
