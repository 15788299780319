import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalInformativoGenericoComponent } from 'app/shared/components/modal-informativo-generico/modal-informativo-generico.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    imports: [CommonModule, MatIconModule, MatDialogModule, TranslocoModule],
    declarations: [ModalInformativoGenericoComponent],
    exports: [ModalInformativoGenericoComponent],
})
export class ModalInformativoGenericoModule {}
