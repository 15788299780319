import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TooltipPenduloComponent } from './tooltip-pendulo.component';
import { TranslocoRootModule } from 'app/transloco-root.module';

@NgModule({
    declarations: [TooltipPenduloComponent],
    imports: [CommonModule, MatTooltipModule, MatIconModule, TranslocoRootModule],
    exports: [TooltipPenduloComponent],
})
export class TooltipPenduloModule {}
