export const ComunicacaoCondicaoEnum = {
    CABECALHO: 'COMUNICACAO_GERAL_DOS_EQUIPAMENTOS',
    COMUNICANDO: 'COMUNICANDO',
    COMUNICANDO_PARCIAL: 'COMUNICANDO_PARCIAL',
    PARCIAL: 'COMUNICACAO_PARCIAL',
    FALHA_COMUNICACAO: 'FALHA_COMUNICACAO',
}

export const StatusComunicacaoBackEnum = {
    COMUNICANDO: 'COMUNICANDO',
    COMUNICANDO_PARCIAL: 'COMUNICANDO_PARCIAL',
    PARCIAL: 'PARCIAL',
    FALHA_COMUNICACAO: 'FALHA_COMUNICACAO',
}

