import { CommonModule } from '@angular/common';
import { NgModule, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { VisaoSilo3dComponent } from 'app/shared/components/visao-silo-3d/visao-silo-3d.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EscalaCoresTemperaturaModule, DataUltimaComunicacaoModule } from 'app/shared/components';
import { TranslocoModule } from '@ngneat/transloco';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        EscalaCoresTemperaturaModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        FormsModule,
        DataUltimaComunicacaoModule,
        TranslocoModule,
        MatProgressSpinnerModule
    ],
    declarations: [VisaoSilo3dComponent],
    exports: [VisaoSilo3dComponent],
})
export class VisaoSilo3dModule {
    @ViewChild(VisaoSilo3dComponent) visaoSilo3dComponent: VisaoSilo3dComponent;
}
