import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalEntendaVisaoSuperiorArmazemComponent } from './modal-entenda-visao-superior-armazem.component';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [CommonModule, TranslocoModule, MatIconModule],
    declarations: [ModalEntendaVisaoSuperiorArmazemComponent],
})
export class ModalEntendaVisaoSuperiorArmazemModule {}
