import { ModalEscalaTemperaturaModal } from './modal/modal-escala-temperatura.modal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EscalaCoresTemperaturaComponent } from './escala-cores-temperatura.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    declarations: [EscalaCoresTemperaturaComponent, ModalEscalaTemperaturaModal],
    imports: [CommonModule, MatIconModule, MatTooltipModule, TranslocoModule],
    exports: [EscalaCoresTemperaturaComponent],
})
export class EscalaCoresTemperaturaModule {}
