<ng-container *ngIf="listaEquipamentos.length > 0; else semDadosComunicacao">
    <div
        class="flex flex-row items-center"
        matTooltip="{{ primeiraLinhaTooltip | transloco }} {{ statusComunicacao(listaEquipamentos).segundaLinhaTooltip }}"
        matTooltipClass="{{ (statusComunicacao(listaEquipamentos).classTooltip) | transloco }}"
        [matTooltipPosition]="'above'"
    >
        <span class="statusComunicacaoIcon">
            <mat-icon
                [ngStyle]="{
                    color: statusComunicacao(listaEquipamentos).cor
                }"
                svgIcon="mat_solid:circle"
            ></mat-icon>
        </span>
        <span class="text-[#535767] text-xs font-medium ml-2" *ngIf="definirDataMaisRecente(listaEquipamentos)">
            {{ definirDataMaisRecente(listaEquipamentos) | date : "dd/MM/yyyy HH:mm" }}
        </span>
    </div>
</ng-container>

<ng-template #semDadosComunicacao>
    <div
        class="flex flex-row items-center justify-center"
        matTooltip="{{ 'SECADOR_VISAO_SCREEN.TOOLTIP_COMUNICACAO_GERAL_EQUIPAMENTOS_AGUARDANDO_LEITURA' | transloco}}"
        matTooltipClass="tooltip-sem-comunicacao"
        [matTooltipPosition]="'above'"
    >
        <span class="statusComunicacaoIcon">
            <mat-icon [ngStyle]="{ color: '#A44040' }" svgIcon="mat_solid:circle"></mat-icon>
        </span>
        <span class="text-[#535767] text-xs font-medium ml-2"> {{'SECADOR_VISAO_SCREEN.AGUARDANDO_LEITURA' | transloco}} </span>
    </div>
</ng-template>
