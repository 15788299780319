import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { formataDataComHora } from 'app/shared/constants';
import moment from 'moment';

@Component({
    selector: 'app-modal-status-aeracao',
    templateUrl: './modal-status-aeracao.component.html',
    styleUrls: ['./modal-status-aeracao.component.scss'],
})
export class ModalStatusAeracaoComponent {
    dataEquipamento: string;

    constructor(
        public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any,
        private transloco: TranslocoService
    ) {}

    substituiVariavel(valor: string, valorAtual?: any): string {        
        return valor.replace('{{valor}}', valorAtual ?? '');
    }

    contemValorParaSubstituir(valor: string): boolean | false {
        if (valor.includes('{{valor}}')) return true;
    }

    statusSemComunicacao(): string {
        const textoSemComunicacao = this.transloco.translate('MODAL_STATUS_AERACAO_COMPONENT.SEM_COMUNICACAO');
        const textoChave = this.transloco.translate('MODAL_STATUS_AERACAO_COMPONENT.CHAVE');
        const textoRele = this.transloco.translate('MODAL_STATUS_AERACAO_COMPONENT.RELE');  
        const textoStatus = this.transloco.translate('MODAL_STATUS_AERACAO_COMPONENT.STATUS');

        const semComunicacao = [];
        const dataEquipamentos = [];
        if (!this.data?.equipamentoChave?.status) {
            semComunicacao.push(textoChave);
            this.data?.equipamentoChave?.instante
                ? dataEquipamentos.push(moment(this.data?.equipamentoChave?.instante))
                : '';
        }
        if (!this.data?.equipamentoRele?.status) {
            semComunicacao.push(textoRele);
            this.data?.equipamentoRele?.instante
                ? dataEquipamentos.push(moment(this.data?.equipamentoRele?.instante))
                : '';
        }
        if (!this.data?.equipamentoStatus?.status) {
            semComunicacao.push(textoStatus);
            this.data?.equipamentoStatus?.instante
                ? dataEquipamentos.push(moment(this.data?.equipamentoStatus?.instante))
                : '';
        }
        if (dataEquipamentos.length > 0) {
            this.retornarDataEquipamento(dataEquipamentos);
        }
        return `${textoSemComunicacao} (${semComunicacao.join(', ')}).`;
    }

    private retornarDataEquipamento(datas: any[]): void {
        this.dataEquipamento = moment.max(datas).locale(formataDataComHora.locale).format(formataDataComHora.formato);
    }
}
