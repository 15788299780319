import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisaoPlanaArmazemComponent } from './visao-plana-armazem.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule
  ],
  declarations: [VisaoPlanaArmazemComponent],
  exports: [VisaoPlanaArmazemComponent]
})
export class VisaoPlanaArmazemModule { }
