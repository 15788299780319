import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';

import { interval, map, Observable, Subject, takeUntil } from 'rxjs';
import moment from 'moment';

import { CheckInCheckOutObservable } from 'app/shared/observables';

@Component({
    selector: 'app-header-check-in-check-out',
    templateUrl: './header-check-in-check-out.component.html',
    styleUrls: ['./header-check-in-check-out.component.scss'],
})
export class HeaderCheckInCheckOutComponent implements OnInit {
    @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

    tempoDecorrido$: Observable<string>;
    contadorPausado = false;
    checkOutAberto = false;
    tempoDecorrido = 0;

    private unsubscribeAll: Subject<void> = new Subject<void>();

    constructor(
        readonly dialog: MatDialog,
        private router: Router,
        private checkInCheckOutObservable: CheckInCheckOutObservable
    ) {}

    ngOnInit(): void {
        this.iniciarContador();
    }

    reiniciarContagemCheckOut(): void {
        if (this.checkOutAberto) return;

        this.unsubscribeAll.next();
        this.tempoDecorrido = 0;
        this.iniciarContador();
    }

    alternarContagem(): void {
        if (this.checkOutAberto) return;

        this.contadorPausado = !this.contadorPausado;
    }

    abrirCheckOut(): void {
        if (this.checkOutAberto) return;

        this.checkOutAberto = true;
        this.router.navigateByUrl('check-in-check-out/check-out');
    }

    private iniciarContador() {
        this.tempoDecorrido$ = interval(1000).pipe(
            takeUntil(this.unsubscribeAll),
            map(() => {
                if (!this.contadorPausado) {
                    this.tempoDecorrido++;
                }
                const duracao = moment.duration(this.tempoDecorrido, 'seconds');
                this.checkInCheckOutObservable.setValorContadorCheckOut = moment
                    .utc(duracao.asMilliseconds())
                    .format('mm:ss');
                return moment.utc(duracao.asMilliseconds()).format('mm:ss');
            })
        );
    }
}
