import { Injectable } from '@angular/core';
import {
    ListagemSilosInterface,
    SlidersDisponiveisInterface,
} from 'app/modules/armazenagem/visualizacao/silos/interfaces';
import { VerificaObjetoVazio } from 'app/shared/helpers';
import { TipoSlidersEnum } from 'app/modules/armazenagem/visualizacao/silos/enums';
export abstract class IConfiguraSlidersListagemSilosService {
    abstract execute(
        listagemSilos: ListagemSilosInterface,
        unidadeContemProdutoAeracao: boolean
    ): SlidersDisponiveisInterface[];
}

@Injectable({ providedIn: 'root' })
export class ConfiguraSlidersListagemSilosService implements IConfiguraSlidersListagemSilosService {
    private verificaObjetoVazio = VerificaObjetoVazio.execute;

    execute(
        listagemSilos: ListagemSilosInterface,
        unidadeContemProdutoAeracao: boolean
    ): SlidersDisponiveisInterface[] {
        let slidersDisponiveis: SlidersDisponiveisInterface[] = this.iniciaArraySliders();
        if (!this.verificaObjetoVazio(listagemSilos.aeracao) && unidadeContemProdutoAeracao) {
            slidersDisponiveis.splice(0, 0, { slide: 0, tamplate: TipoSlidersEnum.AERACAO });
        }

        if (!this.verificaObjetoVazio(listagemSilos.ultima_leitura.sensor_co2_ultima_leitura)) {
            const index = this.retornaIndexAdicionarSlide(slidersDisponiveis, TipoSlidersEnum.GRAO);
            slidersDisponiveis.splice(index, 0, { slide: index, tamplate: TipoSlidersEnum.SENSOR_CO2 });
        }

        if (!this.verificaObjetoVazio(listagemSilos.ultima_leitura.sensor_plenum_ultima_leitura)) {
            const indexComCO2 = this.retornaIndexAdicionarSlide(slidersDisponiveis, TipoSlidersEnum.SENSOR_CO2);
            if (indexComCO2 >= 0) {
                slidersDisponiveis.splice(indexComCO2, 0, {
                    slide: indexComCO2,
                    tamplate: TipoSlidersEnum.SENSOR_PLENUM,
                });
            } else {
                const indexSemCO2 = this.retornaIndexAdicionarSlide(slidersDisponiveis, TipoSlidersEnum.GRAO);
                slidersDisponiveis.splice(indexSemCO2, 0, {
                    slide: indexSemCO2,
                    tamplate: TipoSlidersEnum.SENSOR_PLENUM,
                });
            }
        }
        this.ordenaSliders(slidersDisponiveis);
        return slidersDisponiveis;
    }

    private iniciaArraySliders(): SlidersDisponiveisInterface[] {
        return [
            {
                slide: 0,
                tamplate: TipoSlidersEnum.GRAO,
            },
            {
                slide: 1,
                tamplate: TipoSlidersEnum.ESTOQUE,
            },
        ];
    }

    private ordenaSliders(slidersDisponiveis: SlidersDisponiveisInterface[]): void {
        slidersDisponiveis.forEach((slide, index) => {
            slide.slide = index;
        });
    }

    private retornaIndexAdicionarSlide(
        slidersDisponiveis: SlidersDisponiveisInterface[],
        tipoSlide: TipoSlidersEnum
    ): number {
        const index = slidersDisponiveis.findIndex((slide) => slide.tamplate === tipoSlide);
        if (index >= 0) {
            return index + 1;
        } else {
            return index;
        }
    }
}
