import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { TranslocoModule } from '@ngneat/transloco';
import { ModalHistoricoVariavelAquecimentoComponent } from 'app/shared/components';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
    imports: [
        CommonModule, 
        MatIconModule, 
        MatTableModule, 
        MatProgressSpinnerModule, 
        TranslocoModule,
        InfiniteScrollModule
    ],
    declarations: [ModalHistoricoVariavelAquecimentoComponent],
    exports: [ModalHistoricoVariavelAquecimentoComponent],
})
export class ModalHistoricoVariavelAquecimentoModule {}
