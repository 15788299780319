import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PainelInformacoesGeraisInterface } from 'app/modules/armazenagem/visualizacao/painel-geral/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import { IApiTransportInterface } from 'app/core/interfaces';
import { IObjectToAnyService } from 'app/shared/services';
import { ResponseInterface } from 'app/shared/interfaces';
import { ApiPaginacao } from 'app/shared/models';

export abstract class IInformacoesGeraisController {
    abstract buscaInformacoesGeraisSilo(
        paginacao: ApiPaginacao
    ): Observable<ResponseInterface<PainelInformacoesGeraisInterface>>;
    abstract buscaInformacoesGeraisArmazem(
        paginacao: ApiPaginacao
    ): Observable<ResponseInterface<PainelInformacoesGeraisInterface>>;
}

@Injectable({
    providedIn: 'root',
})
export class InformacoesGeraisController implements IInformacoesGeraisController {
    private transport: IApiTransportInterface;

    constructor(
        public readonly apiTransportService: ApiTransportService,
        private readonly objectToAnyService: IObjectToAnyService
    ) {
        this.transport = this.apiTransportService.execute('/armazenagem');
    }

    buscaInformacoesGeraisSilo(paginacao: ApiPaginacao): Observable<any> {
        this.transport.options.params = this.objectToAnyService.toQueryString({
            page: paginacao.page,
            limit: paginacao.limit,
        });
        return this.transport.http.get(`${this.transport.path}/silo/painel-informacoes-gerais`, this.transport.options);
    }

    buscaInformacoesGeraisArmazem(paginacao: ApiPaginacao): Observable<any> {
        this.transport.options.params = this.objectToAnyService.toQueryString({
            page: paginacao.page,
            limit: paginacao.limit,
        });
        return this.transport.http.get(
            `${this.transport.path}/armazem/painel-informacoes-gerais`,
            this.transport.options
        );
    }
}
