<!-- Loading bar -->
<loading-bar></loading-bar>

<app-aside-menu></app-aside-menu>

<div id="classicLayout" class="flex flex-col flex-auto w-full min-w-0 pl-0 sm:pl-[75px] cursor-default">
    <div id="sobreposicao" (click)="classicService.fecharMenuSobreposto()"></div>
    <div
        class="relative flex flex-0 items-center w-full h-[53.17px] sm:h-16 md:px-5 z-49 shadow dark:shadow-none dark:border-b sm:bg-card dark:bg-transparent print:hidden border-t-2 border-[#535767] bg-[#00091F]"
    >
        <button mat-icon-button (click)="toggleNavigation()">
            <mat-icon [svgIcon]="'heroicons_outline:menu'" class="text-[#bac3d2] sm:text-[#64748b]"></mat-icon>
        </button>
        <div class="flex w-[100%] mr-[-200px] items-center justify-center">
            <app-header-select-customer (trocaUnidade)="trocaUnidade($event)"></app-header-select-customer>
        </div>
        <div class="flex items-center pl-0 ml-auto sm:ml-[90.95px] space-x-0.5 sm:space-x-2 mr-[9px] sm:mr-0">
            <!-- <app-header-check-in-check-out></app-header-check-in-check-out> -->
            <user></user>
        </div>
    </div>
    <ng-container *ngIf="!recarregarComponente">
        <div
            class="sm:flex-row flex-col flex items-center w-full h-auto sm:h-16 shadow dark:shadow-none bg-card dark:bg-transparent md:justify-between print:hidden subHeader md:px-4"
        >
            <div class="text-stone-50 py-2 sm:py-0">
                <app-page-info></app-page-info>
            </div>
            <div
                *ngIf="tipoColetor === tiposColetor.COLETOR_CERES"
                class="w-full sm:w-auto bg-card py-2 sm:py-0 border-[#F4F6F9] border-t-2 sm:border-0"
            >
                <app-sincronizador-equipamento class="w-full"> </app-sincronizador-equipamento>
            </div>
        </div>
        <div class="flex flex-col flex-auto">
            <router-outlet *ngIf="true"></router-outlet>
        </div>
        <div
            class="relative flex flex-0 justify-between items-center w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden"
        >
            <span class="font-medium text-secondary">Ceres Orion 2.0 - v{{ versaoSistema }} </span>
            <span class="w-[151px] h-[36px] flex items-center"><img [src]="logoUrl" /></span>
        </div>
    </ng-container>
</div>

<div class="alerta-fixo" *ngIf="alerta?.mostrar">
    <app-alert *ngIf="alerta?.mostrar" class="mt-0 -mr-0" [showIcon]="true" [type]="alerta.tipo" [appearance]="'fill'">
        <span fuseAlertTitle>{{ alerta.titulo }}</span>
        <span>{{ alerta.mensagem }}</span>
    </app-alert>
</div>
