import { Component, Input, OnInit } from '@angular/core';
import { GraoInterface } from 'app/shared/interfaces';
import { IVerificaValidadeDeAmostragemService } from 'app/shared/services';

@Component({
    selector: 'app-grao-informacoes-gerais',
    templateUrl: './grao-informacoes-gerais.component.html',
    styleUrls: ['./grao-informacoes-gerais.component.scss'],
})
export class GraoInformacoesGeraisComponent {
    @Input() graoSelecionado: GraoInterface;
    @Input() amostragem: any[];
    @Input() listaProdutos: any[];

    constructor(readonly verificaValidadeDeAmostragemService: IVerificaValidadeDeAmostragemService) {}

    retornaNomeGrao(idProduto): string {
        return this.listaProdutos?.find((produto) => produto.id == idProduto)?.nome;
    }
}
