import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SensoresInformacoesGeraisComponent } from './sensores-informacoes-gerais.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { SensorCo2InformacoesGeraisModule } from '../sensor-co2-informacoes-gerais/sensor-co2-informacoes-gerais.module';

@NgModule({
    imports: [CommonModule, MatIconModule, TranslocoModule, SensorCo2InformacoesGeraisModule],
    declarations: [SensoresInformacoesGeraisComponent],
    exports: [SensoresInformacoesGeraisComponent],
})
export class SensoresInformacoesGeraisModule {}
