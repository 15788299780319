import { Component, Input, OnChanges } from '@angular/core';

import { MediaWatcherService } from 'app/core/media-watcher/media-watcher.service';

import { Subject, takeUntil } from 'rxjs';
import { isObject } from 'lodash';

import { EstacaoMeteorologicaInterface } from 'app/shared/interfaces';
import { IFormataCampoValorService } from 'app/shared/services';
import { EquilibrioHigroscopicoModel } from 'app/shared/models';
import { produtoNaoInformado } from 'app/shared/constants';
import { IconeProdutoHelper } from 'app/shared/helpers';

@Component({
    selector: 'app-tamplate-dados-equilibrio-sensor-plenum',
    templateUrl: './tamplate-dados-equilibrio-sensor-plenum.component.html',
    styleUrls: ['./tamplate-dados-equilibrio-sensor-plenum.component.scss'],
})
export class TamplateDadosEquilibrioHSensorPlenumComponent implements OnChanges {
    @Input() equilibrioHigroscopico: EquilibrioHigroscopicoModel | undefined;
    @Input() estacao: EstacaoMeteorologicaInterface | undefined;
    @Input() temEstacaoAtiva: boolean | undefined;
    @Input() sensorPlenum: any | undefined;
    @Input() nomeProduto: string;

    iconeProduto: string;
    modoMobile: boolean;

    private unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private formataCampoValorService: IFormataCampoValorService,
        private mediaWatcherService: MediaWatcherService
    ) {}

    ngOnInit(): void {
        this.mediaWatcherService.onMediaChange$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                this.modoMobile = !matchingAliases.includes('sm');
            });
    }

    ngOnChanges(): void {
        this.iconeProduto = this.selecionaIconeProduto(this.nomeProduto);
        if (isObject(this.sensorPlenum?.temperatura)) {
            this.sensorPlenum.temperatura = this.sensorPlenum.temperatura?.med;
            this.sensorPlenum.umidade = this.sensorPlenum.umidade?.med;
        }
    }

    produtoInvalido(): boolean | false {
        return this.nomeProduto === produtoNaoInformado;
    }

    formataCampo(valor: number | string): number | string {
        if (!this.temEstacaoAtiva || this.produtoInvalido()) {
            return '-';
        }

        return this.formataCampoValorService.execute(valor);
    }

    private selecionaIconeProduto(nomeProduto: string): string {
        return IconeProdutoHelper.selecionaIconeProduto(nomeProduto);
    }
}
