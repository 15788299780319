import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import moment, { Moment } from 'moment';
import { DaterangepickerDirective, LocaleConfig } from 'ngx-daterangepicker-material';

import { carregarOpcoesPersonalizadas, configuracaoCalendario } from 'app/shared/components/calendario-duplo/constants';
import { OpcoesPersonalizadasEnum } from 'app/shared/components/calendario-duplo/enums';
import { OpcoesPersonalizadasInterface } from 'app/shared/components/calendario-duplo/interface';
import { TranslocoService } from '@ngneat/transloco';

type rangeDate = {
    startDate: string;
    endDate: string;
};
@Component({
    selector: 'app-calendario-duplo',
    templateUrl: './calendario-duplo.component.html',
    styleUrls: ['./calendario-duplo.component.scss'],
})
export class CalendarioDuploComponent implements OnInit, AfterViewInit {
    @ViewChild(DaterangepickerDirective, { static: false }) calendarioDuplo: DaterangepickerDirective;

    @Output() labelRange = new EventEmitter<string>();
    @Output() clicouCalendario = new EventEmitter<boolean>();
    @Output() atualizouValores = new EventEmitter<boolean>();

    @Input() habililarOpcoesPersonalizadas: boolean;
    @Input() ocultarCalendario: boolean;
    @Input() controlePeriodo: UntypedFormControl;
    @Input() invalidaPeriodoPassado?: Moment;
    @Input() removerOpcoesPersonalizadas?: OpcoesPersonalizadasEnum[];
    @Input() periodoDefault?: OpcoesPersonalizadasEnum;
    @Input() alinharLado?: 'left' | 'right';
    @Input() isRequired?: boolean = true;

    labelPesonalizadaClicado: boolean = false;
    periodoSelecionado: rangeDate;
    alwaysShowCalendars: boolean;
    opcoesPersonalizadas = {};
    moverParaEsquerda: boolean = false;
    ultimas24Horas: string;
    traducaoCalendario: LocaleConfig = {};

    constructor(private changeDetectorRef: ChangeDetectorRef, private transloco: TranslocoService) {
        this.alwaysShowCalendars = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['isRequired']) {
            this.atualizarValidadores();
        }
    }

    ngOnInit(): void {
        this.opcoesPersonalizadas = carregarOpcoesPersonalizadas();

        this.atualizarValidadores();

        this.transloco.selectTranslate(OpcoesPersonalizadasEnum.ULTIMAS_24_HORAS).subscribe((translation: string) => {
            this.ultimas24Horas = translation;

            this.traducaoCalendario = {
                ...new configuracaoCalendario().carregaCalendario(),
                format: 'DD/MM/YYYY HH:mm:ss',
            };
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.habililarOpcoesPersonalizadas) {
                this.removePeriodosPersonalizado();
            }

            this.setaPeriodoDefault();

            if (this.calendarioDuplo) {
                this.calendarioDuplo.locale = this.traducaoCalendario;
                this.changeDetectorRef.detectChanges();
            }
        }, 1000);

        this.alterarEstilos();
    }

    private atualizarValidadores(): void {
        if (this.isRequired) {
            this.controlePeriodo.setValidators([Validators.required]);
        } else {
            this.controlePeriodo.setValidators(null);
        }
        this.controlePeriodo.updateValueAndValidity();
    }

    private alterarEstilos(): void {
        if (this.moverParaEsquerda) {
            this.adicionarEstiloMoverParaEsquerda();
        } else {
            this.removerEstiloMoverParaEsquerda();
        }
    }

    private adicionarEstiloMoverParaEsquerda(): void {
        const element = document.querySelector('.md-drppicker') as Element;
        if (element) {
            element.setAttribute('style', 'left: 30px;');
        }
    }

    private removerEstiloMoverParaEsquerda(): void {
        const element = document.querySelector('.md-drppicker') as Element;
        if (element) {
            element.setAttribute('style', 'left: -15px;');
        }
    }

    setaPeriodoDefault(): void {
        this.periodoDefault = this.transloco.translate(this.periodoDefault);

        const rangeDefault = this.periodoDefault || this.ultimas24Horas;
        const periodosPersonalizadas = Object.create({});

        Object.assign(periodosPersonalizadas, this.opcoesPersonalizadas);

        for (let periodoDefault in periodosPersonalizadas) {
            if (rangeDefault === periodoDefault) {
                this.labelRange.emit(rangeDefault);
                this.periodoSelecionado = {
                    startDate: periodosPersonalizadas[periodoDefault][0],
                    endDate: periodosPersonalizadas[periodoDefault][1],
                };
                this.controlePeriodo.setValue(this.periodoSelecionado);
            }
        }

        this.inicializaCalendario();
    }

    removePeriodosPersonalizado(): void {
        if (this.removerOpcoesPersonalizadas) {
            const periodosPersonalizadas = { ...this.opcoesPersonalizadas };

            const periodosPersonalizadasRemover = this.removerOpcoesPersonalizadas.map((item) => {
                return this.transloco.translate(item);
            });

            for (let periodoRemover of periodosPersonalizadasRemover) {
                if (periodosPersonalizadas.hasOwnProperty(periodoRemover)) {
                    delete periodosPersonalizadas[periodoRemover];
                }
            }

            this.opcoesPersonalizadas = periodosPersonalizadas;
        }
    }

    dataInvalida = (m: moment.Moment) => {
        return m > moment() || m < this.invalidaPeriodoPassado;
    };

    abrirCalendario(e?: MouseEvent): void {
        this.clicouCalendario.emit(true);
        if (e) {
            this.calendarioDuplo.open(e);
        } else {
            this.calendarioDuplo.open();
        }
    }

    opacaoPersonalizadaSelecionada(event: OpcoesPersonalizadasInterface): void {
        this.atualizaHoras(event);
        if (event.label) {
            this.labelRange.emit(event.label);
            this.labelPesonalizadaClicado = true;
        }
    }

    altaracaoDeRange(): void {
        this.atualizouValores.emit(true);

        if (!this.labelPesonalizadaClicado) {
            this.labelRange.emit(undefined);
        } else {
            this.labelPesonalizadaClicado = false;
        }
    }

    private inicializaCalendario(): void {
        let data = new Date();
        this.calendarioDuplo.picker.timepickerVariables.left.selectedHour = data.getHours();
        this.calendarioDuplo.picker.timepickerVariables.left.selectedMinute = data.getMinutes();
        this.calendarioDuplo.picker.timepickerVariables.right.selectedHour = data.getHours();
        this.calendarioDuplo.picker.timepickerVariables.right.selectedMinute = data.getMinutes();
    }

    private atualizaHoras(horarios: OpcoesPersonalizadasInterface): void {
        switch (horarios.label) {
            case OpcoesPersonalizadasEnum.HOJE:
            case OpcoesPersonalizadasEnum.ONTEM:
                this.calendarioDuplo.picker.timepickerVariables.left.selectedHour = 0;
                this.calendarioDuplo.picker.timepickerVariables.left.selectedMinute = 0;
                this.calendarioDuplo.picker.timepickerVariables.right.selectedHour = 23;
                this.calendarioDuplo.picker.timepickerVariables.right.selectedMinute = 59;
            case OpcoesPersonalizadasEnum.ULTIMAS_24_HORAS:
                this.inicializaCalendario();
            default:
                break;
        }
    }
}
