import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SensorCo2InformacoesGeraisComponent } from './sensor-co2-informacoes-gerais.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { DataUltimaComunicacaoModule } from '../data-ultima-comunicacao/data-ultima-comunicacao.module';

@NgModule({
    imports: [CommonModule, MatIconModule, TranslocoModule, DataUltimaComunicacaoModule],
    declarations: [SensorCo2InformacoesGeraisComponent],
    exports: [SensorCo2InformacoesGeraisComponent],
})
export class SensorCo2InformacoesGeraisModule {}
