import { PenduloInterface, ProdutoInterface } from 'app/shared/interfaces';

export interface AmostragemInterface {
    arquivado?: boolean;
    estrutura_armazenagem_id?: string;
    estrutura_armazenagem_divisao_id?: string;
    produto_id?: string;
    data_amostra?: string;
    umidade_controle?: number;
    impureza_controle?: number;
    avariados_controle?: number;
    notas?: string;
    estrutura_amostragem_leitura?: AmostragemLeituraInterface[];
    produto?: ProdutoInterface;
    estrutura_armazenagem_divisao_pendulo?: PenduloInterface[];
}

export interface AmostragemLeituraInterface {
    id?: string;
    pendulo_id: string;
    local_coleta: string;
    descarga_posicao: string;
    umidade: number;
    impureza: number;
    avariados: number;
}
