import { Component, Input, OnInit } from '@angular/core';

import { Subject, takeUntil } from 'rxjs';
import { ConfiguracoesTamanho, StatusTamanho } from 'app/shared/enums';
import { MediaWatcherService } from 'app/core/media-watcher/media-watcher.service';
import { naoNuloOuIndefinido } from 'app/shared/helpers';

@Component({
    selector: 'app-status-automatico-manual',
    templateUrl: './status-automatico-manual.component.html',
    styleUrls: ['./status-automatico-manual.component.scss'],
})
export class StatusAutomaticoManualComponent implements OnInit {
    @Input() statusAutomatico: any | undefined;
    @Input() possuiStatusComunicacao: boolean | undefined;
    @Input() equipamentoComunicando?: boolean | undefined;
    @Input() reduzirWidth?: boolean | false;

    modoMobile: boolean;
    classWidth: string = StatusTamanho.PADRAO;

    naoNuloOuIndefinido = naoNuloOuIndefinido;
    
    private unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private mediaWatcherService: MediaWatcherService) {}

    ngOnInit(): void {
        this.mediaWatcherService.onMediaChange$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                this.modoMobile = matchingAliases.includes(ConfiguracoesTamanho.TELAPEQUENA);
                this.atualizarClassWidth();
            });

        window.addEventListener('resize', this.onResize.bind(this));
    }

    private atualizarClassWidth(): void {
        if (!this.modoMobile || this.reduzirWidth) {
            this.classWidth = StatusTamanho.REDUZIDO;
        } else {
            this.classWidth = StatusTamanho.PADRAO;
        }
    }

    private onResize(): void {
        const maxWidthMobile = ConfiguracoesTamanho.TAMANHOMOBILE;
        this.modoMobile = window.innerWidth < maxWidthMobile;
        this.atualizarClassWidth();
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();

        window.removeEventListener('resize', this.onResize.bind(this));
    }
}
