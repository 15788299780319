import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-caixa-notificacao',
    templateUrl: './caixa-notificacao.component.html',
    styleUrls: ['./caixa-notificacao.component.scss'],
})
export class CaixaAlertaComponent implements OnInit {
    @Input() tipo: string;
    @Input() titulo: string;
    @Input() mensagem: string;
    @Input() exibirNotificacao: boolean;

    constructor() {}

    ngOnInit(): void {}

    selecionaTipoAlerta(): string {
        const tipos = {
            alerta: '#fcf1d4',
            erro: '#de0909',
            sucesso: '#09de16',
        };

        const tipo = tipos[this.tipo];

        if (!tipo) {
            throw Error('O parâmetro tipo está incorreto. Insira um valor compatível');
        }

        return `bg-[${tipo}] text-[#B48707]`;
    }
}
