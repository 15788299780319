import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EstoqueInformacoesGeraisComponent } from './estoque-informacoes-gerais.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { DataUltimaComunicacaoModule } from '../data-ultima-comunicacao/data-ultima-comunicacao.module';

@NgModule({
    imports: [CommonModule, MatIconModule, TranslocoModule, DataUltimaComunicacaoModule],
    declarations: [EstoqueInformacoesGeraisComponent],
    exports: [EstoqueInformacoesGeraisComponent],
})
export class EstoqueInformacoesGeraisModule {}
