import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { whiteLabelService } from 'app/core/services/white-label';

@NgModule()
export class IconsModule {
    get whiteLabelCliente(): string {
        this.whiteLabelService.obterCliente();
        return localStorage.getItem('whiteLabelCliente');
    }

    constructor(
        private _domSanitizer: DomSanitizer,
        private _matIconRegistry: MatIconRegistry,
        private whiteLabelService: whiteLabelService
    ) {
        this._matIconRegistry.addSvgIconSet(
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-twotone.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'mat_outline',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'mat_solid',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-solid.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'iconsmind',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/iconsmind.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'feather',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/feather.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'heroicons_outline',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-outline.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'heroicons_solid',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-solid.svg')
        );
        //Icons Menus
        this._matIconRegistry.addSvgIcon(
            'icon-module-armazenagem',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/modulo_armazenagem.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-module-secador',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-secagem.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-module-gestao-unidade',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/modulo_gestao_unidade.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-module-configuracoes',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_configuracoes.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-grupo-visualizacao',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/menus/grupo_visualizacao_kepler.svg'
                    : 'assets/icons/menus/grupo_visualizacao.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-painel-geral',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_painel_geral.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-planta-unidade',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_planta_unidade.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-secador-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-secador-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-silos',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente
                    ? 'assets/icons/menus/menu-silos-white-label.svg'
                    : 'assets/icons/menus/menu-silos-ceres.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-armazens',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_armazens.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-grupo-analise',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/menus/icon-grupo-analise-kepler.svg'
                    : 'assets/icons/menus/grupo_analise.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-relatorios',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_relatorios.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-leitura-sensores',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-leitura-sensores.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-sensores',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icone-sensores-laranja.svg'
                    : 'assets/icons/menus/silo/sensores-icone.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-aeracao-tempo',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente
                    ? 'assets/icons/menus/menu-aeracao-tempo-white-label.svg'
                    : 'assets/icons/menus/menu-aeracao-tempo-ceres.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-sensor-plenum',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-sensor-plenum.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-aeracao-programa',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-aeracao-programa.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-aeracao-equilibrio',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-aeracao-equilibrio.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-umidade-grao',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-umidade-grao.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-leitura-auxiliar',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-leitura-auxiliar.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-leitura-co2',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-leitura-co2.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-grao-secagem',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-grao-secagem.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-pluviometro-estacao',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-pluviometro-estacao.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-graficos',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-graficos.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-eventos',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/icon-menu-eventos.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-estoque',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-estoque.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-pluviometria',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-pluviometria.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-armazenagem-preditiva',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-armazenagem-preditiva.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-grupo-cadastro',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/menus/grupo_cadastro_kepler.svg'
                    : 'assets/icons/menus/grupo_cadastro.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-amostragens',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente
                    ? 'assets/icons/menus/menu-amostragens-white-label.svg'
                    : 'assets/icons/menus/menu-amostragens-ceres.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-grupo-monitoramento',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/grupo_monitoramento.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-analisadores',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-analisadores.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-grupo-configuracoes',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/menus/grupo_configuracoes_kepler.svg'
                    : 'assets/icons/menus/grupo_configuracoes.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-regras',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-regras.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-programas',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-programas.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-gestao-energia',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-gestao-energia.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-variaveis-aquecimento',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-variaveis-aquecimento.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-unidade-armazenadora',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente
                    ? 'assets/icons/menus/menu-unidade-armazenadora-white-label.svg'
                    : 'assets/icons/menus/menu-unidade-armazenadora-ceres.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-sensores',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_sensores.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-alertas',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-alertas.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-painel',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_painel.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-visao-geral',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_visao_geral.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-historico',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_historico.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-painel-amostragem',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_painel_amostragem.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-programas',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-progrmas.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-expedicao-recebimento',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-expedicao-recebimento.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-qualidade-amostragem',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_qualidade_amostragem.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-expedicao-recebimento-truck',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_expedicao_recebimento_truck.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-qualidade-amostragem',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_qualidade_amostragem.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-tipos-amostras',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_tipos_amostras.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-equipamentos-industriais',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_equipamentos_industriais.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-integracao-industrial',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_integracao_industrial.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-permissoes-usuarios',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_permissoes_usuarios.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-versoes-sistema',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_versoes_sistema.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-grao',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-grao.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-communicating-circle',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-communicating-circle.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-gota',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-gota.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-gota-hover',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-gota-laranja.svg'
                    : 'assets/icons/icon-gota-azul.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-temperatura',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-temperatura-laranja.svg'
                    : 'assets/icons/icon-temperatura-azul.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-temperatura-painel',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-temperatura-painel-laranja.svg'
                    : 'assets/media/storage/icon-temperature.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-umidade-painel',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-umidade-painel-laranja.svg'
                    : 'assets/media/storage/icon-humidity.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-temperatura-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-temperatura-cinza.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-eaquipamentos',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-eaquipamentos.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-info',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-info-laranja.svg'
                    : 'assets/icons/icon-info.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-grao',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icone-grao-laranja.svg'
                    : './assets/icons/menus/silo/icone-grao.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-grao-grande',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icone-grao-grande-laranja.svg'
                    : 'assets/icons/icone-grao-grande-azul.svg'
            )
        );

        this._matIconRegistry.addSvgIcon(
            'icon-controle-estoque',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-controle-estoque-laranja.svg'
                    : 'assets/icons/icon-controle-estoque.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-silo-vazio-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-silo-vazio-cinza.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-silo-cheio-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-silo-cheio-cinza.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-silo-estoque',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-silo-laranja-estoque.svg'
                    : 'assets/icons/icon-silo-azul-estoque.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-silo-historico',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-silo-historico-estoque.svg'
                    : 'assets/media/storage/silo-azul.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-silo-controle',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-silo-controle.svg'
                    : 'assets/icons/icon-silo-controle-azul.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-menu-estoque',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente
                    ? 'assets/icons/menus/icone-menu-estoque-whitelabel.svg'
                    : 'assets/icons/menus/icone-menu-estoque-ceres.svg'
            )
        );

        this._matIconRegistry.addSvgIcon(
            'icon-alert-amarelo',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-alert-amarelo.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-editar',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-editar-laranja.svg'
                    : 'assets/icons/icon-editar.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-editar-branco',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-editar-branco.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-calendario',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-calendario.svg')
        );

        this._matIconRegistry.addSvgIcon(
            'icon-voltar-um',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-voltar-um.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-voltar-todos',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-voltar-todos.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-avancar-um',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-avancar-um.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-play',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-play.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-avancar-todos',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-avancar-todos.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-pausa',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-pausa.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-amostragem',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-amostragem-laranja.svg'
                    : 'assets/icons/icon-amostragem.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-lixeira',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-lixeira.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-lixeira-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-lixeira-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-olho',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/olho-laranja.svg'
                    : 'assets/icons/olho-azul.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-duplicar',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-duplicar-laranja.svg'
                    : 'assets/icons/icon-duplicar.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-exportar',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-exportar-laranja.svg'
                    : 'assets/icons/icon-exportar.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-desarquivar',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-desarquivar-laranja.svg'
                    : 'assets/icons/icon-desarquivar-azul.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-arquivar',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icon-arquivar-laranja.svg'
                    : 'assets/icons/icon-arquivar-azul.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-whatsapp-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icone-whatsapp-laranja.svg'
                    : 'assets/icons/icone-whatsapp-azul.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-carta-email-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icone-carta-email-laranja.svg'
                    : 'assets/icons/icone-carta-email-azul.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-seta-esquerda-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icone-seta-esquerda-laranja.svg'
                    : 'assets/icons/icone-seta-esquerda-azul.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-telefone-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icone-telefone-laranja.svg'
                    : 'assets/icons/icone-telefone-azul.svg'
            )
        );

        this._matIconRegistry.addSvgIcon(
            'icone-grafico',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-grafico.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-grafico-pluviometria-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-grafico-pluviometria-azul.svg')
        );

        this._matIconRegistry.addSvgIcon(
            'icone-gota-cinza-habilitado',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-gota-cinza-habilitado.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-gota-cinza-desabilitado',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-gota-cinza-desabilitado.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-temperatura-cinza-desabilitado',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-temperatura-cinza-desabilitado.svg')
        );

        this._matIconRegistry.addSvgIcon(
            'icone-equilibrio-higroscopico',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-equilibrio-higroscopico.svg')
        );

        this._matIconRegistry.addSvgIcon(
            'icone-link-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icone-link-laranja.svg'
                    : 'assets/icons/icone-link-azul.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-aguardando',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-aguardando.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-sobrecarga-eletrica',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-sobrecarga-eletrica.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-horario-pico-energia',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-horario-pico-energia.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-qualidade-grao',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-qualidade-grao.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-aeracao',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-aeracao.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-aeracao-wl',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.whiteLabelCliente === 'keplerweber'
                    ? 'assets/icons/icone-aeracao-laranja.svg'
                    : 'assets/icons/icone-aeracao-azul.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-aeracao-acionamento-manual',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-aeracao-acionamento-manual.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-plenum-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-plenum-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-volumetria-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-volumetria-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-volumetria-white',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-volumetria-white.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-co2-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-co2-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-temperatura-branco',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-temperatura-branco.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-umidade-branco',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-umidade-branco.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-umidade-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-umidade-cinza.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-umidade-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-umidade-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-fornalha-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-fornalha-cinza.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-fornalha-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-fornalha-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-parametros-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-parametros-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-relogio-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-relogio-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-automacao-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-automacao-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-seta-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-seta-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-seta-vermelha',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-seta-vermelha.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-condicoes-externas-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-condicoes-externas-cinza.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-massa-graos-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-massa-graos-cinza.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-saida-ar-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-saida-ar-cinza.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-carga-graos-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-carga-graos-cinza.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-descarga-graos-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-descarga-graos-cinza.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-recirculacao-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-recirculacao-cinza.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-entrada-ar-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-entrada-ar-cinza.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-sensor-pressao-branco',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-sensor-pressao-branco.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-sensor-pressao-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-sensor-pressao-cinza.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-sensor-pressao-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-sensor-pressao-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-umidade-grao-branco',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-umidade-grao-branco.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-umidade-grao-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-umidade-grao-cinza.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-umidade-grao-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-umidade-grao-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-limpeza-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-limpeza-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-informacoes-gerais-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-informacoes-gerais-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-grafico-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-grafico-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-sistema',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-sistema.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-whatsapp',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-whatsapp.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-alarme-amarelo',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-alarme-amarelo.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-alarme-desabilitado',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-alarme-desabilitado.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-desligado-desabilitado',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-desligado-desabilitado.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-desligado-vermelho',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-desligado-vermelho.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-manutencao-desabilitado',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-manutencao-desabilitado.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-ligado-desabilitado',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-ligado-desabilitado.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-ligado-verde',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-ligado-verde.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-ligado-vermelho',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-ligado-vermelho.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-manutencao-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-manutencao-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-modo-operacao',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-modo-operacao.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-velocidade-descarga',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-velocidade-descarga.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-eficiencia',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-eficiencia.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-eficiencia-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-eficiencia-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-consumo-combustivel-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-consumo-combustivel-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-grafico-base-dupla',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-grafico-base-dupla.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-operacoes',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-operacoes.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-limpeza',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-limpeza.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-secador-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-secador-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-umidade-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-umidade-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-umidade-grao-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-umidade-grao-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-sensor-pressao-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-sensor-pressao-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-leituras-temperatura',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-leituras-temperatura.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-velocidade-descarga-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-velocidade-descarga-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-medidor-fluxo-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-medidor-fluxo-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-armazem-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-armazem-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-armazem-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-armazem-cinza.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-silo-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-silo-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-silo-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-silo-cinza.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-operacoes-card-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/modulo_operacoes_card_menu.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-carga',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-carga.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-descarga-com-nivel',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-descarga-com-nivel.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-condicao-de-armazenagem',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-condicao-de-armazenagem.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-filtro',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-filtro.svg ')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-outros-secadores',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-outros-secadores.svg ')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-descarga-sem-nivel',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-descarga-sem-nivel.svg ')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-secador-parado',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-secador-parado.svg ')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-silo-cheio-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-silo-cheio-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-silo-vazio-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-silo-vazio-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-gestao-por-grao',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-gestao-por-grao.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-gestao-grao-capacidade',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-estoque-grao-capacidade.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-salvar',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-salvar.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-apagar-filtro',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-apagar-filtro.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-crescente',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-ordem-crescente.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-decrescente',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-ordem-decrescente.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-cadastro',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-cadastro.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-perfis',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/icon-menu-perfis.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-usuarios',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/icon-menu-usuarios.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-olho',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/icon-olho.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-sucesso',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-sucesso.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-error',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-alert-vermelho.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-procer-mini',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-procer-mini.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-procer-mini-desabilitado',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-procer-mini-desabilitado.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-pendulo-triangulo',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-pendulo-triangulo.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-poligono',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-poligono.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-triangulo',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-triangulo.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-arrow-circle',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-arrow-circle.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-operacional-armazenagem-secador',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-gestao-unidades.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-gestao-unidades-operacional',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-gestao-unidades-operacional.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-gestao-unidades-qualidade',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-gestao-unidades-qualidade.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-gestao-unidades-parametrizacoes',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-parametrizacoes.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-gestao-unidades-parametrizacoes-ordem-de-servico',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/menus/menu-parametrizacoes-ordem-de-servico.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-gestao-unidades-parametrizacoes-descontos',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-parametrizacoes-descontos.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-expedicao-amostragens',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-expedicao-amostragens.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-engrenagem',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-engrenagem.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-gestao-unidades-rentabilidade',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/menus/menu-gestao-unidades-rentabilidade.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-dropdown-filtro',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-operacional-filtro-dropdown.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-lupa-zoom',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-lupa-zoom.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-gota-branca',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-gota-branca.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-seta-baixo',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/seta-vermelha-baixo.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-seta-cima',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/seta-verde-cima.svg')
        );

        this._matIconRegistry.addSvgIcon(
            'icon-gestao-unidade-rentabilidade-milho',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-milho.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-gestao-unidade-rentabilidade-soja',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-soja.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-gestao-unidade-rentabilidade-arroz-saca',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-arroz-em-saca.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-volumetria-3d',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu-volumetria-3d.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-seta-pagina-direto',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-seta-pagina-direito.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-seta-pagina-direto-desabilitado',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-seta-pagina-direito-desabilitado.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-seta-pagina-esquerdo',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-seta-pagina-esquerdo.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-seta-pagina-esquerdo-desabilitado',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-seta-pagina-esquerdo-desabilitado.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-gestao-unidade-rentabilidade-cifrao',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-cifrao.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-bandeira-usa',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-bandeira-usa.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-bandeira-br',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-bandeira-br.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-bandeira-uyu',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-bandeira-uyu.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-bandeira-cop',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-bandeira-cop.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-bandeira-clp',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-bandeira-clp.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-bandeira-rmb',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-bandeira-rmb.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-bandeira-eu',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-bandeira-eu.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-bandeira-ars',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-bandeira-ars.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-bandeira-pyg',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-bandeira-pyg.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-bandeira-pen',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-bandeira-pen.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-volume-1d-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-volume-1d.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-volume-3d-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-volume-3d.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-temperatura-umidade-grao',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-temperatura-umidade-grao.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-calendario-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-calendario-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-calculadora',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-calculadora.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-gestao-unidade-rentabilidade-trigo',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-trigo.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-maior-valor-lido',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-temperatura-maior-valor-lido.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-alerta-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-alerta-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-ganhos-armazenagem-propria',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-ganhos-armazenagem-propria.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-plantio-colheita',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-plantio-colheita.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-mapa-unidades',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-mapa-unidades.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-eficiencia-operacional',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-eficiencia-operacional.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-relatorio-e-historico',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-relatorio-e-historico.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-manutencao-equipamentos',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-manutencao-equipamentos.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-bandeira-pt',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/idiomas/icon-bandeira-pt.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-bandeira-en',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/idiomas/icon-bandeira-en.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-seta-direta',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-seta-direta.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-idiomas-mundo',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/idiomas/icon-mundo.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-idiomas-brasil',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/idiomas/icon-brasil.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-idiomas-eua',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/idiomas/icon-eua.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-gestao-unidades-amostragem-classificacao-categorias-amostras',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/menus/amostragem-classificacao-categorias-amostras.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-gestao-unidades-amostragem-classificacao',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/amostragem-classificacao.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-gestao-unidades-amostragem-classificacao-painel-listagem',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/menus/amostragem-classificacao-painel-listagem.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-plantio-colheita-desativado',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-plantio-colheita-desativado.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-mapa-unidades-desativado',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-mapa-unidades-desativado.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-silo-azul-desativado',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-silo-azul-desativado.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-secador-azul-desativado',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-secador-azul-desativado.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-temperatura-umidade-grao-desativado',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/icon-temperatura-umidade-grao-desativado.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-relatorio-e-historico-desativado',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-relatorio-e-historico-desativado.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-manutencao-equipamentos-desativado',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/icon-manutencao-equipamentos-desativado.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-eficiencia-operacional-desativado',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-eficiencia-operacional-desativado.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-expedicao-amostragens-desativado',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-expedicao-amostragens-desativado.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-idiomas-espanhol',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/idiomas/icon-es.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-bandeira-es',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/idiomas/icon-bandeira-es.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-exportar-relatorio',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-exportar-relatorio.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-desarquivar-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-desarquivar-cinza.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-resumo-amostragens-automaticas',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-resumo-amostragens-automaticas.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-resumo-amostragens-grao',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-resumo-amostragens-grao.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-alerta-vermelho',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-alerta-vermelho.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-cadastrar',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-cadastrar.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-seta-baixo',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-seta-baixo.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-seta-baixo-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-seta-baixo-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-seta-baixo-vermelha',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-seta-baixo-vermelha.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-seta-cima-vermelha',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-seta-cima-vermelha.svg')
        );

        this._matIconRegistry.addSvgIcon(
            'icone-carga-graos-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/grafico-sensores-operacoes/icone-carga-graos-cinza.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-condicoes-externas-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/grafico-sensores-operacoes/icone-condicoes-externas-cinza.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-descarga-graos-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/grafico-sensores-operacoes/icone-descarga-graos-cinza.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-entrada-ar-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/grafico-sensores-operacoes/icone-entrada-ar-cinza.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-saida-ar-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/grafico-sensores-operacoes/icone-saida-ar-cinza.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-fornalha-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/grafico-sensores-operacoes/icone-fornalha-cinza.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-massa-graos-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/grafico-sensores-operacoes/icone-massa-graos-cinza.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-recirculacao-cinza',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/grafico-sensores-operacoes/icone-recirculacao-cinza.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-pressao',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/grafico-sensores-operacoes/icone-pressao.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-umidade-grao',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/grafico-sensores-operacoes/icone-umidade-grao.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-umidade-relativa',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/grafico-sensores-operacoes/icone-umidade-relativa.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-temperatura',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/grafico-sensores-operacoes/icone-temperatura.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-iniciar-leitura',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/icon-iniciar-leitura.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-leitura-manual',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/icon-leitura-manual.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-falha-leitura',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/icon-falha-leitura.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-expedicao-recebimento-truck-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menus/menu_expedicao_recebimento_truck-azul.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-manha',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/icon-manha.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-tarde',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/icon-tarde.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-noite',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/icon-noite.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-madrugada',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/icon-madrugada.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-recebimento',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/icone-recebimento.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icone-expedicao',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/icone-expedicao.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'icon-menu-desenvolvimento',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-menu-desenvolvimento.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-gestao-unidades-eficiencia',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-gestao-unidades-eficiencia.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icon-gestao-unidades-eficiencia-medidores-de-fluxo',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-gestao-unidades-eficiencia-medidores-de-fluxo.svg')
        )
        this._matIconRegistry.addSvgIcon(
            'icone-ordenacao',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-ordenacao.svg')
        )
        this._matIconRegistry.addSvgIcon(
            'icon-gestao-unidades-eficiencia-processos',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-gestao-unidades-eficiencia-processos.svg')
        )
        this._matIconRegistry.addSvgIcon(
            'icon-beneficiamento',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-beneficiamento.svg')
        )
        this._matIconRegistry.addSvgIcon(
            'icon-seta-abaixo-preenchida-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-seta-abaixo-preenchida-azul.svg')
        )
        this._matIconRegistry.addSvgIcon(
            'icon-seta-acima-preenchida-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-seta-acima-preenchida-azul.svg')
        )
        this._matIconRegistry.addSvgIcon(
            'icon-eficiencia-recebimento',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-eficiencia-recebimento.svg')
        )
        this._matIconRegistry.addSvgIcon(
            'icon-expedicao',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-expedicao.svg')
        )
        this._matIconRegistry.addSvgIcon(
            'icon-alerta-circulo',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-alerta-circulo.svg')
        )
        this._matIconRegistry.addSvgIcon(
            'icon-vetor-baixo-azul',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-vetor-azul-baixo.svg')
        )
        this._matIconRegistry.addSvgIcon(
            'icone-calculadora-branca',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icone-calculadora-branca.svg')
        );
        this._matIconRegistry.addSvgIcon(
            'icone-conteudo-salvar',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-content-save.svg')
        );
    }
}
