import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusAutomaticoManualComIconeDescricaoComponent } from 'app/shared/components/status-automatico-manual-com-icone-descricao/status-automatico-manual-com-icone-descricao.component';
import { TranslocoRootModule } from 'app/transloco-root.module';

@NgModule({
    imports: [CommonModule, MatIconModule, TranslocoRootModule],
    declarations: [StatusAutomaticoManualComIconeDescricaoComponent],
    exports: [StatusAutomaticoManualComIconeDescricaoComponent],
})
export class StatusAutomaticoManualComIconeDescricaoModule {}
