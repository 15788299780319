import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusOnOffComponent } from './status-on-off.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoRootModule } from 'app/transloco-root.module';

@NgModule({
    imports: [CommonModule, MatTooltipModule, TranslocoRootModule],
    declarations: [StatusOnOffComponent],
    exports: [StatusOnOffComponent],
})
export class StatusOnOffModule {}
