import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, map, Observable } from 'rxjs';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { merge } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class TranslocoHttpLoader implements TranslocoLoader {
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get translation
     *
     * @param lang
     */
    getTranslation(lang: string): Observable<Translation> {                
        const traducao = [
            this._httpClient.get<Translation>(`./assets/i18n/${lang}.json`),
            this._httpClient.get<Translation>(`./assets/i18n/login/${lang}.json`),
        ];

        return forkJoin(traducao).pipe(map((response) => merge({}, ...response)));
    }
}
