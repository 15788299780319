import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces/';
import { ApiTransportService } from 'app/core/services/transport';
import { DadosFiltroModel, EquilibrioHigroscopicoModel } from 'app/shared/models';
import { Observable } from 'rxjs';

export abstract class IEquilibrioHigroscopicoController {
    abstract buscarEquilibrioHigroscopico(
        idEstacao: string,
        tipoEstacao: string,
        produtoTipoId: number,
        aquecimentnumbertplenum: number,
        tplenum?: number | string,
        urplenum?: number | string
    ): Observable<EquilibrioHigroscopicoModel>;
}

@Injectable({
    providedIn: 'root',
})
export class EquilibrioHigroscopicoController implements IEquilibrioHigroscopicoController {
    private transport: IApiTransportInterface;

    constructor(readonly apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/estacao-meteorologica/equilibrio-higroscopico');
    }

    buscarEquilibrioHigroscopico(
        idEstacao: string,
        tipoEstacao: string,
        produtoTipoId: number,
        aquecimento_ar: number,
        tplenum?: number | string,
        urplenum?: number | string
    ): Observable<any> {
        const params = new URLSearchParams({
            aquecimento_ar: aquecimento_ar.toString(),
            ...(tplenum !== undefined && { tplenum: tplenum.toString() }),
            ...(urplenum !== undefined && { urplenum: urplenum.toString() }),
        });

        return this.transport.http.get<any>(
            `${this.transport.path}/${idEstacao}/${tipoEstacao.toLowerCase()}/${produtoTipoId}?${params.toString()}`
        );
    }

    buscarHistoricoEquilibrioHigroscopico(siloId: number, filtro: DadosFiltroModel): Observable<any> {
        return this.transport.http.get<any>(
            `${this.transport.path}/geral/${siloId}/historico?dataInicio=${filtro.dataInicio}&dataFim=${filtro.dataFim}`
        );
    }
}
