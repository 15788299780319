<div class="d-flex componente-modal w-full pt-5">
    <div class="d-flex mb-5 modal-botao-fechar">
        <mat-icon id="icon-close" (click)="dialogRef.close()">close</mat-icon>
    </div>

    <div class="d-flex text-center pb-lg-0 mt-2 modal-titulo">
        <p class="text-sm sm:text-2xl">
            {{ "COMUNICACAO_EQUIPAMENTOS" | transloco }} - {{ estruturaArmazenagem?.nome }}
        </p>
    </div>

    <div class="w-full flex flex-row p-2 d-flex justify-center text-center items-center my-2">        
        <ng-container *ngIf="retornaStatusComunicacaoService.execute(listaStatusComunicacao) === estadoComunicacaoEnum.ALL">
            <mat-icon class="comunicacao comunicacao__positivo icon-size-3" svgIcon="mat_solid:circle"></mat-icon>
            <span class="pl-2" >
                {{ "COMUNICANDO" | transloco }}
            </span>
        </ng-container>

        <ng-container *ngIf="retornaStatusComunicacaoService.execute(listaStatusComunicacao) === estadoComunicacaoEnum.PARTIAL">
            <mat-icon class="comunicacao comunicacao__parcial icon-size-3" svgIcon="mat_solid:circle"></mat-icon>
            <span class="pl-2" >
                {{ "DATA_ULTIMA_COMUNICACAO_COMPONENT.COMUNICACAO_PARCIAL" | transloco }}
            </span>
        </ng-container>

        <ng-container *ngIf="retornaStatusComunicacaoService.execute(listaStatusComunicacao) === estadoComunicacaoEnum.NONE">
            <mat-icon class="comunicacao comunicacao__negativo icon-size-3" svgIcon="mat_solid:circle"></mat-icon>
            <span class="pl-2" >
                {{ "FALHA_DE_COMUNICACAO" | transloco }}
            </span>
        </ng-container>
    </div>

    <div class="componente-tabela-modal mb-5 mt-1 overflow-hidden overflow-y-auto height-modal">
        <ng-container *ngIf="!carregando; else carregandoTabela">
            <table mat-table [dataSource]="listaEquipamentos">
                <ng-container matColumnDef="equipamento">
                    <th mat-header-cell *matHeaderCellDef class="w-[25%] text-xs sm:text-base">
                        {{ "EQUIPAMENTO" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-left text-xs sm:text-base">
                        {{ element.nome }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="categoria">
                    <th mat-header-cell *matHeaderCellDef class="w-[25%] text-xs sm:text-base">
                        <div class="centralizar-texto">
                            {{ "CATEGORIA" | transloco }}
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center text-xs sm:text-base capitalize">
                        {{ defineCategoriaEquipamentoService.execute(element.categoria) }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef class="w-[25%] sm:text-base text-xs">
                        <div class="centralizar-texto">
                            {{ "STATUS" | transloco }}
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let elemento" class="text-xs sm:text-base text-center">
                        <ng-container *ngIf="elemento.equipamento_comunicacao">
                            <div *ngIf="elemento.equipamento_comunicacao.status" class="coluna-status">
                                <mat-icon
                                    class="status status__positivo icon-size-3"
                                    svgIcon="mat_solid:circle"
                                ></mat-icon>
                                <span class="hidden sm:table-cell w-[100px]">
                                    {{ "COMUNICANDO" | transloco }}
                                </span>
                            </div>
                            <div *ngIf="!elemento.equipamento_comunicacao.status" class="coluna-status">
                                <mat-icon
                                    class="status status__negativo icon-size-3"
                                    svgIcon="mat_solid:circle"
                                ></mat-icon>
                                <span class="hidden sm:table-cell w-[180px]">
                                    {{ "FALHA_DE_COMUNICACAO" | transloco }}
                                </span>
                            </div>
                        </ng-container>

                        <div *ngIf="!elemento.equipamento_comunicacao" class="coluna-status">
                            <mat-icon class="status status__negativo icon-size-3" svgIcon="mat_solid:circle"></mat-icon>
                            <span class="hidden sm:table-cell w-[180px]">
                                {{ "AGUARDANDO_LEITURA" | transloco }}
                            </span>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="dataHora">
                    <th mat-header-cell *matHeaderCellDef class="w-[25%] text-xs sm:text-base">
                        <div class="centralizar-texto">
                            {{ "DATA_HORA" | transloco }}
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center text-xs sm:text-base">
                        {{
                            element.equipamento_comunicacao?.instante
                                ? (element.equipamento_comunicacao?.instante | date : "dd/MM/yyyy HH:mm")
                                : ("AGUARDANDO_LEITURA" | transloco)
                        }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="tabelaColunas"></tr>
                <tr mat-row *matRowDef="let row; let i = index; columns: tabelaColunas"></tr>
            </table>
        </ng-container>
    </div>
</div>

<ng-template #carregandoTabela>
    <div class="h-[200px] flex">
        <span class="m-auto flex">
            <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
        </span>
    </div>
</ng-template>
