export class FormatarMinutosEmHoras {
    public static execute(minutos: number): string {
        if (!minutos) {
            return '00:00';
        }

        const horas = Math.floor(minutos / 60);
        const minutosRestantes = minutos % 60;

        const horasFormatadas = horas < 10 ? `0${horas}` : `${horas}`;
        const minutosFormatados = minutosRestantes < 10 ? `0${minutosRestantes}` : `${minutosRestantes}`;

        return `${horasFormatadas}:${minutosFormatados}`;
    }
}
