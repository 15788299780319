<ng-container *ngIf="!verificaObjetoVazio(estacao.ultima_leitura); else semDados">
    <div class="flex flex-row justify-center px-4 mt-3">
        <div class="w-[20%]">
            <p class="text-sm text-[#535767] leading-3 font-normal mt-2">
                {{ "INFORMACOES_ESTACAO_METEREOLOGICA_COMPONENT.TEMPERATURA" | transloco }}
            </p>
            <p
                class="font-semibold text-base leading-4 mt-1"
                [ngClass]="carregaFonteEstacaoMeteorologica(estacao)"
            >
                {{ estacao.ultima_leitura?.temperatura !== null ? (estacao?.ultima_leitura?.temperatura | number : "1.1-1") : "-" }}
            </p>
        </div>
        <div class="w-[20%] sm:w-[23%] md:w-[20%] lg:w-[23%]">
            <p class="text-sm text-[#535767] leading-3 font-normal mt-2">
                {{ "INFORMACOES_ESTACAO_METEREOLOGICA_COMPONENT.UMIDADE_RELATIVA" | transloco }}
            </p>
            <p
                class="font-semibold text-base leading-4 mt-1"
                [ngClass]="carregaFonteEstacaoMeteorologica(estacao)"
            >
                {{ estacao?.ultima_leitura?.umidade !== null ? (estacao.ultima_leitura.umidade | number : "1.1-1") : "-" }}
            </p>
        </div>
        <div class="w-[13%]">
            <p class="text-sm text-[#535767] leading-3 font-normal text-center mt-2">
                {{ "INFORMACOES_ESTACAO_METEREOLOGICA_COMPONENT.CHUVA" | transloco }}
            </p>
            <span
                class="font-semibold text-base leading-4 mt-1 flex justify-center -ml-[6px]"                
                [ngClass]="carregaFonteEstacaoMeteorologica(estacao)"
                #tooltip="matTooltip"
                matTooltip="{{ 'INFORMACOES_ESTACAO_METEREOLOGICA_COMPONENT.VOLUME_CHUVA' | transloco }} {{
                    estacao?.ultima_leitura?.chuva ? (estacao?.ultima_leitura?.chuva | number : '1.1-1') : ''
                }} mm"
                matTooltipClass="tooltip-padrao"
                matTooltipPosition="above"
                [matTooltipDisabled]="!estacao?.ultima_leitura?.chovendo"
            >
                {{ estacao.ultima_leitura ? (estacao.ultima_leitura.chovendo === null ? '-' : (estacao.ultima_leitura.chovendo ? ("SIM" | transloco) : ("NAO" | transloco))) : "-" }}
            </span>
        </div>
        <div class="w-[14%]">
            <p
                #tooltip="matTooltip"
                matTooltip="{{ estacao?.equipamento?.nome }}"
                matTooltipClass="tooltip-padrao"
                matTooltipPosition="above"
                class="text-center mt-4"
            >
                <span
                    class="capitalize rounded-md px-4 mb-2 text-base py-1 leading-4 font-normal"
                    [ngClass]="carregaEstiloEstacaoMeteorologica(estacao)"
                >
                    {{ "INFORMACOES_ESTACAO_METEREOLOGICA_COMPONENT." + estacao.tipo.toUpperCase() | transloco }}
                </span>
            </p>
        </div>
        <div class="w-[10%]">
            <span class="flex justify-center">
                <img
                    *ngIf="estacao.ultima_leitura.chovendo !== null"                    
                    class="w-[56px]"
                    [src]="
                        estacao.ultima_leitura.chovendo
                            ? 'assets/media/storage/gifs/chuva-small.gif'
                            : 'assets/media/storage/gifs/sol-small.gif'
                    "
                />
            </span>
        </div>
        <div class="w-[15%]">
            <div *ngIf="estacao.ultima_leitura" class="mt-4 flex">
                <span class="text-sm text-[#535767] leading-3 font-normal text-right">
                    <span
                        class="rounded-full w-2 h-2 mr-1 inline-block"
                        [ngClass]="estacao.ultima_leitura.validacao_leitura.status ? 'bg-[#34901C]' : 'bg-[#D65757]'"
                    ></span
                    >{{
                        estacao.ultima_leitura
                            ? (estacao.ultima_leitura.validacao_leitura.instante | date : "dd/MM/yyyy HH:mm:ss")
                            : "-"
                    }}
                </span>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #semDados>
    <div class="flex flex-row justify-between px-4 mt-3">
        <div class="w-[20%]">
            <p class="text-sm text-[#535767] leading-3 font-normal mt-2">
                {{ "INFORMACOES_ESTACAO_METEREOLOGICA_COMPONENT.TEMPERATURA" | transloco }}
            </p>
            <p class="text-xs sm:text-base font-semibold leading-4 mt-0.5 px-[2px] text-[#535767]">-</p>
        </div>
        <div class="w-[20%] sm:w-[23%] md:w-[20%] lg:w-[23%]">
            <p class="text-sm text-[#535767] leading-3 font-normal mt-2">
                {{ "INFORMACOES_ESTACAO_METEREOLOGICA_COMPONENT.UMIDADE_RELATIVA" | transloco }}
            </p>
            <p class="text-xs sm:text-base font-semibold leading-4 mt-0.5 px-[2px] text-[#535767]">-</p>
        </div>
        <div class="w-[13%]">
            <p class="text-sm text-[#535767] leading-3 font-normal text-center mt-2">
                {{ "INFORMACOES_ESTACAO_METEREOLOGICA_COMPONENT.CHUVA" | transloco }}
            </p>
            <p class="text-xs sm:text-base font-semibold leading-4 mt-0.5 px-[2px] text-center text-[#535767]">-</p>
        </div>
        <div class="w-[14%]">
            <p
                #tooltip="matTooltip"
                matTooltip="{{ estacao?.equipamento?.nome }}"
                matTooltipClass="tooltip-padrao"
                matTooltipPosition="above"
                class="text-center mt-4"
            >
                <span
                    class="capitalize rounded-md px-4 mb-2 text-base py-1 leading-4 font-normal text-[#292b32] bg-[#ebeced]"
                >
                    {{ "INFORMACOES_ESTACAO_METEREOLOGICA_COMPONENT." + estacao.tipo.toUpperCase() | transloco }}
                </span>
            </p>
        </div>
    </div>
</ng-template>
