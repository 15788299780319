<div class="containerSelectPadrao">
  <mat-form-field class="w-full">
    <mat-label class="mb-3">{{ "TELEFONE" | transloco }}</mat-label>
    <input
      type="text"
      matInput
      [formControl]="controleInputTelefone"
      [mask]="paisSelecionado?.mascara"
      (keydown)="apenasNumerosValidos($event)"
    />
    <mat-select matPrefix class="mr-1.5" [value]="paisSelecionado">
      <mat-select-trigger>
        <span class="flex items-center">
          <span
            class="w-6 h-4 mr-1"
            [style.background]="
              'url(\'/assets/images/flags/flags.png\') no-repeat 0 0'
            "
            [style.backgroundSize]="'24px 3876px'"
            [style.backgroundPosition]="paisSelecionado?.posicaoImgBandeira"
          ></span>
          <span class="sm:mx-0.5 font-medium text-default">{{
            paisSelecionado?.codigo
          }}</span>
        </span>
      </mat-select-trigger>
      <mat-option
        *ngFor="let pais of bandeiraPaises; let idx = index"
        [value]="pais"
        (click)="selecionaPaisPelaBandeira(pais)"
      >
        <span class="flex items-center">
          <span
            class="w-6 h-4 overflow-hidden"
            [style.background]="
              'url(\'/assets/images/flags/flags.png\') no-repeat 0 0'
            "
            [style.backgroundSize]="'24px 3876px'"
            [style.backgroundPosition]="pais.posicaoImgBandeira"
          ></span>
          <span class="ml-2">{{ pais.nome }}</span>
          <span class="ml-2 font-medium">{{ pais.codigo }}</span>
        </span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
