import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
    @Input() slidesDisponiveis: string[] | undefined;
    @Output() slideSelecionado: EventEmitter<string> = new EventEmitter<string>();

    slideAtual = 0;

    ngOnInit() {
        this.inicializaSliders();
    }

    avancarSlide(): void {
        if (this.slideAtual < this.slidesDisponiveis.length - 1) {
            this.slideAtual = this.slideAtual + 1;
            this.slideSelecionado.emit(this.slidesDisponiveis[this.slideAtual]);
        }
    }

    voltarSlide(): void {
        if (this.slideAtual > 0) {
            this.slideAtual = this.slideAtual - 1;
            this.slideSelecionado.emit(this.slidesDisponiveis[this.slideAtual]);
        }
    }

    private inicializaSliders(): void {
        this.slideSelecionado.emit(this.slidesDisponiveis[this.slideAtual]);
    }
}
