import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, map } from 'rxjs';
import moment from 'moment';

import { LeituraGraficoSensoresInterface } from 'app/modules/armazenagem/visualizacao/painel-geral/interfaces';
import { TipoSensoresEnum } from 'app/modules/armazenagem/visualizacao/painel-geral/enums';
import { ApiTransportService } from 'app/core/services/transport';
import { IApiTransportInterface } from 'app/core/interfaces';

export abstract class IInformacoesGraficoSensoresController {
    abstract buscaInformacoesGraficoSensores(
        estruturaArmazenagemID: string        
    ): Observable<LeituraGraficoSensoresInterface[]>;
}

@Injectable({
    providedIn: 'root',
})
export class InformacoesGraficoSensoresController implements IInformacoesGraficoSensoresController {
    private transport: IApiTransportInterface;

    constructor(public readonly apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/armazenagem/painel-grafico-sensores/estrutura-armazenagem-divisao');
    }

    buscaInformacoesGraficoSensores(
        estruturaArmazenagemID: string,        
    ): Observable<LeituraGraficoSensoresInterface[]> {
        const params = new HttpParams()
            .set('data_inicio', moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DDT00:01:00.000[Z]'))
            .set('data_fim', moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DDT23:59:00.000[Z]'));

        return this.transport.http.get<LeituraGraficoSensoresInterface[]>(`${this.transport.path}/${estruturaArmazenagemID}`, { params })

    }
}
