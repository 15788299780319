import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisaoPlanaHistoricoSiloComponent } from './visao-plana-historico-silo.component';
import { AeradoresVisaoSiloModule } from 'app/shared/components/aeradores-visao-silo/aeradores-visao-silo.module';
import { TooltipPenduloHistoricoModule } from 'app/shared/components/tooltip-pendulo-historico/tooltip-pendulo-historico.module';

@NgModule({
    declarations: [VisaoPlanaHistoricoSiloComponent],
    imports: [CommonModule, AeradoresVisaoSiloModule, TooltipPenduloHistoricoModule],
    exports: [VisaoPlanaHistoricoSiloComponent],
})
export class VisaoPlanaHistoricoSiloModule {}
