import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import * as BABYLON from "@babylonjs/core";
import * as GUI from "@babylonjs/gui/2D";
import "@babylonjs/loaders/glTF";
import { TranslocoService } from "@ngneat/transloco";

import { CaminhoImagensSilo3DEnum, SiloBaseEnum } from "app/shared/enums";
import { RemoveAcaoScrollElementoHelper } from "app/shared/helpers";
import {
  EquipamentoInformacoesSiloInterface,
  EstacaoMeteorologicaInterface,
  InformacoesSiloInterface,
  PenduloInformacoesSiloInterface,
  PenduloSilo3DInterface,
  SensorVolumetria3DUltimaLeitura,
  Silo3DPropriedadesInterface,
} from "app/shared/interfaces";
import {
  IAplicaPlanoCorteSilo3DService,
  ICriaBotoesSilo3DService,
  ICriaEscadaSilo3DService,
  ICriaGraoSilo3DService,
  ICriaSilo3DBasePlanaService,
  ICriaSilo3DBaseSemiVService,
  ICriaSilo3DBaseVService,
  ICriaTexturaGraoSilo3DService,
  IDefineMaterialConstrucaoSilo3DService,
  IDesenhaPendulosVisaoSuperiorService,
  IFormataLeiturasSensor3DService,
  IMontraVetorSensoresSilo3DService,
  IRetonaInstanteMaisRecenteEquipamentoService,
  IRetornaAlturaPendulosSilo3DService,
  ISincronizarPendulosSilo3DService,
} from "app/shared/services";

@Component({
  selector: "app-visao-silo-3d",
  templateUrl: "./visao-silo-3d.component.html",
  styleUrls: ["./visao-silo-3d.component.scss"],
})
export class VisaoSilo3dComponent implements OnInit, OnDestroy {
  @ViewChild("canvasSilo3D", { static: true })
  canvasSilo3D!: ElementRef<HTMLCanvasElement>;
  @ViewChild("botaoZoomIn", { static: true })
  botaoZoomIn!: ElementRef<HTMLElement>;
  @ViewChild("botaoZoomOut", { static: true })
  botaoZoomOut!: ElementRef<HTMLElement>;
  @ViewChild("resetaCamera", { static: true })
  resetaCamera!: ElementRef<HTMLElement>;
  @ViewChild("rotacionaDireita", { static: true })
  rotacionaDireita!: ElementRef<HTMLElement>;
  @ViewChild("rotacionaEsquerda", { static: true })
  rotacionaEsquerda!: ElementRef<HTMLElement>;

  @Input() silo: InformacoesSiloInterface;
  @Input() cabos: PenduloInformacoesSiloInterface[];
  @Input() equipamentos: EquipamentoInformacoesSiloInterface[];
  @Input() estacaoMeteorologica: EstacaoMeteorologicaInterface;

  private engine!: BABYLON.Engine;
  private cena!: BABYLON.Scene;
  private texturaAvancada: GUI.AdvancedDynamicTexture;
  private camera: BABYLON.ArcRotateCamera;
  private cameraInicial: BABYLON.ArcRotateCamera;
  private linhasPendulos: { [key: number]: BABYLON.LinesMesh } = {};
  public pendulosControle: UntypedFormControl = new UntypedFormControl(false);

  public baseSiloAtual: string = "";
  private penduloData: PenduloSilo3DInterface[] = [];
  private siloData: Silo3DPropriedadesInterface;
  private listaInstantesComunicacao: string[] = [];
  private dataMaisRecente: string;
  private horaUltimaLeitura: Date;
  private vetorSensores: BABYLON.Mesh[][] = [];
  public temPendulos: boolean = true;
  public carregando: boolean = true;

  constructor(
    private desenhaPendulosVisaoSuperiorService: IDesenhaPendulosVisaoSuperiorService,
    private retonaInstanteMaisRecenteEquipamentoService: IRetonaInstanteMaisRecenteEquipamentoService,
    private aplicaPlanoCorteSilo3DService: IAplicaPlanoCorteSilo3DService,
    private criaSilo3DBasePlanaService: ICriaSilo3DBasePlanaService,
    private criaSilo3DBaseSemiVService: ICriaSilo3DBaseSemiVService,
    private criaSilo3DBaseVService: ICriaSilo3DBaseVService,
    private criaEscadaSilo3DService: ICriaEscadaSilo3DService,
    private defineMaterialConstrucaoSilo3DService: IDefineMaterialConstrucaoSilo3DService,
    private criaBotoesSilo3DService: ICriaBotoesSilo3DService,
    private criaGraoSilo3DService: ICriaGraoSilo3DService,
    private criaTexturaGraoSilo3DService: ICriaTexturaGraoSilo3DService,
    private mostraVetorSensoresSilo3DService: IMontraVetorSensoresSilo3DService,
    private sincronizarPendulosSilo3DService: ISincronizarPendulosSilo3DService,
    private retornaAlturaPendulosSilo3DService: IRetornaAlturaPendulosSilo3DService,
    private formataLeiturasSensor3DService: IFormataLeiturasSensor3DService,
    private transloco: TranslocoService
  ) {}

  async ngOnInit() {
    this.listaInstantesComunicacao = [];
    this.equipamentos?.forEach((equipamento) => {
      this.listaInstantesComunicacao.push(
        equipamento.equipamento_comunicacao?.instante
      );
    });
    this.dataMaisRecente =
      this.retonaInstanteMaisRecenteEquipamentoService.execute(
        this.listaInstantesComunicacao
      );
    this.baseSiloAtual = await this.silo?.silo?.base;

    await this.atribuirDimensaoSilo();
    await this.inicializaMecanismo3D();

    RemoveAcaoScrollElementoHelper.execute(this.canvasSilo3D);
  }

  private statusComunicacaoSensor3D(): boolean {
    return this.equipamentos.find(
      (equipamento) =>
        equipamento.id ===
        this.silo.sensores.sensor_volume_3d[0]?.equipamento_id
    ).equipamento_comunicacao?.status;
  }

  private possuiLeituras3D(): boolean {
    if (
      this.silo.sensores.sensor_volume_3d[0]?.sensor_volume_3d_ultima_leitura
        ?.pontos_eixo_x?.length &&
      this.silo.sensores.sensor_volume_3d[0]?.sensor_volume_3d_ultima_leitura
        ?.pontos_eixo_y?.length &&
      this.silo.sensores.sensor_volume_3d[0]?.sensor_volume_3d_ultima_leitura
        ?.pontos_eixo_z?.length
    ) {
      return true;
    }
    return false;
  }

  private async inicializaMecanismo3D(): Promise<void> {
    this.engine = new BABYLON.Engine(this.canvasSilo3D.nativeElement, true);
    const { cena } = await this.criarCena(
      this.engine,
      this.canvasSilo3D.nativeElement
    );
    this.cena = cena;
    this.texturaAvancada = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI");
    this.criarInteracaoBotoesCamera();

    await this.configurarPendulos();
    await this.criaPendulosComSensores();
    await this.criarMassaDeGrao();
    await this.defineFormaSiloBase(
      this.baseSiloAtual,
      this.silo?.estrutura_armazenagem_propriedade?.material
    );

    this.defineMaterialConstrucaoSilo3DService.execute(
      this.baseSiloAtual,
      this.silo?.estrutura_armazenagem_propriedade?.material,
      this.cena
    );

    await this.criaEscadaSilo3DService.execute(
      this.baseSiloAtual,
      this.penduloData,
      this.siloData,
      this.cena
    );

    this.carregando = false;
    this.engine.runRenderLoop(() => {
      this.aplicaPlanoCorte();
      this.cena.render();
    });
  }

  private async aplicaPlanoCorte(): Promise<void> {
    const cilindroAtual = this.cena.getMeshByName(
      `cilindro${this.baseSiloAtual}`
    );
    const chapeuAtual = this.cena.getMeshByName(`chapeu${this.baseSiloAtual}`);

    const fundoAtual =
      this.baseSiloAtual !== SiloBaseEnum.PLANA
        ? this.cena.getMeshByName(`fundo${this.baseSiloAtual}`)
        : undefined;
    const pilaresAtuais =
      this.baseSiloAtual === SiloBaseEnum.EM_V
        ? this.cena.getMeshByName(`pilares${this.baseSiloAtual}`)
        : undefined;

    await this.aplicaPlanoCorteSilo3DService.execute(
      pilaresAtuais,
      cilindroAtual,
      chapeuAtual,
      fundoAtual,
      this.siloData?.diametroSilo,
      this.baseSiloAtual,
      this.silo?.estrutura_armazenagem_propriedade?.material,
      this.cena
    );
  }

  private retornaPosicaoPendulos(
    pendulos: PenduloInformacoesSiloInterface[]
  ): number[][] {
    const posicaoPendulo =
      this.desenhaPendulosVisaoSuperiorService.calculatePendulumPositions(
        pendulos
      );

    let vetorPosicaoPendulo: number[][] = [];

    posicaoPendulo?.forEach((pendulo) => {
      vetorPosicaoPendulo.push([pendulo.posX, pendulo.posY]);
    });

    return vetorPosicaoPendulo;
  }

  private defineFormaSiloBase(siloAtual: string, materialSiloAtual: string) {
    switch (siloAtual) {
      case SiloBaseEnum.PLANA:
        this.criaSilo3DBasePlanaService.execute(this.siloData, this.cena);
        break;
      case SiloBaseEnum.EM_V:
        this.criaSilo3DBaseVService.execute(this.siloData, this.cena);
        break;
      case SiloBaseEnum.SEMI_V:
        this.criaSilo3DBaseSemiVService.execute(this.siloData, this.cena);
        break;
      default:
        break;
    }
  }

  private defineBackgroundClima(cena: BABYLON.Scene): void {
    if (this.estacaoMeteorologica) {
      this.criarBackground(cena, this.estacaoMeteorologica);
    } else {
      this.criarBackground(cena);
    }
  }

  private criarBackground(
    cena: BABYLON.Scene,
    estacao?: EstacaoMeteorologicaInterface
  ): void {
    let containerCeu: BABYLON.Mesh = BABYLON.Mesh.CreateBox(
      "skyBox",
      1000,
      cena
    );
    let containerCeuMaterial: BABYLON.StandardMaterial =
      new BABYLON.StandardMaterial("skyBoxMaterial", cena);

    if (estacao) {
      this.horaUltimaLeitura = new Date(this.dataMaisRecente);
    }

    if (!this.periodoDia()) {
      cena.getLightByName("luzFrente").intensity = 0.6;
      cena.getLightByName("luzAtras").intensity = 0.1;
    }

    containerCeu.position = new BABYLON.Vector3(0, 0, 0);
    containerCeu.isPickable = false;
    containerCeuMaterial.backFaceCulling = false;
    containerCeuMaterial.reflectionTexture = new BABYLON.CubeTexture(
      this.retornarCaminhoBackgroung(this.periodoDia()),
      cena
    );
    containerCeuMaterial.reflectionTexture.coordinatesMode =
      BABYLON.Texture.SKYBOX_MODE;
    containerCeuMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
    containerCeuMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
    containerCeuMaterial.disableLighting = true;
    containerCeu.material = containerCeuMaterial;

    if (estacao?.ultima_leitura?.chovendo) {
      BABYLON.ParticleHelper.CreateAsync("rain", cena, true).then((set) => {
        set.start();
      });
    }
  }

  private retornarCaminhoBackgroung(dia: boolean): string {
    return dia
      ? CaminhoImagensSilo3DEnum.BACKGOUND_DIA.toString()
      : CaminhoImagensSilo3DEnum.BACKGOUND_NOITE.toString();
  }

  private periodoDia(): boolean {
    let hora = this.horaUltimaLeitura?.getHours();
    return hora >= 6 && hora < 18;
  }

  private atribuirDimensaoSilo(): void {
    const escala = 10;
    const alturaPlataforma = 0.1;

    if (this.silo) {
      this.siloData = {
        diametroSilo: this.silo?.silo?.diametro / escala,
        diametroChapeu: (this.silo?.silo?.diametro + 0.2) / escala,
        diametroInferiorFundo: this.silo?.silo?.diametro_inferior / escala,
        alturaSilo: this.silo.silo.altura_cilindro / escala,
        alturaChapeu: this.silo?.silo?.altura_cone_superior / escala,
        alturaFundo: this.silo?.silo?.altura_cone_inferior / escala,
        alturaPlataforma: alturaPlataforma,
        anguloChapeu: this.silo?.silo?.angulo_cone_superior / escala,
        anguloFundo: this.silo?.silo?.angulo_cone_inferior / escala,
      };
      if (!this.siloData.alturaChapeu) {
        this.siloData.alturaSilo = this.retornarAlturaTelhado();
      }
      if (
        !this.siloData.alturaFundo &&
        this.baseSiloAtual !== SiloBaseEnum.PLANA
      ) {
        this.siloData.alturaFundo = this.retornarAlturaFundo();
      }
    }
  }

  private retornaAlturaSilo(escala: number): number {
    return this.baseSiloAtual === SiloBaseEnum.EM_V
      ? this.silo?.silo?.altura_total / escala -
          this.silo?.silo?.altura_cone_inferior / escala / 2
      : this.silo?.silo?.altura_total / escala;
  }

  private retornarAlturaTelhado(): number {
    if (this.siloData?.anguloChapeu !== undefined) {
      return this.retornarAlturaPeloAngulo(
        this.siloData?.diametroChapeu,
        this.siloData?.anguloChapeu
      );
    }
  }

  private retornarAlturaFundo(): number {
    if (this.siloData?.anguloFundo !== undefined) {
      return this.retornarAlturaPeloAngulo(
        this.siloData?.diametroSilo,
        this.siloData?.anguloFundo
      );
    }
  }

  private retornarAlturaPeloAngulo(diametro: number, angulo: number): number {
    let raio: number = diametro / 2;
    let anguloRadianos: number = BABYLON.Tools.ToRadians(angulo);
    let alturaPorAngulo: number = raio * Math.tan(anguloRadianos);

    return parseFloat(alturaPorAngulo.toFixed(2));
  }

  private criarInteracaoBotoesCamera(): void {
    this.botaoZoomIn.nativeElement.addEventListener("click", () => {
      this.camera.radius -= 0.5;
    });

    this.botaoZoomOut.nativeElement.addEventListener("click", () => {
      this.camera.radius += 0.5;
    });

    this.resetaCamera.nativeElement.addEventListener("click", () => {
      this.camera.target = this.cameraInicial.target;
      this.camera.upperRadiusLimit = this.cameraInicial.upperRadiusLimit;
      this.camera.setPosition = this.cameraInicial.setPosition;
      this.camera.radius = this.cameraInicial.radius;
      this.camera.alpha = this.cameraInicial.alpha;
      this.camera.beta = this.cameraInicial.beta;
    });

    this.rotacionaEsquerda.nativeElement.addEventListener("click", () => {
      this.camera.alpha += BABYLON.Tools.ToRadians(10);
    });

    this.rotacionaDireita.nativeElement.addEventListener("click", () => {
      this.camera.alpha -= BABYLON.Tools.ToRadians(10);
    });
  }

  private configuraCameraParaCena(
    cena: BABYLON.Scene,
    canvas: HTMLCanvasElement,
    idCamera: string
  ): BABYLON.ArcRotateCamera {
    const camera = new BABYLON.ArcRotateCamera(
      idCamera,
      BABYLON.Tools.ToRadians(90),
      BABYLON.Tools.ToRadians(90),
      null,
      null,
      cena
    );

    camera.attachControl(canvas, true);
    camera.wheelPrecision = 50;
    camera.lowerRadiusLimit = 1.5;
    camera.upperBetaLimit = BABYLON.Tools.ToRadians(100);

    return camera;
  }

  private configuraLuzesCena(cena: BABYLON.Scene): void {
    const luzFrente = new BABYLON.HemisphericLight(
      "luzFrente",
      new BABYLON.Vector3(1, 1, 0),
      cena
    );
    const luzAtras = new BABYLON.HemisphericLight(
      "luzAtras",
      new BABYLON.Vector3(1, 1, 0),
      cena
    );

    luzFrente.intensity = 0.8;
    luzAtras.intensity = 0.2;

    cena.registerBeforeRender(() => {
      const direcao = this.camera.getDirection(BABYLON.Axis.Z);
      luzFrente.direction = new BABYLON.Vector3(-direcao.x, 0.2, -direcao.z);
      luzAtras.direction = new BABYLON.Vector3(direcao.x, 0.2, direcao.z);
    });
  }

  private async criarCena(
    engine: BABYLON.Engine,
    canvas: HTMLCanvasElement
  ): Promise<{ cena: BABYLON.Scene }> {
    const cena = new BABYLON.Scene(engine);
    cena.clearColor = new BABYLON.Color4(0, 0.62, 0.91, 1);

    this.camera = this.configuraCameraParaCena(cena, canvas, "camera");
    this.cameraInicial = this.configuraCameraParaCena(
      cena,
      canvas,
      "cameraInicial"
    );
    this.configuraLuzesCena(cena);

    this.defineBackgroundClima(cena);

    return { cena };
  }

  private criaPendulosComSensores(): void {
    this.vetorSensores = this.mostraVetorSensoresSilo3DService.execute(
      this.penduloData,
      this.siloData,
      this.cena
    );

    this.criaBotoesSilo3DService.execute(
      this,
      this.penduloData,
      this.texturaAvancada,
      this.pendulosControle,
      this.linhasPendulos,
      this.vetorSensores,
      this.cena,
      this.silo.estrutura_armazenagem.sensor_temperatura_instalado
    );
  }

  private configurarPendulos(): void {
    this.sincronizarPendulosSilo3DService.execute(
      this.silo,
      this.retornaPosicaoPendulos(this.cabos),
      this.siloData,
      this.penduloData,
      this.camera,
      this.cameraInicial
    );
  }

  private criarMassaDeGrao(): void {
    if (
      !this.possuiLeituras3D() ||
      !this.statusComunicacaoSensor3D() ||
      this.baseSiloAtual !== SiloBaseEnum.PLANA
    ) {
      this.criaGraoSilo3DService.execute(
        this.vetorSensores,
        this.baseSiloAtual,
        this.penduloData,
        this.siloData,
        this.cena
      );
    } else {
      this.criaNovoGrao(
        this.silo.sensores.sensor_volume_3d[0].sensor_volume_3d_ultima_leitura
      );
    }
  }

  private criaNovoGrao(leituras: SensorVolumetria3DUltimaLeitura): void {
    const leiturasFormatadas =
      this.formataLeiturasSensor3DService.execute(leituras);
    const alturaCilindro = this.siloData.alturaSilo;
    const diametro = this.siloData.diametroSilo;
    const distanciaSensor = 608 / 1000;
    const raio = diametro / 2;

    const xSensor =
      Math.cos(BABYLON.Tools.ToRadians(30)) * distanciaSensor - raio;
    const zSensor = diametro / 2 - raio;
    const ySensor =
      Math.sin(BABYLON.Tools.ToRadians(30)) * distanciaSensor + alturaCilindro;

    const vetorDePontos: BABYLON.Vector3[] = [];
    let distanciaMinima = Infinity;
    let pontoMaisProximo: BABYLON.Vector3 = null;

    leiturasFormatadas.forEach((item) => {
      const x = item[0] / 1000 - raio;
      const y = item[2] / 1000 + this.siloData.alturaPlataforma * 2;
      const z = item[1] / 1000 - raio;
      const ponto = new BABYLON.Vector3(x, y, z);
      vetorDePontos.push(ponto);
      const distancia = Math.sqrt(
        Math.pow(ponto.x - xSensor, 2) + Math.pow(ponto.z - zSensor, 2)
      );
      if (distancia < distanciaMinima) {
        pontoMaisProximo = ponto;
        distanciaMinima = distancia;
      }
    });

    let linhas = this.divideVetorPontos(vetorDePontos);
    linhas = this.divideLinhasPeloSensor(linhas, pontoMaisProximo);
    linhas = this.criaPontosExternos(linhas, raio, xSensor, zSensor);
    linhas = this.normalizaLinhas(linhas);
    const linhasParede = this.criaParedesDoGrao(linhas);
    this.criaMeshGrao(linhas, linhasParede);
  }

  private divideVetorPontos(
    vetorDePontos: BABYLON.Vector3[]
  ): BABYLON.Vector3[][] {
    function calcularAnguloEntrePontos(ponto1, ponto2) {
      const deltaX = ponto2.x - ponto1.x;
      const deltaZ = ponto2.z - ponto1.z;
      return Math.atan2(deltaZ, deltaX) * (180 / Math.PI);
    }

    function angulosSaoIguais(angulo1, angulo2, tolerancia = 2) {
      return Math.abs(angulo1 - angulo2) <= tolerancia;
    }

    let anguloAtual;
    let linha: BABYLON.Vector3[] = [];
    let linhas: BABYLON.Vector3[][] = [];
    for (let i = 0; i < vetorDePontos.length - 1; i++) {
      const pontoAtual = vetorDePontos[i];
      const proximoPonto = vetorDePontos[i + 1];
      const angulo = calcularAnguloEntrePontos(pontoAtual, proximoPonto);
      if (anguloAtual === undefined) {
        anguloAtual = angulo;
      }

      if (angulosSaoIguais(angulo, anguloAtual)) {
        linha.push(vetorDePontos[i]);
        anguloAtual = angulo;
      } else {
        linha.push(vetorDePontos[i]);
        linhas.push(linha);
        linha = [];
        anguloAtual = undefined;
      }
    }
    linha.push(vetorDePontos[vetorDePontos.length - 1]);
    linhas.push(linha);

    return linhas;
  }

  private divideLinhasPeloSensor(
    linhas: BABYLON.Vector3[][],
    pontoCentro: BABYLON.Vector3
  ): BABYLON.Vector3[][] {
    const linhasDivididas1: BABYLON.Vector3[][] = [];
    const linhasDivididas2: BABYLON.Vector3[][] = [];

    for (let i = 0; i < linhas.length; i++) {
      const linha = linhas[i];

      for (let j = 0; j < linha.length - 1; j++) {
        const ponto1 = linha[j];
        const ponto2 = linha[j + 1];

        if (
          (ponto1.x <= pontoCentro.x && ponto2.x >= pontoCentro.x) ||
          (ponto1.x >= pontoCentro.x && ponto2.x <= pontoCentro.x)
        ) {
          const primeiraMetade: BABYLON.Vector3[] = linha.slice(0, j + 1);
          const segundaMetade: BABYLON.Vector3[] = linha.slice(j + 1);
          linhasDivididas1.push(primeiraMetade);
          linhasDivididas2.push(segundaMetade);
          break;
        }
      }
    }

    for (let i = 0; i < linhasDivididas2.length; i++) {
      const linha2 = linhasDivididas2[i];
      let novaLinha = [];
      for (let j = linha2.length - 1; j >= 0; j--) {
        novaLinha.push(linha2[j]);
      }
      linhasDivididas1.push(novaLinha);
    }

    linhasDivididas1.forEach((linha) => {
      linha.push(pontoCentro);
    });
    return linhasDivididas1;
  }

  private normalizaLinhas(linhas: BABYLON.Vector3[][]): BABYLON.Vector3[][] {
    const maxComprimento: number = linhas.reduce(
      (max, vetor) => Math.max(max, vetor.length),
      0
    );

    linhas.forEach((linha) => {
      const tamanhoLinha = linha.length;
      const qtdPontosExtras = maxComprimento - tamanhoLinha;
      let cont = 0;
      for (let j = 0; j < qtdPontosExtras; j++) {
        if (cont >= tamanhoLinha - 1) {
          cont = 0;
        }
        let primeiro = linha[cont];
        let segundo = linha[cont + 1];
        let x = (primeiro.x + segundo.x) / 2;
        let y = (primeiro.y + segundo.y) / 2;
        let z = (primeiro.z + segundo.z) / 2;
        let extra = new BABYLON.Vector3(x, y, z);
        linha.splice(cont + 1, 0, extra);
        cont += 2;
      }
    });
    linhas.push(linhas[0]);
    return linhas;
  }

  private criaPontosExternos(
    linhas: BABYLON.Vector3[][],
    raio: number,
    xSensor: number,
    zSensor: number
  ): BABYLON.Vector3[][] {
    const pontoSensor = new BABYLON.Vector3(xSensor, 0, zSensor);

    linhas.forEach((linha, i) => {
      const margem = 0.01;
      const ponto1 = linha[0];
      const distBorda = raio + margem - Math.hypot(ponto1.x, ponto1.z);
      let proporcao = distBorda / raio;
      if (proporcao < 0) {
        proporcao = -proporcao;
      }
      const difX = ponto1.x - pontoSensor.x;
      const difZ = ponto1.z - pontoSensor.z;
      const angulo = Math.atan2(difZ, difX);
      const x = ponto1.x + raio * Math.cos(angulo) * proporcao;
      const y = ponto1.y; // Mesma altura
      const z = ponto1.z + raio * Math.sin(angulo) * proporcao;
      const pontoExtra = new BABYLON.Vector3(x * 0.99, y, z * 0.99);
      linha.unshift(pontoExtra);
    });
    return linhas;
  }

  private criaParedesDoGrao(linhas: BABYLON.Vector3[][]): BABYLON.Vector3[][] {
    const linhasParede: BABYLON.Vector3[][] = [];
    linhas.forEach((linha) => {
      const novaLinha: BABYLON.Vector3[] = [];
      const pontoExterno = linha[0];
      const x = pontoExterno.x;
      const z = pontoExterno.z;
      let alturas = this.retornaAlturaPendulosSilo3DService.execute(
        this.siloData,
        this.baseSiloAtual,
        x,
        z
      );
      const y = alturas[0];
      const novoPonto = new BABYLON.Vector3(x, y, z);
      novaLinha.push(pontoExterno);
      novaLinha.push(novoPonto);
      linhasParede.push(novaLinha);
    });
    return linhasParede;
  }

  private criaMeshGrao(
    linhas: BABYLON.Vector3[][],
    linhasParede: BABYLON.Vector3[][]
  ): void {
    const meshGrao3D = BABYLON.MeshBuilder.CreateRibbon("meshGrao3D", {
      pathArray: linhas,
      sideOrientation: BABYLON.Mesh.BACKSIDE,
    });
    this.criaTexturaGraoSilo3DService.execute(
      this.cena,
      meshGrao3D,
      null,
      null
    );
    const paredeGrao = BABYLON.MeshBuilder.CreateRibbon("graoDinamico", {
      pathArray: linhasParede,
      sideOrientation: BABYLON.Mesh.BACKSIDE,
      invertUV: true,
      closeArray: true,
    });
    this.criaTexturaGraoSilo3DService.execute(
      this.cena,
      paredeGrao,
      null,
      null
    );
  }

  private resetaMecanismos3D(): void {
    this.texturaAvancada.dispose();
    this.cena.dispose();
    this.vetorSensores.forEach((vetor) => {
      vetor.forEach((sensor) => {
        sensor.dispose();
      });
    });
    this.engine.stopRenderLoop();
    this.engine.dispose();
  }

  ngOnDestroy(): void {
    this.resetaMecanismos3D();
  }
}
