import { Injectable } from '@angular/core';
import { GraficoOpcoes } from 'app/shared/interfaces';
import { mth, shortD, shortM, day } from 'app/shared/enums';
import moment from 'moment';
import { dadosGraficoSecadoresOperacao, serieAnotacaoQualidade } from 'app/modules/gestao-unidades/operacional/shared/interfaces';
import { duploYaxisGrafico, unicoYaxisGrafico } from 'app/shared/constants';
import { ApexYAxis } from 'ng-apexcharts';
import { TranslocoService } from '@ngneat/transloco';
import { FormatoGraficoDataHoraEnum } from 'app/modules/armazenagem/analise/graficos/pluviometria/enums';
import { OpcoesPersonalizadasEnum } from 'app/shared/components/calendario-duplo/enums';
export abstract class IDefineGraficoLinesEficienciaService {
    abstract execute(
        series: dadosGraficoSecadoresOperacao[],
        seriesAnotacao: serieAnotacaoQualidade[] | null,
        labelRange: string,
        modal?: boolean,
        tickAmount?: number
    ): Partial<GraficoOpcoes>;
}

@Injectable({ providedIn: 'root' })
export class DefineGraficoLinesEficienciaService implements IDefineGraficoLinesEficienciaService {
    formatoEixoGrafico: string = FormatoGraficoDataHoraEnum.DIA;

    constructor(private transloco: TranslocoService) {}
    execute(
        series: dadosGraficoSecadoresOperacao[],
        seriesAnotacao: serieAnotacaoQualidade[] | ApexYAxis | null,
        labelRange?: string,
        modal?: boolean,
    ): Partial<GraficoOpcoes> {
        this.formatoEixoGrafico = this.retornaLabelEixo(labelRange);
        let self = this;

        return {
            annotations: {
                points: seriesAnotacao,
            },
            series: series,
            chart: {
                height: modal ? 650 : 380,
                width: modal ? '100%' : 800,
                type: 'line',
                zoom: {
                    enabled: false,
                },
                defaultLocale: 'pt-br',
                locales: [
                    {
                        name: 'pt-br',
                        options: {
                            months: [
                                mth.JANEIRO,
                                mth.FEVEREIRO,
                                mth.MARCO,
                                mth.ABRIL,
                                mth.MAIO,
                                mth.JUNHO,
                                mth.JULHO,
                                mth.AGOSTO,
                                mth.SETEMBRO,
                                mth.OUTUBRO,
                                mth.NOVEMBRO,
                                mth.DEZEMBRO,
                            ],
                            shortMonths: [
                                shortM.JAN,
                                shortM.FEV,
                                shortM.MAR,
                                shortM.ABR,
                                shortM.MAI,
                                shortM.JUN,
                                shortM.JUL,
                                shortM.AGO,
                                shortM.SET,
                                shortM.OUT,
                                shortM.NOV,
                                shortM.DEZ,
                            ],
                            days: [day.DOMINGO, day.SEGUNDA, day.TERCA, day.QUARTA, day.QUINTA, day.SEXTA, day.SABADO],
                            shortDays: [
                                shortD.DOM,
                                shortD.SEG,
                                shortD.TER,
                                shortD.QUA,
                                shortD.QUI,
                                shortD.SEX,
                                shortD.SAB,
                            ],
                        },
                    },
                ],
            },
            stroke: {
                width: 2,
                curve: 'straight',
                lineCap: 'butt',
                colors: undefined,
                dashArray: seriesAnotacao ? [0, 0, 0, 0, 3, 3, 3] : 0,
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                markers: {
                    width: 14,
                    height: 14,
                    radius: 2,
                    strokeWidth: 0,
                    strokeColor: '#fff',
                    fillColors: undefined,
                    customHTML: undefined,
                    onClick: undefined,
                    offsetX: 0,
                    offsetY: 0,
                },
                fontFamily: 'obvia-wide-regular',
                color: '#333',
                fontSize: '9',
                fontWeight: 400,
            },
            yaxis:
                typeof seriesAnotacao === 'object'
                    ? seriesAnotacao
                    : seriesAnotacao
                    ? duploYaxisGrafico
                    : unicoYaxisGrafico,

            xaxis: {
                labels: {
                    trim: false,
                    datetimeFormatter: {
                        year: 'yyyy',
                        month: 'MMM / yy',
                        day: 'dd MMM',
                        hour: 'HH:mm',
                    },
                    datetimeUTC: false,
                },
                categories: self.retornaEixoX(series[0].data.length || 7),
                type: 'datetime',
                axisBorder: {
                    show: true,
                    color: '#444',
                    strokeWidth: 2,
                },
                axisTicks: {
                    show: true,
                    color: '#444',
                    height: 5,
                },
            },
            tooltip: {
                x: {
                    show: true,
                    formatter(val) {
                        return self.formatarTooltip(val);
                    },
                },
            },
            grid: {
                borderColor: '#f1f1f1',
            },
        } as Partial<GraficoOpcoes>;
    }

    private formatarTooltip(val: number): string {
        if (this.formatoEixoGrafico === FormatoGraficoDataHoraEnum.HORA) {
            return moment(val).locale('pt-br').format(FormatoGraficoDataHoraEnum.HORA);
        }
        if (this.formatoEixoGrafico === FormatoGraficoDataHoraEnum.DIA) {
            return `${moment(val).year(moment().year()).locale('pt-br').format(FormatoGraficoDataHoraEnum.DATA)} `;
        }
        if (this.formatoEixoGrafico === FormatoGraficoDataHoraEnum.MES) {
            return `${moment(val).locale('pt-br').format(FormatoGraficoDataHoraEnum.MES)} `;
        }
    }

    private retornaLabelEixo(labelRange: string): string {
        const formatos = {
            [this.transloco.translate(OpcoesPersonalizadasEnum.HOJE)]: FormatoGraficoDataHoraEnum.HORA,
            [this.transloco.translate(OpcoesPersonalizadasEnum.ONTEM)]: FormatoGraficoDataHoraEnum.HORA,
            [this.transloco.translate(OpcoesPersonalizadasEnum.ULTIMAS_24_HORAS)]: FormatoGraficoDataHoraEnum.HORA,
            [this.transloco.translate(OpcoesPersonalizadasEnum.ESTA_SEMANA)]: FormatoGraficoDataHoraEnum.DIA,
            [this.transloco.translate(OpcoesPersonalizadasEnum.ULTIMOS_7_DIAS)]: FormatoGraficoDataHoraEnum.DIA,
            [this.transloco.translate(OpcoesPersonalizadasEnum.ULTIMOS_10_DIAS)]: FormatoGraficoDataHoraEnum.DIA,
            [this.transloco.translate(OpcoesPersonalizadasEnum.ESTE_MES)]: FormatoGraficoDataHoraEnum.DIA,
            [this.transloco.translate(OpcoesPersonalizadasEnum.ULTIMOS_30_DIAS)]: FormatoGraficoDataHoraEnum.DIA,
            [this.transloco.translate(OpcoesPersonalizadasEnum.ULTIMOS_3_MESES)]: FormatoGraficoDataHoraEnum.MES,
            [this.transloco.translate(OpcoesPersonalizadasEnum.ESTE_ANO)]: FormatoGraficoDataHoraEnum.MES,
            [this.transloco.translate(OpcoesPersonalizadasEnum.ANO_PASSADO)]: FormatoGraficoDataHoraEnum.MES,
        };

        const formato = formatos[labelRange] || FormatoGraficoDataHoraEnum.DIA;
        return formato;
    }

    private retornaEixoX(qtdEixos: number): string[] {
        const hoje = moment();

        if (this.formatoEixoGrafico === FormatoGraficoDataHoraEnum.HORA) {
            return Array.from({ length: qtdEixos }, (_, i) => {
                const date = hoje.clone().subtract(qtdEixos - 1 - i, 'hours');
                return date.toISOString();
            });
        }

        if (this.formatoEixoGrafico === FormatoGraficoDataHoraEnum.DIA) {
            return Array.from({ length: qtdEixos }, (_, i) => hoje.clone().subtract(qtdEixos - 1 - i, 'days').format('MM-DD'));
        }

        if (this.formatoEixoGrafico === FormatoGraficoDataHoraEnum.MES) {
            return Array.from({ length: qtdEixos }, (_, i) => hoje.clone().subtract(qtdEixos - 1 - i, 'months').format('YYYY-MM'));
        }

        return [];
    }
}
