import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

import { CalendarioSimplesComponent } from './calendario-simples.component';
import { TranslocoModule } from '@ngneat/transloco';
import { configuracaoCalendario } from 'app/shared/components/calendario-duplo/constants';

@NgModule({
    declarations: [CalendarioSimplesComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxDaterangepickerMd.forRoot(configuracaoCalendario.prototype.carregaCalendario()),
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        TranslocoModule
    ],
    exports: [CalendarioSimplesComponent],
})
export class CalendarioSimplesModule {}
