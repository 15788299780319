import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalInformativoInterface } from 'app/shared/interfaces';

@Component({
    selector: 'app-modal-informativo-generico',
    templateUrl: './modal-informativo-generico.component.html',
    styleUrls: ['./modal-informativo-generico.component.scss'],
})
export class ModalInformativoGenericoComponent {
    constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: ModalInformativoInterface) {}
}
