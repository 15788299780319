<div class="componente-modal text-center flex flex-col p-6">
    <span class="wl-cor-primaria text-sm sm:text-2xl mt-8">
        {{ data.config.icone.modal.titulo }}
    </span>

    <span class="text-[#535767] text-xs sm:text-lg mt-6 font-semibold">
        {{
            !data.equipamentoChave.status || !data.equipamentoRele.status || !data.equipamentoStatus.status
                ? statusSemComunicacao()
                : data.config.icone.modal.subtitulo
        }}
    </span>

    <p class="text-[#535767] text-xs sm:text-lg mt-6">
        <ng-container *ngIf="!contemValorParaSubstituir(data.config.icone.modal.mensagem)">
            {{ substituiVariavel(data.config.icone.modal.mensagem) }}
        </ng-container>
        <ng-container *ngIf="contemValorParaSubstituir(data.config.icone.modal.mensagem) && dataEquipamento">
            {{ substituiVariavel(data.config.icone.modal.mensagem, dataEquipamento) }}
        </ng-container>
        <ng-container *ngIf="contemValorParaSubstituir(data.config.icone.modal.mensagem) && !dataEquipamento">
            {{"MODAL_STATUS_AERACAO_COMPONENT.SEM_DADOS_COMUNICACAO" | transloco}}.
        </ng-container>
    </p>
    <mat-dialog-actions class="flex justify-center p-10 mt-6">
        <button (click)="dialogRef.close()" type="button" class="w-[128px] h-[49px] leading-5 text-xs sm:text-lg">
            {{"MODAL_STATUS_AERACAO_COMPONENT.FECHAR" | transloco}}
        </button>
    </mat-dialog-actions>
</div>
