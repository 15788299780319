<div class="d-flex componente-modal w-full">
    <div class="d-flex mb-5 modal-botao-fechar">
        <mat-icon id="icon-close" (click)="dialogRef.close()">close</mat-icon>
    </div>

    <div class="d-flex text-center pb-lg-0 mt-2 modal-titulo">
        <p class="text-sm sm:text-2xl">
            {{ "HISTORICO_ALTERACOES_CONTROLE_AERACAO" | transloco }}
        </p>
    </div>

    <div class="componente-tabela-modal mb-5 mt-5 sm:mt-8 overflow-hidden overflow-y-auto max-h-60"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()"
        [scrollWindow]="false"
    >
        <ng-container *ngIf="!carregando; else carregandoTabela">
            <table mat-table [dataSource]="historico">
                <ng-container matColumnDef="data">
                    <th mat-header-cell *matHeaderCellDef class="text-xs sm:text-base">
                        {{ "DATA_HORA" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center text-xs sm:text-base">
                        {{
                            element.instante ? (element.instante | date : "dd/MM/yyyy HH:mm:ss") : ("AGUARDANDO_LEITURA" | transloco)
                        }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="controle">
                    <th mat-header-cell *matHeaderCellDef class="text-xs sm:text-base">
                        {{ "CONTROLE_DE_AERACAO" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="w-full flex justify-center">
                            <app-status-automatico-manual-com-icone-descricao
                                [statusAutomatico]="element.controle_aeracao_automatica"
                            >
                            </app-status-automatico-manual-com-icone-descricao>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-xs sm:text-base">
                        {{ "USUARIO" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center text-xs sm:text-base">
                        {{ element.usuario?.nome ?? "-" }} {{ element.usuario?.sobrenome ?? "" }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="tabelaColunas"></tr>
                <tr mat-row *matRowDef="let row; let i = index; columns: tabelaColunas"></tr>
            </table>
        </ng-container>
        <ng-container *ngIf="carregandoDadosAdicionais">            
            <div class="h-[100px] flex">
                <span class="m-auto flex">
                    <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
                </span>
            </div>                    
        </ng-container>
    </div>

 
</div>
<ng-template #carregandoTabela>
    <div class="h-[200px] flex">
        <span class="m-auto flex">
            <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
        </span>
    </div>
</ng-template>
<ng-template [ngIf]="!carregando && historico.length == 0">
    <div class="h-[150px] flex">
        <span class="m-auto flex"> {{ "NENHUM_REGISTRO_ENCONTRADO" }} </span>
    </div>
</ng-template>
