import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';

@Component({
    selector: 'app-calendario-simples',
    templateUrl: './calendario-simples.component.html',
    styleUrls: ['./calendario-simples.component.scss'],
})
export class CalendarioSimplesComponent implements OnInit {
    @ViewChild(DaterangepickerDirective, { static: false })
    calendarioSimples: DaterangepickerDirective;
    @Input() controleDataHora: UntypedFormControl;
    @Input() exibeSegundos: boolean = true;
    @Input() desabilitar?: boolean | undefined;
    sempreMostrarCalendario: boolean;
    locale: any;
    placeholder: any;

    constructor() {
        this.sempreMostrarCalendario = true;
    }

    ngOnInit(): void {
        this.locale = { format: this.exibeSegundos ? 'DD/MM/YYYY HH:mm:ss' : 'DD/MM/YYYY HH:mm', firstDay: 1 };
        this.placeholder = this.exibeSegundos ? '00/00/00 00:00:00' : '00/00/00 00:00';
        if (this.desabilitar) {
            this.controleDataHora.disable();
        }
    }

    abrirCalendario(e?: MouseEvent): void {
        if (this.desabilitar) {
            return;
        }
        if (e) {
            this.calendarioSimples.open(e);
        } else {
            this.calendarioSimples.open();
        }
    }
}
