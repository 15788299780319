import { BandeiraPaisInterface } from "app/shared/interfaces";

export const BANDEIRA_PAISES = [
  {
    id: "19430ee3-b0fe-4987-a7c8-74453ad5504d",
    iso: "af",
    nome: "Afghanistan",
    codigo: "+93",
    posicaoImgBandeira: "-1px -3180px",
    mascara: "000 000 000",
  },
  {
    id: "6c6b5c5c-97d5-4881-b5e1-e05b8f739ee7",
    iso: "al",
    nome: "Albania",
    codigo: "+355",
    posicaoImgBandeira: "-1px -1310px",
    mascara: "0 000 0000",
  },
  {
    id: "d1f3941f-075e-4777-a5fd-8b196d98cd5a",
    iso: "dz",
    nome: "Algeria",
    codigo: "+213",
    posicaoImgBandeira: "-1px -681px",
    mascara: "000 00 0000",
  },
  {
    id: "0dc3d1b8-f7f3-4c3d-8493-0d8b5a679910",
    iso: "as",
    nome: "American Samoa",
    codigo: "+1",
    posicaoImgBandeira: "-1px -2058px",
    mascara: "(000) 000 0000",
  },
  {
    id: "e2e88578-b410-499f-aa59-9bb8da13c781",
    iso: "ad",
    nome: "Andorra",
    codigo: "+376",
    posicaoImgBandeira: "-1px -766px",
    mascara: "000 000",
  },
  {
    id: "4446885b-b391-4b84-866f-2b36603053c4",
    iso: "ao",
    nome: "Angola",
    codigo: "+244",
    posicaoImgBandeira: "-1px -2636px",
    mascara: "000 000 000",
  },
  {
    id: "07024099-a3db-4881-a628-24e8c0ba2508",
    iso: "ai",
    nome: "Anguilla",
    codigo: "+1",
    posicaoImgBandeira: "-1px -2687px",
    mascara: "(000) 000 0000",
  },
  {
    id: "26be08bc-d87a-4134-9fb0-73b6a5b47cea",
    iso: "ag",
    nome: "Antigua & Barbuda",
    codigo: "+1",
    posicaoImgBandeira: "-1px -1140px",
    mascara: "(000) 000 0000",
  },
  {
    id: "53c77399-494e-49df-9e3a-587b536c033e",
    iso: "ar",
    nome: "Argentina",
    codigo: "+54",
    posicaoImgBandeira: "-1px -3282px",
    mascara: "00 0000 0000",
  },
  {
    id: "9f5753c4-e9e4-4975-86b4-9eb9f4f484de",
    iso: "am",
    nome: "Armenia",
    codigo: "+374",
    posicaoImgBandeira: "-1px -205px",
    mascara: "00 000 000",
  },
  {
    id: "f1bbb833-5c47-4e17-b8c3-1d492107dc86",
    iso: "aw",
    nome: "Aruba",
    codigo: "+297",
    posicaoImgBandeira: "-1px -1021px",
    mascara: "000 0000",
  },
  {
    id: "dc7e3322-8bd5-4c49-932d-a8e50bd1f9ad",
    iso: "ac",
    nome: "Ascension Island",
    codigo: "+247",
    posicaoImgBandeira: "-1px -86px",
    mascara: "000 000",
  },
  {
    id: "4505ba35-afa5-47ef-a6c7-9b57f1dcd187",
    iso: "au",
    nome: "Australia",
    codigo: "+61",
    posicaoImgBandeira: "-1px -2279px",
    mascara: "0 0000 0000",
  },
  {
    id: "57b3cd1f-d5d6-403b-8137-fbeeacaf136a",
    iso: "at",
    nome: "Austria",
    codigo: "+43",
    posicaoImgBandeira: "-1px -1735px",
    mascara: "0 00000 0000",
  },
  {
    id: "11cbde08-3c33-422c-bf4b-85561595ffb5",
    iso: "az",
    nome: "Azerbaijan",
    codigo: "+994",
    posicaoImgBandeira: "-1px -1599px",
    mascara: "00 000 00 00",
  },
  {
    id: "48c1e060-e685-4e91-8de8-725f42576e6c",
    iso: "bs",
    nome: "Bahamas",
    codigo: "+1 242",
    posicaoImgBandeira: "-1px -460px",
    mascara: "000 0000",
  },
  {
    id: "ee23ffb8-9540-4630-948e-ceba52fa54ce",
    iso: "bh",
    nome: "Bahrain",
    codigo: "+973",
    posicaoImgBandeira: "-1px -1956px",
    mascara: "0000 0000",
  },
  {
    id: "b5f37cb6-7870-4ed9-8f92-3864bd870062",
    iso: "bd",
    nome: "Bangladesh",
    codigo: "+880",
    posicaoImgBandeira: "-1px -2364px",
    mascara: "0 0000 0000",
  },
  {
    id: "92de9080-f709-493e-a9fa-d23b3d4093d4",
    iso: "bb",
    nome: "Barbados",
    codigo: "+1",
    posicaoImgBandeira: "-1px -2075px",
    mascara: "(000) 000 0000",
  },
  {
    id: "a2f4ff04-86b8-4bc0-952f-686bfe99c07f",
    iso: "by",
    nome: "Belarus",
    codigo: "+375",
    posicaoImgBandeira: "-1px -1412px",
    mascara: "00 000 0000",
  },
  {
    id: "2025b6b3-1287-4b4c-8b13-36deb44e5751",
    iso: "be",
    nome: "Belgium",
    codigo: "+32",
    posicaoImgBandeira: "-1px -1px",
    mascara: "0 000 0000",
  },
  {
    id: "70d82950-3eca-496f-866d-d99c136260e5",
    iso: "bz",
    nome: "Belize",
    codigo: "+501",
    posicaoImgBandeira: "-1px -613px",
    mascara: "000 0000",
  },
  {
    id: "dc0bedf5-e197-46b4-af21-c2e495b15768",
    iso: "bj",
    nome: "Benin",
    codigo: "+229",
    posicaoImgBandeira: "-1px -1684px",
    mascara: "00 000 000",
  },
  {
    id: "aeee4f9d-99a1-4c6b-826c-f3c0ff707dce",
    iso: "bm",
    nome: "Bermuda",
    codigo: "+1",
    posicaoImgBandeira: "-1px -2585px",
    mascara: "(000) 000 0000",
  },
  {
    id: "73b80fa7-50d0-4fd5-8d26-24baade525a2",
    iso: "bt",
    nome: "Bhutan",
    codigo: "+975",
    posicaoImgBandeira: "-1px -2483px",
    mascara: "0 000000",
  },
  {
    id: "571bf396-810b-4fc4-9ffc-c9e4db9d3bef",
    iso: "bo",
    nome: "Bolivia",
    codigo: "+591",
    posicaoImgBandeira: "-1px -2177px",
    mascara: "0 000 0000",
  },
  {
    id: "cbfbf28b-b79b-4b7d-a2e9-37a2000aa15b",
    iso: "ba",
    nome: "Bosnia & Herzegovina",
    codigo: "+387",
    posicaoImgBandeira: "-1px -2092px",
    mascara: "00 000 000",
  },
  {
    id: "f929da82-915c-4ac8-ba13-aa1b44174c71",
    iso: "bw",
    nome: "Botswana",
    codigo: "+267",
    posicaoImgBandeira: "-1px -3724px",
    mascara: "000 0000",
  },
  {
    id: "2dea0689-0548-400c-a58f-ebcd6373cd07",
    iso: "br",
    nome: "Brazil",
    codigo: "+55",
    posicaoImgBandeira: "-1px -1004px",
    mascara: "(00) 0 0000 0000",
  },
  {
    id: "d2c2c16f-15f8-467b-8c42-a02babe5362b",
    iso: "io",
    nome: "British Indian Ocean Territory",
    codigo: "+246",
    posicaoImgBandeira: "-1px -86px",
    mascara: "000 000",
  },
  {
    id: "1d90db23-ca7c-4d23-a995-9b2a8021f4ad",
    iso: "vg",
    nome: "British Virgin Islands",
    codigo: "+1",
    posicaoImgBandeira: "-1px -1854px",
    mascara: "(000) 000 0000",
  },
  {
    id: "f16aebb2-cdae-4af2-aba5-f66f34d6ac3a",
    iso: "bn",
    nome: "Brunei",
    codigo: "+673",
    posicaoImgBandeira: "-1px -2228px",
    mascara: "000 0000",
  },
  {
    id: "499d6ee6-8f8b-4a5b-bb92-9cce9d1c6546",
    iso: "bg",
    nome: "Bulgaria",
    codigo: "+359",
    posicaoImgBandeira: "-1px -3537px",
    mascara: "0 000 0000",
  },
  {
    id: "67e2986b-98d0-44c3-b08f-6cbba8b14ff8",
    iso: "bf",
    nome: "Burkina Faso",
    codigo: "+226",
    posicaoImgBandeira: "-1px -953px",
    mascara: "00 00 00 00",
  },
  {
    id: "fea611f2-4aa3-427f-86e1-657e8aef24a8",
    iso: "bi",
    nome: "Burundi",
    codigo: "+257",
    posicaoImgBandeira: "-1px -2551px",
    mascara: "00 00 0000",
  },
  {
    id: "3b959360-3d04-4018-afdf-a392afa1881d",
    iso: "kh",
    nome: "Cambodia",
    codigo: "+855",
    posicaoImgBandeira: "-1px -290px",
    mascara: "00 00 000 0000",
  },
  {
    id: "9336ba3b-01be-4b84-82b5-f02395856ac5",
    iso: "cm",
    nome: "Cameroon",
    codigo: "+237",
    posicaoImgBandeira: "-1px -2806px",
    mascara: "00000 0000",
  },
  {
    id: "36a159b0-f33e-4481-85b0-751bdd9ea79d",
    iso: "ca",
    nome: "Canada",
    codigo: "+1",
    posicaoImgBandeira: "-1px -1803px",
    mascara: "000 000 0000",
  },
  {
    id: "a3038010-382e-436e-b61d-e4b923aa1cb3",
    iso: "cv",
    nome: "Cape Verde",
    codigo: "+238",
    posicaoImgBandeira: "-1px -3639px",
    mascara: "000 0000",
  },
  {
    id: "dd898165-12a9-4c90-a3e4-012149c0feac",
    iso: "bq",
    nome: "Caribbean Netherlands",
    codigo: "+599",
    posicaoImgBandeira: "-1px -3741px",
    mascara: "0 0000000",
  },
  {
    id: "a1f30091-26da-481a-a84f-2638b2d7c14d",
    iso: "ky",
    nome: "Cayman Islands",
    codigo: "+1",
    posicaoImgBandeira: "-1px -375px",
    mascara: "(000) 000 0000",
  },
  {
    id: "469b4a79-8a1a-4428-b7bd-4665202b7292",
    iso: "cf",
    nome: "Central African Republic",
    codigo: "+236",
    posicaoImgBandeira: "-1px -2466px",
    mascara: "0000 0000",
  },
  {
    id: "a9c2fa4b-c22a-41bd-9735-b4adeadab7f7",
    iso: "td",
    nome: "Chad",
    codigo: "+235",
    posicaoImgBandeira: "-1px -1055px",
    mascara: "0000 0000",
  },
  {
    id: "f0825f0d-e086-49e0-846e-9e4784bf872c",
    iso: "cl",
    nome: "Chile",
    codigo: "+56",
    posicaoImgBandeira: "-1px -1752px",
    mascara: "0 0000 0000",
  },
  {
    id: "89d3f07d-446e-459d-b168-595af96d708f",
    iso: "cn",
    nome: "China",
    codigo: "+86",
    posicaoImgBandeira: "-1px -1072px",
    mascara: "00 0000 0000",
  },
  {
    id: "903801ce-2f83-4df8-a380-9dc6df6c35cf",
    iso: "co",
    nome: "Colombia",
    codigo: "+57",
    posicaoImgBandeira: "-1px -409px",
    mascara: "0 000 0000",
  },
  {
    id: "55d7d2be-8273-4770-844c-1ef87524cd27",
    iso: "km",
    nome: "Comoros",
    codigo: "+269",
    posicaoImgBandeira: "-1px -1871px",
    mascara: "000 00 00",
  },
  {
    id: "a5b00b2f-01de-4c0d-914f-fe05c92c8f43",
    iso: "cg",
    nome: "Congo - Brazzaville",
    codigo: "+242",
    posicaoImgBandeira: "-1px -2398px",
    mascara: "00 000 0000",
  },
  {
    id: "58e07572-21b9-4630-a17c-a51c0ade4b8a",
    iso: "cd",
    nome: "Congo - Kinshasa",
    codigo: "+243",
    posicaoImgBandeira: "-1px -1990px",
    mascara: "00 000 0000",
  },
  {
    id: "5a09d08e-b6ab-4084-8350-1d97d504c222",
    iso: "ck",
    nome: "Cook Islands",
    codigo: "+682",
    posicaoImgBandeira: "-1px -3112px",
    mascara: "00 000",
  },
  {
    id: "760f2b33-0822-4ad9-83cf-b497dcf273bb",
    iso: "cr",
    nome: "Costa Rica",
    codigo: "+506",
    posicaoImgBandeira: "-1px -2857px",
    mascara: "0000 0000",
  },
  {
    id: "489db55f-6316-4f43-a1c7-a0921e16743a",
    iso: "ci",
    nome: "Côte d’Ivoire",
    codigo: "+225",
    posicaoImgBandeira: "-1px -2194px",
    mascara: "00 000 0000",
  },
  {
    id: "398c1d99-7ee4-44cd-9c2a-067acba2c8fb",
    iso: "hr",
    nome: "Croatia",
    codigo: "+385",
    posicaoImgBandeira: "-1px -1174px",
    mascara: "0 000 0000",
  },
  {
    id: "572da7dc-8463-4797-ad84-7fcf8f53bb80",
    iso: "cu",
    nome: "Cuba",
    codigo: "+53",
    posicaoImgBandeira: "-1px -987px",
    mascara: "0000 0000",
  },
  {
    id: "572674e5-b0d4-4206-8310-70f4656e65e2",
    iso: "cw",
    nome: "Curaçao",
    codigo: "+599",
    posicaoImgBandeira: "-1px -3758px",
    mascara: "(0) 000 0000",
  },
  {
    id: "ac1e2a9d-a888-427e-9ad3-a0cbb27e603a",
    iso: "cy",
    nome: "Cyprus",
    codigo: "+357",
    posicaoImgBandeira: "-1px -732px",
    mascara: "00 000000",
  },
  {
    id: "075ce3fd-83e7-472a-89cb-8b5e224102c4",
    iso: "cz",
    nome: "Czechia",
    codigo: "+420",
    posicaoImgBandeira: "-1px -3095px",
    mascara: "000 000 000",
  },
  {
    id: "4cde631a-97e9-4fc2-9465-9d9a433ca5c1",
    iso: "dk",
    nome: "Denmark",
    codigo: "+45",
    posicaoImgBandeira: "-1px -1820px",
    mascara: "00 00 0000",
  },
  {
    id: "1b9c40a6-bf03-4759-b6ab-8edefafd8b44",
    iso: "dj",
    nome: "Djibouti",
    codigo: "+253",
    posicaoImgBandeira: "-1px -2874px",
    mascara: "00 000 000",
  },
  {
    id: "f5eec2ba-1a0b-465c-b3e5-9bd8458d0704",
    iso: "dm",
    nome: "Dominica",
    codigo: "+1",
    posicaoImgBandeira: "-1px -3350px",
    mascara: "00 000 000",
  },
  {
    id: "cb6921fc-df2a-4a97-8a34-4d901ac1e994",
    iso: "do",
    nome: "Dominican Republic",
    codigo: "+1",
    posicaoImgBandeira: "-1px -2007px",
    mascara: "000 000 0000",
  },
  {
    id: "7d6641f1-ef97-4bee-b1b8-0f54fea35aeb",
    iso: "ec",
    nome: "Ecuador",
    codigo: "+593",
    posicaoImgBandeira: "-1px -1531px",
    mascara: "0 000 0000",
  },
  {
    id: "dfeb30b9-b4b8-4931-9334-c3961b7843a6",
    iso: "eg",
    nome: "Egypt",
    codigo: "+20",
    posicaoImgBandeira: "-1px -3027px",
    mascara: "0 0000 0000",
  },
  {
    id: "7d9f7158-7206-491f-a614-6a3e7e6af354",
    iso: "sv",
    nome: "El Salvador",
    codigo: "+503",
    posicaoImgBandeira: "-1px -2160px",
    mascara: "0000 0000",
  },
  {
    id: "bcdbebc2-a51d-4891-93b0-52b463d0841d",
    iso: "gq",
    nome: "Equatorial Guinea",
    codigo: "+240",
    posicaoImgBandeira: "-1px -1973px",
    mascara: "000 000 000",
  },
  {
    id: "53c2c225-f321-406f-b377-7c8b6720bcb4",
    iso: "er",
    nome: "Eritrea",
    codigo: "+291",
    posicaoImgBandeira: "-1px -936px",
    mascara: "0 000 000",
  },
  {
    id: "ba0e995a-17a8-48ff-88e6-54ff8207b038",
    iso: "ee",
    nome: "Estonia",
    codigo: "+372",
    posicaoImgBandeira: "-1px -3333px",
    mascara: "000 0000",
  },
  {
    id: "abe9af9b-91da-4bba-9adf-a496bf414719",
    iso: "sz",
    nome: "Eswatini",
    codigo: "+268",
    posicaoImgBandeira: "-1px -3129px",
    mascara: "0000 0000",
  },
  {
    id: "e993ecc8-732a-4446-8ab1-144c084f3192",
    iso: "et",
    nome: "Ethiopia",
    codigo: "+251",
    posicaoImgBandeira: "-1px -3367px",
    mascara: "00 000 0000",
  },
  {
    id: "6c7aae9d-e18d-4d09-8467-7bb99d925768",
    iso: "fk",
    nome: "Falkland Islands (Islas Malvinas)",
    codigo: "+500",
    posicaoImgBandeira: "-1px -3809px",
    mascara: "00 000 0000",
  },
  {
    id: "92e704eb-9573-4d91-b932-2b1eddaacb3e",
    iso: "fo",
    nome: "Faroe Islands",
    codigo: "+298",
    posicaoImgBandeira: "-1px -1429px",
    mascara: "000 000",
  },
  {
    id: "561c079c-69c2-4e62-b947-5cd76783a67c",
    iso: "fj",
    nome: "Fiji",
    codigo: "+679",
    posicaoImgBandeira: "-1px -2500px",
    mascara: "000 0000",
  },
  {
    id: "3f31a88e-c7ed-47fa-9aae-2058be7cbe09",
    iso: "fi",
    nome: "Finland",
    codigo: "+358",
    posicaoImgBandeira: "-1px -2568px",
    mascara: "0 000 000",
  },
  {
    id: "4c8ba1fc-0203-4a8f-8321-4dda4a0c6732",
    iso: "fr",
    nome: "France",
    codigo: "+33",
    posicaoImgBandeira: "-1px -324px",
    mascara: "0 0000 0000",
  },
  {
    id: "198074d5-67a2-4fd3-b13d-429a394b6371",
    iso: "gf",
    nome: "French Guiana",
    codigo: "+594",
    posicaoImgBandeira: "-1px -324px",
    mascara: "0000 00000",
  },
  {
    id: "2f5ff3d1-745e-48a1-b4e8-a377b22af812",
    iso: "pf",
    nome: "French Polynesia",
    codigo: "+689",
    posicaoImgBandeira: "-1px -2262px",
    mascara: "000 000",
  },
  {
    id: "a8b80121-5529-4cfe-83fb-6b1f6c81abcb",
    iso: "ga",
    nome: "Gabon",
    codigo: "+241",
    posicaoImgBandeira: "-1px -1157px",
    mascara: "0000 00000",
  },
  {
    id: "c9bc7d57-7883-4f63-bc6e-5dcc3db8612d",
    iso: "gm",
    nome: "Gambia",
    codigo: "+220",
    posicaoImgBandeira: "-1px -817px",
    mascara: "000 0000",
  },
  {
    id: "1fc146d8-cebe-4ef1-bb0f-30bd0870ccf9",
    iso: "ge",
    nome: "Georgia",
    codigo: "+995",
    posicaoImgBandeira: "-1px -1123px",
    mascara: "00 000 0000",
  },
  {
    id: "e74ac4b1-0b4b-4630-bac0-2e53e270b363",
    iso: "de",
    nome: "Germany",
    codigo: "+49",
    posicaoImgBandeira: "-1px -3452px",
    mascara: "00 0000 0",
  },
  {
    id: "adda89c9-4b47-4552-85c4-668f2cef2dbd",
    iso: "gh",
    nome: "Ghana",
    codigo: "+233",
    posicaoImgBandeira: "-1px -2891px",
    mascara: "00 000 0000",
  },
  {
    id: "962a059b-a5ac-4e2f-9405-5c418cadb6b0",
    iso: "gi",
    nome: "Gibraltar",
    codigo: "+350",
    posicaoImgBandeira: "-1px -341px",
    mascara: "0000 0000",
  },
  {
    id: "0acd0dae-0f39-4c23-be1d-c0295539d8c4",
    iso: "gr",
    nome: "Greece",
    codigo: "+30",
    posicaoImgBandeira: "-1px -188px",
    mascara: "000 000 0000",
  },
  {
    id: "7529a6e4-8a6a-4c27-885e-ff0c5e15e515",
    iso: "gl",
    nome: "Greenland",
    codigo: "+299",
    posicaoImgBandeira: "-1px -2347px",
    mascara: "000 000",
  },
  {
    id: "416ba85d-f860-48dc-9c60-32602c07e266",
    iso: "gd",
    nome: "Grenada",
    codigo: "+1 473",
    posicaoImgBandeira: "-1px -3316px",
    mascara: "000 0000",
  },
  {
    id: "f43f1f96-1fb1-4e5e-b818-71e60e501fd4",
    iso: "gp",
    nome: "Guadeloupe",
    codigo: "+590",
    posicaoImgBandeira: "-1px -511px",
    mascara: "0000 00000",
  },
  {
    id: "e29122da-20cf-4d24-bc68-93f9c3296730",
    iso: "gu",
    nome: "Guam",
    codigo: "+1",
    posicaoImgBandeira: "-1px -3265px",
    mascara: "(000) 000 0000",
  },
  {
    id: "8a24ff28-dcae-4846-b0c1-18cfcb04de06",
    iso: "gt",
    nome: "Guatemala",
    codigo: "+502",
    posicaoImgBandeira: "-1px -1208px",
    mascara: "0000 0000",
  },
  {
    id: "b617a005-be15-49c8-9533-c0376681a564",
    iso: "gn",
    nome: "Guinea",
    codigo: "+224",
    posicaoImgBandeira: "-1px -3520px",
    mascara: "000 000 000",
  },
  {
    id: "d9913e74-e340-4a4f-bf4b-aaaf1747364b",
    iso: "gw",
    nome: "Guinea-Bissau",
    codigo: "+245",
    posicaoImgBandeira: "-1px -2602px",
    mascara: "000 00000",
  },
  {
    id: "c8245da4-cd4f-4818-a41e-42afec6faa9a",
    iso: "gy",
    nome: "Guyana",
    codigo: "+592",
    posicaoImgBandeira: "-1px -1038px",
    mascara: "000 0000",
  },
  {
    id: "c598961d-3040-4dbb-8934-6d8eb4b9be97",
    iso: "ht",
    nome: "Haiti",
    codigo: "+509",
    posicaoImgBandeira: "-1px -392px",
    mascara: "0000 0000",
  },
  {
    id: "f51aadf1-3c7a-4d24-b8fb-69c7e05243e4",
    iso: "hn",
    nome: "Honduras",
    codigo: "+504",
    posicaoImgBandeira: "-1px -2959px",
    mascara: "0000 0000",
  },
  {
    id: "a621dbe5-fb11-4f7f-9a8d-2330bd20c563",
    iso: "hk",
    nome: "Hong Kong",
    codigo: "+852",
    posicaoImgBandeira: "-1px -3707px",
    mascara: "0000 0000",
  },
  {
    id: "a113fe26-d409-4ab7-b27c-0e8ac112071f",
    iso: "hu",
    nome: "Hungary",
    codigo: "+36",
    posicaoImgBandeira: "-1px -902px",
    mascara: "0 000 0000",
  },
  {
    id: "6430b612-4071-4614-bfdb-408fbb0b8fa4",
    iso: "is",
    nome: "Iceland",
    codigo: "+354",
    posicaoImgBandeira: "-1px -2704px",
    mascara: "000 0000",
  },
  {
    id: "4cce1334-df1f-4b11-9f15-a4faaac3d0db",
    iso: "in",
    nome: "India",
    codigo: "+91",
    posicaoImgBandeira: "-1px -2245px",
    mascara: "00 0000 0000",
  },
  {
    id: "54969b2f-6aa9-4a58-850d-b4779ef3038e",
    iso: "id",
    nome: "Indonesia",
    codigo: "+62",
    posicaoImgBandeira: "-1px -2653px",
    mascara: "00 0000 0000",
  },
  {
    id: "cb631628-5854-44d2-9dbc-47cdf9c9ea5e",
    iso: "ir",
    nome: "Iran",
    codigo: "+98",
    posicaoImgBandeira: "-1px -2738px",
    mascara: "00 0000 0000",
  },
  {
    id: "21a50cc1-954c-49c2-8296-696f1f57b79e",
    iso: "iq",
    nome: "Iraq",
    codigo: "+964",
    posicaoImgBandeira: "-1px -851px",
    mascara: "000 000 0000",
  },
  {
    id: "3e17cb8a-9c44-4c75-b417-556546ceebff",
    iso: "ie",
    nome: "Ireland",
    codigo: "+353",
    posicaoImgBandeira: "-1px -2670px",
    mascara: "0 000 0000",
  },
  {
    id: "0a15f5a3-7571-478a-9fcd-6cbd6563e08c",
    iso: "il",
    nome: "Israel",
    codigo: "+972",
    posicaoImgBandeira: "-1px -426px",
    mascara: "0 000 0000",
  },
  {
    id: "2cbab786-d79b-4ea1-ab26-0553c5e423d3",
    iso: "it",
    nome: "Italy",
    codigo: "+39",
    posicaoImgBandeira: "-1px -154px",
    mascara: "00 00000",
  },
  {
    id: "33a67cd8-0858-46c3-b833-4fd395d2daa4",
    iso: "jm",
    nome: "Jamaica",
    codigo: "+1",
    posicaoImgBandeira: "-1px -2296px",
    mascara: "(000) 000 0000",
  },
  {
    id: "5edf8bb6-6a29-44ee-b5f2-7d7cbf61f971",
    iso: "jp",
    nome: "Japan",
    codigo: "+81",
    posicaoImgBandeira: "-1px -528px",
    mascara: "0 0000 0000",
  },
  {
    id: "879b69bb-3f8f-484f-a767-7fdeef6bae15",
    iso: "jo",
    nome: "Jordan",
    codigo: "+962",
    posicaoImgBandeira: "-1px -1905px",
    mascara: "0 000 0000",
  },
  {
    id: "4217e52c-2835-4c7b-87d3-e290c4fa6074",
    iso: "kz",
    nome: "Kazakhstan",
    codigo: "+7",
    posicaoImgBandeira: "-1px -1565px",
    mascara: "0000 00 00 00",
  },
  {
    id: "934b172d-4427-47f6-8648-6411652be23d",
    iso: "ke",
    nome: "Kenya",
    codigo: "+254",
    posicaoImgBandeira: "-1px -3605px",
    mascara: "00 000 0000",
  },
  {
    id: "2358e177-3956-4bcf-a954-56275e90e28d",
    iso: "ki",
    nome: "Kiribati",
    codigo: "+686",
    posicaoImgBandeira: "-1px -477px",
    mascara: "00 000",
  },
  {
    id: "98e8fae8-cd1b-419f-813b-ee348b51d843",
    iso: "xk",
    nome: "Kosovo",
    codigo: "+383",
    posicaoImgBandeira: "-1px -3860px",
    mascara: "000 0000 0000",
  },
  {
    id: "5376f774-4fcb-47dc-b118-e48d34b030ef",
    iso: "kw",
    nome: "Kuwait",
    codigo: "+965",
    posicaoImgBandeira: "-1px -3435px",
    mascara: "0000 0000",
  },
  {
    id: "9bc380c4-5840-4d26-a615-310cd817ae94",
    iso: "kg",
    nome: "Kyrgyzstan",
    codigo: "+996",
    posicaoImgBandeira: "-1px -2143px",
    mascara: "000 00 0000",
  },
  {
    id: "3278e7f0-176b-4352-9e38-df59b052b91f",
    iso: "la",
    nome: "Laos",
    codigo: "+856",
    posicaoImgBandeira: "-1px -562px",
    mascara: "00 000 000",
  },
  {
    id: "e2ba5fad-f531-467c-b195-a6cd90136e19",
    iso: "lv",
    nome: "Latvia",
    codigo: "+371",
    posicaoImgBandeira: "-1px -2619px",
    mascara: "0000 0000",
  },
  {
    id: "49f74ca5-9ff1-44af-8e9c-59e1c4704e83",
    iso: "lb",
    nome: "Lebanon",
    codigo: "+961",
    posicaoImgBandeira: "-1px -1616px",
    mascara: "0 000 000",
  },
  {
    id: "d94b6d96-17c1-4de8-abc3-3e14873b62c0",
    iso: "ls",
    nome: "Lesotho",
    codigo: "+266",
    posicaoImgBandeira: "-1px -3010px",
    mascara: "000 0000",
  },
  {
    id: "e35005f8-285e-4fe5-9cda-def721d9cc7b",
    iso: "lr",
    nome: "Liberia",
    codigo: "+231",
    posicaoImgBandeira: "-1px -2823px",
    mascara: "00 000 0000",
  },
  {
    id: "60788779-78f0-4b2b-8ad8-c7e4bbde10b5",
    iso: "ly",
    nome: "Libya",
    codigo: "+218",
    posicaoImgBandeira: "-1px -137px",
    mascara: "00 000 0000",
  },
  {
    id: "f24ad4ea-454a-4d40-a1f1-db188ec0b75e",
    iso: "li",
    nome: "Liechtenstein",
    codigo: "+423",
    posicaoImgBandeira: "-1px -1276px",
    mascara: "000 00 00",
  },
  {
    id: "f6709b72-4150-4cde-a37b-e6eb95f5bd1d",
    iso: "lt",
    nome: "Lithuania",
    codigo: "+370",
    posicaoImgBandeira: "-1px -1446px",
    mascara: "0 000 0000",
  },
  {
    id: "0d0c1a84-f645-4ffe-87d2-9a7bb4f88bbc",
    iso: "lu",
    nome: "Luxembourg",
    codigo: "+352",
    posicaoImgBandeira: "-1px -1922px",
    mascara: "00 00 00 00",
  },
  {
    id: "5b3fdebe-a4ed-47c6-88c3-d867d3a79bf0",
    iso: "mo",
    nome: "Macao",
    codigo: "+853",
    posicaoImgBandeira: "-1px -3554px",
    mascara: "0000 0000",
  },
  {
    id: "6a84f456-bc77-4b76-8651-e2a0994f3278",
    iso: "mg",
    nome: "Madagascar",
    codigo: "+261",
    posicaoImgBandeira: "-1px -1667px",
    mascara: "00 00 000 00",
  },
  {
    id: "2a5d5baf-1db7-4606-a330-227834c77098",
    iso: "mw",
    nome: "Malawi",
    codigo: "+265",
    posicaoImgBandeira: "-1px -2942px",
    mascara: "0 000 000",
  },
  {
    id: "f2b32090-6d8d-40db-ba50-a63037926508",
    iso: "my",
    nome: "Malaysia",
    codigo: "+60",
    posicaoImgBandeira: "-1px -2517px",
    mascara: "0 0000 0000",
  },
  {
    id: "51c7830c-0c76-44ed-bcdf-be75688e1d0c",
    iso: "mv",
    nome: "Maldives",
    codigo: "+960",
    posicaoImgBandeira: "-1px -800px",
    mascara: "0 000 0000",
  },
  {
    id: "ea7a2274-0542-4bbb-b629-aa63bef97442",
    iso: "ml",
    nome: "Mali",
    codigo: "+223",
    posicaoImgBandeira: "-1px -3469px",
    mascara: "00 00 00 00",
  },
  {
    id: "6f70796e-8f64-4a1a-ac2a-990d7d502db3",
    iso: "mt",
    nome: "Malta",
    codigo: "+356",
    posicaoImgBandeira: "-1px -2041px",
    mascara: "0000 0000",
  },
  {
    id: "c60f429e-0d4f-42cf-96f9-e7dc4fdcd5ee",
    iso: "mh",
    nome: "Marshall Islands",
    codigo: "+692",
    posicaoImgBandeira: "-1px -1463px",
    mascara: "000 0000",
  },
  {
    id: "e8afae89-e5b0-4551-bbd4-bbfcee50c8ad",
    iso: "mq",
    nome: "Martinique",
    codigo: "+596",
    posicaoImgBandeira: "-1px -239px",
    mascara: "0000 0000",
  },
  {
    id: "361afc7c-ee94-464b-b5cb-f059ecd79e99",
    iso: "mr",
    nome: "Mauritania",
    codigo: "+222",
    posicaoImgBandeira: "-1px -307px",
    mascara: "0000 0000",
  },
  {
    id: "bce43b5e-d2f7-47ca-b5c9-9ae72ba67bda",
    iso: "mu",
    nome: "Mauritius",
    codigo: "+230",
    posicaoImgBandeira: "-1px -2993px",
    mascara: "000 0000",
  },
  {
    id: "d153dc32-4821-4f05-a5c8-564d003da5e1",
    iso: "mx",
    nome: "Mexico",
    codigo: "+52",
    posicaoImgBandeira: "-1px -2755px",
    mascara: "00 0000 0000",
  },
  {
    id: "80f9f386-231f-4d96-b950-5f6b6edbeb63",
    iso: "fm",
    nome: "Micronesia",
    codigo: "+691",
    posicaoImgBandeira: "-1px -2313px",
    mascara: "000 0000",
  },
  {
    id: "a1d89e32-4b91-4519-b0d9-7d61299394ef",
    iso: "md",
    nome: "Moldova",
    codigo: "+373",
    posicaoImgBandeira: "-1px -3690px",
    mascara: "00 000 000",
  },
  {
    id: "0afeb22c-c106-479b-af45-1380fb8b404c",
    iso: "mc",
    nome: "Monaco",
    codigo: "+377",
    posicaoImgBandeira: "-1px -1191px",
    mascara: "00 00 00 00",
  },
  {
    id: "a18d0204-7c4a-425c-a33e-cbfac01be162",
    iso: "mn",
    nome: "Mongolia",
    codigo: "+976",
    posicaoImgBandeira: "-1px -3503px",
    mascara: "0000 0000",
  },
  {
    id: "260479fc-0410-4ccd-a963-e06c9f059bdb",
    iso: "me",
    nome: "Montenegro",
    codigo: "+382",
    posicaoImgBandeira: "-1px -2976px",
    mascara: "00 000 000",
  },
  {
    id: "a66872f1-ba90-420f-8f55-f0fbb10abce1",
    iso: "ms",
    nome: "Montserrat",
    codigo: "+1",
    posicaoImgBandeira: "-1px -749px",
    mascara: "(000) 000 0000",
  },
  {
    id: "8fd1ba13-cb1a-488d-b715-01724d56d9dd",
    iso: "ma",
    nome: "Morocco",
    codigo: "+212",
    posicaoImgBandeira: "-1px -3214px",
    mascara: "0 00 00 00 00",
  },
  {
    id: "5d26fba4-6d15-4cd4-a23f-9034d952e580",
    iso: "mz",
    nome: "Mozambique",
    codigo: "+258",
    posicaoImgBandeira: "-1px -834px",
    mascara: "00 00 0000",
  },
  {
    id: "f9c12031-14dc-495f-b150-28dddce17e3f",
    iso: "mm",
    nome: "Myanmar (Burma)",
    codigo: "+95",
    posicaoImgBandeira: "-1px -18px",
    mascara: "0 000 0000",
  },
  {
    id: "6e21e956-2740-4058-a758-3b249f628a7b",
    iso: "na",
    nome: "Namibia",
    codigo: "+264",
    posicaoImgBandeira: "-1px -2534px",
    mascara: "00 000 0000",
  },
  {
    id: "4a07dd5a-9341-4b06-969f-4bcd9c32e2a0",
    iso: "nr",
    nome: "Nauru",
    codigo: "+674",
    posicaoImgBandeira: "-1px -2330px",
    mascara: "000 0000",
  },
  {
    id: "9d7121ce-1445-4c84-9401-ddc703d9dedb",
    iso: "np",
    nome: "Nepal",
    codigo: "+977",
    posicaoImgBandeira: "-1px -120px",
    mascara: "0 000 0000",
  },
  {
    id: "31fbb24d-7c38-4ca8-b385-48d76a0685e3",
    iso: "nl",
    nome: "Netherlands",
    codigo: "+31",
    posicaoImgBandeira: "-1px -1888px",
    mascara: "00 000 0000",
  },
  {
    id: "18071cc2-c457-4b4f-9217-2519a0b52c25",
    iso: "nc",
    nome: "New Caledonia",
    codigo: "+687",
    posicaoImgBandeira: "-1px -1650px",
    mascara: "000 000",
  },
  {
    id: "c4b0e7d1-08b2-421b-8ff6-913020cbf271",
    iso: "nz",
    nome: "New Zealand",
    codigo: "+64",
    posicaoImgBandeira: "-1px -2024px",
    mascara: "0 000 0000",
  },
  {
    id: "25719230-2c64-4525-96c4-d4427dd2e40b",
    iso: "ni",
    nome: "Nicaragua",
    codigo: "+505",
    posicaoImgBandeira: "-1px -171px",
    mascara: "0000 0000",
  },
  {
    id: "a1090a0b-7f89-4d75-8c92-e460da9103ab",
    iso: "ne",
    nome: "Niger",
    codigo: "+227",
    posicaoImgBandeira: "-1px -715px",
    mascara: "00 00 00 00",
  },
  {
    id: "6869e4bb-32b8-43ff-84d1-67d9ee832e1f",
    iso: "ng",
    nome: "Nigeria",
    codigo: "+234",
    posicaoImgBandeira: "-1px -3418px",
    mascara: "0 000 0000",
  },
  {
    id: "52b3ae35-196a-4e22-81e2-67b816a32d0e",
    iso: "nu",
    nome: "Niue",
    codigo: "+683",
    posicaoImgBandeira: "-1px -2840px",
    mascara: "000 0000",
  },
  {
    id: "9f4e45d4-c7e1-4ba9-84d0-e712e7213c95",
    iso: "nf",
    nome: "Norfolk Island",
    codigo: "+672",
    posicaoImgBandeira: "-1px -256px",
    mascara: "(0) 0000",
  },
  {
    id: "2db1b02c-631e-40a0-94d8-f1e567b1f705",
    iso: "kp",
    nome: "North Korea",
    codigo: "+850",
    posicaoImgBandeira: "-1px -2415px",
    mascara: "0 000 0000",
  },
  {
    id: "92621b3f-55f5-42bb-8604-d0302e355e31",
    iso: "mk",
    nome: "North Macedonia",
    codigo: "+389",
    posicaoImgBandeira: "-1px -1769px",
    mascara: "0 000 0000",
  },
  {
    id: "3cee8ab2-5cb3-43ea-b8ab-7016187d33e9",
    iso: "mp",
    nome: "Northern Mariana Islands",
    codigo: "+1",
    posicaoImgBandeira: "-1px -919px",
    mascara: "(000) 000 0000",
  },
  {
    id: "77683fad-f106-4a94-a629-9562650edb35",
    iso: "no",
    nome: "Norway",
    codigo: "+47",
    posicaoImgBandeira: "-1px -1089px",
    mascara: "00 00 0000",
  },
  {
    id: "09090411-ef9b-44f3-aeb9-65b5e338b8d6",
    iso: "om",
    nome: "Oman",
    codigo: "+968",
    posicaoImgBandeira: "-1px -3384px",
    mascara: "00 00 0000",
  },
  {
    id: "18d4f06b-233b-4398-a9f8-6b4a4eaf6c71",
    iso: "pk",
    nome: "Pakistan",
    codigo: "+92",
    posicaoImgBandeira: "-1px -2772px",
    mascara: "00 000 0000",
  },
  {
    id: "b1da5023-aab9-431c-921c-4f3e12b1aa7a",
    iso: "pw",
    nome: "Palau",
    codigo: "+680",
    posicaoImgBandeira: "-1px -273px",
    mascara: "000 0000",
  },
  {
    id: "e6442ab2-ac99-4a02-9d7c-fd878e50de8a",
    iso: "ps",
    nome: "Palestine",
    codigo: "+970",
    posicaoImgBandeira: "-1px -1548px",
    mascara: "000 0000",
  },
  {
    id: "6bb10fb5-8b4a-4136-a82e-6be6c017ab76",
    iso: "pa",
    nome: "Panama",
    codigo: "+507",
    posicaoImgBandeira: "-1px -1106px",
    mascara: "000 0000",
  },
  {
    id: "b070a014-2ce4-4939-a868-951bd1e70923",
    iso: "pg",
    nome: "Papua New Guinea",
    codigo: "+675",
    posicaoImgBandeira: "-1px -1939px",
    mascara: "000 0000",
  },
  {
    id: "5e23c743-ce7d-4abc-9dd4-44a700b29090",
    iso: "py",
    nome: "Paraguay",
    codigo: "+595",
    posicaoImgBandeira: "-1px -3231px",
    mascara: "00 000 000",
  },
  {
    id: "1a83f99d-91b3-438d-a576-5bf0f05fdd12",
    iso: "pe",
    nome: "Peru",
    codigo: "+51",
    posicaoImgBandeira: "-1px -1225px",
    mascara: "0 000 0000",
  },
  {
    id: "667c9699-46b9-40f9-a41f-2c52826bb3cb",
    iso: "ph",
    nome: "Philippines",
    codigo: "+63",
    posicaoImgBandeira: "-1px -2432px",
    mascara: "0 0000 0000",
  },
  {
    id: "b84030ab-3193-4aa2-aef2-d4d21997e536",
    iso: "pl",
    nome: "Poland",
    codigo: "+48",
    posicaoImgBandeira: "-1px -1514px",
    mascara: "00 000 0000",
  },
  {
    id: "e26d0064-6173-42ab-b761-bf8c639199fa",
    iso: "pt",
    nome: "Portugal",
    codigo: "+351",
    posicaoImgBandeira: "-1px -664px",
    mascara: "00 000 0000",
  },
  {
    id: "0fd9770d-2a91-4b81-8633-f465bc151e16",
    iso: "pr",
    nome: "Puerto Rico",
    codigo: "+1",
    posicaoImgBandeira: "-1px -596px",
    mascara: "(000) 000 0000",
  },
  {
    id: "f866eeeb-e64f-4123-ab63-c16e0a00d029",
    iso: "qa",
    nome: "Qatar",
    codigo: "+974",
    posicaoImgBandeira: "-1px -579px",
    mascara: "0000 0000",
  },
  {
    id: "c3a3fb54-5731-4a28-96bd-4190cfeeaff0",
    iso: "re",
    nome: "Réunion",
    codigo: "+262",
    posicaoImgBandeira: "-1px -324px",
    mascara: "000 00 0000",
  },
  {
    id: "a6a48809-7e33-42c8-a25a-56ccdd7ccdfe",
    iso: "ro",
    nome: "Romania",
    codigo: "+40",
    posicaoImgBandeira: "-1px -885px",
    mascara: "00 000 0000",
  },
  {
    id: "9556d1e9-3d02-4c5b-a0ce-97a2fd55c74a",
    iso: "ru",
    nome: "Russia",
    codigo: "+7",
    posicaoImgBandeira: "-1px -868px",
    mascara: "000 000 0000",
  },
  {
    id: "6f7f0a97-e8b5-455d-bace-6953de7324eb",
    iso: "rw",
    nome: "Rwanda",
    codigo: "+250",
    posicaoImgBandeira: "-1px -3673px",
    mascara: "000 000 000",
  },
  {
    id: "e251cad5-7655-48f7-9892-6edf04a14fd7",
    iso: "ws",
    nome: "Samoa",
    codigo: "+685",
    posicaoImgBandeira: "-1px -3163px",
    mascara: "00 000",
  },
  {
    id: "f1cfec8c-a960-43b3-8e11-2cad72b4fff8",
    iso: "sm",
    nome: "San Marino",
    codigo: "+378",
    posicaoImgBandeira: "-1px -2908px",
    mascara: "0000 000 000",
  },
  {
    id: "c5301260-13dc-4012-9678-2b57a5e409ae",
    iso: "st",
    nome: "São Tomé & Príncipe",
    codigo: "+239",
    posicaoImgBandeira: "-1px -3299px",
    mascara: "000 0000",
  },
  {
    id: "02599f80-225a-451b-8c25-03b8993f88ac",
    iso: "sa",
    nome: "Saudi Arabia",
    codigo: "+966",
    posicaoImgBandeira: "-1px -52px",
  },
  {
    id: "a54c3469-9668-4063-bfa0-04c450b43d3e",
    iso: "sn",
    nome: "Senegal",
    codigo: "+221",
    posicaoImgBandeira: "-1px -2925px",
    mascara: "00 000 0000",
  },
  {
    id: "687ea07b-a7df-4778-b802-b040676fa56c",
    iso: "rs",
    nome: "Serbia",
    codigo: "+381",
    posicaoImgBandeira: "-1px -3401px",
    mascara: "00 000 0000",
  },
  {
    id: "d010fb25-7044-4055-9c60-25bc89d83f64",
    iso: "sc",
    nome: "Seychelles",
    codigo: "+248",
    posicaoImgBandeira: "-1px -1327px",
    mascara: "000 0000",
  },
  {
    id: "0c46a1e9-fcd8-4e7e-bbb1-ef3bfa83539b",
    iso: "sl",
    nome: "Sierra Leone",
    codigo: "+232",
    posicaoImgBandeira: "-1px -970px",
    mascara: "00 000 000",
  },
  {
    id: "e724edb6-9df4-42fb-bc1e-417996aa3020",
    iso: "sg",
    nome: "Singapore",
    codigo: "+65",
    posicaoImgBandeira: "-1px -35px",
    mascara: "0000 0000",
  },
  {
    id: "7478814a-dc3f-41ff-9341-da7e07ba8499",
    iso: "sx",
    nome: "Sint Maarten",
    codigo: "+1",
    posicaoImgBandeira: "-1px -3826px",
    mascara: "(000) 000 0000",
  },
  {
    id: "b1a34e32-38dd-4a38-b63a-7133baf1417a",
    iso: "sk",
    nome: "Slovakia",
    codigo: "+421",
    posicaoImgBandeira: "-1px -3044px",
    mascara: "0 0000 0000",
  },
  {
    id: "1c1689a5-580b-411f-9283-b1e8333b351e",
    iso: "si",
    nome: "Slovenia",
    codigo: "+386",
    posicaoImgBandeira: "-1px -1582px",
    mascara: "00 000 000",
  },
  {
    id: "4b1c6a42-90b0-49ea-b968-8c95b871f0ec",
    iso: "sb",
    nome: "Solomon Islands",
    codigo: "+677",
    posicaoImgBandeira: "-1px -1361px",
    mascara: "00000",
  },
  {
    id: "7ec9fdff-8ae6-4a14-b55e-6262d46bc3ef",
    iso: "so",
    nome: "Somalia",
    codigo: "+252",
    posicaoImgBandeira: "-1px -1786px",
    mascara: "0 000 000",
  },
  {
    id: "5e62f404-3e2c-4d63-ad7b-ab0755903842",
    iso: "za",
    nome: "South Africa",
    codigo: "+27",
    posicaoImgBandeira: "-1px -3248px",
    mascara: "00 000 0000",
  },
  {
    id: "31966c2a-7d24-4ebc-8e02-392e4f04f12b",
    iso: "kr",
    nome: "South Korea",
    codigo: "+82",
    posicaoImgBandeira: "-1px -3078px",
    mascara: "0 000 0000",
  },
  {
    id: "1b7ba825-bf7d-42c0-bb73-81f10a4009bf",
    iso: "ss",
    nome: "South Sudan",
    codigo: "+211",
    posicaoImgBandeira: "-1px -3775px",
    mascara: "000 000 000",
  },
  {
    id: "55c4137b-e437-4e80-bc8f-7857cd7c9364",
    iso: "es",
    nome: "Spain",
    codigo: "+34",
    posicaoImgBandeira: "-1px -1480px",
    mascara: "00 000 0000",
  },
  {
    id: "fce4c284-e6a1-4e8c-96ca-6edf09e8a401",
    iso: "lk",
    nome: "Sri Lanka",
    codigo: "+94",
    posicaoImgBandeira: "-1px -3622px",
    mascara: "00 000 0000",
  },
  {
    id: "0ae719a5-ae43-45d0-b669-66976a050ef1",
    iso: "bl",
    nome: "St. Barthélemy",
    codigo: "+590",
    posicaoImgBandeira: "-1px -324px",
    mascara: "000 00 00 00",
  },
  {
    id: "a588cc85-32a4-45ff-ba69-627105dab27a",
    iso: "sh",
    nome: "St. Helena",
    codigo: "+290",
    posicaoImgBandeira: "-1px -630px",
    mascara: "0000",
  },
  {
    id: "f065aa7c-8d9e-419c-bbf0-9a97011cf272",
    iso: "kn",
    nome: "St. Kitts & Nevis",
    codigo: "+1",
    posicaoImgBandeira: "-1px -103px",
    mascara: "(000) 000 0000",
  },
  {
    id: "9ea73bcc-2bf5-4ad9-9b39-de33de125f98",
    iso: "lc",
    nome: "St. Lucia",
    codigo: "+1",
    posicaoImgBandeira: "-1px -1837px",
    mascara: "(000) 000 0000",
  },
  {
    id: "86a5a0e8-bfd4-480e-9bc0-7b88b2248a57",
    iso: "mf",
    nome: "St. Martin",
    codigo: "+590",
    posicaoImgBandeira: "-1px -86px",
    mascara: "(000) 000 0000",
  },
  {
    id: "540857ba-923a-4656-a19f-cb3914825ecc",
    iso: "pm",
    nome: "St. Pierre & Miquelon",
    codigo: "+508",
    posicaoImgBandeira: "-1px -1378px",
    mascara: "(000) 00 00 00",
  },
  {
    id: "d381eb44-e77a-4dbd-abbb-224d7158e96d",
    iso: "vc",
    nome: "St. Vincent & Grenadines",
    codigo: "+1",
    posicaoImgBandeira: "-1px -3588px",
    mascara: "(000) 000 0000",
  },
  {
    id: "7015db62-072d-49a2-8320-7587ec8b952f",
    iso: "sd",
    nome: "Sudan",
    codigo: "+249",
    posicaoImgBandeira: "-1px -443px",
    mascara: "000 000 000",
  },
  {
    id: "d7bbb285-aa4e-4a92-8613-8d2645c351ee",
    iso: "sr",
    nome: "Suriname",
    codigo: "+597",
    posicaoImgBandeira: "-1px -3656px",
    mascara: "000 000",
  },
  {
    id: "78978092-7be3-4ec8-b201-068089035cff",
    iso: "se",
    nome: "Sweden",
    codigo: "+46",
    posicaoImgBandeira: "-1px -494px",
    mascara: "0 000 0000",
  },
  {
    id: "9f3fbec3-b58a-4b5a-9c4b-3997398c4148",
    iso: "ch",
    nome: "Switzerland",
    codigo: "+41",
    posicaoImgBandeira: "-1px -1718px",
    mascara: "00 000 0000",
  },
  {
    id: "7ce0562c-fdc4-444c-bba3-02239c3c17da",
    iso: "sy",
    nome: "Syria",
    codigo: "+963",
    posicaoImgBandeira: "-1px -2449px",
    mascara: "00 0000 0000",
  },
  {
    id: "2d57a4a1-3f5a-41a2-a320-74a8f0db92e5",
    iso: "tw",
    nome: "Taiwan",
    codigo: "+886",
    posicaoImgBandeira: "-1px -647px",
    mascara: "0 0000 0000",
  },
  {
    id: "e1f747c5-4e91-487b-8265-8f70b3430849",
    iso: "tj",
    nome: "Tajikistan",
    codigo: "+992",
    posicaoImgBandeira: "-1px -222px",
    mascara: "00 000 0000",
  },
  {
    id: "f07e257c-e049-4046-b031-f4348fb1734a",
    iso: "tz",
    nome: "Tanzania",
    codigo: "+255",
    posicaoImgBandeira: "-1px -3146px",
    mascara: "00 000 0000",
  },
  {
    id: "684a0dde-5b5f-4072-98a4-46fc8de09556",
    iso: "th",
    nome: "Thailand",
    codigo: "+66",
    posicaoImgBandeira: "-1px -1242px",
    mascara: "0 000 0000",
  },
  {
    id: "0376e29f-d9dd-4449-aa4e-d47353c16873",
    iso: "tl",
    nome: "Timor-Leste",
    codigo: "+670",
    posicaoImgBandeira: "-1px -3843px",
    mascara: "000 0000",
  },
  {
    id: "fd647814-fc64-4724-bba7-4cd4da26c11e",
    iso: "tg",
    nome: "Togo",
    codigo: "+228",
    posicaoImgBandeira: "-1px -783px",
    mascara: "00 00 00 00",
  },
  {
    id: "ed271b14-39ee-4403-9be6-b54ac89b0ed3",
    iso: "tk",
    nome: "Tokelau",
    codigo: "+690",
    posicaoImgBandeira: "-1px -3792px",
    mascara: "0000",
  },
  {
    id: "e2b83ecb-5a79-4ca0-9860-4baeae0380bb",
    iso: "to",
    nome: "Tonga",
    codigo: "+676",
    posicaoImgBandeira: "-1px -1395px",
    mascara: "00 000",
  },
  {
    id: "33bca09c-cc33-4680-929b-191ccbbc959a",
    iso: "tt",
    nome: "Trinidad & Tobago",
    codigo: "+1",
    posicaoImgBandeira: "-1px -545px",
    mascara: "(000) 000 0000",
  },
  {
    id: "ab25c5da-7698-4b96-af34-5d20523915d9",
    iso: "tn",
    nome: "Tunisia",
    codigo: "+216",
    posicaoImgBandeira: "-1px -698px",
    mascara: "00 000 000",
  },
  {
    id: "784ac645-bc50-4b35-b5fb-effd72f99749",
    iso: "tr",
    nome: "Turkey",
    codigo: "+90",
    posicaoImgBandeira: "-1px -2126px",
    mascara: "000 000 0000",
  },
  {
    id: "9a3b8bd3-bc73-4251-a068-a4842365e91a",
    iso: "tm",
    nome: "Turkmenistan",
    codigo: "+993",
    posicaoImgBandeira: "-1px -3486px",
    mascara: "00 00 00 00",
  },
  {
    id: "361bcad4-44d1-41fb-9bbf-39ea0fb87d49",
    iso: "tc",
    nome: "Turks & Caicos Islands",
    codigo: "+1",
    posicaoImgBandeira: "-1px -1701px",
    mascara: "(000) 000 0000",
  },
  {
    id: "26fb1484-c756-4592-8523-99af9c870bb5",
    iso: "tv",
    nome: "Tuvalu",
    codigo: "+688",
    posicaoImgBandeira: "-1px -358px",
    mascara: "00 000",
  },
  {
    id: "cdb8455e-4eda-48f7-b30a-63c20838a364",
    iso: "vi",
    nome: "U.S. Virgin Islands",
    codigo: "+1",
    posicaoImgBandeira: "-1px -2381px",
    mascara: "(000) 000 0000",
  },
  {
    id: "f47476cc-3da6-4377-83c9-33ab9f5293d1",
    iso: "ug",
    nome: "Uganda",
    codigo: "+256",
    posicaoImgBandeira: "-1px -1497px",
    mascara: "000 000 000",
  },
  {
    id: "5fcb791a-91be-416a-895d-0502fc509838",
    iso: "ua",
    nome: "Ukraine",
    codigo: "+380",
    posicaoImgBandeira: "-1px -2721px",
    mascara: "00 000 0000",
  },
  {
    id: "7c8e1ced-0dd7-42b6-880b-19b3486d11e5",
    iso: "ae",
    nome: "United Arab Emirates",
    codigo: "+971",
    posicaoImgBandeira: "-1px -3061px",
    mascara: "0 000 0000",
  },
  {
    id: "9f1362e7-e87c-4123-ade8-e5cfa6e99c09",
    iso: "gb",
    nome: "United Kingdom",
    codigo: "+44",
    posicaoImgBandeira: "-1px -86px",
    mascara: "0 000 0000",
  },
  {
    id: "f9033267-9df0-46e4-9f79-c8b022e5c835",
    iso: "us",
    nome: "United States",
    codigo: "+1",
    posicaoImgBandeira: "-1px -69px",
    mascara: "000 000 0000",
  },
  {
    id: "2cab7122-ec9a-48ac-8415-392b4f67ae51",
    iso: "uy",
    nome: "Uruguay",
    codigo: "+598",
    posicaoImgBandeira: "-1px -3571px",
    mascara: "00 000 000 00",
  },
  {
    id: "f442740c-94c3-4f2f-afb2-c7c279224b5f",
    iso: "uz",
    nome: "Uzbekistan",
    codigo: "+998",
    posicaoImgBandeira: "-1px -1293px",
    mascara: "00 000 0000",
  },
  {
    id: "e6774547-6ab1-41a2-8107-201f913937b2",
    iso: "vu",
    nome: "Vanuatu",
    codigo: "+678",
    posicaoImgBandeira: "-1px -1633px",
    mascara: "00000",
  },
  {
    id: "d600d6b0-e21f-4b6e-9036-0435a6ac2ea6",
    iso: "va",
    nome: "Vatican",
    codigo: "+379",
    posicaoImgBandeira: "-1px -3197px",
    mascara: "00 000 00000",
  },
  {
    id: "b8e0072d-498b-4bb4-a5b6-354d4200f882",
    iso: "ve",
    nome: "Venezuela",
    codigo: "+58",
    posicaoImgBandeira: "-1px -1344px",
    mascara: "000 000 0000",
  },
  {
    id: "15dc081a-4690-42e9-a40d-b3bcea3173fc",
    iso: "vn",
    nome: "Vietnam",
    codigo: "+84",
    posicaoImgBandeira: "-1px -1259px",
    mascara: "00 0000 0000",
  },
  {
    id: "4452a787-5f31-4eb7-b14c-ae3175564ae5",
    iso: "wf",
    nome: "Wallis & Futuna",
    codigo: "+681",
    posicaoImgBandeira: "-1px -324px",
    mascara: "00 00 00 00",
  },
  {
    id: "237c9f8d-3b6c-4b70-af72-8a58a7154144",
    iso: "ye",
    nome: "Yemen",
    codigo: "+967",
    posicaoImgBandeira: "-1px -2211px",
    mascara: "0 000 0000",
  },
  {
    id: "02a76f62-3078-472a-bd42-edb759cf3079",
    iso: "zm",
    nome: "Zambia",
    codigo: "+260",
    posicaoImgBandeira: "-1px -2109px",
    mascara: "000 000 000",
  },
  {
    id: "10e8e117-6832-4d3f-9b05-f66832c2f5ec",
    iso: "zw",
    nome: "Zimbabwe",
    codigo: "+263",
    posicaoImgBandeira: "-1px -2789px",
    mascara: "000 000 000",
  },
] as BandeiraPaisInterface[];
