export const horariosGraficoExpansivoProcessos: string[] = [
    '00 h',
    '01 h',
    '02 h',
    '03 h',
    '04 h',
    '05 h',
    '06 h',
    '07 h',
    '08 h',
    '09 h',
    '10 h',
    '11 h',
    '12 h',
    '13 h',
    '14 h',
    '15 h',
    '16 h',
    '17 h',
    '18 h',
    '19 h',
    '20 h',
    '21 h',
    '22 h',
    '23 h',
];
