import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { EstadoComunicacaoEnum } from 'app/shared/enums';
import { EquipamentoComponenteDataInterface } from 'app/shared/interfaces';
import { IFormataEquipamentosComponenteDataService, RetornaEstiloStatusComunicacao } from 'app/shared/services';
import { TranslocoHttpLoader } from 'app/transloco-root.module';

@Component({
    selector: 'status-comunicacao-tooltip-simples',
    templateUrl: './status-comunicacao-tooltip-simples.component.html',
    styleUrls: ['./status-comunicacao-tooltip-simples.component.scss'],
})
export class StatusComunicacaoTooltipSimplesComponent {
    @Input() listaEquipamentos: EquipamentoComponenteDataInterface[];

    primeiraLinhaTooltip = 'SECADOR_VISAO_SCREEN.COMUNICACAO_GERAL_EQUIPAMENTOS';
    segundaLinhaTooltip = 'SECADOR_VISAO_SCREEN.AGUARDANDO_LEITURA';
    classTooltip = 'tooltip-sem-comunicacao';

    traduzPagina = {
        traducaoComunicacao: '',
        traducaoFalhaComunicacao: '',
        traducaoComunicacaoParcial: '',
    };

    constructor(
        readonly formataEquipamentosComponenteDataService: IFormataEquipamentosComponenteDataService,
        private translocoHttpLoader: TranslocoHttpLoader,
        private translocoService: TranslocoService
    ) {
        this.translocoHttpLoader.getTranslation(this.translocoService.getActiveLang()).subscribe((translation) => {
            this.traduzPagina.traducaoComunicacao = translation.COMUNICANDO;
            this.traduzPagina.traducaoFalhaComunicacao = translation.FALHA_DE_COMUNICACAO;
            this.traduzPagina.traducaoComunicacaoParcial = translation.COMUNICACAO_PARCIAL;
        });
    }

    definirStatusGeral(equipamentos: EquipamentoComponenteDataInterface[]): string {
        if (equipamentos.length > 0) {
            let contStatus = 0;
            const length = equipamentos.length;
            equipamentos.forEach((equipamento) => {
                if (equipamento.status) {
                    contStatus += 1;
                }
            });
            if (contStatus === 0) {
                return EstadoComunicacaoEnum.NONE;
            }
            if (contStatus === length) {
                return EstadoComunicacaoEnum.ALL;
            }
            return EstadoComunicacaoEnum.PARTIAL;
        }
        return EstadoComunicacaoEnum.NONE;
    }

    statusComunicacao(equipamentos: EquipamentoComponenteDataInterface[]): any {
        return RetornaEstiloStatusComunicacao.prototype.statusComunicacaoSilo(
            this.definirStatusGeral(equipamentos),
            this.traduzPagina.traducaoComunicacao,
            this.traduzPagina.traducaoFalhaComunicacao,
            this.traduzPagina.traducaoComunicacaoParcial
        );
    }

    definirDataMaisRecente(equipamentos: EquipamentoComponenteDataInterface[]): string {
        const datas = [];
        equipamentos.forEach((equipamento) => {
            if (equipamento.instante) {
                datas.push(new Date(equipamento.instante));
            }
        });
        if (datas.length == 0) {
            return '';
        }
        const data = datas.reduce((anterior, atual) => {
            return anterior > atual ? anterior : atual;
        });
        return equipamentos[datas.indexOf(data)].instante;
    }
}
