export enum TabelaGraosCapacidadeEnum {
    GRAO = 'grao',
    ESTOQUE_TONELADA = 'estoqueTonelada',
    ESTOQUE_SACA = 'estoqueSaca',
    ESTOQUE_PERCENTUAL = 'estoquePercentual',
    CAPACIDADE_TONELADA = 'capacidadeTonelada',
    CAPACIDADE_SACA = 'capacidadeSaca'
}

export enum RadioEstoqueEnum {
    GRAFICO = 'GRAFICO',
    TABELA = 'TABELA'
}

export enum TipoMedidaEnum {
    SACA = 'Sacas (sc)',
    TONELADA = 't'
}

export enum LegendaGraficoEnum {
    TONELADA = 'Estoque (t)',
    SACA = 'Estoque (s)'
}

export enum PaginacaoCapacidadeEnum {
    PROXIMA = 'proxima',
    ANTERIOR = 'anterior'
}
