import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ComandoAeracaoInterface } from 'app/modules/armazenagem/visualizacao/painel-geral/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import { IApiTransportInterface } from 'app/core/interfaces/';
export abstract class IControleAeracaoController {
    abstract enviaComandoAeracao(comando: ComandoAeracaoInterface): Observable<any>;
}

@Injectable({
    providedIn: 'root',
})
export class ControleAeracaoController implements IControleAeracaoController {
    private transport: IApiTransportInterface;

    constructor(private apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/aeracao');
    }

    enviaComandoAeracao(comando: ComandoAeracaoInterface): Observable<any> {
        return this.transport.http.post(`${this.transport.path}/controle-aeracao/acionamento-manual`, comando);
    }
}
