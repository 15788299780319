import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ModalVisaoSuperiorSiloComponent } from './modal-visao-superior-silo.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    declarations: [ModalVisaoSuperiorSiloComponent],
    imports: [MatIconModule, CommonModule, TranslocoModule],
    exports: [ModalVisaoSuperiorSiloComponent],
})
export class ModalVisaoSuperiorSiloModule {}
