import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ModalAcionamentoManulAeracaoComponent } from './modal-acionamento-manul-aeracao.component';
import { TranslocoRootModule } from 'app/transloco-root.module';

@NgModule({
    declarations: [ModalAcionamentoManulAeracaoComponent],
    imports: [MatIconModule, MatDialogModule, CommonModule, TranslocoRootModule],
    exports: [ModalAcionamentoManulAeracaoComponent],
})
export class ModalAcionamentoManulAeracaoModule {}
