import { RouterModule } from '@angular/router';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgModule } from '@angular/core';
import { BarraProgressoComponent } from './barra-progresso.component';
import { TranslocoRootModule } from 'app/transloco-root.module';

@NgModule({
    declarations: [BarraProgressoComponent],
    imports: [MatProgressBarModule, RouterModule, TranslocoRootModule],
    exports: [BarraProgressoComponent],
})
export class BarraProgressoModule {}
