import { delay, take } from 'rxjs';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HistoricoAlteracoesProgramaColunasEnum } from 'app/shared/enums';
import { ApiPaginacao } from 'app/shared/models';
import { HistoricoAlteracoesProgramaInterface, UnidadeInterface } from 'app/shared/interfaces';
import { UnidadeParametrizacaoObservable } from 'app/shared/observables';
import { IDesestruturaStringFormulaListagemService } from 'app/shared/services';
import { IProgramaAeracaoController } from 'app/shared/controllers';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'app-modal-historico-alteracoes-programa',
    templateUrl: './modal-historico-alteracoes-programa.component.html',
    styleUrls: ['./modal-historico-alteracoes-programa.component.scss'],
})
export class ModalHistoricoAlteracoesProgramaComponent implements OnInit {
    tabelaColunas: string[] = [
        HistoricoAlteracoesProgramaColunasEnum.DATA,
        HistoricoAlteracoesProgramaColunasEnum.PROGRAMA,
        HistoricoAlteracoesProgramaColunasEnum.DETALHES,
        HistoricoAlteracoesProgramaColunasEnum.USUARIO,
    ];
    
    paginacao = new ApiPaginacao();
    carregando: boolean = true;
    carregandoDadosAdicionais: boolean = false;   
    historico: HistoricoAlteracoesProgramaInterface[] = [];
    parametrosUnidade: UnidadeInterface;

    constructor(
        public readonly dialogRef: MatDialogRef<any>,
        private programaAeracaoController: IProgramaAeracaoController,
        private unidadeParametrizacaoObservable: UnidadeParametrizacaoObservable,
        private desestruturaStringFormulaListagemService: IDesestruturaStringFormulaListagemService,
        private changeDetectorRef: ChangeDetectorRef,
        private transloco: TranslocoService,
        @Inject(MAT_DIALOG_DATA) public readonly data: any
    ) {}

    ngOnInit(): void {
        this.paginacao.limit = 6;
        this.paginacao.page = 1;
        this.buscarHistorico();
        this.unidadeParametrizacaoObservable.buscaParametrosUnidade$.pipe(take(1)).subscribe((parametros) => {
            this.parametrosUnidade = parametros;
        });
    }

    ngAfterOnInit(): void {
        this.changeDetectorRef.detectChanges();
    }

    onScroll(): void {
        if (this.paginacao.page == this.paginacao.length) return;

        this.paginacao.page += 1;
        this.buscarHistorico(true);
    }

    buscarHistorico(dadosAdicionais?: boolean): void {
        if (dadosAdicionais) this.carregandoDadosAdicionais = true; else this.carregando = true;  
        this.programaAeracaoController
            .buscarHistorico(this.data.armazenagemRef, this.paginacao)
            .pipe(
                take(1),                
                delay(1000)
            )
            .subscribe({
                next: (historico) => {
                    const data: HistoricoAlteracoesProgramaInterface[] = [];
                    historico.data.forEach((elemento) => {
                        data.push(elemento);
                    });
                    this.historico = [...this.historico, ...data]
                    this.paginacao.length = historico.meta.totalPages;                    
                },
                error: (erro) => {
                    console.log(erro);                    
                },
                complete: () => {
                    if (dadosAdicionais) this.carregandoDadosAdicionais = false; else this.carregando = false;                                
                }
            });
    }

    formatarFormulaApenasParaVisualizacao(expressao_ligar, expressao_manter_ligado): string {
        return `(${this.desestruturaStringFormulaListagemService.execute(
            expressao_ligar,
            this.parametrosUnidade.unidade_parametrizacao
        )} ${this.transloco.translate('ARMAZENAGEM_CADASTRO_REGRAS_SCREEN.AO_LIGAR_E_PARA_MANTER_LIGADO')} ${this.desestruturaStringFormulaListagemService.execute(
            expressao_manter_ligado,
            this.parametrosUnidade.unidade_parametrizacao
        )})`;
    }
}
