import { Injectable } from '@angular/core';
import { ApiTransportService } from 'app/core/services/transport';
import { Observable } from 'rxjs';

import { IApiTransportInterface } from 'app/core/interfaces/';
import { ListaRecursosInterface } from 'app/shared/interfaces';
import { ApiPaginacao, EquipamentoModel } from 'app/shared/models';
import { IObjectToAnyService } from 'app/shared/services';

export abstract class IEquipamentoController {
    abstract buscaHistorico(
        idEquimanento: number,
        paginacao: ApiPaginacao
    ): Observable<ListaRecursosInterface<EquipamentoModel>>;
}

@Injectable({
    providedIn: 'root',
})
export class EquipamentoController implements IEquipamentoController {
    private transport: IApiTransportInterface;

    constructor(
        public readonly apiTransportService: ApiTransportService,
        private readonly objectToAnyService: IObjectToAnyService
    ) {
        this.transport = this.apiTransportService.execute('/equipamento-comunicacao');
    }

    buscaHistorico(idEquimanento: number, paginacao: ApiPaginacao): Observable<any> {
        this.transport.options.params = this.objectToAnyService.toQueryString({
            page: paginacao.page,
            limit: paginacao.limit,
        });
        return this.transport.http.get(`${this.transport.path}/historico/${idEquimanento}/`, this.transport.options);
    }
}
