import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { ModalValoresPlenumComponent } from 'app/shared/components';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GraosUnidadeController, IGraosUnidadeController } from 'app/shared/controllers';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatDialogModule,
        TranslocoRootModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        FormsModule,
        TranslocoModule,
        MatProgressSpinnerModule,
    ],
    declarations: [ModalValoresPlenumComponent],
    exports: [ModalValoresPlenumComponent],
    providers: [{ provide: IGraosUnidadeController, useClass: GraosUnidadeController }],
})
export class ModalValoresPlenumModule {}
