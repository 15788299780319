<span
    *ngIf="statusAutomatico"
    class="flex rounded-md min-w-[137px] p-2 h-8 justify-center bg-[#B2E7A7] text-[#34901C] text-xs sm:text-base items-center cursor-default"
>
    <mat-icon
        class="wl-cor-primaria icon-size-4 mr-2 cursor-pointer fill-[#34901C]"
        [svgIcon]="'mat_solid:check_circle'"
    ></mat-icon>
    {{"STATUS_AUTOMATICO_MANUAL_ICONE_COMPONENT.AUTOMATICO" | transloco}}
</span>
<span
    *ngIf="!statusAutomatico"
    class="flex rounded-md min-w-[137px] p-2 h-8 justify-center bg-[#FBBC0566] text-[#B48707] text-xs sm:text-base items-center cursor-default"
>
    <mat-icon
        class="wl-cor-primaria icon-size-4 mr-2 cursor-pointer fill-[#B48707]"
        [svgIcon]="'mat_solid:back_hand'"
    ></mat-icon>
    {{"STATUS_AUTOMATICO_MANUAL_ICONE_COMPONENT.MANUAL" | transloco}}
</span>
