import { IdiomaSiglaEnum } from "app/shared/enums";

export interface InfoInterface {
    cliente: {
        id: string
        nome: string
    }
    estrutura: {
        id: string
        estrutura_armazenagem_divisao_id: string
        tipo: string
    }
    token: {
        access_token: string
        refresh_token: string
    }
    unidade: {
        id: string
        nome: string
    }
    usuario: {
        id: string;
        nome: string;
        sobrenome: string;
        email: string;
        idioma: IdiomaSiglaEnum;
        obrigar_resetar_senha: boolean;
        categoria: string;
        usuario_unidade: {
            id: string;
            permite_cadastro_usuario: boolean;
        };
    };
}