import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { formataDataComHora } from 'app/shared/constants';
import { AeradorStatuSodeEnum } from 'app/shared/enums';
import { AeradorInterface, EquipamentoInformacoesSiloInterface } from 'app/shared/interfaces';
import { StatusAeracaoModel } from 'app/shared/models';
import { IConfigurarStatusAeracaoService } from 'app/shared/services';
import moment from 'moment';

@Component({
    selector: 'app-alertas-aeracao',
    templateUrl: './alertas-aeracao.component.html',
    styleUrls: ['./alertas-aeracao.component.scss'],
})
export class AlertasAeracaoComponent implements OnChanges {
    @Input() statusCode?: AeradorStatuSodeEnum;
    @Input() listaAeradores?: AeradorInterface[];
    @Input() listaEquipamentos?: EquipamentoInformacoesSiloInterface[];
    dataEquipamento: string;
    configuracaoStatusAerador: StatusAeracaoModel;

    constructor(
        private configurarStatusAeracaoService: IConfigurarStatusAeracaoService,
        private transloco: TranslocoService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.configuracaoStatusAerador = this.configurarStatusAeracaoService.execute(this.statusCode);
    }

    substituiVariavel(valor: string, valorAtual?: any): string {
        return valor.replace('{{valor}}', valorAtual ?? '');
    }

    contemValorParaSubstituir(valor: string): boolean | false {
        if (valor.includes('{{valor}}')) return true;
    }

    statusSemComunicacao(): string | undefined {
        const textoSemComunicacao = this.transloco.translate('MODAL_STATUS_AERACAO_COMPONENT.SEM_COMUNICACAO');
        const textoChave = this.transloco.translate('MODAL_STATUS_AERACAO_COMPONENT.CHAVE');
        const textoRele = this.transloco.translate('MODAL_STATUS_AERACAO_COMPONENT.RELE');  
        const textoStatus = this.transloco.translate('MODAL_STATUS_AERACAO_COMPONENT.STATUS');

        const semComunicacao = [];
        const dataEquipamentos = [];
        this.listaAeradores.forEach((aeradorItem) => {
            const statusLeituraChave = this.listaEquipamentos.filter((item:EquipamentoInformacoesSiloInterface) => item.id === aeradorItem.aerador.aerador_leitura_chave?.equipamento_id);
            const statusLeituraRele = this.listaEquipamentos.filter((item:EquipamentoInformacoesSiloInterface) => item.id === aeradorItem.aerador.aerador_leitura_rele?.equipamento_id);
            const statusLeituraStatus = this.listaEquipamentos.filter((item:EquipamentoInformacoesSiloInterface) => item.id === aeradorItem.aerador.aerador_leitura_status?.equipamento_id);            
            if (!statusLeituraChave[0]?.equipamento_comunicacao?.status) {
                semComunicacao.push(textoChave);
                statusLeituraChave[0]?.equipamento_comunicacao?.instante
                    ? dataEquipamentos.push(moment(statusLeituraChave[0]?.equipamento_comunicacao?.instante))
                    : '';
            }
            if (!statusLeituraRele[0]?.equipamento_comunicacao?.status) {
                semComunicacao.push(textoRele);
                statusLeituraRele[0]?.equipamento_comunicacao?.instante
                    ? dataEquipamentos.push(moment(statusLeituraRele[0]?.equipamento_comunicacao?.instante))
                    : '';
            }
            if (!statusLeituraStatus[0]?.equipamento_comunicacao?.status) {
                semComunicacao.push(textoStatus);
                statusLeituraStatus[0]?.equipamento_comunicacao?.instante
                    ? dataEquipamentos.push(moment(statusLeituraStatus[0]?.equipamento_comunicacao?.instante))
                    : '';
            }
        });

        if (dataEquipamentos.length > 0) {
            this.retornarDataEquipamento(dataEquipamentos);
        }
        const arraySemRepeticao = semComunicacao.filter(function (elemento, posicao, novoElemento) {
            return novoElemento.indexOf(elemento) == posicao;
        });
        if (arraySemRepeticao.length === 0) return;
        return `${textoSemComunicacao} (${arraySemRepeticao.join(', ')}).`;
    }

    private retornarDataEquipamento(datas: any[]): void {
        this.dataEquipamento = moment.max(datas).locale(formataDataComHora.locale).format(formataDataComHora.formato);
    }
}
