export enum FiltroPainelGestaoUnidadesEnum {
    CLIENTE = 'Cliente',
    UNIDADES = 'Unidades',
    GRAOS = 'Grão(s)',
    MOEDA = 'Moeda',
    CLASSIFICADOR = 'Classificador',
    PERIODO = 'Período',
    CODIGO = 'Código',
    CATEGORIA = 'Categoria',
    OPERACAO = 'Operação',
    PLACA = 'Placa',
    ESTRUTURAS_ARMAZENAGEM = 'Estruturas de armazenagem/Equipamentos industriais',
}

export enum NomesPaineisEnum {
    OPERACIONAL = 'operacional',
    QUALIDADE = 'qualidade',
    RENTABILIDADE = 'rentabilidade',
    AMOSTRAGEM_CLASSIFICACAO = 'painel-listagem',
    RECEBIMENTO_EXPEDICAO = 'painel-listagem-recebimento-expedicao',
    MEDIDORES_FLUXO = 'medidores-fluxo',
    PROCESSOS = 'processos',
}
