<div class="componente-modal">
    <div class="modal-titulo flex justify-center items-center">
        <span class="text-base sm:text-2xl">            
            {{ "MODAL_ENTENDA_VISAO_SUPERIOR_COMPONENT.ENTENDA_VISAO_SUPERIOR" | transloco }}
        </span>
    </div>
    <div class="mt-10 text-[#00091F] font-medium flex flex-col max-w-90 mx-auto text-sm sm:text-base">
        <p class="flex items-center flex-row">
            <span class="flex flex-col mr-3">
                <mat-icon class="icon-size-6 my-auto align-top" [svgIcon]="'icon-pendulo-triangulo'"></mat-icon>
            </span>
            <span class="text-justify">                
                {{ "MODAL_ENTENDA_VISAO_SUPERIOR_COMPONENT.AS_DIFERENTES_FORMAS_GEOMETRICAS" | transloco }}
            </span>
        </p>
        <p class="flex items-center flex-row text-justify mt-5">
            <span class="flex flex-col mr-3">
                <mat-icon class="icon-size-6 my-auto align-top" [svgIcon]="'icon-poligono'"></mat-icon>
                <mat-icon class="icon-size-6 my-auto align-top" [svgIcon]="'icon-triangulo'"></mat-icon>
            </span>
            <span class="text-justify">                
                {{ "MODAL_ENTENDA_VISAO_SUPERIOR_COMPONENT.AS_FORMAS_GEOMETRICAS" | transloco }}
            </span>
        </p>
        <button class="botao-fechar-botton mx-auto" (click)="matDialogRef.close()">
            {{ "FECHAR" | transloco }}
        </button>
    </div>
</div>
