import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

import { Subject, takeUntil } from 'rxjs';

import { IEquilibrioHigroscopicoController, IGraosUnidadeController } from 'app/shared/controllers';
import { opcoesSelectGraos } from 'app/shared/components/modal-valores-plenum/data/graos';
import { EquilibrioHigroscopicoModel } from 'app/shared/models';
import { FormataCampoValorService } from 'app/shared/services';

import {
    ModalEquilibrioHigroscopicoInterface,
    GraoInterface,
} from 'app/shared/components/modal-valores-plenum/interfaces';

@Component({
    selector: 'app-modal-valores-plenum',
    templateUrl: './modal-valores-plenum.component.html',
    styleUrls: ['./modal-valores-plenum.component.scss'],
})
export class ModalValoresPlenumComponent implements OnInit, OnDestroy {
    equilibrioHigroscopico: EquilibrioHigroscopicoModel;
    buscandoEquilibrioHigroscopico: boolean = false;
    opcaoAquecimentoSelecionada: string | number;
    graos: GraoInterface[] = [];
    temperaturaAmbiente: number;
    imagemSelecionada: string;
    temperaturaPlenum: number | string;
    formGroup: FormGroup;
    urAmbiente: number;
    urPlenum: number | string;

    private unsubscribeAll: Subject<void> = new Subject<void>();

    constructor(
        @Inject(MAT_DIALOG_DATA) readonly data: ModalEquilibrioHigroscopicoInterface,

        private equilibrioHigroscopicoController: IEquilibrioHigroscopicoController,
        private graosUnidadeController: IGraosUnidadeController,
        private formBuilder: FormBuilder,

        readonly dialogRef: MatDialogRef<ModalValoresPlenumComponent>,
        readonly formataCampoValorService: FormataCampoValorService
    ) {
        this.inicializarFormulario();
    }

    ngOnInit(): void {
        this.buscarGraos();
    }

    selecionaProdutoTipo(produtoSelecionado: MatSelectChange): void {
        this.imagemSelecionada = opcoesSelectGraos.find((opcao) => opcao.id === produtoSelecionado.value)?.imagem;

        if (this.opcaoAquecimentoSelecionada === 'plenum') {
            this.atualizarEquilibrioHigroscopico(
                produtoSelecionado.value,
                this.data.aquecimento,
                this.temperaturaPlenum,
                this.urPlenum
            );
        } else {
            this.atualizarEquilibrioHigroscopico(produtoSelecionado.value);
        }
    }

    selecionaVariavelAquecimento(variavelAquecimento: MatSelectChange): void {
        this.opcaoAquecimentoSelecionada = variavelAquecimento.value;
        this.equilibrioHigroscopico.equilibrio_higroscopico.aq = this.data.aquecimento;

        if (variavelAquecimento.value === 'plenum') {
            this.opcaoAquecimentoSelecionada;
            this.atualizarEquilibrioHigroscopico(
                undefined,
                this.data.aquecimento,
                this.temperaturaPlenum,
                this.urPlenum
            );
        } else {
            this.atualizarEquilibrioHigroscopico(undefined, variavelAquecimento.value);
        }
    }

    private definirValoresSensorPlenum(): void {
        this.temperaturaPlenum = this.data?.sensorPlenum.temperatura.med ?? '-';
        this.urPlenum = this.data?.sensorPlenum.umidade.med ?? '-';
    }

    private buscarGraos(): void {
        this.graosUnidadeController
            .buscaGraos()
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe({
                next: (response) => {
                    this.graos = response.data;
                    this.definirValoresIniciais();
                },
                error: (error) => {
                    console.log(error);
                },
            });
    }

    private atualizarEquilibrioHigroscopico(
        produtoTipoId?: number,
        aquecimento?: number,
        tPlenum?: number | string,
        urPlenum?: number | string
    ): void {
        const produtoId = produtoTipoId ?? this.formGroup.get('produtoTipo').value;
        const aquecimentoValor = aquecimento ?? this.formGroup.get('variavelAquecimento').value;
        const parametrosSensorPlenum =
            this.data?.sensorPlenum || this.formGroup.get('variavelAquecimento').value === 'plenum'
                ? [tPlenum, urPlenum]
                : [];

        this.buscarEquilibrioHigroscopico(
            this.data.estacao.id,
            this.data.estacao.tipo,
            produtoId,
            aquecimentoValor,
            ...parametrosSensorPlenum
        );
    }

    private buscarEquilibrioHigroscopico(
        idEstacao: string,
        tipoEstacao: string,
        produtoTipoId: number,
        aquecimento_ar: number,
        tPlenum?: number | string,
        urPlenum?: number | string
    ): void {
        if (this.formGroup.invalid) return;

        this.buscandoEquilibrioHigroscopico = true;

        this.equilibrioHigroscopicoController
            .buscarEquilibrioHigroscopico(idEstacao, tipoEstacao, produtoTipoId, aquecimento_ar, tPlenum, urPlenum)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe({
                next: (response) => {
                    this.equilibrioHigroscopico = response;
                },
                error: (error) => {
                    console.log(error);
                },
            })
            .add(() => {
                this.buscandoEquilibrioHigroscopico = false;
            });
    }

    private definirValoresIniciais(): void {
        this.opcaoAquecimentoSelecionada = this.data?.sensorPlenum ? 'plenum' : this.data.aquecimento.toString();
        this.imagemSelecionada = opcoesSelectGraos.find((opcao) => opcao.id === this.data.produtoTipo)?.imagem;
        this.temperaturaAmbiente = this.data?.estacao.ultima_leitura.temperatura;
        this.urAmbiente = this.data?.estacao.ultima_leitura.umidade;

        const produto = this.graos.find((grao) => grao.nome === this.data.nomeProduto);

        this.formGroup.get('produtoTipo').setValue(produto?.produto_tipo.id);
        this.formGroup
            .get('variavelAquecimento')
            .setValue(this.data?.sensorPlenum ? 'plenum' : this.data.aquecimento.toString());

        if (this.data?.sensorPlenum) {
            this.definirValoresSensorPlenum();
            this.atualizarEquilibrioHigroscopico(
                produto?.produto_tipo.id,
                this.data.aquecimento,
                this.temperaturaPlenum,
                this.urPlenum
            );
        } else {
            this.atualizarEquilibrioHigroscopico(produto?.produto_tipo.id, this.data.aquecimento);
        }
    }

    private inicializarFormulario(): void {
        this.formGroup = this.formBuilder.group({
            produtoTipo: ['', [Validators.required]],
            variavelAquecimento: ['', [Validators.required]],
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
