<div class="w-full flex min-h-[66px] bg-[#F8FAFB] flex-row rounded p-[10px]">
    <div class="w-[45%] flex flex-col">
        <span class="items-center flex">
            {{ "CO" | transloco }}<sub class="mr-1">2</sub>
            {{ "PPM_ATUAL_PARENTESES" | transloco }}
            <span>
                <mat-icon
                    [svgIcon]="'icon-info'"
                    class="wl-cor-primaria ml-0.5 p-1.5 cursor-pointer"
                    (click)="abrirModalEscalaCores()"
                ></mat-icon>
            </span>
        </span>
        <span class="flex items-center rounded mt-2">
            <app-data-ultima-comunicacao
                [equipamentos]="formataArrayEquipamentosComponenteData(listaEquimentosSensoresCO2)"
                [visualizarTempo]="true"
                [tamanhoIconeStatus]="'icon-size-09'"
                [ocultarModal]="ocultarModal"
            >
            </app-data-ultima-comunicacao>
        </span>
    </div>
    <div class="w-[55%] text-right my-auto items-end">
        <span
            class="rounded-md min-w-[56.5px] text-center pt-1 pb-1 inline-block"
            [ngClass]="sensorCO2?.co2?.co2?.med ? 'pl-3 pr-3' : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'"
            [ngStyle]="{
                background: defineCoresCO2(sensorCO2?.co2?.co2?.med)?.fundo,
                color: defineCoresCO2(sensorCO2?.co2?.co2?.med)?.cor
            }"
        >
            {{ sensorCO2?.co2?.co2?.med ? (sensorCO2?.co2?.co2?.med | number : "1.0" : "pt") : "-" }}
        </span>
        <br />
        <div
            *ngIf="sensorCO2?.co2?.validacao?.ppm"
            class="max-w-max p-1 mt-2 rounded border text-right ml-auto text-[#16171B]"
            [ngStyle]="{
                'border-color': defineCoresCO2(sensorCO2.co2)?.fundo
            }"
        >
            {{
                sensorCO2?.co2?.validacao?.ppm
                    ? Object.values(ppmEnum)[Object.keys(ppmEnum).indexOf(sensorCO2.co2?.validacao.ppm)]
                    : ""
            }}
        </div>
    </div>
</div>
<div class="w-full flex min-h-[66px] bg-[#FFFFFF] flex-row rounded p-[10px]">
    <div class="w-[45%] flex justify-center flex-col">
        <span>{{ "BOLSAO_DE_AR" | transloco }}</span>
    </div>
    <div class="w-[55%] flex justify-end flex-row">
        <div class="max-w-max justify-center text-center mx-4">
            <span class="wl-cor-primaria text-sm">{{ "TEMP" | transloco }}. (°C)</span><br />
            <span
                class="rounded-md min-w-[56.5px] text-center pt-1 pb-1 inline-block"
                [ngClass]="
                    sensorCO2.co2?.temperatura ? 'pl-3 pr-3' : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
                "
                [ngStyle]="{
                    background: defineCoresTemperatura(sensorCO2.co2?.temperatura?.med)?.fundo,
                    color: defineCoresTemperatura(sensorCO2.co2?.temperatura?.med)?.cor
                }"
                >{{
                    sensorCO2.co2?.temperatura?.med
                        ? (sensorCO2.co2?.temperatura?.med | number : "1.1-1" : "pt")
                        : " - "
                }}</span
            >
        </div>
        <div class="max-w-max justify-center text-center">
            <span class="wl-cor-primaria text-sm">{{ "UR_PORCENTAGEM" | transloco }}</span
            ><br />
            <span
                class="rounded-md min-w-[56.5px] text-center pt-1 pb-1 inline-block"
                [ngClass]="
                    sensorCO2.co2?.umidade?.med ? 'pl-3 pr-3' : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
                "
                [ngStyle]="{
                    background: defineCoresUmidade(sensorCO2.co2?.umidade?.med)?.fundo,
                    color: defineCoresUmidade(sensorCO2.co2?.umidade?.med)?.cor
                }"
                >{{
                    sensorCO2.co2?.umidade?.med ? (sensorCO2.co2?.umidade?.med | number : "1.1-1" : "pt") : " - "
                }}</span
            >
        </div>
    </div>
</div>
<div class="w-full flex min-h-[66px] bg-[#F8FAFB] flex-row rounded p-[10px]">
    <div class="w-[35%] flex justify-center flex-col">
        <span>{{ "CHAPA_METALICA" | transloco }}</span>
    </div>
    <div class="w-[65%] flex justify-end flex-row">
        <div class="max-w-max justify-center text-center mx-4">
            <span class="wl-cor-primaria text-sm">{{ "TEMP" | transloco }}. (°C)</span> <br />
            <span
                class="rounded-md min-w-[56.5px] text-center pt-1 pb-1 inline-block"
                [ngClass]="
                    sensorCO2.co2?.temperatura_chapa?.med
                        ? 'pl-3 pr-3'
                        : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
                "
                [ngStyle]="{
                    background: defineCoresTemperatura(sensorCO2.co2?.temperatura_chapa?.med)?.fundo,
                    color: defineCoresTemperatura(sensorCO2.co2?.temperatura_chapa?.med)?.cor
                }"
                >{{
                    sensorCO2.co2?.temperatura_chapa?.med
                        ? (sensorCO2.co2?.temperatura_chapa?.med | number : "1.1-1" : "pt")
                        : " - "
                }}</span
            >
        </div>
        <div class="max-w-max justify-center text-center">
            <span class="wl-cor-primaria text-sm">{{ "SENSOR_CO2_COMPONENT.RISCO_CONDENSACAO" | transloco }}</span>
            <br />
            <span class="items-center flex justify-center">
                <ng-container *ngIf="sensorCO2?.co2?.validacao; else semInformacao">
                    {{
                        sensorCO2?.co2?.validacao
                            ? sensorCO2.co2?.validacao.condensacao
                                ? ("SIM" | transloco)
                                : ("NAO" | transloco)
                            : ""
                    }}
                </ng-container>
                <span>
                    <mat-icon
                        [svgIcon]="'icon-info'"
                        class="wl-cor-primaria ml-0.5 p-1.5 cursor-pointer"
                        (click)="abrirModalCondensacao()"
                    ></mat-icon>
                </span>
            </span>
        </div>
    </div>
</div>
<ng-template #semInformacao>
    <span
        class="rounded-md min-w-[56.5px] text-center pt-1 pb-1 inline-block opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]"
        >-</span
    >
</ng-template>
