import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-modal-equilibrio-higroscopico',
    templateUrl: './modal-equilibrio-higroscopico.component.html',
    styleUrls: ['./modal-equilibrio-higroscopico.component.scss'],
})
export class ModalEquilibrioHigroscopicoComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) readonly dialogData: any,
        readonly dialogRef: MatDialogRef<ModalEquilibrioHigroscopicoComponent>
    ) {}

    ngOnInit(): void {}
}
