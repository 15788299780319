import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-modal-confirmacao',
    templateUrl: './modal-confirmacao.component.html',
    styleUrls: ['./modal-confirmacao.component.scss'],
})
export class ModalConfirmacaoComponent {
    constructor(public dialogRef: MatDialogRef<ModalConfirmacaoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.dialogRef.addPanelClass('modal-confirmacao');
    }
}
