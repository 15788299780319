import { RotasInterface } from "app/shared/interfaces";

export const redirecionamentoRotas: RotasInterface[] = [
    {
        rotaAtual: '/secador/visualizacao/visao-detalhada-secador/principal',
        rotaRedirecionamento: '/secador/visualizacao/visao-geral-secadores'
    }, 
    {
        rotaAtual: '/armazenagem/visualizacao/visao-detalhada-silo/principal/',
        rotaRedirecionamento: '/armazenagem/visualizacao/visao-geral-silos'
    }, 
    {
        rotaAtual: '/armazenagem/visualizacao/visao-detalhada-armazem/principal/',
        rotaRedirecionamento: '/armazenagem/visualizacao/visao-geral-armazens'
    }, 
]