import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-status-automatico-manual-com-icone-descricao',
    templateUrl: './status-automatico-manual-com-icone-descricao.component.html',
    styleUrls: ['./status-automatico-manual-com-icone-descricao.component.scss'],
})
export class StatusAutomaticoManualComIconeDescricaoComponent implements OnInit {
    @Input() statusAutomatico: any | undefined;
    constructor() {}

    ngOnInit() {}
}
