import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { configuracaoTamanhoModal } from 'app/shared/constants';
import { FormaGeometricaPenduloEnum } from 'app/shared/enums';
import {
    AeradoreVisaoSuperiorInterface,
    AeradoresComFormaGeometrica,
    ArmazemEquipamentoVisualizacaoInterface,
    ArmazemPassarelaVisualizacaoInterface,
    ArmazemPortaVisualizacaoInterface,
    DivisoesComPedulosInterface,
    EquipamentoVisaoSuperiorInterface,
    PenduloVisaoSuperiorInterface,
} from 'app/shared/interfaces';
import {
    IAgrupaPendulosPorDivisaoService,
    ICriaElementosVisuaisVisaoSuperiorArmazemService,
    ICriaPaletaCoresTermometriaService,
    IDefineAeradoresPorDivisaoService,
    IMontaFormaGeometricaVisaoSuperiorArmazemService,
    IRetornaSvgAeradorService,
} from 'app/shared/services';
import * as d3 from 'd3';
import { ModalEntendaVisaoSuperiorArmazemComponent } from 'app/shared/components';

@Component({
    selector: 'app-visao-superior-armazem',
    templateUrl: './visao-superior-armazem.component.html',
    styleUrls: ['./visao-superior-armazem.component.scss'],
})
export class VisaoSuperiorArmazemComponent implements OnInit {
    @Input() pendulos: PenduloVisaoSuperiorInterface[];
    @Input() passarelas: ArmazemPassarelaVisualizacaoInterface[];
    @Input() portas: ArmazemPortaVisualizacaoInterface[];
    @Input() equipamentos: ArmazemEquipamentoVisualizacaoInterface[];
    @Input() aeradores: AeradoreVisaoSuperiorInterface[];
    @Input() listaEquipamentos: EquipamentoVisaoSuperiorInterface[];

    tamanhoContainerSvg: number = 0;
    temperaturaControle: UntypedFormControl = new UntypedFormControl();
    linhasArcosControle: UntypedFormControl = new UntypedFormControl(true);
    paddingSvgPaiX: number = 50;
    paddingSvgPaiY: number = 0;
    tamnahoFonte: number = 10;
    svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>;

    opcoesTemperatura = [
        { valor: 'min', nome: 'Temperatura mínima (°C)' },
        { valor: 'med', nome: 'Temperatura média (°C)' },
        { valor: 'max', nome: 'Temperatura máxima (°C)' },
    ];

    formasGeometricasSubcelulas = [
        FormaGeometricaPenduloEnum.CIRCUNFERENCIA,
        FormaGeometricaPenduloEnum.QUADRADO,
        FormaGeometricaPenduloEnum.PENTAGONO,
        FormaGeometricaPenduloEnum.TRIANGULO,
        FormaGeometricaPenduloEnum.OCTAGONO,
        FormaGeometricaPenduloEnum.TRAPAZIO,
    ];

    nameSpace = 'http://www.w3.org/2000/svg';

    escalaCorrente: number = 1;
    translateCorrente: number[] = [0, 0];
    numeroDeArcos: number = 0;

    divisaoComPendulos: DivisoesComPedulosInterface[] = [];
    constructor(
        private retornaSvgAeradorService: IRetornaSvgAeradorService,
        private agrupaPendulosPorDivisaoService: IAgrupaPendulosPorDivisaoService,
        private defineAeradoresPorDivisaoService: IDefineAeradoresPorDivisaoService,
        private montaPendulosVisaoSuperiorArmazemService: IMontaFormaGeometricaVisaoSuperiorArmazemService,
        private criaElementosVisuaisVisaoSuperiorArmazemService: ICriaElementosVisuaisVisaoSuperiorArmazemService,
        private criaPaletaCoresTermometriaService: ICriaPaletaCoresTermometriaService,
        private dialog: MatDialog,
        private breakpointObserver: BreakpointObserver
    ) {}

    ngOnInit() {
        this.temperaturaControle.setValue('med');
        this.agrupaPendulosPorDivisao();
        this.configuraObjetoEquipamentos();
        this.setaConfiguracoesSvgInical();
        this.temperaturaControle.valueChanges.subscribe((temp) => {
            this.alteraCorTemperaturasPendulos(temp);
        });
        this.linhasArcosControle.valueChanges.subscribe((valor) => {
            this.ocultarExibirArcosLinhas(valor);
        });
    }

    private agrupaPendulosPorDivisao() {
        this.divisaoComPendulos = this.agrupaPendulosPorDivisaoService.execute(this.pendulos);
        this.defineFormaGeometricaPendulo();
    }

    private configuraObjetoEquipamentos(): void {
        this.equipamentos.forEach((equipamento) => {
            const equip = this.listaEquipamentos.find((e) => e.id === equipamento.equipamento_id);
            equipamento.nome = equip?.nome;
        });
    }

    private defineFormaGeometricaPendulo(): void {
        let contador = 0;

        this.divisaoComPendulos.forEach((pendulo, idx) => {
            pendulo.formaGeometrica = this.formasGeometricasSubcelulas[contador];
            contador++;
            if (contador > this.formasGeometricasSubcelulas.length - 1) {
                contador = 0;
            }
        });
    }

    setaConfiguracoesSvgInical() {
        this.setaNumeroDeArcos();
        this.tamanhoContainerSvg = document.getElementById('containerVisaoSuperiorArmazem').offsetWidth;
        const svgPaiHeight = 715;
        const containerArmazemHeihgt = 545;
        this.svg = d3
            .select('#containerArmamzem')
            .append('svg')
            .attr('viewBox', `0 0 ${this.tamanhoContainerSvg} ${svgPaiHeight}`)
            .attr('id', 'svgPai')
            .attr('height', `${svgPaiHeight}`);
        this.svg.append('g').attr('id', 'grupo');
        this.criaRetanguloArmazem(this.svg, this.tamanhoContainerSvg, containerArmazemHeihgt);
        this.criaArcos(this.svg);
        this.criaLinhas(this.svg);
        this.criaElementosVisuaisVisaoSuperiorArmazemService.criaPortas(this.svg, this.portas);
        this.criaAeradores(this.svg);
        this.criaElementosVisuaisVisaoSuperiorArmazemService.criaPassarelas(this.svg, this.passarelas);
        this.criaElementosVisuaisVisaoSuperiorArmazemService.criaEquipamtos(this.svg, this.equipamentos);
        this.criaPendulos(this.svg);
        this.defineAeradoresVinculadosPorDivisao(this.svg);
    }

    criaRetanguloArmazem(svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>, width, height): void {
        const svgPaiHeight = parseFloat(document.getElementById('svgPai').getAttribute('height'));
        this.paddingSvgPaiY = (svgPaiHeight - height) / 2;
        svg.select('g')
            ?.append('rect')
            .attr('width', `${width - this.paddingSvgPaiX}`)
            .attr('height', `${height}`)
            .attr('x', `${this.paddingSvgPaiX / 2}`)
            .attr('y', `${this.paddingSvgPaiY}`)
            .attr('fill', 'white')
            .attr('stroke', '#535767')
            .attr('stroke-width', '1.5')
            .attr('id', 'retanguloArmazem');
    }

    setaNumeroDeArcos(): void {
        let arcos: number[] = [];

        this.pendulos.forEach((arco) => {
            arcos.push(arco.armazem_pendulo_visualizacao.arco);
        });

        this.numeroDeArcos = arcos.reduce((a, b) => Math.max(a, b));
    }

    criaArcos(svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>): void {
        this.criaElementosVisuaisVisaoSuperiorArmazemService.criaArcos(svg, this.numeroDeArcos);
    }

    criaLinhas(svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>): void {
        let linhas: number[] = [];

        this.pendulos.forEach((linha) => {
            linhas.push(linha.armazem_pendulo_visualizacao.linha);
        });

        const numroLinhas = linhas.reduce((a, b) => Math.max(a, b));
        this.criaElementosVisuaisVisaoSuperiorArmazemService.criaLinhas(svg, numroLinhas);
    }

    ocultarExibirArcosLinhas(valor: boolean): void {
        const numroLinhas = this.defineNumeroLinhasOuArcos('linha');
        const numroArcos = this.defineNumeroLinhasOuArcos('arco');

        for (let i = 0; i < numroArcos; i++) {
            const arcoSvg = d3.select(`#arco${i}`);
            const arcoLabelSvg = d3.select(`#arcoLabel${i}`);
            arcoSvg.attr('visibility', `${!valor ? 'hidden' : 'visible'}`);
            arcoLabelSvg.attr('visibility', `${!valor ? 'hidden' : 'visible'}`);
        }
        for (let i = 0; i < numroLinhas; i++) {
            const linhaSvg = d3.select(`#linha${i}`);
            const linhaLabelSvg = d3.select(`#linhaLabel${i}`);
            linhaSvg.attr('visibility', `${!valor ? 'hidden' : 'visible'}`);
            linhaLabelSvg.attr('visibility', `${!valor ? 'hidden' : 'visible'}`);
        }
    }

    defineNumeroLinhasOuArcos(item: string): number {
        let items: number[] = [];
        this.pendulos.forEach((elemento) => {
            items.push(elemento.armazem_pendulo_visualizacao[item]);
        });

        return items.reduce((a, b) => Math.max(a, b));
    }

    defineAeradoresVinculadosPorDivisao(svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>): void {
        const aeradoresAgrupadosKey = this.agrupaAeradoresComForma(
            this.defineAeradoresPorDivisaoService.execute(this.divisaoComPendulos, this.aeradores)
        );

        const svgPai = document.getElementById('svgPai').getBoundingClientRect();
        const retanguloArmazem = d3.select('#retanguloArmazem').node() as SVGAElement;
        const margemAeradorTopo = (17 / svgPai.height) * 100;
        const margemAeradorBase = (45 / svgPai.height) * 100;
        const XInicial = parseFloat(retanguloArmazem.getAttribute('x'));
        const margemXInicial = (XInicial / svgPai.width) * 100;

        let aeradoresAgrupados = [];
        for (let idAerador in aeradoresAgrupadosKey) {
            aeradoresAgrupados.push(aeradoresAgrupadosKey[idAerador]);
        }

        aeradoresAgrupados.forEach((aeradores) => {
            aeradores.forEach((aerador, index) => {
                if (!aerador?.posicao_x) return;
                const posicao_x =
                    aerador?.posicao_x < 50 ? margemXInicial + aerador?.posicao_x : aerador?.posicao_x + margemXInicial;
                const posicao_y =
                    aerador?.posicao_y < 50
                        ? aerador?.posicao_y + margemAeradorTopo
                        : aerador?.posicao_y - margemAeradorBase;

                const posicao_x_px = (posicao_x * svgPai.width) / 100;
                const posicao_y_px = (posicao_y * svgPai.height) / 100;
                const tamanhoIcone = 14;
                const margemAerador = aerador.icone.includes('mao') ? 40 : 25;
                const margemYIcone = posicao_y_px < 100 ? 23 : 40;
                this.montaPendulosVisaoSuperiorArmazemService.montaFormaGeometrica(
                    aerador.formaGeometrica,
                    aerador.icone.includes('mao')
                        ? posicao_x_px +
                              margemAerador +
                              this.retonarMargemFormaGeometricaAerador(aerador.formaGeometrica)
                        : posicao_x_px +
                              margemAerador +
                              this.retonarMargemFormaGeometricaAerador(aerador.formaGeometrica),
                    index === 0
                        ? posicao_y_px - tamanhoIcone + margemYIcone
                        : posicao_y_px + tamanhoIcone / 2 + margemYIcone,
                    tamanhoIcone,
                    '#535767',
                    undefined,
                    'none',
                    svg
                );
            });
        });
    }

    agrupaAeradoresComForma(aeradoresComFormas: AeradoresComFormaGeometrica[]): any[] {
        let itensAgrupados = [];
        aeradoresComFormas.forEach((item) => {
            if (!itensAgrupados[item.idAerador]) {
                itensAgrupados[item.idAerador] = [item];
            } else {
                itensAgrupados[item.idAerador].push(item);
            }
        });
        return itensAgrupados;
    }

    private criaAeradores(svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>): void {
        const svgPai = document.getElementById('svgPai').getBoundingClientRect();
        const containerElementos = document.getElementById('grupo') as unknown as SVGAElement;
        const retanguloArmazem = d3.select('#retanguloArmazem').node() as SVGAElement;
        const margemAeradorTopo = (17 / svgPai.height) * 100;
        const margemAeradorBase = (45 / svgPai.height) * 100;
        const XInicial = parseFloat(retanguloArmazem.getAttribute('x'));
        const margemXInicial = (XInicial / svgPai.width) * 100;

        this.aeradores.forEach((aerador, index) => {
            if (!aerador.armazem_aerador_visualizacao?.posicao_x) return;
            const posicao_x =
                aerador.armazem_aerador_visualizacao?.posicao_x < 50
                    ? margemXInicial + aerador.armazem_aerador_visualizacao?.posicao_x
                    : aerador.armazem_aerador_visualizacao?.posicao_x + margemXInicial;
            const posicao_y =
                aerador.armazem_aerador_visualizacao?.posicao_y < 50
                    ? aerador.armazem_aerador_visualizacao?.posicao_y + margemAeradorTopo
                    : aerador.armazem_aerador_visualizacao?.posicao_y - margemAeradorBase;

            this.retornaSvgAeradorService.execute(aerador.aeracao.status, function (svgElement) {
                const svgAeradorClone = svgElement.cloneNode(true) as SVGAElement;
                svgAeradorClone.setAttribute('x', `${posicao_x}%`);
                svgAeradorClone.setAttribute('y', `${posicao_y}%`);
                svgAeradorClone.setAttribute('id', `aerador${aerador.codigo}`);
                containerElementos.appendChild(svgAeradorClone);
            });
            const labelAerador = document.createElementNS(this.nameSpace, 'text');
            labelAerador.setAttribute('font-size', `${this.tamnahoFonte}`);
            labelAerador.setAttribute('dominant-baseline', 'middle');
            labelAerador.setAttribute('fill', '#535767');
            labelAerador.textContent = `Aerador ${aerador.codigo}`;
            labelAerador.setAttribute('text-anchor', 'end');
            labelAerador.setAttribute('id', `labelAerador${aerador.codigo}`);
            containerElementos.appendChild(labelAerador);
            const posicao_x_px = (posicao_x * svgPai.width) / 100;
            const posicao_y_px = (posicao_y * svgPai.height) / 100;
            if (posicao_y_px > 100) {
                labelAerador.setAttribute('text-anchor', 'end');
                labelAerador.setAttribute(
                    'transform',
                    `translate(${posicao_x_px - 11},${
                        posicao_y_px + this.paddingSvgPaiY - this.tamnahoFonte
                    }) rotate(90)`
                );
            } else if (posicao_y_px <= 100) {
                labelAerador.setAttribute('text-anchor', 'end');
                labelAerador?.setAttribute(
                    'transform',
                    `translate(${posicao_x_px - 11},${
                        posicao_y_px + this.paddingSvgPaiY - this.tamnahoFonte - 23
                    }) rotate(90)`
                );
            }
        });
    }

    alteraCorTemperaturasPendulos(temperaturaTipo: string): void {
        this.divisaoComPendulos.forEach((divisao) => {
            divisao.pendulos.forEach((pendulo) => {
                const equipamentoPendulo = this.listaEquipamentos?.find(
                    (equipamento) => equipamento.id === pendulo.pendulo_canal.equipamento_id
                ).equipamento_comunicacao.status;
                const penduloSvg = d3.select(`#pendulo${pendulo.codigo}`);
                penduloSvg.attr(
                    'fill',
                    equipamentoPendulo
                        ? this.criaPaletaCoresTermometriaService.criarPaletaTemperatura(
                              pendulo.pendulo_ultima_leitura[temperaturaTipo]
                          ).fundo
                        : '#868994'
                );
            });
        });
    }

    private criaPendulos(svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>): void {
        this.divisaoComPendulos.forEach((divisao) => {
            divisao.pendulos.forEach((pendulo) => {
                this.montaPendulosVisaoSuperiorArmazemService.montaPendulo(
                    svg,
                    pendulo,
                    divisao.formaGeometrica,
                    this.temperaturaControle.value,
                    this.listaEquipamentos
                );
            });
        });
    }

    retonarMargemFormaGeometricaAerador(forma: FormaGeometricaPenduloEnum): number {
        switch (forma) {
            case FormaGeometricaPenduloEnum.PENTAGONO:
                return -2;
            case FormaGeometricaPenduloEnum.TRIANGULO:
                return -5;
            default:
                return 0;
        }
    }

    abrirModalEntendaVisaoSuberior(): void {
        const mobile = this.breakpointObserver.isMatched('(max-width: 600px)');
        this.dialog.open(ModalEntendaVisaoSuperiorArmazemComponent, {
            minWidth: mobile
                ? configuracaoTamanhoModal.informativo.mobile.width
                : configuracaoTamanhoModal.informativo.desktop.width,
            minHeight: mobile
                ? configuracaoTamanhoModal.informativo.mobile.height
                : configuracaoTamanhoModal.informativo.desktop.height,
        });
    }

    aumentarZoom(): void {
        this.escalaCorrente += 0.1;
        this.aplicarZoom();
    }

    diminuirZoom(): void {
        this.escalaCorrente -= 0.1;
        this.aplicarZoom();
    }

    resetarZoom(): void {
        this.escalaCorrente = 1;
        this.translateCorrente = [0, 0];
        this.aplicarZoom();
    }

    aplicarZoom(): void {
        const conteudo = d3.select('#grupo');
        const retanguloArmazem = d3.select('#retanguloArmazem');
        const widthConteudo = parseFloat(retanguloArmazem.attr('width'));
        const heightConteudo = parseFloat(retanguloArmazem.attr('height'));

        retanguloArmazem.attr('cursor', 'grabbing');
        conteudo
            .transition()
            .duration(200)
            .attr('transform', `translate(${this.translateCorrente})  scale(${this.escalaCorrente})`);
        const drag = d3
            .drag()
            .on('start', () => {
                conteudo.classed('dragging', true);
            })
            .on('drag', (evento) => {
                if (evento.x > widthConteudo - 30) {
                    this.translateCorrente[0] = widthConteudo - 30;
                } else if (evento.x < 30) {
                    this.translateCorrente[0] = 30 - widthConteudo;
                } else {
                    this.translateCorrente[0] += evento.dx;
                }
                if (evento.y > heightConteudo - 30) {
                    this.translateCorrente[1] = heightConteudo - 30;
                } else if (evento.y < 30) {
                    this.translateCorrente[1] = 30 - heightConteudo;
                } else {
                    this.translateCorrente[1] += evento.dy;
                }
                this.aplicarZoom();
            })
            .on('end', () => {
                conteudo.classed('dragging', false);
            });
        conteudo.call(drag);
    }
}
