<ng-container *ngIf="!carrgandoEstruturas; else progressSpinner">
    <ng-container *ngIf="listagemEstruturaArmazenagem?.length; else semDados">
        <section class="p-4">
            <div class="flex items-center border-b flex-row sm:h-auto min-h-10">
                <div class="flex items-center justify-between w-full">
                    <span class="p-0 flex"
                        >{{
                            !verificaObjetoVazio(
                                estruturaArmazenagemSelecionada.value?.ultima_leitura?.sensor_plenum_ultima_leitura
                            )
                                ? ('EQUILIBRIO_HIGROSCOPICO_LISTAGEM_COMPONENT.SENSOR_PLENUM_/' | transloco)
                                : ""
                        }}{{ "EQUILIBRIO_HIGROSCOPICO_LISTAGEM_COMPONENT.EQUILIBRIO_HIGROSCOPICO" | transloco }}
                    </span>
                    <div class="containerSelectPadrao" *ngIf="listagemEstruturaArmazenagem.length > 0">
                        <mat-form-field [hideRequiredMarker]="true" class="w-[190px]">
                            <mat-select
                                [formControl]="estruturaArmazenagemSelecionada"
                                (selectionChange)="trocaEstruturaArmazenagem($event)"
                            >
                                <mat-option
                                    *ngFor="let estruturaArmazenagem of listagemEstruturaArmazenagem"
                                    [value]="estruturaArmazenagem"
                                >
                                    {{
                                        visaoArmazem && estruturaArmazenagem?.estrutura_armazenagem?.nome
                                            ? estruturaArmazenagem.estrutura_armazenagem.nome + " - "
                                            : ""
                                    }}
                                    {{ estruturaArmazenagem?.nome }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <ng-container
                *ngIf="
                    trocaEstacao?.ultima_leitura?.validacao_leitura?.status &&
                        nomeProduto !== produtoNaoInformado &&
                        !carrgandoEstruturas;
                    else estacaoInativa
                "
            >
                <ng-container *ngIf="equilibrioHigroscopico$ | async as equilibrio; else progressSpinnerEquilibrio">
                    <ng-container
                        *ngIf="
                            verificaObjetoVazio(
                                estruturaArmazenagemSelecionada.value?.ultima_leitura?.sensor_plenum_ultima_leitura
                            )
                        "
                    >
                        <app-tamplate-dados-equilibrio-higroscopico
                            [nomeProduto]="nomeProduto"
                            [equilibrioHigroscopico]="equilibrio"
                            [temEstacaoAtiva]="temEstacaoAtiva()"
                        ></app-tamplate-dados-equilibrio-higroscopico>
                    </ng-container>
                    <ng-container
                        *ngIf="
                            !verificaObjetoVazio(
                                estruturaArmazenagemSelecionada.value?.ultima_leitura?.sensor_plenum_ultima_leitura
                            )
                        "
                    >
                        <app-tamplate-dados-equilibrio-sensor-plenum
                            [nomeProduto]="nomeProduto"
                            [equilibrioHigroscopico]="equilibrio"
                            [temEstacaoAtiva]="temEstacaoAtiva()"
                            [sensorPlenum]="
                                estruturaArmazenagemSelecionada.value?.ultima_leitura?.sensor_plenum_ultima_leitura
                            "
                            [estacao]="trocaEstacao"
                        ></app-tamplate-dados-equilibrio-sensor-plenum>
                    </ng-container>
                </ng-container>
            </ng-container>
        </section>
    </ng-container>
</ng-container>

<ng-template #estacaoInativa>
    <ng-container
        *ngIf="verificaObjetoVazio(estruturaArmazenagemSelecionada.value?.ultima_leitura?.sensor_plenum_ultima_leitura)"
    >
        <app-tamplate-dados-equilibrio-higroscopico
            [nomeProduto]="nomeProduto"
            [temEstacaoAtiva]="temEstacaoAtiva()"
        ></app-tamplate-dados-equilibrio-higroscopico>
    </ng-container>
    <ng-container
        *ngIf="
            !verificaObjetoVazio(estruturaArmazenagemSelecionada.value?.ultima_leitura?.sensor_plenum_ultima_leitura)
        "
    >
        <app-tamplate-dados-equilibrio-sensor-plenum
            [nomeProduto]="nomeProduto"
            [temEstacaoAtiva]="temEstacaoAtiva()"
        ></app-tamplate-dados-equilibrio-sensor-plenum>
    </ng-container>
</ng-template>

<ng-template #progressSpinner class="absolute">
    <div class="h-[140px] flex">
        <span class="m-auto flex">
            <mat-progress-spinner *ngIf="progressSpinner" [diameter]="24" [mode]="'indeterminate'">
            </mat-progress-spinner>
        </span>
    </div>
</ng-template>

<ng-template #progressSpinnerEquilibrio class="absolute">
    <div class="h-[70px] flex">
        <span class="m-auto flex">
            <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
        </span>
    </div>
</ng-template>

<ng-template #semDados>
    <div class="flex flex-col w-full h-[140px] p-4">
        <div class="flex items-center border-b">
            <span class="text-[#535767] text-[14px] leading-4 font-medium mb-2">Equilíbrio Higroscópico</span>
        </div>
        <span class="m-auto flex text-[#535767]">{{ "EQUILIBRIO_HIGROSCOPICO_LISTAGEM_COMPONENT.NENHUM_RESULTADO_ENCONTRADO" | transloco }} </span>
    </div>
</ng-template>
