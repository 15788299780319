<div class="max-w-[450px] componente-modal text-center flex flex-col">
    <div class="d-flex mb-5 modal-botao-fechar">
        <mat-icon id="icon-close" (click)="dialogRef.close()">close</mat-icon>
    </div>
    <div *ngIf="data?.icone" class="text-center modal-titulo justify-center flex">
        <mat-icon class="wl-cor-primaria mr-1 my-auto align-top icon-size-10" [svgIcon]="data?.icone"></mat-icon>
    </div>
    <span class="wl-cor-primaria text-[14px]  mt-5">
        {{ data?.titulo }}
    </span>

    <ng-container *ngIf="data?.mensagem">
        <p class="text-[#535767] text-[13px] mt-6" [innerHTML]="data?.mensagem"></p>
    </ng-container>
    
    <mat-dialog-actions class="flex justify-center p-10">
        <button (click)="dialogRef.close()" type="button" class="w-[128px] h-[40px] leading-5 text-[13px]">
            {{"FECHAR" | transloco}}
        </button>
    </mat-dialog-actions>
</div>
