<ng-container *ngIf="graos.length && equilibrioHigroscopico; else carregando">
    <section class="componente-modal">
        <div class="mb-5 modal-botao-fechar">
            <mat-icon id="icon-close" (click)="dialogRef.close()">close</mat-icon>
        </div>
        <div class="text-center mt-2 modal-titulo">
            <p class="text-base sm:text-2xl">
                {{ "MODAL_ENTENDA_VALORES_COMPONENT.TITULO" | transloco }}
            </p>
        </div>
        <div class="text-center flex justify-center">
            <a href="https://www.procer.com.br/solucoes/calculadora-equilibrio-higroscopico/" target="_blank">
                <mat-icon class="icon-size-4 wl-cor-primaria">launch</mat-icon>
            </a>
            <span class="px-1 text-[12px] items-center">{{
                "MODAL_ENTENDA_VALORES_COMPONENT.LINK_CALCULADORA_EH" | transloco
            }}</span>
        </div>
        <div class="mb-5 mt-5 sm:mt-8 overflow-hidden">
            <form [formGroup]="formGroup">
                <div class="flex sm:flex-row flex-col mt-4 gap-4 justify-between">
                    <div class="containerSelectPadrao flex-col flex items-center sm:ml-8">
                        <mat-label class="text-center">{{ "GRAO" | transloco }}</mat-label>
                        <mat-form-field class="sm:w-[200px] w-[280px] h-[20px] mt-1.5" [hideRequiredMarker]="true">
                            <mat-select formControlName="produtoTipo" (selectionChange)="selecionaProdutoTipo($event)">
                                <mat-option *ngFor="let grao of graos" [value]="grao.produto_tipo.id">{{
                                    grao.nome
                                }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="containerSelectPadrao flex-col flex items-center sm:mr-8">
                        <mat-label class="text-center">{{
                            "MODAL_ENTENDA_VALORES_COMPONENT.SIMULAR_AQUECIMENTO" | transloco
                        }}</mat-label>
                        <mat-form-field class="sm:w-[200px] w-[280px] h-[20px] mt-1.5" [hideRequiredMarker]="true">
                            <mat-select
                                formControlName="variavelAquecimento"
                                (selectionChange)="selecionaVariavelAquecimento($event)"
                            >
                                <mat-option value="plenum" *ngIf="data?.sensorPlenum">Plenum</mat-option>
                                <mat-option value="0">0,0</mat-option>
                                <mat-option value="1">1,0</mat-option>
                                <mat-option value="2">2,0</mat-option>
                                <mat-option value="3">3,0</mat-option>
                                <mat-option value="4">4,0</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </form>
            <div class="flex">
                <div class="flex w-[40%] mt-4" *ngIf="imagemSelecionada">
                    <img class="" [src]="imagemSelecionada" alt="Imagem Selecionada" />
                </div>
                <div class="flex flex-col w-[60%] relative">
                    <div
                        *ngIf="opcaoAquecimentoSelecionada === 'plenum'; else semSensorPlenum"
                        class="text-[#16171B] wl-bg-cor-primaria-opacidade w-[137px] h-[29px] absolute bottom-18 sm:bottom-24 left-0 p-[3px] rounded-md"
                    >
                        {{ "MODAL_ENTENDA_VALORES_COMPONENT.AQPLENUM" | transloco }}:
                        <a class="wl-cor-primaria"
                            >{{
                                formataCampoValorService.execute(equilibrioHigroscopico?.equilibrio_higroscopico?.aq) ||
                                    "-"
                            }}
                            °C</a
                        >
                    </div>
                    <ng-template #semSensorPlenum
                        ><div
                            class="text-[#16171B] wl-bg-cor-primaria-opacidade w-[137px] h-[29px] absolute bottom-24 left-0 p-[3px] rounded-md"
                        >
                            {{ "AQ" | transloco }}:
                            <a class="wl-cor-primaria">{{ opcaoAquecimentoSelecionada || "-" }} °C</a>
                        </div>
                    </ng-template>

                    <div class="flex flex-col absolute md:bottom-18 bottom-0 right-0 xl:right-8">
                        <ng-container *ngIf="!buscandoEquilibrioHigroscopico">
                            <div class="text-[#16171B] w-[137px] h-[25px] p-[3px]">
                                {{ "MODAL_ENTENDA_VALORES_COMPONENT.TAMB" | transloco }}:
                                <a class="wl-cor-primaria"
                                    >{{ formataCampoValorService.execute(temperaturaAmbiente) || "-" }} °C</a
                                >
                            </div>
                            <div class="text-[#16171B] w-[137px] h-[25px] p-[3px]">
                                {{ "MODAL_ENTENDA_VALORES_COMPONENT.URAMB" | transloco }}:
                                <a class="wl-cor-primaria"
                                    >{{ formataCampoValorService.execute(urAmbiente) || "-" }} %</a
                                >
                            </div>
                            <div
                                class="text-[#16171B] wl-bg-cor-primaria-opacidade w-[160px] h-[25px] p-[3px] rounded-md"
                            >
                                {{ "MODAL_ENTENDA_VALORES_COMPONENT.EHAMB" | transloco }}:
                                <a class="wl-cor-primaria"
                                    >{{
                                        formataCampoValorService.execute(
                                            equilibrioHigroscopico?.equilibrio_higroscopico?.ehamb
                                        ) || "-"
                                    }}
                                    %</a
                                >
                            </div>
                        </ng-container>
                        <ng-container *ngIf="buscandoEquilibrioHigroscopico">
                            <div class="w-[137px] h-[75px] p-[3px] flex justify-center items-center">
                                <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div *ngIf="opcaoAquecimentoSelecionada === 'plenum'; else ehAq" class="flex flex-col sm:ml-8 xl:ml-14">
                <ng-container *ngIf="!buscandoEquilibrioHigroscopico">
                    <div class="text-[#16171B] w-[137px] h-[25px] p-[3px]">
                        {{ "MODAL_ENTENDA_VALORES_COMPONENT.TPLENUM" | transloco }}:
                        <a class="wl-cor-primaria"
                            >{{ formataCampoValorService.execute(temperaturaPlenum) || "-" }} °C</a
                        >
                    </div>
                    <div class="text-[#16171B] w-[160px] h-[25px] p-[3px]">
                        {{ "MODAL_ENTENDA_VALORES_COMPONENT.URPLENUM" | transloco }}:
                        <a class="wl-cor-primaria">{{ formataCampoValorService.execute(urPlenum) || "-" }} %</a>
                    </div>
                    <div class="text-[#16171B] wl-bg-cor-primaria-opacidade w-[160px] h-[25px] p-[3px] rounded-md">
                        {{ "MODAL_ENTENDA_VALORES_COMPONENT.EHPLENUM" | transloco }}:
                        <a class="wl-cor-primaria"
                            >{{
                                formataCampoValorService.execute(
                                    equilibrioHigroscopico?.equilibrio_higroscopico?.ehplenum
                                ) || "-"
                            }}
                            %</a
                        >
                    </div>
                </ng-container>
                <ng-container *ngIf="buscandoEquilibrioHigroscopico">
                    <div class="w-[137px] h-[75px] p-[3px] flex justify-center items-center">
                        <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
                    </div>
                </ng-container>
            </div>
            <ng-template #ehAq>
                <div class="flex w-full flex-col sm:ml-8 xl:ml-14">
                    <ng-container *ngIf="!buscandoEquilibrioHigroscopico">
                        <div class="text-[#16171B] w-[137px] h-[25px] p-[3px]">
                            {{ "MODAL_ENTENDA_VALORES_COMPONENT.TAQ" | transloco }}:
                            <a class="wl-cor-primaria"
                                >{{
                                    formataCampoValorService.execute(
                                        equilibrioHigroscopico?.equilibrio_higroscopico?.taq
                                    ) || "-"
                                }}
                                °C</a
                            >
                        </div>
                        <div class="text-[#16171B] w-[137px] h-[25px] p-[3px]">
                            {{ "MODAL_ENTENDA_VALORES_COMPONENT.URAQ" | transloco }}:
                            <a class="wl-cor-primaria"
                                >{{
                                    formataCampoValorService.execute(
                                        equilibrioHigroscopico?.equilibrio_higroscopico?.uraq
                                    ) || "-"
                                }}
                                %</a
                            >
                        </div>
                        <div class="text-[#16171B] wl-bg-cor-primaria-opacidade w-[150px] h-[25px] p-[3px] rounded-md">
                            {{ "MODAL_ENTENDA_VALORES_COMPONENT.EHAQ" | transloco }}:
                            <a class="wl-cor-primaria"
                                >{{
                                    formataCampoValorService.execute(
                                        equilibrioHigroscopico?.equilibrio_higroscopico?.ehaq
                                    ) || "-"
                                }}
                                %</a
                            >
                        </div>
                    </ng-container>
                    <ng-container *ngIf="buscandoEquilibrioHigroscopico">
                        <div class="w-[137px] h-[75px] p-[3px] flex justify-center items-center">
                            <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
                        </div>
                    </ng-container>
                </div>
            </ng-template>
            <div class="grid text-[12px] grid-cols-2 mt-4 gap-4">
                <div class="flex flex-col">
                    <p>
                        <a class="font-bold">{{ "MODAL_ENTENDA_VALORES_COMPONENT.TAMB" | transloco }}: </a>
                        {{ "MODAL_ENTENDA_VALORES_COMPONENT.TAMB_LEGENDA" | transloco }}
                    </p>
                    <p>
                        <a class="font-bold">{{ "MODAL_ENTENDA_VALORES_COMPONENT.URAMB" | transloco }}: </a>
                        {{ "MODAL_ENTENDA_VALORES_COMPONENT.URAMB_LEGENDA" | transloco }}
                    </p>
                    <p>
                        <a class="font-bold">{{ "MODAL_ENTENDA_VALORES_COMPONENT.EHAMB" | transloco }}: </a>
                        {{ "MODAL_ENTENDA_VALORES_COMPONENT.EHAMB_LEGENDA" | transloco }}
                    </p>
                </div>
                <div *ngIf="opcaoAquecimentoSelecionada === 'plenum'; else semPlenums" class="flex flex-col">
                    <p>
                        <a class="font-bold">{{ "MODAL_ENTENDA_VALORES_COMPONENT.AQPLENUM" | transloco }}: </a>
                        {{ "MODAL_ENTENDA_VALORES_COMPONENT.AQ_LEGENDA" | transloco }}
                    </p>
                    <p>
                        <a class="font-bold">{{ "MODAL_ENTENDA_VALORES_COMPONENT.TPLENUM" | transloco }}: </a>
                        {{ "MODAL_ENTENDA_VALORES_COMPONENT.TPLENUM_LEGENDA" | transloco }}
                    </p>
                    <p>
                        <a class="font-bold">{{ "MODAL_ENTENDA_VALORES_COMPONENT.URPLENUM" | transloco }}: </a>
                        {{ "MODAL_ENTENDA_VALORES_COMPONENT.URPLENUM_LEGENDA" | transloco }}
                    </p>
                    <p>
                        <a class="font-bold">{{ "MODAL_ENTENDA_VALORES_COMPONENT.EHPLENUM" | transloco }}: </a>
                        {{ "MODAL_ENTENDA_VALORES_COMPONENT.EHPLENUM_LEGENDA" | transloco }}
                    </p>
                </div>
                <ng-template #semPlenums>
                    <div class="flex flex-col">
                        <p>
                            <a class="font-bold">{{ "AQ" | transloco }}: </a
                            >{{ "MODAL_ENTENDA_VALORES_COMPONENT.AQ_LEGENDA" | transloco }}
                        </p>
                        <p>
                            <a class="font-bold">{{ "MODAL_ENTENDA_VALORES_COMPONENT.TAQ" | transloco }}: </a
                            >{{ "MODAL_ENTENDA_VALORES_COMPONENT.TAQ_LEGENDA" | transloco }}
                        </p>
                        <p>
                            <a class="font-bold">{{ "MODAL_ENTENDA_VALORES_COMPONENT.URAQ" | transloco }}: </a>
                            {{ "MODAL_ENTENDA_VALORES_COMPONENT.URAQ_LEGENDA" | transloco }}
                        </p>
                        <p>
                            <a class="font-bold">{{ "MODAL_ENTENDA_VALORES_COMPONENT.EHAQ" | transloco }}: </a>
                            {{ "MODAL_ENTENDA_VALORES_COMPONENT.EHAQ_LEGENDA" | transloco }}
                        </p>
                    </div>
                </ng-template>
            </div>
        </div>
    </section>
</ng-container>

<ng-template #carregando class="absolute">
    <div class="h-[648px] w-[600px] flex overflow-hidden">
        <span class="m-auto flex overflow-hidden">
            <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
        </span>
    </div>
</ng-template>

<ng-template #carregandoEquilibrio>
    <div class="h-[180px] flex justify-center items-center">
        <span class="m-auto flex">
            <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
        </span>
    </div>
</ng-template>
