import { parametrosFiltroPersonalizados } from 'app/modules/gestao-unidades/operacional/shared/interfaces';

export enum TabelaCondicaoArmazenagem {
    SILOS_ARMAZEM = 'siloArmazem',
    UNIDADES = 'unidades',
    MINIMO = 'minimo',
    MEDIA = 'medio',
    MAXIMO = 'maximo',
    GRAO = 'grao',
    UG = 'ug',
    ESTOQUE = 'estoque',
    AERACAO_NAS_ULTIMAS_24 = 'aeracao',
    MODAL = 'modal',
}

export enum CondicoesArmazenagemFiltro {
    TEMPERATURA_MEDIA_MAIOR_QUE_28_C = 'Temperatura média maior que 28 °C',
    TEMPERATURA_MAXIMA_MAIOR_QUE_30_C = 'Temperatura máxima maior que 30 °C',
    MAIOR_TEMPERATURA = 'Maior temperatura',
    MAIOR_UMIDADE_DO_GRAO = 'Maior umidade do grão',
    MENOR_UMIDADE_DO_GRAO = 'Menor umidade do grão',
    MAIOR_TEMPO_DE_AERACAO = 'Maior tempo de aeração',
    MENOR_TEMPO_DE_AERACAO = 'Menor tempo de aeração',
    PROGRAMA_EM_SECAGEM_PAINEL_AUTOMATICO_AMOSTRAS_ATRASO = 'Programa em secagem, painel em automático e amostras em atraso',
    PROGRAMA_EM_SECAGEM_E_PAINEL_EM_MANUAL = 'Programa em secagem e painel em manual',
    PROGRAMA_EM_SECAGEM_PAINEL_MANUAL_AMOSTRA_ATRASO = 'Programa em secagem, painel em manual e amostra em atraso',
    PROGRAMA_EM_SECAGEM_UMIDADE_MENOR_QUE_15_PERCENT = 'Programa em secagem e umidade menor que 15%',
    PROGRAMA_EM_SECAGEM_MOTORES_LIGADOS_MENOS_DE_15H_24H = 'Programa em secagem e motores ligados com menos de 15h nas últimas 24h',
    PROGRAMA_EM_REFRIGERACAO_UMIDADE_MAIOR_QUE_15_PERCENT = 'Programa em resfriamento e umidade maior que 15%',
    PROGRAMA_EM_REFRIGERACAO_PAINEL_MANUAL_TEMPERATURA_MEDIA_MAIOR_QUE_28C = 'Programa em resfriamento, painel em manual e temperatura média maior que 28°C',
    PROGRAMA_EM_REFRIGERACAO_PAINEL_MANUAL_TEMPERATURA_MAXIMA_MAIOR_QUE_30C = 'Programa em resfriamento, painel em manual e temperatura máxima maior que 30°C',
    PAINEL_MANUAL_TEMPERATURA_MEDIA_MAIOR_QUE_28C = 'Painel em manual e temperatura média maior que 28°C',
    PAINEL_MANUAL_TEMPERATURA_MAXIMA_MAIOR_QUE_30C = 'Painel em manual e temperatura máxima maior que 30°C',
}

export enum GraficoCondicaoArmazenagemName {
    ESTOQUE = 'Estoque (t)',
    TEMPO_DE_AERACAO = 'Tempo de aeração (h)',
    TEMPERATURA_MEDIA = 'Temperatura média (°C)',
    TEMPERATURA_MAXIMA = 'Temperatura máxima (°C)',
}

export enum AscendenteDescendente {
    ASCENDENTE = 'asc',
    DESCENDENTE = 'desc',
}

export const OperadoresFiltrosPersonalizadosParametros: parametrosFiltroPersonalizados[] = [
    {
        nome: 'Temperatura média (°C)',
        valor: 'temperatura_media',
    },
    {
        nome: 'Temperatura máxima  (°C)',
        valor: 'temperatura_maxima',
    },
    {
        nome: 'Temperatura mínima  (°C)',
        valor: 'temperatura_minima',
    },
    {
        nome: 'Umidade do grão (%)',
        valor: 'umidade_grao',
    },
];

export const OperadoresFiltrosPersonalizadosOperadores: parametrosFiltroPersonalizados[] = [
    {
        nome: 'Igual',
        valor: '=',
    },
    {
        nome: 'Maior',
        valor: '>',
    },
    {
        nome: 'Menor',
        valor: '<',
    },
    {
        nome: 'Diferente',
        valor: '<>',
    },
    {
        nome: 'Maior ou igual',
        valor: '>=',
    },
    {
        nome: 'Menor ou igual',
        valor: '<=',
    },
];
