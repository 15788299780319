import { BANDEIRA_PAISES } from "app/shared/constants";

export class RetornaMascaraTelefoneDdiHelper {
  public static execute(ddi: string): string {
    const numeroDigitosDdi = ddi.length - 1;

    return `+${"0".repeat(numeroDigitosDdi)} ${
      BANDEIRA_PAISES.find((pais) => pais.codigo === ddi).mascara
    }`;
  }
}
