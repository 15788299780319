export const EstacaoMeteorologicaStyles = {
    chovendo: 'wl-cor-primaria wl-bg-cor-primaria-opacidade',
    ensolarado: 'text-[#F1B826] bg-[#FEF8EA]',
    sem_leitura: 'text-[#292b32] bg-[#ebeced]',
};

export const EstacaoMeteorologicaFonteStyles = {
    chovendo: 'wl-cor-primaria',
    ensolarado: 'text-[#F1B826]',
    sem_leitura: 'text-[#292b32]',
};
