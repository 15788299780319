import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { VisaoSuperiorSiloComponent } from './visao-superior-silo.component';

@NgModule({
    declarations: [VisaoSuperiorSiloComponent],
    imports: [
        CommonModule,
        MatPaginatorModule,
        MatSelectModule,
        MatFormFieldModule,
        MatIconModule,
        NgxMatSelectSearchModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatTableModule,
        MatSortModule,
        TranslocoModule,
    ],
    exports: [VisaoSuperiorSiloComponent],
})
export class VisaoSuperiorSiloModule {}
