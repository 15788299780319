import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { AsideMenuObservable, UnidadeParametrizacaoObservable } from 'app/shared/observables';
import { CarregaVersaoService, IAlertaService } from 'app/shared/services';
import { TipoDeAlerta } from 'app/shared/models';
import { environment } from 'environments/environment';
import { Subject, takeUntil } from 'rxjs';
import { ClassicService } from './service';
import { MediaWatcherService } from 'app/core/media-watcher/media-watcher.service';
import { OrigemDadosColetor } from 'app/shared/enums';

@Component({
    selector: 'classic-layout',
    templateUrl: './classic.component.html',
    styleUrls: ['./classic.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ClassicLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    alerta: TipoDeAlerta = new TipoDeAlerta({
        titulo: '',
        mostrar: false,
    });
    testEnviromentMode = false;
    recarregarComponente = false;
    logoUrl: string = environment.whiteLabel.logoRodape;
    isMenuShow = !this.telaMobile();
    menuPrincipalVisivel: boolean = true;
    temporizador: ReturnType<typeof setTimeout>;
    versaoSistema: string;
    tipoColetor: OrigemDadosColetor;
    tiposColetor = OrigemDadosColetor;

    telaMobile(): boolean {
        return screen.width < 600;
    }

    private unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _navigationService: NavigationService,
        private _mediaWatcherService: MediaWatcherService,
        private _changeDetectorRef: ChangeDetectorRef,
        private asideMenuObservable: AsideMenuObservable,
        private alertaService: IAlertaService,
        private carregaVersaoService: CarregaVersaoService,
        private unidadeParametrizacaoObservable: UnidadeParametrizacaoObservable,
        readonly classicService: ClassicService
    ) {}

    get currentYear(): number {
        return new Date().getFullYear();
    }

    ngOnInit(): void {
        this.alertaService.alerta$.pipe(takeUntil(this.unsubscribeAll)).subscribe((alerta) => {
            this.alerta = alerta;
        });

        this.unidadeParametrizacaoObservable.buscaParametrosUnidade$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((parametros) => {
                this.tipoColetor = parametros?.unidade_parametrizacao?.origem_dados;
            });

        // Subscribe to navigation data
        this._navigationService.navigation$.pipe(takeUntil(this.unsubscribeAll)).subscribe((navigation: Navigation) => {
            this.navigation = navigation;
        });

        this._mediaWatcherService.onMediaChange$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                this.isScreenSmall = !matchingAliases.includes('md');
            });
        this.asideMenuObservable.menuMinimizadoSubject.pipe(takeUntil(this.unsubscribeAll)).subscribe((hide) => {
            this.isMenuShow = !hide;
            this._changeDetectorRef.detectChanges();
        });

        this.atualizaVersaoSistema();
        this.testEnviromentMode = !environment.production;
    }

    trocaUnidade(e: any): void {
        window.clearTimeout(this.temporizador);
        if (e) {
            this.recarregarComponente = e;
        }
        this.temporizador = setTimeout(() => {
            this.recarregarComponente = false;
            this._changeDetectorRef.detectChanges();
        }, 100);
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }

    toggleNavigation(): void {
        if (this.telaMobile()) {
            this.toggleMobileNavigation();
        } else {
            this.toggleDesktopNavigation();
        }
    }

    private atualizaVersaoSistema(): void {
        this.carregaVersaoService.retornaVersao().subscribe((versao) => {
            this.versaoSistema = versao;
        });
    }

    private toggleMobileNavigation(): void {
        if (this.isMenuShow) {
            this.classicService.fecharMenuSobreposto();
        } else {
            this.classicService.abrirMenuSobrepostoMobile();
        }
    }

    private toggleDesktopNavigation(): void {
        if (this.menuPrincipalVisivel) {
            this.classicService.removerMenu();
        } else {
            this.classicService.adicionarMenu();
        }
        this.menuPrincipalVisivel = !this.menuPrincipalVisivel;
    }
}
