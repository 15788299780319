<div class="w-full componente-modal text-center" *ngIf="!pendulos">
    <div class="d-flex mb-5 modal-botao-fechar">
        <mat-icon id="icon-close" (click)="dialogRef.close()">close</mat-icon>
    </div>
    <p class="wl-cor-primaria mb-6">{{"VISAO_SUPERIOR" | transloco}}</p>
    <img [src]="'assets/img/default_view.png'" class="inline-block mb-9 w-[70%] h-[70%]" alt="Visão superior" />
</div>
<div class="w-full componente-modal text-center" *ngIf="pendulos">
    <div class="d-flex mb-5 modal-botao-fechar">
        <mat-icon id="icon-close" (click)="dialogRef.close()">close</mat-icon>
    </div>
    <p class="wl-cor-primaria mb-6">{{"VISAO_SUPERIOR" | transloco}}</p>
    <img
        [src]="'assets/img/cable-position/center/' + pendulos + '.png'"
        class="inline-block mb-9 w-[70%] h-[70%]"
        alt="Visão superior"
    />
</div>
