<div class="cursor-default">
    <div class="text-[#0b0c0e] wl-cor-primaria flex items-center mb-2 w-full px-2">
        <div class="flex items-center w-[90%] justify-start">
            <mat-icon class="p-[2px] ml-[-3px]" [svgIcon]="'icone-aeracao-wl'"></mat-icon>
            <span class="my-1 ml-2">{{ "VISAO_DETALHADA_SILOS_SCREEN.AERACAO" | transloco }}</span>
        </div>
        <div class="flex items-center w-[10%] justify-end" *ngIf="!historicoSilo && !ocultarControles">
            <button
                (click)="abrirModalAcionamentoManual(retornarConfiguracaoStatusAeracao(statusAeracao), silo)"
                class="rounded-full h-13 w-13 bg-transparent border-0 disabled:opacity-60"
                [disabled]="
                    !permissaoAdministrar ||
                    retornarConfiguracaoStatusAeracao(statusAeracao)
                        ? !retornarConfiguracaoStatusAeracao(statusAeracao)?.acionamento_manual.habilitado
                        : true
                "
            >
                <mat-icon
                    class="p-[2px] ml-[-3px] icon-size-[50px]"
                    [svgIcon]="'icone-aeracao-acionamento-manual'"
                ></mat-icon>
            </button>
        </div>
    </div>
    <ng-container *ngIf="!ocultarControles">
        <div class="flex items-center mb-2 w-full h-11 bg-[#F4F6F999] px-2">
            <div class="flex items-center w-[30%] sm:w-[50%] xl:w-[40%] justify-start">
                <span class="items-center flex text-xs sm:text-base"
                    >{{ "VISAO_DETALHADA_SILOS_SCREEN.CONTROLE_AERACAO" | transloco }}
                    <span *ngIf="!historicoSilo">
                        <mat-icon
                            [svgIcon]="'icon-info'"
                            class="wl-cor-primaria ml-0.5 p-1.5 cursor-pointer"
                            (click)="mostrarHistoricoControleAeracao(silo.id)"
                        ></mat-icon>
                    </span>
                </span>
            </div>
            <div class="flex items-center w-[70%] sm:w-[50%] xl:w-[60%] justify-end aeracao sm:pr-4">
                <app-status-automatico-manual-com-icone-descricao
                    [statusAutomatico]="controleAeracao"
                ></app-status-automatico-manual-com-icone-descricao>
                <ng-container *ngIf="!historicoSilo">
                    <mat-icon
                        *ngIf="!carregando || campoEmEdicao != tipoCampoEmEdicaoEnum.CONTROLE; else carregandoDados"
                        class="ml-2 cursor-pointer"
                        svgIcon="mat_outline:edit"
                        [matMenuTriggerFor]="!permissaoAdministrar ? null : menuSilo"
                        [ngClass]="{
                            'opacity-50 cursor-default': !permissaoAdministrar,
                            'cursor-pointer': permissaoAdministrar
                        }"
                    ></mat-icon>
                    <mat-menu #menuSilo="matMenu">
                        <button
                            mat-menu-item
                            [ngStyle]="{
                                'background-color': controleAeracao ? 'var(--cor-primaria-opacidade)' : '',
                                color: controleAeracao ? 'var(--cor-primaria)' : '#535767'
                            }"
                            (click)="editarControleAeracao(true)"
                        >
                            <mat-icon
                                class="wl-cor-primaria icon-size-4 mr-2 cursor-pointer"
                                [svgIcon]="'mat_solid:check_circle'"
                                [ngStyle]="{
                                    fill: controleAeracao ? 'var(--cor-primaria)' : '#535767'
                                }"
                            ></mat-icon>
                            <span class="text-xs sm:text-base">{{ "STATUS_AUTOMATICO_MANUAL_ICONE_COMPONENT.AUTOMATICO" | transloco }}</span>
                        </button>
                        <hr class="my-0 w-[90%] mx-auto" />
                        <button
                            mat-menu-item
                            [ngStyle]="{
                                'background-color': !controleAeracao ? 'var(--cor-primaria-opacidade)' : '',
                                color: !controleAeracao ? 'var(--cor-primaria)' : '#535767'
                            }"
                            (click)="editarControleAeracao(false)"
                        >
                            <mat-icon
                                class="wl-cor-primaria icon-size-4 mr-2 cursor-pointer"
                                [svgIcon]="'mat_solid:back_hand'"
                                [ngStyle]="{
                                    fill: !controleAeracao ? 'var(--cor-primaria)' : '#535767'
                                }"
                            ></mat-icon>
                            <span class="text-xs sm:text-base">{{ "STATUS_AUTOMATICO_MANUAL_ICONE_COMPONENT.MANUAL" | transloco }}</span>
                        </button>
                    </mat-menu>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!ocultarControles">
        <div class="flex items-center mb-2 w-full h-11 px-2">
            <div class="flex items-center w-[80%] justify-start">
                <span class="items-center flex text-xs sm:text-base"
                    >{{ "VISAO_DETALHADA_SILOS_SCREEN.COMANDO_AERACAO" | transloco }}
                    <span *ngIf="!historicoSilo">
                        <mat-icon
                            [svgIcon]="'icon-info'"
                            class="wl-cor-primaria ml-0.5 p-1.5 cursor-pointer"
                            (click)="mostrarHistoricoComandoAeracao(silo.id)"
                        ></mat-icon>
                    </span>
                </span>
            </div>
            <div class="flex items-center w-[20%] justify-end aeracao">
                <app-status-on-off [statusOn]="comandoAeracao" [possuiStatusComunicacao]="false"></app-status-on-off>
            </div>
        </div>
        <div class="flex items-center mb-2 w-full h-11 px-2 bg-[#F4F6F999]">
            <div class="flex items-center w-[20%] sm:w-[50%] xl:w-[40%] justify-start">
                <span class="items-center flex text-xs sm:text-base"
                    >{{ "VISAO_DETALHADA_SILOS_SCREEN.PROGRAMA" | transloco }}
                    <span *ngIf="!historicoSilo">
                        <mat-icon
                            [svgIcon]="'icon-info'"
                            class="wl-cor-primaria ml-0.5 p-1.5 cursor-pointer"
                            (click)="mostrarHistoricoAlteracoesPrograma(silo.id)"
                        ></mat-icon>
                    </span>
                </span>
            </div>
            <div class="flex items-center w-[80%] justify-end containerSelectAeracao text-xs sm:text-base">
                <mat-form-field
                    [hideRequiredMarker]="true"
                    class="my-auto px-0 text-xs sm:text-base"
                    (click)="customizaPanelSelect()"
                    *ngIf="!historicoSilo"
                >
                    <mat-select
                        id="select-aeracao"
                        panelClass="selectAeracao"
                        [formControl]="programaControle"
                        #selectPrograma
                        (selectionChange)="editarPrograma($event.value)"
                    >
                        <mat-option value="null" class="text-xs sm:text-base">
                            {{ "VISAO_DETALHADA_SILOS_SCREEN.NENHUM" | transloco }}
                        </mat-option>
                        <mat-option
                            *ngFor="let programa of listaDeProgramas?.data"
                            [value]="programa.id"
                            class="text-xs sm:text-base"
                        >
                            {{ programa?.nome }}
                        </mat-option>
                    </mat-select>
                    <ng-container *ngIf="!historicoSilo">
                        <mat-icon
                            *ngIf="!carregando || campoEmEdicao != tipoCampoEmEdicaoEnum.PROGRAMA; else carregandoDados"
                            class="ml-2 cursor-pointer"
                            svgIcon="mat_outline:edit"
                            [ngClass]="{
                                'opacity-50 cursor-default': !permissaoAdministrar,
                                'cursor-pointer': permissaoAdministrar
                            }"
                        ></mat-icon>
                    </ng-container>
                </mat-form-field>
                <span *ngIf="historicoSilo" class="text-xs sm:text-base">{{
                    programaSetadoSilo?.nome ??    ("VISAO_DETALHADA_SILOS_SCREEN.NENHUM" | transloco)
                }}</span>
            </div>
        </div>
        <div class="flex items-center mb-2 w-full h-11 px-2 justify-between text-xs sm:text-base">
            <span>{{ "VISAO_DETALHADA_SILOS_SCREEN.OBJETIVO" | transloco }}</span>
            <span *ngIf="!historicoSilo">{{ objetivoProgramaUsado ??   ("VISAO_DETALHADA_SILOS_SCREEN.NENHUM" | transloco) }}</span>
            <span *ngIf="historicoSilo">{{
                objetivoProgramaUsadoHistorico(programaSetadoSilo?.objetivo ?? programaSetadoSilo?.objetivo_programa) ??
                ("VISAO_DETALHADA_SILOS_SCREEN.NENHUM" | transloco)
            }}</span>
        </div>
        <div
            class="flex justify-between items-center mb-2 w-full h-fit p-1 px-2 bg-[#F4F6F999]"
            *ngIf="tempoAeracaoAutomatica || tempoAeracaoManual"
        >
            <div class="w-[30%]">
                <span class="text-xs sm:text-base"
                    >{{ "VISAO_DETALHADA_SILOS_SCREEN.TEMPO_DE_FUNC" | transloco }}</span
                >
            </div>
            <div class="flex flex-col gap-1 justify-end items-end sm:items-start sm:flex-row sm:w-[70%]">
                <div class="w-[35%] flex flex-row items-center justify-end" *ngIf="tempoAeracaoAutomatica">
                    <span
                        class="flex rounded-md min-w-9 p-2 h-8 justify-center bg-[#B2E7A7] text-[#34901C] text-xs sm:text-base items-center mr-2"
                    >
                        {{ "VISAO_DETALHADA_SILOS_SCREEN.A" | transloco }}
                    </span>
                    <span class="text-xs sm:text-base">{{ tempoAeracaoAutomatica }}</span>
                </div>
                <div class="w-[35%] sm:ml-7 flex flex-row items-center justify-end" *ngIf="tempoAeracaoManual">
                    <span
                        class="flex rounded-md min-w-9 p-2 h-8 justify-center bg-[#FBBC0566] text-[#B48707] text-xs sm:text-base items-center mr-2"
                    >
                        {{ "VISAO_DETALHADA_SILOS_SCREEN.M" | transloco }}
                    </span>
                    <span class="text-xs sm:text-base">{{ tempoAeracaoManual }}</span>
                </div>
            </div>
        </div>
        <div class="flex items-center mb-2 w-full h-11 px-2">
            <div class="flex items-center w-[50%] justify-start">
                <span class="items-center flex text-xs sm:text-base"
                    >{{ "VISAO_DETALHADA_SILOS_SCREEN.VARIAVEL_DE_AQUECIMENTO" | transloco }}
                    <span *ngIf="!historicoSilo">
                        <mat-icon
                            [svgIcon]="'icon-info'"
                            class="wl-cor-primaria ml-0.5 p-1.5 cursor-pointer"
                            (click)="mostrarHistoricoVariavelAquecimento(silo.id)"
                        ></mat-icon>
                    </span>
                </span>
            </div>
            <div class="flex items-center w-[50%] justify-end containerSelectAeracao text-xs sm:text-base">
                <mat-form-field
                    [hideRequiredMarker]="true"
                    class="my-auto px-0 text-xs sm:text-base"
                    (click)="customizaPanelSelect()"
                    *ngIf="
                        !historicoSilo &&
                        listaVariavelAquecimento &&
                        (silo?.sensores?.sensor_plenum?.length <= 0 || !silo?.sensores?.sensor_plenum?.length)
                    "
                >
                    <mat-select
                        id="select-aeracao"
                        panelClass="selectAeracao"
                        [formControl]="variavelAquecimentoControle"
                        #selectPrograma
                        (selectionChange)="editarVariavel($event.value)"
                    >
                        <mat-option
                            class="text-xs sm:text-base"
                            *ngFor="let variavel of Object.keys(listaVariavelAquecimento); let i = index"
                            [value]="variavel"
                        >
                            {{ listaVariavelAquecimento[variavel] | number : "1.1" : "pt" }} °C
                        </mat-option>
                    </mat-select>
                    <ng-container *ngIf="!historicoSilo">
                        <mat-icon
                            *ngIf="
                                !carregando || campoEmEdicao != tipoCampoEmEdicaoEnum.VARIAVEL_AQUECIMENTO;
                                else carregandoDados
                            "
                            class="ml-2 cursor-pointer"
                            svgIcon="mat_outline:edit"
                            [ngClass]="{
                                'opacity-50 cursor-default': !permissaoAdministrar,
                                'cursor-pointer': permissaoAdministrar
                            }"
                        ></mat-icon>
                    </ng-container>
                </mat-form-field>
                <span
                    *ngIf="
                        historicoSilo &&
                        (silo?.sensores?.sensor_plenum?.length <= 0 || !silo?.sensores?.sensor_plenum?.length)
                    "
                    >{{ variavelAquecimento ? (variavelAquecimento | number : "1.1" : "pt") : "Nenhum" }}</span
                >
                <span *ngIf="silo?.sensores?.sensor_plenum.length > 0">{{ "VISAO_DETALHADA_SILOS_SCREEN.PLENUM" | transloco }}</span>
            </div>
        </div>
    </ng-container>
    <!-- Tabela lista de aeradores -->
    <ng-container #listagemAeradores *ngIf="dadosTabelaInformacoesAeracao.data?.length > 0">
        <div class="componente-tabela mt-2 aeracao flex justify-center flex-col">
            <table
                mat-table
                matSort
                matSortDirection="asc"
                [dataSource]="dadosTabelaInformacoesAeracao"
                class="sm:scale-100 scale-90"
            >
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef class="w-[14.2%] sm:w-15 text-xs sm:text-base">
                        {{ "VISAO_DETALHADA_SILOS_SCREEN.AERADOR" | transloco }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let elemento"
                        class="text-center lg:text-center sm:text-left text-xs sm:text-base"
                    >
                        {{ elemento?.aerador?.codigo ?? "-" }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="cmd">
                    <th mat-header-cell *matHeaderCellDef class="w-[14.2%] sm:w-15">
                        <span
                            class="text-xs sm:text-base"
                            #tooltip="matTooltip"
                            [matTooltip]="'VISAO_DETALHADA_SILOS_SCREEN.TOOLTIP_COMANDO_SISTEMA' | transloco"
                            matTooltipClass="tooltip-tabela-aeracao"
                            matTooltipPosition="above"
                        >
                            {{ "VISAO_DETALHADA_SILOS_SCREEN.CMD" | transloco }}
                        </span>
                    </th>
                    <td mat-cell *matCellDef="let elemento" class="text-left">
                        <app-status-on-off
                            [reduzirWidth]="true"
                            [statusOn]="elemento?.aerador?.aerador_leitura_rele?.valor"
                            [equipamentoComunicando]="
                                buscaEquipamentoStatus(elemento?.aerador?.aerador_leitura_rele?.equipamento_id)
                            "
                            [possuiStatusComunicacao]="true"
                        ></app-status-on-off>
                    </td>
                </ng-container>
                <ng-container matColumnDef="rtn">
                    <th mat-header-cell *matHeaderCellDef class="w-[14.2%] sm:w-15">
                        <span
                            class="text-xs sm:text-base"
                            #tooltip="matTooltip"
                            [matTooltip]="'VISAO_DETALHADA_SILOS_SCREEN.TOOLTIP_RETORNO_AERACAO' | transloco"
                            matTooltipClass="tooltip-tabela-aeracao"
                            matTooltipPosition="above"
                        >
                            {{ "VISAO_DETALHADA_SILOS_SCREEN.RTN" | transloco }}
                        </span>
                    </th>
                    <td mat-cell *matCellDef="let elemento" class="text-left">
                        <app-status-on-off
                            [reduzirWidth]="true"
                            [statusOn]="elemento?.aerador?.aerador_leitura_status?.valor"
                            [equipamentoComunicando]="
                                buscaEquipamentoStatus(elemento?.aerador?.aerador_leitura_status?.equipamento_id)
                            "
                            [possuiStatusComunicacao]="true"
                        ></app-status-on-off>
                    </td>
                </ng-container>
                <ng-container matColumnDef="pnl">
                    <th mat-header-cell *matHeaderCellDef class="w-[14.2%] sm:w-15">
                        <span
                            class="text-xs sm:text-base"
                            #tooltip="matTooltip"
                            [matTooltip]="'VISAO_DETALHADA_SILOS_SCREEN.TOOLTIP_PAINEL_SISTEMA' | transloco"
                            matTooltipClass="tooltip-tabela-aeracao"
                            matTooltipPosition="above"
                        >
                            {{ "VISAO_DETALHADA_SILOS_SCREEN.PNL" | transloco }}
                        </span>
                    </th>
                    <td mat-cell *matCellDef="let elemento" class="text-left">
                        <app-status-automatico-manual
                            [reduzirWidth]="true"
                            [statusAutomatico]="elemento.aerador?.aerador_leitura_chave?.valor"
                            [possuiStatusComunicacao]="true"
                            [equipamentoComunicando]="
                                buscaEquipamentoStatus(elemento?.aerador?.aerador_leitura_chave?.equipamento_id)
                            "
                        ></app-status-automatico-manual>
                    </td>
                </ng-container>
                <ng-container matColumnDef="in_a">
                    <th mat-header-cell *matHeaderCellDef class="w-[14.2%] sm:w-15">
                        <span
                            class="text-xs sm:text-base"
                            #tooltip="matTooltip"
                            [matTooltip]="'VISAO_DETALHADA_SILOS_SCREEN.TOOLTIP_CORRENTE_NOMINAL' | transloco"
                            matTooltipClass="tooltip-tabela-aeracao"
                            matTooltipPosition="above"
                        >
                            {{ "VISAO_DETALHADA_SILOS_SCREEN.IN" | transloco }}
                            ({{ "VISAO_DETALHADA_SILOS_SCREEN.A" | transloco }})
                        </span>
                    </th>
                    <td mat-cell *matCellDef="let elemento" class="text-center sm:text-left text-xs sm:text-base">
                        <ng-container
                            *ngIf="
                                elemento?.aerador?.corrente_nominal ||
                                elemento?.aerador?.aerador_motor?.corrente_nominal
                            "
                        >
                            {{
                                elemento?.aerador?.corrente_nominal
                                    ? (elemento?.aerador?.corrente_nominal | number : "1.1" : "pt")
                                    : (elemento?.aerador?.aerador_motor?.corrente_nominal | number : "1.1" : "pt")
                            }}
                        </ng-container>
                        <ng-container
                            *ngIf="
                                !elemento?.aerador?.corrente_nominal &&
                                !elemento?.aerador?.aerador_motor?.corrente_nominal
                            "
                        >
                            <span
                                [ngClass]="classWidth"
                                class="max-w-10 min-w-10 flex rounded-md h-8 justify-center bg-[#E8E9EA] text-[#030229] text-xs sm:text-base items-center cursor-default"
                            >
                                -
                            </span>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="p_cv">
                    <th mat-header-cell *matHeaderCellDef class="w-[14.2%] sm:w-15">
                        <span
             Z               class="text-xs sm:text-base"
                            #tooltip="matTooltip"
                            [matTooltip]="'VISAO_DETALHADA_SILOS_SCREEN.TOOLTIP_POTENCIA_MECANICA' | transloco"
                            matTooltipClass="tooltip-tabela-aeracao"
                            matTooltipPosition="above"
                        >
                            {{ "VISAO_DETALHADA_SILOS_SCREEN.P" | transloco }}
                            ({{ "VISAO_DETALHADA_SILOS_SCREEN.CV" | transloco }})
                        </span>
                    </th>
                    <td mat-cell *matCellDef="let elemento" class="text-center sm:text-left text-xs sm:text-base">
                        <ng-container
                            *ngIf="
                                elemento?.aerador?.potencia_eletrica ||
                                elemento?.aerador?.aerador_motor?.potencia_eletrica
                            "
                        >
                            {{
                                elemento?.aerador?.potencia_eletrica
                                    ? (elemento?.aerador?.potencia_eletrica | number : "1.1" : "pt")
                                    : (elemento?.aerador?.aerador_motor?.potencia_eletrica | number : "1.1" : "pt")
                            }}
                        </ng-container>
                        <ng-container
                            *ngIf="
                                !elemento?.aerador?.potencia_eletrica &&
                                !elemento?.aerador?.aerador_motor?.potencia_eletrica
                            "
                        >
                            <span
                                [ngClass]="classWidth"
                                class="max-w-10 min-w-10 flex rounded-md h-8 justify-center bg-[#E8E9EA] text-[#030229] text-xs sm:text-base items-center cursor-default"
                            >
                                -
                            </span>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef class="w-[14.2%] sm:w-15 text-xs sm:text-base">
                        {{ "VISAO_DETALHADA_SILOS_SCREEN.STATUS" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let elemento" class="text-left">
                        <app-status-aeracao [statusCode]="elemento?.aerador?.aeracao?.status"></app-status-aeracao>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="tabelaColunas"></tr>
                <tr mat-row *matRowDef="let row; let i = index; columns: tabelaColunas"></tr>
            </table>
            <ng-container *ngIf="valoresPaginacao.length > 10">
                <div class="w-full">
                    <mat-paginator
                        [length]="this.valoresPaginacao.length"
                        [pageSize]="this.valoresPaginacao.pageSize"
                        [pageIndex]="this.valoresPaginacao.pageIndex"
                        (page)="configurarPaginacao($event)"
                        class="pagnator-em-slider bg-[#f8fafb]"
                    >
                    </mat-paginator>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>
<ng-template #carregandoDados>
    <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'" class="ml-2"> </mat-progress-spinner>
</ng-template>
