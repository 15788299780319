import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { produtoNaoInformado } from 'app/shared/constants';
import { IEquilibrioHigroscopicoController } from 'app/shared/controllers';
import { IconeProdutoHelper, VerificaObjetoVazio } from 'app/shared/helpers';

import { EquilibrioHigroscopicoModel } from 'app/shared/models';
import { ICalculaAqplenumService } from 'app/shared/services';
import { Observable } from 'rxjs';

import {
    EstacaoMeteorologicaInterface,
    GraoListaInterface,
    ListaSimplificadaEstruturaArmazenagemInterface,
} from 'app/shared/interfaces';

@Component({
    selector: 'app-equilibrio-higroscopico-listagem',
    templateUrl: './equilibrio-higroscopico-listagem.component.html',
    styleUrls: ['./equilibrio-higroscopico-listagem.component.scss'],
})
export class EquilibrioHigroscopicoListagemComponent implements OnChanges {
    @Input() listagemEstruturaArmazenagem: ListaSimplificadaEstruturaArmazenagemInterface[];
    @Input() listagemEstacoesMeteorologicas: EstacaoMeteorologicaInterface[];
    @Input() trocaEstacao: EstacaoMeteorologicaInterface;
    @Input() carrgandoEstruturas: boolean;
    @Input() visaoArmazem?: boolean;

    estruturaArmazenagemSelecionada: UntypedFormControl = new UntypedFormControl();
    equilibrioHigroscopico$: Observable<EquilibrioHigroscopicoModel>;
    produtoNaoInformado = produtoNaoInformado;
    carregandoDados: boolean = false;
    loadInicial: boolean = true;
    aquecimentoAr: number;
    iconeProduto: string;
    nomeProduto: string;

    verificaObjetoVazio = VerificaObjetoVazio.execute;

    constructor(
        private equilibrioHigroscopicoController: IEquilibrioHigroscopicoController,
        private calculaAqplenumService: ICalculaAqplenumService
    ) {}

    ngOnChanges(): void {
        this.carregandoDados = true;

        if (this.trocaEstacao) {
            if (this.estruturaArmazenagemSelecionada.value?.ultima_leitura?.sensor_plenum_ultima_leitura?.temperatura) {
                this.aquecimentoAr = this.calculaAqplenumService.execute(
                    this.estruturaArmazenagemSelecionada.value.ultima_leitura.sensor_plenum_ultima_leitura.temperatura,
                    this.trocaEstacao?.ultima_leitura?.temperatura
                );
            } else if (this.estruturaArmazenagemSelecionada.value?.aquecimento_ar) {
                this.aquecimentoAr = this.estruturaArmazenagemSelecionada.value.aquecimento_ar;
            }
            if (this.verificaObjetoVazio(this.estruturaArmazenagemSelecionada.value?.produto)) {
                this.nomeProduto = produtoNaoInformado;
            } else {
                if (this.trocaEstacao?.ultima_leitura?.validacao_leitura?.status && !this.loadInicial) {
                    this.buscarInformacoesEquilibrioHigroscopico(
                        this.trocaEstacao.id,
                        this.trocaEstacao.tipo,
                        this.estruturaArmazenagemSelecionada.value?.produto.produto_tipo_id
                    );
                }
            }

            if (!this.trocaEstacao?.ultima_leitura?.validacao_leitura?.status && !this.loadInicial) {
                this.carregandoDados = false;
            }
        }

        if (this.loadInicial) {
            if (this.listagemEstruturaArmazenagem.length > 0) {
                this.estruturaArmazenagemSelecionada.setValue(this.listagemEstruturaArmazenagem[0]);
                this.aquecimentoAr = this.estruturaArmazenagemSelecionada.value.aquecimento_ar;
                if (this.estruturaArmazenagemSelecionada.value?.produto.produto_tipo_id) {
                    this.selecionaGrao(this.estruturaArmazenagemSelecionada.value.produto);
                }
                if (this.verificaObjetoVazio(this.estruturaArmazenagemSelecionada.value?.produto)) {
                    this.nomeProduto = produtoNaoInformado;
                }
                this.loadInicial = false;
            }
        }
    }

    trocaEstruturaArmazenagem({ value }): void {
        this.aquecimentoAr = value.aquecimento_ar;
        if (this.verificaObjetoVazio(this.estruturaArmazenagemSelecionada.value?.produto)) {
            this.nomeProduto = produtoNaoInformado;
        } else {
            this.nomeProduto = value.produto?.nome;
            this.iconeProduto = this.selecionaIconeProduto(this.nomeProduto);
            if (this.trocaEstacao?.ultima_leitura?.validacao_leitura?.status) {
                this.buscarInformacoesEquilibrioHigroscopico(
                    this.trocaEstacao.id,
                    this.trocaEstacao.tipo,
                    value.produto.produto_tipo_id
                );
            }
        }
    }

    temEstacaoAtiva(): boolean {
        return this.listagemEstacoesMeteorologicas?.some((e) => e.ultima_leitura);
    }

    private selecionaGrao(graoSelecionado: GraoListaInterface): void {
        this.nomeProduto = graoSelecionado.nome;
        this.iconeProduto = this.selecionaIconeProduto(this.nomeProduto);
        if (this.trocaEstacao?.ultima_leitura?.validacao_leitura?.status) {
            this.buscarInformacoesEquilibrioHigroscopico(
                this.trocaEstacao.id,
                this.trocaEstacao.tipo,
                graoSelecionado.produto_tipo_id
            );
        }
    }

    private selecionaIconeProduto(nomeProduto: string): string {
        return IconeProdutoHelper.selecionaIconeProduto(nomeProduto);
    }

    private buscarInformacoesEquilibrioHigroscopico(
        idEstacao: string,
        tipoEstacao: string,
        produtoTipoId: number
    ): void {
        this.equilibrioHigroscopico$ = this.equilibrioHigroscopicoController.buscarEquilibrioHigroscopico(
            idEstacao,
            tipoEstacao,
            produtoTipoId,
            this.aquecimentoAr ?? 2,
            this.estruturaArmazenagemSelecionada.value?.ultima_leitura?.sensor_plenum_ultima_leitura.temperatura,
            this.estruturaArmazenagemSelecionada.value?.ultima_leitura?.sensor_plenum_ultima_leitura.umidade
        );
    }
}
