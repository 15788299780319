import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusComunicacaoTooltipSimplesComponent } from './status-comunicacao-tooltip-simples.component';

@NgModule({
    imports: [CommonModule, MatIconModule, MatTooltipModule],
    declarations: [StatusComunicacaoTooltipSimplesComponent],
    exports: [StatusComunicacaoTooltipSimplesComponent],
})
export class StatusComunicacaoTooltipSimplesModule {}
