import { Injectable } from '@angular/core';
import { ObjetoGenericoParaSelectModel } from 'app/shared/models';
import { RetornaIndexArrayService } from 'app/shared/services';
export abstract class IControleTrocaSiloService {
    abstract voltarSiloAnterior(controleSilo: any, listaSilos: ObjetoGenericoParaSelectModel[]): any;
    abstract voltarPrimeiroSilo(controleSilo: any, listaSilos: ObjetoGenericoParaSelectModel[]): any;
    abstract avancarProximoSilo(controleSilo: any, listaSilos: ObjetoGenericoParaSelectModel[]): any;
    abstract avancarUltimoSilo(controleSilo: any, listaSilos: ObjetoGenericoParaSelectModel[]): any;
    abstract desabilitaBotoesSilosAnteriores(id: any, listaSilos: ObjetoGenericoParaSelectModel[]): boolean;
    abstract desabilitaBotoesProximosSilos(id: any, listaSilos: ObjetoGenericoParaSelectModel[]): boolean;
}

@Injectable({ providedIn: 'root' })
export class ControleTrocaSiloService implements IControleTrocaSiloService {
    public voltarSiloAnterior(controleSilo: any, listaSilos: ObjetoGenericoParaSelectModel[]): any {
        const posicaoAtualSilo = RetornaIndexArrayService.prototype.retornaIndex(controleSilo.value.id, listaSilos);
        if (posicaoAtualSilo > 0) {
            controleSilo.setValue(listaSilos[posicaoAtualSilo - 1]);
            return listaSilos[posicaoAtualSilo - 1].id;
        }
    }

    public voltarPrimeiroSilo(controleSilo: any, listaSilos: ObjetoGenericoParaSelectModel[]): any {
        const posicaoAtualSilo = RetornaIndexArrayService.prototype.retornaIndex(controleSilo.value.id, listaSilos);
        if (posicaoAtualSilo > 0) {
            controleSilo.setValue(listaSilos[0]);
            return listaSilos[0].id;
        }
    }

    public avancarProximoSilo(controleSilo: any, listaSilos: ObjetoGenericoParaSelectModel[]): any {
        const posicaoAtualSilo = RetornaIndexArrayService.prototype.retornaIndex(controleSilo.value.id, listaSilos);
        if (posicaoAtualSilo < listaSilos.length - 1) {
            controleSilo.setValue(listaSilos[posicaoAtualSilo + 1]);
            return listaSilos[posicaoAtualSilo + 1].id;
        }
    }

    public avancarUltimoSilo(controleSilo: any, listaSilos: ObjetoGenericoParaSelectModel[]): any {
        const posicaoAtualSilo = RetornaIndexArrayService.prototype.retornaIndex(controleSilo.value.id, listaSilos);
        if (posicaoAtualSilo < listaSilos.length - 1) {
            controleSilo.setValue(listaSilos[listaSilos.length - 1]);
            return listaSilos[listaSilos.length - 1].id;
        }
    }

    public desabilitaBotoesSilosAnteriores(id: any, listaSilos: ObjetoGenericoParaSelectModel[]): boolean {
        const posicaoAtualSilo = RetornaIndexArrayService.prototype.retornaIndex(id, listaSilos);
        if (posicaoAtualSilo === 0) {
            return true;
        }
        if (posicaoAtualSilo > 0) {
            return false;
        }
    }

    public desabilitaBotoesProximosSilos(id: any, listaSilos: ObjetoGenericoParaSelectModel[]): boolean {
        const posicaoAtualSilo = RetornaIndexArrayService.prototype.retornaIndex(id, listaSilos);
        if (posicaoAtualSilo === listaSilos.length - 1) {
            return true;
        }
        if (posicaoAtualSilo < listaSilos.length - 1) {
            return false;
        }
    }
}
