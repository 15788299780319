<ng-container *ngIf="exibirTooltip">
    <app-tooltip-pendulo-historico *ngIf="historico" [historico]="historico"> </app-tooltip-pendulo-historico>
</ng-container>

<canvas
    #selector
    class="plain-silo-view mx-auto"
    style="z-index: 11; position: relative"
    [attr.data-config]="config"
    [attr.data-cables]="cables"
    [attr.data-level]="level"
    [attr.data-thermal]="thermal"
    [attr.data-humidity]="humidity"
    [attr.data-emc]="emc"
    [attr.data-selected-map]="selected"
    [attr.data-thermocouples]="thermocouples"
    [attr.data-communicatingCables]="communicatingCables"
    [attr.data-alias]="alias"
    [attr.data-cable-order]="ordenacaoPendulos"
>
</canvas>
<ng-container *ngIf="exibirAeradores && possuiAeracaoAutomatica">
    <div class="inset-0">
        <app-aeradores-visao-silo [listaAeradores]="listaAeradores"  [aeracao]="aeracao"></app-aeradores-visao-silo>
    </div>
</ng-container>
