import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { BANDEIRA_PAISES } from "app/shared/constants/bandeira-paises.constant";
import { BandeiraPaisInterface } from "app/shared/interfaces";

@Component({
  selector: "input-telefone-pais",
  templateUrl: "./input-telefone-pais.component.html",
})
export class InputTelefonePaisComponent implements OnInit {
  @Output() atualizaNumeroTelefone: EventEmitter<string> = new EventEmitter();
  @Input() numeroTelefone?: string;
  @Input() label: string;

  controleInputTelefone: UntypedFormControl = new UntypedFormControl();
  bandeiraPaises: BandeiraPaisInterface[] = BANDEIRA_PAISES;
  paisSelecionado: BandeiraPaisInterface;

  ngOnInit(): void {
    this.setaValorInicial();

    this.controleInputTelefone.valueChanges.subscribe((valor) => {
      if (valor) {
        this.atualizaNumeroTelefone.emit(
          this.paisSelecionado.codigo + " " + valor.trim()
        );
      }
    });
  }

  apenasNumerosValidos(evento: KeyboardEvent): void {
    const numerosValidos = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    if (
      (evento.key !== "Backspace" &&
        evento.key !== "Delete" &&
        evento.key !== "ArrowLeft" &&
        evento.key !== "ArrowRight" &&
        !numerosValidos.includes(Number(evento.key))) ||
      evento.which == 32
    ) {
      evento.preventDefault();
    }
  }

  private setaValorInicial(): void {
    if (!this.numeroTelefone || !this.numeroTelefone.includes("+")) {
      this.paisSelecionado = this.retornaPaisPeloIso("br");
    } else {
      const ddi = this.numeroTelefone.split(" ")[0];
      const numero = this.numeroTelefone.split(" ")[1];

      if (numero) {
        this.controleInputTelefone.setValue(numero);
      }

      if (ddi) {
        this.paisSelecionado = this.retornaPaisPeloDdi(ddi);
      } else {
        this.paisSelecionado = this.retornaPaisPeloDdi("+55");
      }
    }
  }

  private retornaPaisPeloDdi(ddi: string): BandeiraPaisInterface {
    return this.bandeiraPaises.find((pais) => pais.codigo === ddi);
  }

  private retornaPaisPeloIso(iso: string): BandeiraPaisInterface {
    return this.bandeiraPaises.find((pais) => pais.iso === iso);
  }

  selecionaPaisPelaBandeira(pais: BandeiraPaisInterface): void {
    this.paisSelecionado = pais;
    this.controleInputTelefone.reset();
  }
}
