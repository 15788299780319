import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DataUltimaComunicacaoComponent } from './data-ultima-comunicacao.component';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { ModalEquipamentosFullscreenModule } from 'app/shared/components/modal-equipamentos-fullscreen/modal-equipamentos-fullscreen.module';

@NgModule({
    declarations: [DataUltimaComunicacaoComponent],
    imports: [
        CommonModule, 
        MatIconModule, 
        MatTooltipModule, 
        TranslocoRootModule, 
        ModalEquipamentosFullscreenModule
    ],
    exports: [DataUltimaComunicacaoComponent],
})
export class DataUltimaComunicacaoModule {}
