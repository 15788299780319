import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { delay, take } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IComandoAeracaoAutomaticaController } from 'app/shared/controllers';
import { HistoricoComandoAeracaoColunasEnum, StatusPilhaComandoAeracaoEnum } from 'app/shared/enums';
import { HistoricoComandoAeracaoInterface } from 'app/shared/interfaces';
import { ApiPaginacao } from 'app/shared/models';
import { IConfigurarStatusAeracaoService } from 'app/shared/services';

@Component({
    selector: 'app-modal-historico-comando-aeracao',
    templateUrl: './modal-historico-comando-aeracao.component.html',
    styleUrls: ['./modal-historico-comando-aeracao.component.scss'],
})
export class ModalHistoricoComandoAeracaoComponent implements OnInit {
    Object = Object;
    statusPilhaComandoAeracaoEnum = StatusPilhaComandoAeracaoEnum;

    tabelaColunas: string[] = [
        HistoricoComandoAeracaoColunasEnum.INCLUSAO,
        HistoricoComandoAeracaoColunasEnum.ATUALIZACAO,
        HistoricoComandoAeracaoColunasEnum.TIPO,
        HistoricoComandoAeracaoColunasEnum.ESTAGIO,
        HistoricoComandoAeracaoColunasEnum.OPERACAO,
        HistoricoComandoAeracaoColunasEnum.USUARIO,
        HistoricoComandoAeracaoColunasEnum.RESULTADO,
        HistoricoComandoAeracaoColunasEnum.DETALHES,
    ];

    paginacao = new ApiPaginacao();

    carregando: boolean = true;
    carregandoDadosAdicionais: boolean = false;    

    historico: HistoricoComandoAeracaoInterface[] = [];

    constructor(
        public readonly dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: any,
        private ComandoAeracaoAutomaticaController: IComandoAeracaoAutomaticaController,
        private configurarStatusAeracaoService: IConfigurarStatusAeracaoService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.paginacao.limit = 10;
        this.paginacao.page = 1;
        this.buscarHistorico();
    }

    ngAfterOnInit(): void {
        this.changeDetectorRef.detectChanges();
    }

    onScroll(): void {
        if (this.paginacao.page == this.paginacao.length) return;

        this.paginacao.page += 1;
        this.buscarHistorico(true);
    }
    
    buscarHistorico(dadosAdicionais?: boolean): void {
        if (dadosAdicionais) this.carregandoDadosAdicionais = true; else this.carregando = true;                    
        this.ComandoAeracaoAutomaticaController.buscarHistorico(this.data.armazenagemRef, this.paginacao)
            .pipe(
                take(1),                
                delay(1000)
            )
            .subscribe({
                next: (historico) => {                    
                    historico.data.forEach((elemento) => {
                        let updatedElemento = {
                            status_aeracao: this.configurarStatusAeracaoService.execute(elemento.aeracao.status),
                            ...elemento,
                        };                        
                        this.historico = [...this.historico, updatedElemento];
                    });
                    this.paginacao.length = historico.meta.totalPages;                                                                                                  
                },
                error: (erro) => {
                    console.log(erro);                                        
                }, 
                complete: () => {                    
                    if (dadosAdicionais) this.carregandoDadosAdicionais = false; else this.carregando = false;                                
                },
            });
    }
}
