import { IdiomaSiglaEnum } from 'app/shared/enums';
import { CookieHelper } from 'app/shared/helpers';
import * as moment from 'moment';


export function carregarOpcoesPersonalizadas() {
  const idioma = CookieHelper.carregarCookie('idioma')
  switch (idioma) {
    case IdiomaSiglaEnum.INGLES:
      return {
        ['Today']: [moment().hours(0).minute(0), moment()],
        ['Yesterday']: [
          moment().subtract(1, 'days').hours(0).minute(0),
          moment().subtract(1, 'days').hours(23).minute(59),
        ],
        ['Last 24 hours']: [moment().subtract(24, 'hours'), moment()],
        ['This week']: [moment().day(0).hours(0).minute(0), moment()],
        ['Last 7 days']: [moment().subtract(6, 'days'), moment()],
        ['Last 10 days']: [moment().subtract(9, 'days'), moment()],
        ['This month']: [moment().startOf('month').hours(0).minute(0), moment()],
        ['Last 30 days']: [moment().subtract(29, 'days'), moment()],
        ['Last 3 months']: [moment().subtract(90, 'days'), moment()],
        ['This year']: [moment().startOf('year').hours(0).minute(0), moment()],
        ['Last year']: [
          moment().startOf('year').hours(0).minute(0).subtract(1, 'year'),
          moment().endOf('year').hours(23).minute(59).subtract(1, 'year')
        ]
      }; 
    case IdiomaSiglaEnum.ESPANHOL:
      return {
        ['Hoy']: [moment().hours(0).minute(0), moment()],
        ['Ayer']: [
          moment().subtract(1, 'days').hours(0).minute(0),
          moment().subtract(1, 'days').hours(23).minute(59),
        ],
        ['Últimas 24 horas']: [moment().subtract(24, 'hours'), moment()],
        ['Esta semana']: [moment().day(0).hours(0).minute(0), moment()],
        ['Últimos 7 días']: [moment().subtract(6, 'days'), moment()],
        ['Últimos 10 días']: [moment().subtract(9, 'days'), moment()],
        ['Este mes']: [moment().startOf('month').hours(0).minute(0), moment()],
        ['Últimos 30 días']: [moment().subtract(29, 'days'), moment()],
        ['Últimos 3 meses']: [moment().subtract(90, 'days'), moment()],
        ['Este año']: [moment().startOf('year').hours(0).minute(0), moment()],
        ['Año pasado']: [
          moment().startOf('year').hours(0).minute(0).subtract(1, 'year'),
          moment().endOf('year').hours(23).minute(59).subtract(1, 'year')
        ]
      };
    default:
      return {
        ['Hoje']: [moment().hours(0).minute(0), moment()],
        ['Ontem']: [
          moment().subtract(1, 'days').hours(0).minute(0),
          moment().subtract(1, 'days').hours(23).minute(59),
        ],
        ['Últimas 24 horas']: [moment().subtract(24, 'hours'), moment()],
        ['Esta semana']: [moment().day(0).hours(0).minute(0), moment()],
        ['Últimos 7 dias']: [moment().subtract(6, 'days'), moment()],
        ['Últimos 10 dias']: [moment().subtract(9, 'days'), moment()],
        ['Este mês']: [moment().startOf('month').hours(0).minute(0), moment()],
        ['Últimos 30 dias']: [moment().subtract(29, 'days'), moment()],
        ['Últimos 3 meses']: [moment().subtract(90, 'days'), moment()],
        ['Este ano']: [moment().startOf('year').hours(0).minute(0), moment()],
        ['Ano passado']: [
          moment().startOf('year').hours(0).minute(0).subtract(1, 'year'),
          moment().endOf('year').hours(23).minute(59).subtract(1, 'year')
        ]
      };
  }

}
